import { useState } from 'react';

const CustomerTitle = ({ title, description }) => {
  return (
    <div className='title-wrap'>
      <div className='title'>
        <span>{title}</span>
        <div className='circle'></div>
      </div>
      <p className='description'>{description}</p>
    </div>
  );
};

export default CustomerTitle;
