import { Link } from 'react-router-dom';
import CommonSiteMap from './CommonSiteMap';

const CustomerHead = ({ step }) => {
  return (
    <>
      <div className='customer-head'>
        <CommonSiteMap title='고객지원' />
        <h2>고객지원</h2>
        <ul className='tap'>
          <li className={step === '공지사항' ? 'active' : ''}>
            <Link to='/notice'>공지사항</Link>
          </li>
          <li className={step === 'FAQ' ? 'active' : ''}>
            <Link to='/faq'>FAQ</Link>
          </li>
          <li className={step === '1:1 문의' ? 'active' : ''}>
            <Link to='/inquiry'>1:1 문의</Link>
          </li>
          <li className={step === '환불안내' ? 'active' : ''}>
            <Link to='/refund'>환불안내</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CustomerHead;
