import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import close from 'image/Mypage/my-page-popup-close.svg';
import { applicantListFunc } from 'js/selfAPI';
import { catchError } from 'js/commonFn';

const PatentView = ({ setPatentModal, appCodeList }) => {
  const [appData, setAppData] = useState([]);
  const navigate = useNavigate();

  const getAppList = async () => {
    const result = await applicantListFunc(appCodeList);
    if (typeof result === 'object') {
      setAppData(result?.data);
    } else return catchError(result, navigate);
  };

  useEffect(() => {
    getAppList();
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <>
      <div className='modal-overlay patent-view'>
        <div className='modal'>
          <div className='head'>
            <h4>특허고객번호별 출원 상표 내역</h4>
            <img
              src={close}
              alt='특허고객번호별 출원 상표 내역 닫기'
              onClick={() => setPatentModal(false)}
            />
          </div>
          {appData?.length > 0 ? (
            appData?.map((item, idx) => {
              return (
                <>
                  <div className='body' key={idx}>
                    <span>특허고객번호 : {item?.applicant_code}</span>
                    <div className='table-wrap'>
                      <div className='table-head'>
                        <div>No</div>
                        <div>상표명</div>
                        <div>대표 출원인</div>
                        <div>특허고객번호</div>
                        <div>출원번호</div>
                        <div>출원일자</div>
                        <div>등록번호</div>
                        <div>등록일자</div>
                      </div>
                      {item?.information?.map((item2, idx2) => {
                        return (
                          <div className='table-body'>
                            <div>{idx2 + 1}</div>
                            <div>{item2?.title}</div>
                            <div>{item2?.applicantName}</div>
                            <div>{item?.applicant_code}</div>
                            <div>{item2?.applicationNumber}</div>
                            <div>{item2?.applicationDate?.split('T')[0]}</div>
                            <div>{item2?.registrationNumber}</div>
                            <div>{item2?.registrationDate?.split('T')[0]}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <hr className='line' />
                </>
              );
            })
          ) : (
            <>
              <div className='loader-wrap'>
                <div className='loader'></div>
                <p>정보를 불러오는 중입니다.</p>
                <p>잠시만 기다려주세요.</p>
              </div>
            </>
          )}

          <div className='btn-wrap'>
            <button onClick={() => setPatentModal(false)}>닫기</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatentView;
