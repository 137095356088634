import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPassword } from 'js/selfAPI';
import { catchError } from 'js/commonFn';
import close from 'image/Modal/modal-close.svg';

const MyPageUserInfoModal = ({
  setUserInfoModal,
  setCurPassword,
  curPassword,
  setUserInfoOn,
  userId,
  userType,
  setUserType,
  setUserDeleteModal,
}) => {

  const navigate = useNavigate()

  useEffect(() => {
    // 모달 창이 열릴 때, body 요소의 스크롤을 막습니다.
    document.body.style.overflow = 'hidden';

    // 모달 창이 닫힐 때, 스크롤을 다시 허용합니다.
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const passwordOk = async () => {
    const result = await checkPassword(userId, curPassword);
    if (typeof result === 'object') {
      if (userType === 'email') {
        setUserInfoModal(false);
        setUserInfoOn(true);
      } else if (userType === 'withdrawal') {
        setUserInfoModal(false);
        setUserDeleteModal(true);
      }
      //= 유저타입 초기화 -> 유저타입이 있는경우는 각각 다른 기능을 작동하기 위함
      setUserType('');
    } else return catchError(result, navigate)
  };

  return (
    <>
      <div className='modal-overlay user-pw-info'>
        <div className='modal'>
          <div className='head'>
            <p>비밀번호 재확인</p>
            <button onClick={() => setUserInfoModal(false)}>
              <img src={close} alt='임시저장 모달창 닫기 버튼' />
            </button>
          </div>
          <div className='txt-wrap'>
            개인 정보 보호를 위해 회원님의 비밀번호를 다시 한 번 확인합니다.
            <br />
            비밀번호는 타인에게 노출되지 않도록 주의해 주세요.
          </div>
          <div className='pw-input-wrap'>
            <span>비밀번호 :</span>
            <input
              value={curPassword}
              type='password'
              placeholder='비밀번호를 입력하세요.'
              autoComplete='off'
              onChange={e => setCurPassword(e.target.value)}
              onKeyDown={e => (e.keyCode === 13 ? passwordOk() : '')}
            />
          </div>
          <div className='user-btn-wrap'>
            <button
              className='ok'
              onClick={() => {
                passwordOk();
              }}>
              확인
            </button>
            <button
              className='close'
              onClick={() => {
                setUserInfoModal(false);
                setCurPassword('');
              }}>
              취소
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPageUserInfoModal;
