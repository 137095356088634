const PaymentSuccess = ({
  priceArr,
  typeArr,
  application,
  set2Arr,
  totalPrice,
  paymentMethod,
  paymentInfo,
  returnTypeList,
}) => {
  const duplicationCheck = type => {
    const result = {};
    application.forEach(x => {
      result[x] = (result[x] || 0) + 1;
    });
    return result[type];
  };

  const renderTableBody = () => {
    return set2Arr.map((i, idx) => {
      return (
        <tr>
          <td>
            {set2Arr[idx] === 'NORMAL'
              ? '일반 출원'
              : set2Arr[idx] === 'FAST'
              ? '신속 출원'
              : set2Arr[idx] === 'SEARCH'
              ? '조사 출원'
              : '신속조사 출원'}
          </td>
          <td>{returnTypeList(i, 'str').replaceAll(' | ', ', ')}</td>
          <td>{paymentMethod === 'card' ? '신용카드' : '무통장입금'}</td>
          <td>{duplicationCheck(set2Arr[idx])}</td>
          <td className='black'>{priceArr[idx].toLocaleString()}원</td>
        </tr>
      );
    }, <></>);
  };

  return (
    <div className='column payment-check'>
      <h2 className='guide-context'>
        마크픽에서
        <span> 등록한 내역</span>
        {/* <span> 결제한 내역</span> */}
        을<br />
        안내해 드립니다.
      </h2>
      <p className='guide-comment first'>
        <span className='user-id'>{paymentInfo?.buyer_name}</span>님,
        반갑습니다.
      </p>
      <p className='guide-comment'>
        마크픽을 이용해 주셔서 감사합니다.
        <br />
        {/* 고객님께서 결제하신 내역을 안내 드립니다. */}
        {/* <br /> */}
        {/* 결제하신 서비스는 마이페이지에서 자세하게 확인하실 수 있습니다. */}
        등록을 신청하신 내역은 마이페이지에서 자세하게 확인하실 수 있습니다.
        <br />
        하단의 무통장입금 계좌 정보로 무통장입금이 이루어지면 출원 의뢰가
        완료됩니다.
      </p>
      <h4 className='payment'>결제정보</h4>
      <hr />
      <div className='row price'>
        <span>총 상품 금액 ({application?.length}건)</span>
        <span>{totalPrice?.toLocaleString()}원</span>
      </div>
      <hr />
      <div className='row price'>
        {/* <span>총 결제 금액</span> */}
        <span>결제 대기 금액</span>
        <span>{totalPrice?.toLocaleString()}원</span>
      </div>
      <div className='row price pay-method'>
        <span>{paymentMethod === 'card' ? '신용카드' : '무통장 입금'}</span>
        <span>{totalPrice?.toLocaleString()}원</span>
      </div>
      <hr />
      <h4 className='purchase'>구입내역</h4>
      <table>
        <thead>
          <tr>
            <th>분류</th>
            <th>류</th>
            <th>구매방법</th>
            <th>개수</th>
            <th>가격</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </table>
      <h4>무통장입금 계좌 정보</h4>
      <table>
        <thead>
          <tr>
            <th>입금 은행</th>
            <th>계좌번호</th>
            <th>예금주</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>우리은행</td>
            <td>1005-003-376009</td>
            <td>정상일 (해율특허법률사무소)</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentSuccess;
