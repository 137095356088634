import { useState, useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
//# 컴포넌트
import CustomerHead from 'components/CustomerHead';
import CustomerTitle from 'components/CustomerTitle';
//! 이미지(SVG)
import { catchError, changeTitle } from 'js/commonFn';
import { createInquiry } from 'js/selfAPI';
import { getCookie } from 'js/cookie';

const Refund = () => {
  //# 글자수 관련 state
  const [textCount, setTextCount] = useState({ title: 0, context: 0 });
  //# 1:1문의글 작성 관련 state
  const [textParams, setTextParams] = useState({
    title: '',
    context: '',
    service_code: 100,
  });
  //# 알림창 관련 state
  const [alertMsg, setAlertMsg] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    changeTitle('마크픽 > 1:1 문의');
  }, []);

  const postInquiry = async () => {
    if (getCookie('pickMyToken') === undefined) {
      alert('로그인후 시도해 주세요.');
      return navigate('/sign-in');
    }
    if (textParams.title.trim() === '') {
      $('.i-data-title').focus();
      return alert('제목을 입력해 주세요.');
    } else if (textParams.context.trim() === '') {
      $('.i-data-context').focus();
      return alert('내용을 입력해 주세요.');
    }
    let result;
    const confirm = window.confirm(
      '1:1 문의를 등록하시겠습니까?\n등록 후 마이페이지로 이동됩니다.'
    );
    if (confirm) {
      result = await createInquiry(textParams);
    } else {
      return;
    }
    if (typeof result === 'object') {
      if (confirm) {
        //입력값 초기화
        $('.i-data-title,.i-data-context').val('');
        setTextParams({ title: '', context: '' });
        navigate('/my-page');
      }
    } else return catchError(result, navigate)
  };

  return (
    <div className='content-customer-wrap'>
      <CustomerHead step='환불안내' />
      <div className='customer refund'>
        <CustomerTitle
          title='환불안내'
          description='마크픽이 제공하는 상표출원 서비스의 환불 규정 안내입니다.'
        />
        <ul className='content-list'>
          <li>
            <strong>- 100% 환불되는 경우</strong>
            <hr />
            <ul>
              <li>• 당사 담당 변리사와 상담 진행 전 환불을 요청한 경우</li>
              <li>
                • 당사 담당 변리사와 상담 후 상표조사를 진행하지 않고 환불을
                요청한 경우
              </li>
              <li>
                • 동일상표가 발견되어 상표조사 및 출원을 진행하지 않고 환불을
                요청한 경우
              </li>
            </ul>
          </li>
          <li>
            <strong>- 출원료만 환불되는 경우</strong>
            <hr />
            <ul>
              <li>
                • 조사출원 및 신속조사출원의 경우 상표조사 시작 이후 환불을
                요청한 경우
              </li>
            </ul>
          </li>
          <li>
            <strong>- 관납료만 환불되는 경우</strong>
            <hr />
            <ul>
              <li>• 특허청 출원서 제출 전까지 환불을 요청한 경우</li>
            </ul>
          </li>
          <li>
            <strong>- 환불이 불가한 경우</strong>
            <hr />
            <ul>
              <li>• 특허청 출원서 제출 후</li>
            </ul>
          </li>
        </ul>
        <p>
          * 특허청 상표등록 심사결과가 거절 결정이 되더라도 별도의 환불금은
          없습니다.
        </p>
      </div>
    </div>
  );
};

export default Refund;
