import IntroTitle from 'components/Introduction/IntroTitle';
const IntroSurvey = () => {
  return (
    <>
      <IntroTitle title='조사출원' />
      <p className='self-service-disc survey'>
        온라인으로 간편하게 신청하고 상표 등록 가능성 조사 및 등록 가능성을 확인
        후에 상표 출원 절차를 대행해 드립니다.
      </p>
      <IntroTitle title='이용방법' />
      <ul className='utilization-wrap'>
        <li>
          <div className='base'>
            <span>Step 1</span>
          </div>
          <div className='text-wrap'>회원가입</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 2</span>
          </div>
          <div className='text-wrap'>상표 출원 신청 및 결제</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 3</span>
          </div>
          <div className='text-wrap'>전문 조사 의뢰</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 4</span>
          </div>
          <div className='text-wrap'>상담 전화</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 5</span>
          </div>
          <div className='text-wrap'>출원인 최종 확인</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 6</span>
          </div>
          <div className='text-wrap'>출원 완료</div>
        </li>
      </ul>
      <IntroTitle title='서비스 비용' />
      <table className='survey-table'>
        <thead>
          <tr>
            <th colSpan={2} className='th-first'></th>
            <th>문자상표</th>
            <th>도형상표</th>
            <th>
              복합상표
              <br />
              <span>(도형+문자결합)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2} className='first'>
              당사
            </td>
            <td className='two'>수수료</td>
            <td className='three'>100,000원</td>
            <td className='three'>130,000원</td>
            <td className='three'>170,000원</td>
          </tr>
          <tr>
            <td className='two'>부가세</td>
            <td className='three'>10,000원</td>
            <td className='three'>13,000원</td>
            <td className='three'>17,000원</td>
          </tr>
          <tr>
            <td className='four'>특허청</td>
            <td className='five'>출원 관납료</td>
            <td colSpan={3}>
              56,000원
              <p className='guide-red'>(*비 고시 명칭 상품으로 출원 진행 시 6,000원 추가비용 발생)</p>
            </td>
          </tr>
          <tr className='last'>
            <td colSpan={2}>합계</td>
            <td className='three'>166,000원</td>
            <td className='three'>199,000원</td>
            <td className='three'>243,000원</td>
          </tr>
        </tbody>
      </table>
      <IntroTitle title='유의사항' />
      <ul className='utilization-wrap'>
        <li>
          <div className='base'>
            <span>1</span>
          </div>
          <div className='text-wrap'>
            1개 상표, 1개 분류에 한 명의 권리자가 출원하는 기준의 비용입니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>2</span>
          </div>
          <div className='text-wrap'>
            조사 의뢰 후에는 조사 비용 환불이 불가합니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>3</span>
          </div>
          <div className='text-wrap'>
            조사 결과 등록 가능성이 낮은 경우 동일 유형에서 1회 재조사가
            가능합니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>4</span>
          </div>
          <div className='text-wrap'>
            특허청 출원 진행 요청 후에는 출원 비용 환불이 불가합니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>5</span>
          </div>
          <div className='text-wrap'>
            공동출원, 미성년자, 외국인, 국가기관인 경우 출원 시와 등록 시
            추가요금이 발생합니다.
          </div>
        </li>
      </ul>
    </>
  );
};

export default IntroSurvey;
