import { useState } from 'react';
import modalClose from 'image/Modal/modal-close.svg';
import { selectSelfSearch } from 'js/selfAPI';
import { searchProduct } from 'js/agenciesAPI';
import { enterFn } from 'js/commonFn';

const RegiProductSearchModal = ({
  page,
  typeFilter,
  setModal,
  setModalType,
  selectProduct,
  setSelectProduct,
}) => {
  const [input, setInput] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(prevSelectAll => !prevSelectAll);
    setSearchData(prevSearchData =>
      prevSearchData.map(item => ({
        ...item,
        selected: !selectAll,
      }))
    );
  };

  const toggleItemSelection = idx => {
    setSearchData(prevSearchData =>
      prevSearchData.map((item, index) => {
        if (index === idx) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      })
    );
  };

  const searchFunc = async () => {
    if (input.trim() === '') {
      return alert('검색어를 입력해 주세요.');
    }

    let result;
    if (page === 'agencies') result = await searchProduct(typeFilter, input);
    else {
      let number;
      if (typeFilter) {
        number = parseInt(typeFilter, 10); // "류" 앞의 숫자를 정수로 변환
      }
      result = await selectSelfSearch(input, number);
    }

    if (
      Array.isArray(
        page === 'agencies'
          ? result?.data?.product_name
          : result?.data?.product_name
      )
    ) {
      if (
        page === 'agencies'
          ? result?.data?.product_name?.length === 0
          : result?.data?.length === 0
      ) {
        return alert('상품 키워드에 해당하는 추천상품이 존재하지 않습니다.');
      }
      let arr;
      if (page === 'agencies') {
        arr = result?.data?.product_name;
      } else {
        arr = result?.data?.product_name?.slice(0, 10);
      }
      const formattedResult = arr.map(item => ({
        name: item,
        selected: false,
      }));
      setSearchData(formattedResult || []);
    } else {
      setSearchData([]);
    }
  };

  const selectAllChecked = searchData.every(item => item.selected);
  const indeterminate =
    searchData.some(item => item.selected) && !selectAllChecked;

  return (
    <div className='modal-bg'>
      <div className='modal column'>
        <div className='head'>
          <h3>등록대상상품 검색</h3>
          <img
            src={modalClose}
            alt='직점검색창 닫기'
            onClick={() => setModal(false)}
            className='close-btn'
          />
        </div>
        <hr />
        <div className='row input-section search'>
          <span>상품 :</span>
          <input
            type='text'
            className='common-text-input'
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={e => enterFn(e, searchFunc)}
          />

          <button className='search-btn' onClick={searchFunc}>
            검색
          </button>
        </div>

        {searchData.length > 0 && (
          <>
            <div className='input-table-wrap'>
              <div className='t-head'>
                <div className='input-wrap'>
                  <label>
                    <input
                      type='checkbox'
                      className='checkbox-input'
                      checked={selectAllChecked}
                      onChange={toggleSelectAll}
                      ref={input => {
                        if (input) {
                          input.indeterminate = indeterminate;
                        }
                      }}
                    />
                    <span className='checkbox-custom'></span>
                  </label>
                </div>
                <div className='content'>상품</div>
              </div>
              {searchData.map((item, idx) => (
                <div className='t-body' key={idx}>
                  <div className='input-wrap'>
                    <label>
                      <input
                        type='checkbox'
                        className='checkbox-input'
                        checked={item.selected}
                        onChange={() => toggleItemSelection(idx)}
                      />
                      <span className='checkbox-custom'></span>
                    </label>
                  </div>
                  <div className='content'>{item.name}</div>
                </div>
              ))}
            </div>

            <div className='btn-wrap row'>
              <button
                className='gradient-btn'
                onClick={() => {
                  const selectedProducts = searchData
                    .filter(item => item.selected)
                    .map(item => item.name);
                  if (selectedProducts.length === 0) {
                    return alert('선택된 상품이 없습니다.');
                  }
                  let difference = selectProduct.filter(x =>
                    selectedProducts.includes(x)
                  );
                  if (difference?.length) {
                    return alert('이미 있는 상품입니다.');
                  } else {
                    setSelectProduct(prev => [...prev, ...selectedProducts]);
                    setInput('');
                    setModal(false);
                    setModalType('');
                  }
                }}>
                저장
              </button>
              <button
                className='gray-btn'
                onClick={() => {
                  setInput('');
                  setModal(false);
                  setModalType('');
                }}>
                취소
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RegiProductSearchModal;
