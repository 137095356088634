import { checkPassword, userPwChange } from 'js/selfAPI';
import { useState } from 'react';

const PasswordChange = ({
  setCurPassword,
  curPassword,
  setUserPwInfoModal,
  userId,
}) => {
  const [newPw, setNewPw] = useState({
    pw1: '',
    pw2: '',
  });
  //# 비밀번호 변경
  const userPwChangeFunc = async () => {
    if (newPw?.pw1 === curPassword || newPw?.pw2 === curPassword)
      return alert('새 비밀번호를 입력해 주세요.');
    else if (newPw?.pw1 !== newPw?.pw2) {
      return alert(
        '입력하신 새 비밀번호가 일치하지 않습니다.다시 입력해 주세요.'
      );
    }
    const pwCheck = await checkPassword(userId, curPassword);
    if (pwCheck?.data === 'Password correct') {
      const changePassword = await userPwChange(
        userId,
        curPassword,
        newPw?.pw1
      );
      if (typeof changePassword === 'object') {
        alert('비밀번호가 변경되었습니다.');
        return setUserPwInfoModal(false);
      } else {
        return alert('다시 시도해 주세요.');
      }
    } else {
      alert('현재 사용 중인 비밀번호가 일치하지 않습니다. 다시 입력해 주세요.');
    }
  };

  return (
    <>
      <div className='modal-overlay user-pw-change'>
        <div className='modal'>
          <div className='head'>
            <p>비밀번호 변경</p>
          </div>
          <div className='txt-wrap'>
            안전한 비밀번호로 소중한 개인정보를 보호하세요.
            <br />
            <span className='blue'>
              다른 사이트 혹은 이전에 사용한 적 없는 비밀번호가 안전합니다.
            </span>
            <br />
            비밀번호는{' '}
            <span className='red'>8~20자리 숫자/소문자/특수문자</span>를 모두
            포함해야합니다.
          </div>
          <div className='pw-input-wrap'>
            <span>비밀번호 :</span>
            <div>
              <input
                value={curPassword}
                type='password'
                placeholder='비밀번호를 입력하세요'
                autoComplete='off'
                onChange={e => setCurPassword(e.target.value)}
              />
            </div>
          </div>
          <div className='pw-input-wrap change-pw'>
            <span>새 비밀번호 :</span>
            <div>
              <input
                value={newPw.pw1}
                type='password'
                placeholder='비밀번호를 입력하세요'
                autoComplete='off'
                onChange={e => setNewPw({ ...newPw, pw1: e.target.value })}
              />
            </div>
          </div>
          <div className='pw-input-wrap change-pw2'>
            <span>새 비밀번호 확인 :</span>
            <div>
              <input
                value={newPw.pw2}
                type='password'
                placeholder='비밀번호를 입력하세요'
                autoComplete='off'
                onChange={e => setNewPw({ ...newPw, pw2: e.target.value })}
              />
            </div>
          </div>
          <hr className='line' />
          <div className='user-btn-wrap'>
            <button
              className='ok'
              onClick={() => {
                userPwChangeFunc();
              }}>
              확인
            </button>
            <button
              className='close'
              onClick={() => {
                setUserPwInfoModal(false);
                setCurPassword('');
              }}>
              취소
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;
