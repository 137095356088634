import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { downloadSelfFunc } from 'js/selfAPI';
import { catchError } from 'js/commonFn';
import FinalViewData from './FinalViewData';

const ApplicantFinalStep = ({
  applicants,
  markNameArr,
  fileName,
  applicationType,
  selectProductArr,
  setImgUrl,
  img,
  selectSearchType,
  searchTxt,
  categoryCode,
  tempData,
}) => {
  const [imgChange, setImgChange] = useState(false);
  const [imgOn, setImgOn] = useState(markNameArr[0] === '');

  const navigate = useNavigate();

  const handleImgDownload = () => {
    const imgText = markNameArr?.join('\n') ?? '상표명\n미리보기'; // 텍스트 생성
    const fileName = 'image.jpg';
    const imgElement = document.querySelector('.img-preview');

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 800;
    canvas.height = 800;
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = '100px sans-serif';
    context.fillStyle = '#000000';
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    // 텍스트 그리기
    const lines = imgText.split('\n');
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const y = canvas.height / 2 + (i - (lines.length - 1) / 2) * 120;
      context.fillText(line, canvas.width / 2, y);
    }

    const dataUrl = canvas.toDataURL('image/jpeg', 1);

    const link = document.createElement('a');
    link.download = fileName;
    link.href = dataUrl;
    link.click();

    // 새로운 이미지 요소 생성
    const newImgElement = new Image();
    newImgElement.src = dataUrl;
    newImgElement.className = 'img-preview';
    newImgElement.width = '120';
    newImgElement.height = '120';

    // 기존 이미지 요소 대체
    imgElement.replaceWith(newImgElement);

    setImgUrl(dataUrl);
  };

  const imgDownFunc = () => {
    if (imgOn) {
      const link = document.createElement('a');
      link.download = fileName;
      link.href = img;
      link.click();
    }
  };

  //# step5 상표등록정보 파일 다운로드
  const fileDownFunc = async () => {
    //# applicants id 키값 제외한 변수 재생성
    const updatedApplicants = applicants.map(
      ({ id, applicant_share, ...rest }) => {
        let newObj = { ...rest };

        if (applicant_share !== undefined) {
          newObj.applicant_share = String(applicant_share);
        }

        return newObj;
      }
    );

    const result = await downloadSelfFunc(
      tempData,
      updatedApplicants,
      selectProductArr,
      applicationType,
      fileName,
      selectSearchType,
      searchTxt,
      categoryCode,
      img,
      markNameArr
    );
    if (typeof result === 'object') {
      const xmlData = result?.data;
      // Blob 객체 생성
      const blob = new Blob([xmlData], { type: 'application/xml' });
      // Blob 객체로부터 URL 생성
      const url = URL.createObjectURL(blob);
      // a 태그 생성
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // 이 파일명은 임의로 지정하세요.
      a.download = 'myData.xml';
      // a 태그를 DOM에 추가
      document.body.appendChild(a);
      // a 태그를 클릭하여 파일 다운로드
      a.click();
      // a 태그 삭제
      document.body.removeChild(a);
    } else catchError(result, navigate);
  };

  return (
    <>
      <div className='final-info-wrap'>
        <div className='head'>
          <h4>1.출원인 정보</h4>
          <hr />
          {applicants?.map((item, idx) => (
            <>
              <FinalViewData
                name='출원인 이름'
                infoData={item?.applicant_name}
                key={`출원인${idx}`}
              />
              <FinalViewData
                name='특허고객번호'
                key={`특허고객번호${idx}`}
                infoData={item?.applicant_code}
              />
            </>
          ))}
        </div>
        <div className='head  mt-56'>
          <h4>2.상표 정보</h4>
          <hr />
          <FinalViewData
            name='상표유형'
            infoData={
              applicationType === 'text'
                ? '문자상표'
                : applicationType === 'figure'
                ? '도형상표'
                : '복합상표'
            }
          />

          {!imgOn && (
            <>
              {markNameArr[0] !== '' && (
                <FinalViewData
                  name='상표명'
                  infoData={markNameArr?.reduce((acc, cur) => `${acc} ${cur} `)}
                />
              )}
              <div className='img-wrap final-img-wrap'>
                <div>상표이미지</div>
                <div>
                  {imgChange ? (
                    <>
                      <div className='img-preview'>
                        {markNameArr?.map(item => (
                          <div className='preview-text' key={item}>
                            {item}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className='img-preview preview-text'>
                      상표명
                      <br />
                      미리보기
                    </div>
                  )}
                  {imgChange ? (
                    <button onClick={() => handleImgDownload()}>
                      이미지 다운로드
                    </button>
                  ) : (
                    <button onClick={() => setImgChange(true)}>
                      이미지 전환하기
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
          {imgOn && (
            <>
              {markNameArr[0] !== '' && (
                <FinalViewData
                  name='상표명'
                  infoData={markNameArr?.reduce((acc, cur) => `${acc} ${cur} `)}
                />
              )}
              <div className='img-wrap final-img-wrap'>
                <div>상표이미지</div>
                <div>
                  <div className='img-preview'>
                    <img src={img} alt={fileName} />
                  </div>
                  <button onClick={() => imgDownFunc()}>이미지 다운로드</button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='head  mt-56'>
          <h4>3.상품분류정보</h4>
          <hr />
          <FinalViewData
            name='상품분류코드'
            infoData={selectProductArr
              .reduce((acc, cur) => `${acc}${cur.mark_class}류, `, '')
              .slice(0, -2)}
          />
        </div>
        <div className='head  mt-56'>
          <h4>4.등록상품정보</h4>
          <hr />

          <FinalViewData
            name='등록상품'
            infoData={selectProductArr.map((item, index) => {
              const productNames = Array.isArray(item.register_product)
                ? item.register_product.join(', ')
                : item.register_product;
              return (
                <div>
                  {`${item.mark_class}류 - ${productNames}`}
                  {index !== selectProductArr.length - 1 && <br />}
                </div>
              );
            })}
          />
        </div>
        <div className='final-btn-wrap'>
          <button onClick={() => fileDownFunc()}>
            상표등록정보 파일 다운로드
          </button>
        </div>
      </div>
    </>
  );
};

export default ApplicantFinalStep;
