const AgreeModal = ({ modal, setModal }) => {
  return (
    <div className='modal-bg'>
      <div className='modal terms-modal column'>
        <h1>
          {modal.mode === 'terms'
            ? '마크픽 서비스 이용약관 동의'
            : '마크픽 개인정보 수집 및 이용 동의'}
        </h1>
        <div className='sign-up-desc'>
          {modal.mode === 'terms' ? (
            <>
              <h1>제1장 총칙</h1>

              <h2>제1조 (목적)</h2>
              <p>
                이 약관은 '해율특허법률사무소'(이하 '회사'라 합니다)가 제공하는
                마크픽 서비스(이하 '서비스'라 합니다)의 이용과 관련하여 회사와
                이용자의 권리·의무 및 책임 사항, 기타 필요한 사항을 규정함을
                목적으로 합니다.
              </p>
              <h2>제2조 제2조(정의)</h2>
              <ol>
                <li>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</li>
                <li>
                  ① '서비스'란 '회원'이 이용할 수 있는 다음의 서비스 범위를
                  말합니다.
                </li>
                <li>1. 별도의 신청을 통해 제공되는 '상표 셀프출원 서비스'</li>
                <li>
                  2. 별도의 신청 및 결제를 통해 제공되는 '상표 출원대행 서비스'
                </li>
                <li>
                  ② '이용자'란 이 약관에 따라 회사에서 제공하는 서비스를 받는
                  회원을 말합니다.
                </li>
                <li>
                  ③ '회원'이라 함은 회사에 개인정보를 제공하고 회원등록을 한
                  사람을 말합니다.
                </li>
                <li>
                  {' '}
                  ④ '이용자 ID(이하 '아이디'라 합니다)'라 함은 회원의 식별 및
                  서비스 이용을 위하여 회원이 선정하고 회사가 부여하는 고유한
                  문자와 숫자의 조합을 말합니다.
                </li>
                <li>
                  {' '}
                  ⑤ '비밀번호'라 함은 아이디로 식별되는 회원의 본인 여부를
                  검증하기 위하여 회원이 설정하여 회사에 등록한 고유의 문자와
                  숫자의 조합을 말합니다.
                </li>
              </ol>
              <h2>제3조 (약관 이외의 준칙)</h2>
              <p>
                이 약관에 언급되지 않은 사항이 전기통신기본법, 전기통신사업법,
                기타 관련 법령에 규정되어 있는 경우 그 규정에 따라 적용할 수
                있습니다.
              </p>

              <h2>제3조(약관의 명시와 개정)</h2>
              <ol>
                <li>
                  ① 이 약관은 회사 웹사이트를 통해 온라인으로 공지함으로써
                  효력을 발생하며, 합리적인 사유가 발생할 경우 관련 법령에
                  위배되지 않는 범위 안에서 개정될 수 있습니다. 개정된 약관은
                  정당한 절차에 따라 공지함으로써 효력을 발생합니다.
                </li>
                <li>
                  ② 회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
                  주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
                  전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업
                  신고번호, 개인정보 관리책임자 등을 이용자가 쉽게 알 수 있도록
                  서비스화면 하단에 게시합니다. 다만, 약관의 내용은 이용자가
                  연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                </li>
                <li>
                  ③ 회사는 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수
                  있으며, 이 경우 적용 일자 및 개정 사유를 명시하여 현행약관과
                  함께 서비스의 화면에 그 적용 일자 7일 이전에 공지합니다. 다만,
                  이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일
                  이상의 사전 유예기간을 두고 공지합니다.
                </li>
                <li>
                  ④ 회원이 등록한 전자우편 주소의 오류, 회원이 서비스 화면이나
                  연결 화면에 게시된 약관의 변경 사항을 확인하지 아니함으로써
                  발생하는 회원의 피해는 회사에서 책임지지 않습니다.
                </li>
                <li>
                  ⑤ 회원은 변경된 약관에 동의하지 않을 경우 해지를 요청할 수
                  있으며, 변경된 약관의 효력발생일로부터 7일 이후에 회원이
                  서비스를 사용할 경우 변경된 약관의 적용에 동의한 것으로
                  간주합니다.
                </li>
                <li>
                  ⑥ 회사는 필요한 경우 별도의 서비스마다 개별약관을 정할 수
                  있으며, 이 약관과 서비스별 개별약관의 내용이 상충하는 경우에는
                  서비스별 개별약관의 내용을 우선하여 적용합니다.
                </li>
                <li>
                  ⑦ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
                  전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에
                  관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자
                  보호지침 및 관계 법령 또는 상관례에 따릅니다.
                </li>
              </ol>

              <h2>제4조(약관의 해석)</h2>
              <p>
                ① 회원이 마크픽과 개별계약을 체결하여 서비스를 이용하는 경우
                마크픽은 개별 서비스에 대한 이용약관 또는 운영정책 등(이하
                운영정책 등)을 둘 수 있으며, 해당 내용이 본 약관과 상충되는 경우
                개별서비스에 대한 운영정책 등이 우선합니다.
              </p>
              <p>
                ② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는
                "유료서비스약관 등" 및 관계법령 또는 상관례에 따릅니다.
              </p>

              <h2>제5조(회원가입 및 이용신청)</h2>
              <ol>
                <li>
                  ① 마크픽과 회원 사이의 서비스 이용계약(이하 이용계약이라 함)은
                  서비스를 이용하고자 하는 자(이하 가입신청자라 함)의 회원가입의
                  이용신청 및 본 약관과 개인정보 처리방침의 내용에 대한 동의에
                  대한 회사의 이용승낙으로 성립합니다.
                </li>
                <li>
                  {' '}
                  ② 본 약관 및 개인정보처리방침에 대한 동의의 의사표시는
                  가입신청자가 가입신청 당시 회원가입 버튼을 누름으로써
                  성립합니다. 이와 동시에 위 가입신청자가 본 약관 및
                  개인정보처리방침에 대하여 동의한 것으로 간주합니다.
                </li>
                <li>
                  ③ 마크픽은 가입신청자의 신청에 대하여 서비스 이용을 승낙함을
                  원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에
                  대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수
                  있습니다.
                </li>
                <li>
                  가. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한
                  적이 있는 경우, 단 마크픽의 회원 재가입 승낙을 얻은 경우에는
                  예외로 함.
                </li>
                <li>
                  나. 허위의 정보를 기재하거나, 마크픽이 제시하는 내용을
                  기재하지 않은 경우
                </li>
                <li>다. 14세 미만 아동</li>
                <li>
                  라. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한
                  제반 사항을 위반하며 신청하는 경우
                </li>
                <li>
                  {' '}
                  ④ 제1항에 따른 신청에 있어 마크픽 회원의 종류에 따라
                  전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
                </li>
                <li>
                  ⑤ 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나
                  유보한 경우, 마크픽은 이를 가입신청자에게 알릴 의무를 지지
                  아니합니다.
                </li>
                <li>
                  ⑥ 회원 이용계약의 성립 시기는 마크픽이 가입완료를 신청절차
                  상에서 표시한 시점으로 합니다.
                </li>
              </ol>

              <h2>제6조(회원정보의 변경)</h2>
              <ol>
                <li>
                  ① 회원은 개인정보관리화면(마이페이지)를 통하여 본인의
                  개인정보를 열람하고 수정하거나, 회사에 회원 정보의 열람,
                  수정을 요청할 수 있습니다. 다만, 서비스 관리를 위해 아이디
                  등의 수정기능이 제공되지 않거나, 개인정보관리화면에 제공되지
                  않을 수 있습니다.
                </li>
                <li>
                  ② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우
                  온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여
                  그 변경사항을 반드시 알려야 합니다.
                </li>
                <li>
                  ③ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익은 회원의
                  책임으로 합니다.
                </li>
              </ol>

              <h2>제8조(회원의 "아이디" 및 "비밀번호"의 관리에 대한 의무)</h2>

              <p>
                ① 회원의 "아이디"와 "비밀번호"에 관한 관리책임은 회원에게
                있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
              </p>
              <p>
                ② 회사는 회원의 "아이디"가 개인정보 유출 우려가 있거나, 반사회적
                또는 미풍양속에 어긋나거나 마크픽 및 마크픽 운영자로 오인한
                우려가 있는 경우, 해당 "아이디"의 이용을 제한할 수 있습니다.
              </p>
              <p>
                ③ 회원은 "아이디" 및 "비밀번호"가 도용되거나 제3자가 사용하고
                있음을 인지한 경우에는 이를 즉시 법률사무소에 통지하고 회사의
                안내에 따라야 합니다.
              </p>
              <p>
                ④ 제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나,
                통지한 경우에도 마크픽의 안내에 따르지 않아 발생한 불이익에
                관하여는 회원이 책임을 집니다.
              </p>

              <h2>제9조(회원에 대한 통지)</h2>
              <ol>
                <li>
                  ① 회사는 회원에 대한 통지를 하는 경우, 이 약관에 별도 규정이
                  없는 한 서비스 내 전자우편주소를 이용한 전자우편 송달, SMS을
                  통한 메시지 발송 등의 방법을 이용할 수 있습니다.
                </li>
                <li>
                  ② 회사는 불특정 다수 회원에 대한 통지의 경우, 1주일 이상
                  회사의 공지사항에 게시함으로써 개별 통지에 갈음할 수 있습니다.
                  다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
                  대하여는 개별 통지를 합니다.
                </li>
              </ol>

              <h2>제10조(회사의 의무)</h2>
              <ol>
                <li>
                  ① 회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는
                  행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기
                  위하여 최선을 다하여 노력합니다.
                </li>
                <li>
                  ② 회사는 이용자가 안전하게 서비스를 이용할 수 있도록
                  개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며
                  개인정보취급방침을 공시하고 준수합니다.
                </li>
                <li>
                  ③ 회사는 관련법령을 준수하고, 회원을 위한 고객 상담센터를
                  운영하면서 각종 민원 해소를 위해 노력합니다.
                </li>
              </ol>

              <h2>제11조(회원의 의무)</h2>

              <p>① 회원은 다음 행위를 하여서는 안 됩니다.</p>
              <p>
                가. 신청 또는 변경시 허위내용의 등록 또는 타인의 개인정보, 신용
                및 금융정보, 연락처, 아이디 도용 및 임의사용
              </p>
              <p>
                나. 회사가 게시한 정보를 변경하거나 고의로 변경을 시도하는 행위
              </p>
              <p>
                다. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
                또는 게시
              </p>
              <p>라. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
              <p>
                마. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는
                행위
              </p>
              <p>
                바. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
                반하는 정보를 서비스에 공개 또는 게시하는 행위
              </p>
              <p>사. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</p>
              <p>
                아. 회사가 이용자를 위해서 시행하는 각종 이벤트 서비스(할인쿠폰
                등)을 회사의 서비스정책에 맞지 않도록 사용하는 행위
              </p>
              <p>
                자. 현금융통을 위하여 신용카드를 사용하거나, 금융감독원 기타
                수사기관의 제재를 받을 수 있는 사항의 거래요청, 회사의 정당한
                관련서류 제출요구에 응하지 않는 행위
              </p>
              <p>
                차. 회사의 고객불만사항 처리절차 중 통상적인 범위를 현저히
                벗어나는 욕설, 성적 비하 등 비인격적 언행 표출 및 위력과시 등
                공포감을 유발하는 행위
              </p>
              <p>카. 기타 불법적이거나 부당한 행위</p>
              <p>
                ② 이용자는 관계 법령, 이 약관의 규정, 이용안내 및 서비스와
                관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
                하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
              </p>
              <p>
                ③ 이용자가 제1항 이용자의 의무를 이행하지 않은 경우 회사는
                이용자에 대하여 의무 불이행 사실을 고지함으로써 회사와 이용자
                사이에서 체결된 서비스 이용계약을 일방적으로 해지할 수 있고,
                이용계약 체결을 거부할 수 있습니다.
              </p>

              <h2>제12조(서비스의 제공 등)</h2>
              <p>
                ① 회사는 다음과 같은 유료서비스를 제공하며, 회사의 사정, 기타
                제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다.
              </p>
              <p>가. 국내 상표출원대행 및 등록대행 서비스</p>
              <p>나. 국내 상표의견대응, 심판 서비스</p>
              <p>다. 국내 우선심사신청 서비스</p>
              <p>라. 국내 상표조사 서비스</p>
              <p>
                마. 기타 회사가 추가 개발하거나 제휴 등을 통해 회원에게 제공하는
                서비스 일체
              </p>
              <p>
                ② 회사는 다음과 같은 무료서비스를 제공하며, 회사의 사정, 기타
                제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다.가.
                국내 셀프상표출원안내 및 등록안내 서비스
              </p>
              <p>
                ③ 회사는 제1항, 제2항의 서비스의 제공에 관하여 이 약관 외의
                별도의 규정을 둘 수 있습니다.
              </p>
              <p>
                ④ 회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능시간을
                별도로 지정할 수 있습니다. 다만, 이용가능시간을 별도 지정할 경우
                그 내용을 사전 공지합니다.
              </p>
              <p>
                ⑤ 서비스는 평일 09시~18시 사이에 제공함을 원칙으로 합니다. 다만
                고객불만 처리를 위한 상담센터 운영 서비스를 예외적으로 운영할 수
                있습니다.
              </p>
              <p>
                ⑥ 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
                통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을
                일시적으로 중단할 수 있습니다. 이 경우 회사는 제 9 조[회원에
                대한 통지]에 정한 방법으로 회원에게 사전 통지합니다. 다만,
                회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 홈페이지
                공지로 대신하거나, 사후에 통지할 수 있습니다.
              </p>
              <p>
                ⑦ 회사는 제5항의 사유로 서비스 제공이 일시적으로 중단됨으로
                인하여 이용자 또는 제3자가 입은 손해에 대하여 회사의 고의,
                과실이 없는 경우에는 배상하지 아니합니다.
              </p>
              <p>
                ⑧ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수
                있으며, 정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다.
              </p>

              <h2>제13조(서비스의 변경)</h2>

              <p>
                ① 회원은 회사가 제공하는 서비스를 이 약관, 운영정책 등 회사가
                정한 규칙에 따라 이용할 수 있습니다.
              </p>
              <p>
                ② 회사가 회원에게 제공하는 서비스에 대하여 회사는 제작, 변경,
                유지, 보수에 관한 포괄적인 권한을 가집니다.
              </p>
              <p>
                ③ 회사는 새로운 서비스 내용 등 서비스의 운영상 또는 기술상의
                필요한 경우, 제공하고 있는 서비스의 전부 또는 일부를 상시적으로
                수정, 추가, 폐지 등 변경할 수 있습니다.
              </p>
              <p>
                ④ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책
                기획이나 운영상 또는 회사의 긴박한 상황 등 필요에 의해 수정,
                중단, 변경할 수 있으며, 이에 대하여 관련 법령상 특별한 규정이
                없는 한 회원에게 별도의 보상을 하지 않습니다.
              </p>
              <p>
                ⑤ 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를
                제한하거나 중지할 수 있습니다.
              </p>
              <p>
                가. 전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인
                사유가 있는 경우
              </p>
              <p>
                나. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
                서비스 이용에 지장이 있는 경우
              </p>
              <p>다. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</p>
              <p>라. 기타 회사의 제반 사정으로 서비스를 할 수 없는 경우</p>
              <p>
                ⑥ 회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는
                중대한 과실로 인한 경우를 제외하고는 서비스의 변경 및 중지로
                발생하는 문제에 대해서 책임을 부담하지 않습니다.
              </p>

              <h2>제14조(정보의 제공 및 광고의 게재)</h2>
              <p>
                ① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를
                회사의 홈페이지에 게시하거나 이메일 등을 이용하여 회원에게
                제공할 수 있습니다. 다만, 회원은 관련 법령에 따른 거래관련 정보,
                고객센터 답변 등을 제외한 정보의 전송에 대하여 언제든지 이메일
                등을 통하여 수신거절의 의사표시를 할 수 있습니다.
              </p>
              <p>
                ② 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며,
                회원은 서비스 이용 시 노출되는 광고게재에 대하여 동의합니다.
              </p>
              <p>
                ③ 회사가 제공하는, 제3자가 주체인, 제2항의 광고에 회원이
                참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에
                대해서 회사는 어떠한 책임도 부담하지 않습니다.
              </p>
              <p>
                ④ 회사는 적법하게 수집한 회원의 개인정보를 활용하여 제2항의 광고
                등을 메일주소 등을 활용하여 발송할 수 있으며, 이용자가 원하지
                않는 경우에는 언제든지 수신을 거부할 수 있습니다.
              </p>

              <h2>제15조(지급방법)</h2>
              <p>
                ① 회사에 대한 대금 지급방법은 다음 각 호의 방법 중 회사가 제공
                가능한 방법으로 할 수 있습니다.
              </p>
              <p>가. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</p>
              <p>나. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</p>
              <p>다. 온라인 무통장입금</p>
              <p>라. 전자화폐에 의한 결제</p>
              <p>마. 수령시 대금지급</p>
              <p>바. 기타 전자적 지급 방법에 의한 대금 지급 등</p>
              <p>
                ② 이용자가 구매대금의 결제와 관련하여 입력한 정보 및 그에 대한
                책임은 이용자에게 있으며, 잘못된 정보가 입력되거나 합리적인 기간
                내에 결제가 이루어지지 않는 경우 회사는 주문을 취소할 수
                있습니다.
              </p>
              <p>
                ③ 회사는 구매자의 결제수단에 대하여 정당한 사용권한 보유여부를
                확인할 수 있으며 필요한 경우 해당 거래진행의 정지 및 소명자료의
                제출을 요청할 수 있습니다.
              </p>

              <h2>제16조(유료서비스 가액 변경)</h2>
              <ol>
                <p>
                  회사는 운영상, 기술상의 필요에 따라 공급하는 유료서비스 가액을
                  변경할 수 있으며, 제9조에서 정한 방법에 따라 유료회원에게
                  사전공지합니다.
                </p>
              </ol>

              <h2>제17조(계약의 성립 시점)</h2>

              <p>
                ① 회사는 이용자의 서비스 이용신청에 대하여 다음 각 호에 해당하지
                않는 한 승낙합니다. 한편 미성년자와 계약을 체결하는 경우에는
                법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이
                계약을 취소할 수 있다는 내용을 고지하여야 합니다.
              </p>
              <p>
                가. 서비스 신청에 승낙하는 것이 회사의 기술상 현저히 지장이
                있다고 판단하는 경우
              </p>
              <p>
                나. 서비스 신청회원 자격이 제한, 정지 또는 상실된 회원으로
                확인되었을 경우
              </p>
              <p>다. 서비스 신청 고객이 사이트의 거래질서를 방해한 경우</p>
              <p>
                라. 서비스 신청 고객이 법령 위반행위, 반사회질서 법률행위,
                범죄행위에 해당하는 내용을 이유로 회사의 서비스를 신청하는 경우
              </p>
              <p>마. 기타 제반 법령 및 정부의 가이드라인에 위반되는 경우 등</p>
              <p>
                ② 회사는 이용자의 이용신청이 있는 경우 이용자에게 수신확인통지를
                합니다.
              </p>
              <p>
                ③ 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는
                경우에는 수신확인 통지를 받은 후 이용신청 변경 및 취소를 요청할
                수 있습니다.
              </p>
              <p>
                ④ 회사는 이용자가 서비스 미사용 상태에서 이용신청 변경 및 취소
                요청이 있는 경우 지체없이 해당 요청에 따라 처리합니다. 서비스
                사용과 미사용을 구분하는 기준점(취소 가능 기준점)은 회사가
                이용자에게 상표출원 절차 진행을 위한 담당자를 배정한 시점이며,
                회사가 이용자에게 상표출원 절차 진행을 위한 확인절차에 착수한
                후에는 서비스 이용 취소 및 환불이 제7항의 기준에 따라
                제한됩니다.
              </p>
              <p>
                ⑤ 이용자가 결제를 완료한 시점에 서비스 이용계약이 성립한 것으로
                봅니다. 계약이 성립한 후 회사가 제1항 각 호의 사유를 발견한 경우
                회사는 즉시 계약 취소 사실을 전화, 전자우편, 문자 메시지 등
                적절한 방법을 이용하여 이용자에게 통지하는 방법으로 계약을
                취소할 수 있으며, 계약 취소시 고객이 결제한 요금은 환불
                처리됩니다.
              </p>
              <p>
                ⑥ 이용자는 서비스 이용신청을 한 후 7일 이내에 이용금액 전액을
                결제하여야 합니다. 만일 이 기간 내에 이용자가 이용금액을
                결제하지 않으면 회사는 신청을 취소할 수 있습니다.
              </p>

              <h2>
                {' '}
                제18조(세금계산서 등의 발행, 발행 범위, 회사의 권한, 이용자의
                책임)
              </h2>
              <p>
                ① 회사는 이용자에게 서비스를 제공하고 지급받은 이용대금에 대한
                세금계산서, 현금영수증(이하 '세금계산서 등')을 조세법,
                부가가치세법 기타 조세 관련 법령이 정하는 바에 따라 발행합니다.
                다만 회사가 세금계산서 등을 발행하는 범위는 회사가 서비스를
                제공하는 대가로 수령한 금원에 한정되며, 이용자가 국가(특허청,
                특허심판원)에 지급하여야 하는 출원료, 등록료, 공증비 등 회사에
                지급하는 서비스 대가 이외의 부분에 대하여는 회사가 세금계산서
                등을 발행하지 아니합니다.
              </p>
              <p>
                ② 이용자가 국가(특허청, 특허심판원)에 지급하여야 하는 출원료,
                등록료, 공증비 등 회사에 지급하는 서비스 대가 이외의 부분에
                대하여는, 이용자의 요청이 있을 시 회사가 관납료 영수증, 공증비
                영수증 등 제반 서비스 비용에 대한 증빙자료를 이용자에게 대리권의
                범위 한도에서 발급받아 전달합니다.
              </p>
              <p>
                ③ 회사는 상표출원 신청을 위해서 도장 제작, 신청서의
                작성,제출,취하, 기타 이에 부수하는 일체의 행위를 할 권한을
                보유합니다.
              </p>
              <p>
                ④ 서비스 특성상 이용자가 입력하는 내용을 바탕으로 작성된
                상표출원서에 대하여 이용자의 '최종확인' 이 있는 경우 특허청에
                출원서 제출 및 출원료 납부를 진행하여 상표출원절차가 완료됩니다.
                이용자의 최종 확인이 종료되었음에도 불구하고 오류가 발생하거나
                사실관계 기재가 잘못된 경우, 이로 인하여 이용자 또는 제3자에게
                발생하는 민, 형사상 책임은 이용자가 부담합니다.
              </p>

              <h2>제19조(환불)</h2>
              <p>
                ① 회사가 서비스의 제공을 할 수 없을 때와 이용요금의 과오납이
                있을 때 회사는 그 사유가 발생한 날로부터 7일 이내에 이용요금을
                정산하고 남은 잔액, 과오 납액의 환불 등 필요한 조치를 하고 그
                사유를 통지하여야 합니다. 다만, 정상적인 업무처리가 이루어진
                경우 회사는 이용요금의 환불에 대한 책임을 지지 아니합니다.
              </p>
              <p>
                ② 이용자가 회사의 서비스 이용을 위해 가입 후, 요금을 결제하여
                계약이 성립되었다 하더라도 일반 상거래 기준에 비추어 환불 요청이
                정당하다고 판단되는 경우에는 서비스 이용요금 전액에 대한 환불을
                요청할 수 있습니다.
              </p>
              <p>
                ③ 회사가 이용자에게 무료로 제공한 서비스에 대해 환불을 요청한
                경우에는 환불이 불가합니다.
              </p>
              <p>
                ④ 이용자가 서비스 이용 취소를 원하실 경우, 회사의 서비스 진행
                단계에 기반한 아래 기준에 따라 환불해 드립니다. 이용자는 회사의
                서비스 단계별로 담당자가 하여야 하는 업무를 진행한 후에는 해당
                업무 부분에 관하여는 환불이 불가능하다는 사실에 대하여 인지하고
                동의합니다.
              </p>
              <p>
                가. 이용자 결제 후 상표출원진행요청 전까지 : 수수료 및 관납료
                전액 환불
              </p>
              <p>나. 조사 서비스 진행시 조사서비스 비용은 환불 불가</p>
              <p>
                다. 상표출원진행요청 후 상표출원 전까지: 수수료 환불 불가,
                관납료 환불 가능
              </p>
              <p>라. 상표출원 후 : 수수료 및 관납료 전액 환불 불가</p>

              <h2>제20조("게시물"의 저작권)</h2>
              <p>
                ① 회사가 작성한 저작물에 대한 저작권 및 기타 지식재산권은 회사에
                귀속합니다. 회사는 서비스와 관련하여 이용자에게 회사가 정한
                이용조건에 따라 계정, ID, 콘텐츠 등을 이용할 수 있는 이용권만을
                부여하며, 이용자는 서비스를 이용함으로써 얻은 정보 중 회사에게
                지식재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신,
                출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나
                제3자에게 이용하게 하여서는 안됩니다.
              </p>
              <p>
                ② 이용자가 서비스 내에 게시하는 "게시물"은 검색결과 내지 서비스
                및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한
                범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이
                경우 회사는 저작권법 규정을 준수하며, 이용자는 언제든지 고객센터
                또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과
                제외, 비공개 등의 조치를 취할 수 있습니다.
              </p>
              <p>
                ③ 회사가 제2항 이외의 방법으로 이용자의 "게시물"을 이용하고자
                하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 이용자의
                동의를 얻어야 합니다.
              </p>

              <h2>제21조("게시물"의 관리)</h2>
              <p>
                ① 이용자의 게시물이 『정보통신망 이용촉진 및 정보보호 등에 관한
                법률』, 『개인정보보호법』 및 『저작권법』등 관련법령에 위반되는
                내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당
                게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련
                법령에 따라 조치를 취하여야 합니다.
              </p>
              <p>
                ② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가
                인정될 만한 사유가 있거나 기타 정책 및 관련 법령에 위반되는
                경우에는 법령에 따라 해당 게시물에 대해 임시조치 등을 취할 수
                있습니다.
              </p>
              <p>
                ③ 이 조에 따른 세부절차는 『정보통신망 이용촉진 및 정보보호 등에
                관한 법률』, 『개인정보보호법』 및 『저작권법』이 규정한 범위
                내에서 회사가 정한 게시중단요청서비스에 따릅니다.
              </p>

              <h2>제22조(권리의 귀속)</h2>
              <p>
                ① 이용자가 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한
                해당 이용자에게 있으며, 회사는 이용자가 게시하거나 등록하는
                서비스의 내용물이 다음 각 항에 해당한다고 판단되는 경우에
                사전통지 없이 삭제할 수 있고, 이에 대하여 회사는 책임을 지지
                않습니다.
              </p>
              <p>
                가. 제3자에 대한 허위사실 적시, 비방하거나 명예를 손상시키는
                내용인 경우
              </p>
              <p>나. 공서양속에 위반되는 내용일 경우</p>
              <p>다. 범죄적 행위에 결부된다고 인정되는 경우</p>
              <p>
                라. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인
                경우
              </p>
              <p>
                마. 회원이 사이트와 게시판에 음란물을 게재하거나 음란사이트를
                링크하는 경우
              </p>
              <p>
                바. 회사로부터 사전 승인 받지 아니한 상업광고, 판촉내용을
                게시하는 경우
              </p>
              <p>사. 해당 상품과 관련 없는 내용인 경우</p>
              <p>
                아. 정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 경우
              </p>
              <p>자. 기타 관련 법령에 위반된다고 판단되는 경우</p>
              <p>
                ② 이용자는 서비스를 이용하면서 얻은 정보를 회사의 사전 승낙 없이
                복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 이용하거나
                제3자에게 이용하게 하여서는 안됩니다.
              </p>
              <h2>제23조(회원탈퇴 등)</h2>
              <p>
                ① 회원은 언제든지 서비스초기화면의 고객센터 또는 마이페이지 내
                회원정보관리 메뉴 등을 통하여 회원탈퇴 신청을 할 수 있으며,
                회사는 관련 법령 등이 정하는 바에 따라 이를 즉시 처리하여야
                합니다.
              </p>
              <p>
                ② 회원이 틸퇴할 경우, 관련 법령 및 개인정보취급방침에 따라
                회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의
                모든 데이터는 소멸됩니다.
              </p>
              <p>
                ③ 회원이 탈퇴할 경우, 회원이 작성한 "게시물" 중 게시판에 기재한
                내용 등은 삭제되지 않습니다. 회원 탈퇴로 인하여 회원 정보가
                삭제되어 작성자 본인을 확인할 수 없어 게시물 편집 및 삭제가
                원천적으로 불가합니다. 회원이 작성한 게시물의 삭제를 원할
                경우에는 회원 탈퇴 이전에 게시물을 모두 삭제하여야 합니다.
              </p>
              <h2>제24조(이용제한 등)</h2>
              <p>
                ① 회사는 회원이 본 약관의 의무를 위반하거나 서비스의 정상적인
                운영을 방해한 경우, 서비스 이용을 경고, 일시정지, 계약해지로
                단계적으로 제한할 수 있습니다.
              </p>
              <p>
                ② 회사는 제1항의 규정에도 불구하고, 주민등록법을 위반한 명의도용
                및 결제도용, 저작권법 및 컴퓨터프로그램보호법을 위반한
                불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호
                등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포,
                접속권한 초과행위 등과 같이 관련법령을 위반한 경우에는 즉시
                계약을 해지 할 수 있습니다. 이에 따른 계약해지시 서비스 이용을
                통해 획득한 혜택 등은 모두 소멸되며, 회사는 이에 대해 별도로
                보상하지 않습니다.
              </p>
              <p>
                ③ 회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은
                이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에
                의합니다.
              </p>
              <p>
                ④ 본조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는
                회사는 제9조에서 정한 방법으로 통지합니다.
              </p>
              <h2>제25조(회사의 면책)</h2>
              <p>
                ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
                제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
                회사는 천재지변, 컴퓨터 등 정보통신설비의 보수, 점검, 교체, 고장
                및 통신두절 등의 사유가 발생한 경우에는 서비스 제공을 일시적으로
                중단할 수 있으며, 이와 관련하여 고의 또는 중대한 과실이 없는 한
                어떠한 책임도 부담하지 아니합니다.
              </p>
              <p>
                ② 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여
                책임을 지지 않습니다.
              </p>
              <p>
                ③ 회사는 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것에
                대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 자료로
                인한 손해에 관하여 책임을 지지 않습니다.
              </p>
              <p>
                ④ 회사는 제출 서류에 기재된 권리관계에 대하여 관여하지 아니하며,
                권리관계로 인한 책임을 부담하지 아니합니다. 또한 회사는 서비스
                특성상 이용자가 서비스 이용 과정에서 직접 입력한 정보 일체의
                진실성을 담보하지 아니하며, 고의 또는 과실로 정보가 진실하지
                아니하여 생기는 모든 문제는 해당 정보를 입력한 이용자가 전적으로
                부담합니다.
              </p>
              <p>
                ⑤ 회사는 이용자의 귀책사유로 서비스 이용에 장애가 발생하는 것에
                대하여 책임을 부담하지 아니합니다.
              </p>
              <h2>제26조(금지행위)</h2>
              <p>
                ① 회사가 제공하는 서비스 이용방법을 따르지 아니하고 비정상적인
                방법으로 서비스를 이용하거나 시스템에 접근하는 행위는
                금지됩니다.
              </p>
              <p>
                ② 타인의 명의, 카드정보, 계좌정보 등을 무단으로 사용하여 회사가
                제공하는 서비스를 이용하는 행위는 금지됩니다.
              </p>
              <p>
                ③ 상담예약을 가장하여 자금을 융통하는 것과 같이 여신전문금융업법
                등 법령에 따라 금지된 방법인 비정상적인 결제 행위는 금지됩니다.
              </p>
              <p>
                ④ 실질적인 상담의사가 없는 예약행위와 회사가 제공하는 할인율
                등을 이용하여 비정상적인 거래를 하는 것은 금지됩니다.
              </p>
              <p>
                ⑤ 회사는 이용자의 부당한 이용행위 또는 시스템 부정행위 등이 있는
                경우에는 해당 이용자에게 부가적으로 제공한 혜택의 일부 또는
                전부의 회수, 서비스의 이용 제한, 이용계약 해지 등의 조치를 할 수
                있습니다.
              </p>
              <h2>제27조(분쟁해결)</h2>
              <p>
                ① 회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그
                사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
                이용자에게 그 사유와 처리일정을 즉시 통보합니다.
              </p>
              <p>
                ② 본 약관의 환불 등 일부 규정이 대한민국의 강행법규와 상충되는
                경우에는 그 강행법규에 따르기로 하고, 이로 인해 본 약관 중 일부
                조항의 효력이 정지되는 경우에도 다른 조항의 효력에는 영향을
                미치지 아니합니다.
              </p>
              <h2>제28조(재판권 및 준거법)</h2>
              <p>
                ① 이 약관에 명시되지 않은 사항은 전기통신사업법 등 대한민국의
                관계 법령과 상관습에 따릅니다.
              </p>
              <p>
                ② 회사가 제공하는 서비스에 대해 이용자와 회사 간 발생한 문제에
                대해서는 이 약관과 서비스 운영정책을 따릅니다.
              </p>
              <p>
                ③ 서비스 이용에 관하여 발생한 분쟁으로 소송이 제기되는 경우
                관할법원은 서울중앙지방법원으로 정합니다.
              </p>
              <h3>&lt;부칙&gt;</h3>
              <p>(시행일) 본 약관은 2023년 7월 1일부터 적용됩니다.</p>
            </>
          ) : (
            <>
              <h1>1. 개인정보의 수집 항목</h1>
              <p>
                마크픽는 회원가입 후 대부분 컨텐츠에 자유롭게 접근할 수
                있습니다. 마크픽의 회원제 서비스를 이용하시고자 할 경우 다음의
                정보를 입력해주셔야 하며 선택항목을 입력하시지 않았을 경우
                서비스 이용에 제한을 둡니다.
              </p>
              <ol>
                <li>
                  1. 회원가입 시 수집하는 개인정보의 항목
                  <ul>
                    <li>- 필수항목 : 비밀번호, 성명, 전화번호, 이메일주소</li>
                  </ul>
                </li>
                <li>
                  2. 기타 서비스 이용 과정이나 사업처리 과정에서 생성되는 정보
                  <ul>
                    <li>
                      - 서비스 이용기록, 접속로그, 쿠키, 결제기록, 이용정지 기록
                    </li>
                  </ul>
                </li>
              </ol>
              <h1>2. 개인정보의 수집 목적 및 이용 목적</h1>
              <ol>
                <li>
                  1. 마크픽은 다음과 같은 목적을 위하여 개인정보를 수집하고
                  있습니다. ( 각 수집 항목별 수집 목적 제시 )
                  <ul>
                    <li>
                      - 성명, 비밀번호 : 회원제 서비스 이용에 따른 본인 식별
                      절차에 이용
                    </li>
                    <li>
                      - 이메일주소, 전화번호 : 고지사항 전달, 본인 의사 확인,
                      불만 처리 등 원활한 의사소통 경로의 확보, 새로운 서비스,
                      이벤트 정보 등 최신 정보 의 안내
                    </li>
                    <li>
                      - 그 외 선택항목 : 개인 맞춤 서비스를 제공하기 위한 자료
                    </li>
                  </ul>
                </li>
                <li>
                  2. 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한
                  개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적
                  성향 및 범죄기록, 건강 상태 및 성생활 등)는 수집하지 않습니다.
                </li>
              </ol>
              <h1>3. 개인정보의 보유 기간 및 이용 기간</h1>
              <ol>
                <li>
                  1. 귀하의 개인정보는 다음과 같이 개인정보의 수집 목적 또는
                  제공받은 목적이 달성되면 파기됩니다. 단, 상법 등 법령의 규정에
                  의하여 보존할 필요성이 있는 경우에는 예외로 합니다.
                  <ul>
                    <li>
                      - 회원 가입정보의 경우, 회원가입을 탈퇴하거나 회원에서
                      제명된 때
                    </li>
                    <li>
                      - 보존 대상 개인정보 : 최초 인터넷 회원 가입일, 최후
                      인터넷 회원 탈퇴일{' '}
                    </li>
                    <li>
                      - 보존 기간 : 소송이나 분쟁의 근거자료로 보존하는
                      목적으로서 10년
                    </li>
                    <li>- 기타 개별적으로 이용자의 동의를 받은 경우 : 3년</li>
                    <li>
                      - 대금 지급정보의 경우, 대금의 완제일 또는 채권소멸시효
                      기간이 만료된 때
                    </li>
                    <li>
                      - 설문조사, 이벤트 등 일시적 목적을 위하여 수집한 경우,
                      당해 설문조사, 이벤트 등이 종료한 때
                    </li>
                  </ul>
                </li>
                <li>
                  2. 위 개인정보 수집 목적 달성 시 즉시 파기 원칙에도 불구하고
                  다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정
                  기간 보유하여야 할 필요가 있는 경우에는 미리 보유기간 및 이용
                  기간을 명시하고 귀하의 동의를 받습니다.
                  <ul>
                    <li>
                      - 소송이나 분쟁에 있어 근거자료로 보존할 필요성이 있는
                      경우: 10년
                    </li>
                    <li>- 기타 개별적으로 이용자의 동의를 받은 경우 : 3년 </li>
                  </ul>
                </li>
              </ol>
            </>
          )}
        </div>
        <div className='btn-wrap row'>
          <button
            className='agree-btn'
            onClick={() =>
              setModal({
                bool: false,
                mode: '',
              })
            }>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgreeModal;
