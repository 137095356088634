import { useState, useEffect } from 'react';
import SimpleCost from 'components/cost/SimpleCost';
import DetailCost from 'components/cost/DetailCost';
import { changeTitle } from 'js/commonFn';
import CommonSiteMap from 'components/CommonSiteMap';
import activeImg from 'image/activeSelect.svg';
import inactiveImg from 'image/inactiveSelect.svg';

const Cost = () => {
  const [select, setSelect] = useState('simple');

  useEffect(() => {
    changeTitle('마크픽 > 요금 안내');
  }, []);

  return (
    <div className='content-wrap cost'>
      <CommonSiteMap title='요금 안내' />
      <div className='select-wrap row'>
        <div
          className={`row ${select === 'simple' ? 'active' : ''}`}
          onClick={() => setSelect('simple')}>
          <span>서비스 별 기본 출원 비용</span>
          <img
            src={select === 'simple' ? activeImg : inactiveImg}
            alt='체크 이미지'
          />
        </div>
        <div
          className={`row ${select === 'detail' ? 'active' : ''}`}
          onClick={() => setSelect('detail')}>
          <span>서비스 출원까지의 상세 비용</span>
          <img
            src={select === 'detail' ? activeImg : inactiveImg}
            alt='체크 이미지'
          />
        </div>
      </div>
      {select === 'simple' ? <SimpleCost /> : <DetailCost />}
    </div>
  );
};

export default Cost;
