import { useState } from 'react';
import CommonSelect from 'components/CommonSelect';
import notifyIcon from 'image/notifyIcon.svg';
import { guideEvent, changeState, inputCheck } from 'js/commonFn';
import { useEffect } from 'react';
import { emailArr } from 'js/commonFn';

const KoreaPersonalForm = ({
  emailFilter,
  setEmailFilter,
  applicantInfo,
  setApplicantInfo,
  openPostModal,
}) => {
  const [guide, setGuide] = useState(false);
  const [id, setId] = useState({
    first: applicantInfo?.social_security_number?.split('-')[0] || '',
    last: applicantInfo?.social_security_number?.split('-')[1] || '',
  });
  const [email, setEmail] = useState({
    id: applicantInfo?.email?.split('@')[0] || '',
    domain: applicantInfo?.email?.split('@')[1] || '',
  });
  const [phoneNum, setPhoneNum] = useState({
    first: applicantInfo?.phone_number?.split('-')[0] || '',
    middle: applicantInfo?.phone_number?.split('-')[1] || '',
    last: applicantInfo?.phone_number?.split('-')[2] || '',
  });
  const [telNum, setTelNum] = useState({
    first: applicantInfo?.tel_number?.split('-')[0] || '',
    middle: applicantInfo?.tel_number?.split('-')[1] || '',
    last: applicantInfo?.tel_number?.split('-')[2] || '',
  });

  useEffect(() => {
    if (guide)
      window.addEventListener('click', e =>
        guideEvent(e, () => setGuide(false))
      );
  }, [guide]);

  useEffect(() => {
    changeState(setApplicantInfo, 'email', `${email.id}@${email.domain}`);
  }, [email]);

  useEffect(() => {
    changeState(
      setApplicantInfo,
      'social_security_number',
      `${id.first}-${id.last}`
    );
  }, [id]);

  useEffect(() => {
    changeState(
      setApplicantInfo,
      'phone_number',
      `${phoneNum.first}-${phoneNum.middle}-${phoneNum.last}`
    );
  }, [phoneNum]);

  useEffect(() => {
    changeState(
      setApplicantInfo,
      'tel_number',
      `${telNum.first}-${telNum.middle}-${telNum.last}`
    );
  }, [telNum]);

  useEffect(() => {
    if (emailFilter === '직접 입력')
      changeState(setEmail, 'domain', applicantInfo?.email?.split('@')[1] || '');
    else changeState(setEmail, 'domain', emailFilter);
  }, [emailFilter]);

  return (
    <>
      <div className='form'>
        <div className='row'>
          <span className='essential'>이름(국문)</span>
          <input
            type='text'
            className='common-input'
            onChange={e =>
              inputCheck('korean', e, setApplicantInfo, 'name_kor')
            }
            value={applicantInfo.name_kor || ''}
          />
        </div>
        <div className='row'>
          <span className='essential'>이름(영문)</span>
          <input
            type='text'
            className='common-input'
            onChange={e =>
              inputCheck('english', e, setApplicantInfo, 'name_eng')
            }
            value={applicantInfo.name_eng || ''}
          />
        </div>
        <div className='row'>
          <span className='essential row'>
            주민등록번호
            <img
              src={notifyIcon}
              alt='가이드 아이콘'
              onClick={() => setGuide(!guide)}
              className='guide-icon'
            />
          </span>
          <input
            type='text'
            className='id-input'
            onChange={e => inputCheck('number', e, setId, 'first')}
            value={id.first || ''}
            maxLength={6}
          />
          <span className='hyphen'>-</span>
          <input
            type='password'
            className='id-input'
            onChange={e => inputCheck('number', e, setId, 'last')}
            value={id.last || ''}
            maxLength={7}
          />
          {guide && (
            <div className='guide'>
              마크픽은 ‘개인정보보호법 제24조의2(주민등록번호 처리의 제한)’,
              ‘상표법 시행령 제3조의2(고유식별 정보의 처리)’, ‘변리사법
              제2조(업무)’를 근거로 하여 상표 출원을 위한 주민등록번호를
              요청하고 있습니다.
            </div>
          )}
        </div>
        <div className='row'>
          <span className='essential'>이메일</span>
          <input
            type='text'
            className='email-input'
            placeholder='이메일'
            onChange={e => inputCheck('email', e, setEmail, 'id')}
            value={email.id || ''}
          />
          <span className='at'>@</span>
          <input
            type='text'
            className='email-input'
            onChange={e => inputCheck('email', e, setEmail, 'domain')}
            value={email.domain || ''}
            disabled={emailFilter !== '직접 입력'}
          />
          <CommonSelect
            opt={emailArr}
            selectVal={emailFilter}
            setSelectVal={setEmailFilter}
          />
        </div>
        <div className='row'>
          <span className='essential'>휴대전화</span>
          <input
            type='text'
            className='phone-input'
            onChange={e => inputCheck('number', e, setPhoneNum, 'first')}
            value={phoneNum.first || ''}
            maxLength={3}
          />
          <span className='hyphen'>-</span>
          <input
            type='text'
            className='phone-input'
            onChange={e => inputCheck('number', e, setPhoneNum, 'middle')}
            value={phoneNum.middle || ''}
            maxLength={4}
          />
          <span className='hyphen'>-</span>
          <input
            type='text'
            className='phone-input'
            onChange={e => inputCheck('number', e, setPhoneNum, 'last')}
            value={phoneNum.last || ''}
            maxLength={4}
          />
        </div>
        <div className='row'>
          <span className='optional'>유선전화</span>
          <input
            type='text'
            className='phone-input'
            onChange={e => inputCheck('number', e, setTelNum, 'first')}
            value={telNum.first || ''}
            maxLength={3}
          />
          <span className='hyphen'>-</span>
          <input
            type='text'
            className='phone-input'
            onChange={e => inputCheck('number', e, setTelNum, 'middle')}
            value={telNum.middle || ''}
            maxLength={4}
          />
          <span className='hyphen'>-</span>
          <input
            type='text'
            className='phone-input'
            onChange={e => inputCheck('number', e, setTelNum, 'last')}
            value={telNum.last || ''}
            maxLength={4}
          />
        </div>
        <div className='row'>
          <span className='essential'>우편번호</span>
          <input
            type='text'
            className='search-input'
            value={applicantInfo.zip_code || ''}
            disabled
            maxLength={5}
          />
          <button className='search-btn' onClick={openPostModal}>
            찾기
          </button>
        </div>
        <div className='row'>
          <span className='essential'>등본상 주소</span>
          <input
            type='text'
            className='common-input'
            placeholder='주소 입력'
            value={applicantInfo.address || ''}
            disabled
          />
        </div>
        <div className='row'>
          <input
            type='text'
            className='common-input'
            placeholder='상세 주소 입력'
            onChange={e =>
              changeState(setApplicantInfo, 'detail_address', e.target.value)
            }
            value={applicantInfo.detail_address || ''}
          />
        </div>
      </div>
    </>
  );
};

export default KoreaPersonalForm;
