import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { changeTitle } from 'js/commonFn';
import CommonSiteMap from 'components/CommonSiteMap';
import { getInquiryDetail, inquiryEdit } from 'js/selfAPI';
import { catchError } from 'js/commonFn';
import hamburger from 'image/Customer/hamburgerBtn.svg';
import warning from 'image/warning.svg';
import complete from 'image/inquiry-check.svg';

const InquiryDetails = () => {
  const [inquiryData, setInquiryData] = useState();
  const [editBool, setEditBool] = useState(false);
  const [titleInput, setTitleInput] = useState(inquiryData?.title);
  const [contextData, setContextData] = useState(inquiryData?.context);

  const { inquiryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    changeTitle('마크픽 > 1:1 문의 내역');
    getInquriyDetailData();
  }, []);

  //# 문의내역 상세보기
  const getInquriyDetailData = async () => {
    const detailData = await getInquiryDetail(inquiryId);
    if (typeof detailData === 'object') {
      const { data } = detailData;
      setInquiryData(data);
      setTitleInput(data?.title);
      setContextData(data?.context);
    } else return catchError(detailData, navigate);
  };
  //# 문의내역 수정하기
  const inquiryEditData = async () => {
    const inquiryConfirm = window.confirm('1:1 문의 내용을 수정하시겠습니까?');
    if (inquiryConfirm === false) return;
    if (titleInput?.length === 0) {
      return alert('제목을 입력해 주세요.');
    }
    if (contextData?.length === 0) {
      return alert('내용을 입력해 주세요.');
    }
    const editApi = await inquiryEdit(inquiryId, titleInput, contextData);
    if (typeof editApi === 'object') {
      getInquriyDetailData();
    } else return catchError(editApi, navigate);
  };
  return (
    <div className='inquiry-detail-container'>
      <div className='content-wrap '>
        <CommonSiteMap title='마이페이지' />
        <div className='inquiry-detail-wrap common'>
          <div className='head'>
            <div className='left'>
              <h4>{inquiryData?.user_name}님이 문의하신 내역입니다.</h4>
              <span>
                날짜 :{' '}
                {inquiryData?.updated_at
                  ? inquiryData?.updated_at?.split('T')[0]
                  : inquiryData?.created_at?.split('T')[0]}
              </span>
            </div>
            <div className='right'>
              {inquiryData?.answer ? (
                <div className='img-wrap'>
                  <img src={complete} alt='미답변' />
                  <span>답변</span>
                </div>
              ) : (
                <>
                  <div className='img-wrap'>
                    <img src={warning} alt='미답변' />
                    <span>미답변</span>
                  </div>
                  {editBool === false ? (
                    <div className='btn-wrap' onClick={() => setEditBool(true)}>
                      <button></button>
                      <span>수정</span>
                    </div>
                  ) : (
                    <div
                      className='btn-wrap'
                      onClick={() => {
                        setEditBool(false);
                        inquiryEditData();
                      }}>
                      <button></button>
                      <span>수정완료</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <h5>
            <span>Q.</span> 문의내역
          </h5>
          <div className='inquiry-content'>
            {editBool ? (
              <>
                <strong>
                  <input
                    type='text'
                    className='inquiry-input-edit'
                    autoFocus={true}
                    value={titleInput}
                    placeholder='제목을 입력해 주세요.'
                    onChange={e => setTitleInput(e.target.value)}
                  />
                </strong>
                <div>
                  <textarea
                    value={contextData}
                    placeholder='문의해주실 내용을 입력해 주세요.'
                    onChange={e => setContextData(e.target.value)}></textarea>
                </div>
              </>
            ) : (
              <>
                <strong>{inquiryData?.title}</strong>
                <div>{inquiryData?.context}</div>
              </>
            )}
          </div>
          {inquiryData?.answer ? (
            <>
              <h5>
                <span>A.</span> 답변내용
              </h5>
              <div className='inquiry-content answer'>
                <div>{inquiryData?.answer}</div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className='list-move-btn-wrap'>
            <Link
              to='/my-page'
              exact='true'
              className='list-btn'
              title='목록버튼'>
              <img src={hamburger} alt='공지사항 목록으로 가기' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryDetails;
