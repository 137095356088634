//환불 계좌 리스트(KG이니시스용으로 아임포트에 정리되어 있는 값- https://api.iamport.kr/ 참고할 것)
export const bankList = [
  ['KG국민은행', '04'],
  ['SC제일은행', '23'],
  ['경남은행', '39'],
  ['광주은행', '34'],
  ['기업은행', '03'],
  ['농협', '11'],
  ['대구은행', '31'],
  ['부산은행', '32'],
  ['산업은행', '02'],
  ['새마을금고', '45'],
  ['수협', '07'],
  ['신한은행', '88'],
  ['신협', '48'],
  ['외환은행', '05'],
  ['우리은행', '20'],
  ['우체국', '71'],
  ['전북은행', '37'],
  ['축협', '16'],
  ['카카오뱅크', '90'],
  ['케이뱅크', '89'],
  ['하나은행(서울은행)', '81'],
  ['한국씨티은행(한미은행)', '53'],
];
//토큰 상태 리스트
export const tokenStatusList = {
  Pending: '결제대기',
  Issued: '발급완료',
  Active: '사용중',
  Applied: '적용완료',
  Inactive: '만료',
  Refunded: '환불',
  Revoked: '취소',
  Cancelled: '결제취소',
  Awaiting: '환불대기중',
};
//결제 수단 리스트
export const payMethodList = {
  card: '신용카드',
  vbank: '가상계좌',
};

//대행 출원타입 및 결제상태
export const applicantType = {
  FilingReq: '출원의뢰',
  FilingReqComp: '출원의뢰완료',
  FilingRecv: '출원의뢰접수완료',
  AppProcessing: '출원완료(심사중)',
  GenOpinion: '의견제출통지서 접수(일반의견대응)',
  ExpOpinion: '의견제출통지서 접수(전문의견대응)',
  SpecOpinion: '의견제출통지서 접수(특수대응)',
  GenExpOpinion: '의견제출통지서 접수(일반+전문의견대응)',
  GenSpecOpinion: '의견제출통지서 접수(일반+특수대응)',
  OpnSubmitted: '의견서 제출완료',
  PubApproved: '출원공고 결정(심사합격)',
  OppRecv: '이의신청서 접수',
  OppOngoing: '이의신청 심리중',
  RegDecRecv: '등록결정서 접수',
  RegReqRecv: '등록진행 의뢰접수',
  RegAbandon: '등록포기',
  RegComplete: '등록완료',
  RefusalRecv: '거절결정서 접수',
  TrialProc: '심판진행중',
  InvestCancel: '조사 후 취소',
  FilingCancel: '출원 후 취소(30일 이내)',
  CancelAll: '전체취소',
  Renewal: '갱신',
  dom_indiv: '국내개인',
  dom_corp: '국내법인',
  fgn_indiv: '해외개인',
  fgn_corp: '해외법인',
  dom_inst: '국가기관',
  normal: '일반출원',
  search: '조사출원',
  fast: '신속출원',
  fastsearch: '신속조사출원',
};

export const errorList = {
  wrongUser:
    '본인 인증 정보와 해당 아이디의 회원 정보가 다릅니다.\n아이디를 다시 확인해 주세요.',
  wrongId: '아이디 형식이 잘못되었습니다.\n다시 확인해 주세요.',
  wrongPw: '비밀번호 형식이 잘못되었습니다.\n다시 확인해 주세요.',
  wrongEmail: '이메일 형식이 잘못되었습니다.\n다시 확인해 주세요.',
  wrongBank: '잘못된 계좌 정보입니다.\n다시 입력해 주세요.',
  wrongAccess: '잘못된 접근입니다.',
  serverError: '잠시 후 다시 시도해 주세요.',
  alreadyId: '중복된 아이디입니다.',
  alreadyUser: '이미 가입되어 있습니다.',
  alreadyEmail: '다른 회원과 중복된 이메일입니다.\n다른 이메일을 입력해 주세요.',
  invalidId: '존재하지 않는 아이디입니다.',
  invalidPw: '비밀번호가 일치하지 않습니다.',
  invalidUser: '기존 회원 정보와 일치하지 않는 정보입니다.\n다시 인증해 주세요.',
  retired: '탈퇴한 회원입니다.',
  exceededLogin:
    '로그인 시도 횟수를 초과하였습니다.\n비밀번호 찾기에서 비밀번호를 변경해 주세요.',
  notUser: '존재하지 않는 아이디입니다.\n아이디를 다시 확인해 주세요.',
  wrongToken: '잘못된 접근입니다.\n다시 로그인해 주세요.',
  loginRequired: '로그인이 만료되었습니다.\n다시 로그인해 주세요.',
};
