import EcommerceComp from 'components/clauseComp/EcommerceComp';
import UseComp from 'components/clauseComp/UseComp';
import { useState } from 'react';

const Ecommerce = () => {
  //# use: 이용약관  commerce: 전자상거래 표준약관
  const [type, setType] = useState('use');
  return (
    <>
      <div className='personal-wrap'>
        <div className='use-btn-wrap'>
          <button
            className={type === 'use' ? 'active' : ''}
            onClick={() => setType('use')}>
            이용약관
          </button>
          <button
            className={type === 'commerce' ? 'active' : ''}
            onClick={() => setType('commerce')}>
            전자상거래 표준약관
          </button>
        </div>
        {type === 'use' ? <UseComp /> : <EcommerceComp />}
      </div>
    </>
  );
};

export default Ecommerce;
