//- Module
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//- Components
import CommonSiteMap from 'components/CommonSiteMap';
import AgenciesStep from 'components/agencies/AgenciesStep';
import CommonSelectMark from 'components/CommonSelectMark';
import CommonSelectClassification from 'components/CommonSelectClassification';
import CommonSelectProduct from 'components/CommonSelectProduct';
import InputApplicantInfo from './applicationAgencies/InputApplicantInfo';
import PaymentConfirm from './applicationAgencies/PaymentConfirm';
import TempSave from 'components/modal/TempSave';
//- Function
import {
  changeTitle,
  essentialValueCheck,
  formSwitchFn,
} from 'js/commonFn';
import { handleClickTopMove } from 'js/commonFn';
import { catchError } from 'js/commonFn';
import { getCookie } from 'js/cookie';
//- API
import {
  loadAgenciesTempData,
  agenciesFirstTemp,
  agenciesSecondTemp,
  agenciesThirdTemp,
  koreaPersonalTemp,
  koreaCorporationTemp,
  foreignPersonalYTemp,
  foreignPersonalNTemp,
  foreignCorporationTemp,
  nationalTemp,
  saveImage,
  deleteAgenciesTempData,
  saveApplication,
  applyApplication,
} from 'js/agenciesAPI';
import html2canvas from 'html2canvas';
import Loading from 'components/Loading';

const ApplicationAgencies = () => {
  const [step, setStep] = useState(1);
  const [markName, setMarkName] = useState('');
  const [tempModal, setTempModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [tempData, setTempData] = useState({});
  const [temp, setTemp] = useState(false);
  const [tempWhether, setTempWhether] = useState(false);
  const [load, setLoad] = useState(false);
  const page = 'agencies';

  //= selectMark State
  const [applicationType, setApplicationType] = useState('text');
  const [markNameArr, setMarkNameArr] = useState(['', '', '']);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [img, setImg] = useState('');

  //= selectClassification State
  const [selectSearchType, setSelectSearchType] = useState('select');
  const [categoryCode, setCategoryCode] = useState('');
  const [selectCategoryList, setSelectCategoryList] = useState([]);
  const [selectClassList, setSelectClassList] = useState([]);
  const [searchTxt, setSearchTxt] = useState(''); //- 검색어

  //= selectProduct State
  const [selectProductArr, setSelectProductArr] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [classArr, setClassArr] = useState([]);

  //= inputApplicantInfo State
  const [form, setForm] = useState('koreaPersonal');
  const [applicantInfo, setApplicantInfo] = useState({});

  //= paymentConfirm State
  const [paymentStep, setPaymentStep] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [application, setApplication] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [taxBillInfo, setTaxBillInfo] = useState({});

  const navigate = useNavigate();
  let prevent = false;

  //# selectMark Props
  const selectMarkProps = {
    applicationType, //~ 출원 유형
    setApplicationType,
    markNameArr, //~ 텍스트일 경우 텍스트 담는 배열
    setMarkNameArr,
    fileName, //~ 도형/복합일 경우 이미지 파일 이름
    setFileName,
    setFile, //~ 도형/복합일 경우 파일 저장
    img, //~ 도형/복합일 경우 이미지
    setImg,
    temp, //~ 임시 저장 여부
  };

  //# selectClassification Props
  const selectClassificationProps = {
    page, //~ 셀프 출원 / 상표 출원 대행 여부 (agencies/self)
    selectSearchType, //~ 입력 모드
    setSelectSearchType,
    selectCategoryList, //~ 선택한 카테고리
    setSelectCategoryList,
    selectClassList, //~ 선택한 류
    setSelectClassList,
    categoryCode, //~ 선택한 카테고리 이름
    setCategoryCode,
    temp, //~ 임시 저장 여부
    searchTxt,
    setSearchTxt,
  };

  //# selectProduct Props
  const selectProductProps = {
    page,
    selectProductArr,
    setSelectProductArr,
    selectClassList,
    selectProduct,
    setSelectProduct,
    selectSearchType,
    searchTxt,
    categoryCode,
    selectList,
    setSelectList,
    classArr,
    setClassArr,
    temp,
  };

  //# inputApplicantInfo Props
  const inputApplicantInfoProps = {
    form,
    setForm,
    applicantInfo,
    setApplicantInfo,
    selectProductArr,
    markName,
    temp,
  };

  //# paymentConfirm Props
  const paymentConfirmProps = {
    applicationType,
    markName,
    totalPrice,
    setTotalPrice,
    paymentStep,
    selectProductArr,
    application,
    setApplication,
    setPaymentMethod,
    paymentMethod,
    paymentInfo,
    taxBillInfo,
    setTaxBillInfo,
    form,
    temp,
  };

  //# TempSave Modal Props
  const tempSaveModalProps = {
    setTempModal,
    setTemp,
    modalType,
  };

  //* Image Upload Function
  const imageUpload = async (data, file, okFn) => {
    const formData = new FormData();
    formData.append('logo_agent', file);
    const imageUploadResult = await saveImage(formData);
    if (typeof imageUploadResult === 'object') {
      data.image_filename = file.name;
      data.image_url = imageUploadResult?.data[0]?.url;
      if (okFn) await okFn();
    } else return catchError(imageUploadResult, navigate);
  };

  //* tempData Binding
  const bindingTempData = () => {
    const { step1, step2, step3, step4 } = tempData;
    //= step 1
    setApplicationType(step1?.mark_type?.toLowerCase());
    if (step1?.mark_type === 'TEXT') {
      const markNameTempArr = [];
      for (let i = 0; i < 5; i++) {
        if (step1[`mark_name_step${i + 1}`])
          markNameTempArr[i] = step1[`mark_name_step${i + 1}`];
        else markNameTempArr.splice(i, 1);
      }
      setMarkNameArr(markNameTempArr);
    }
    setImg(step1?.image_url);
    setFileName(step1?.image_filename);
    //= step 2
    if (step2?.input_type === '직접입력') {
      setSelectSearchType('direct');
      setSearchTxt(step2?.input_keyword);
    } else if (step2?.input_type === '선택입력') {
      setSelectSearchType('select');
      setCategoryCode(step2?.category);
    }
    if (step2?.mark_class?.length) setSelectClassList(step2?.mark_class);
    //= step 3
    const product = [];
    const productArr = [];
    step3?.products?.forEach(({ mark_class, register_product }) => {
      register_product
        ?.split(',')
        ?.forEach(product_code => product.push(product_code));
      if (register_product?.split(',').length)
        productArr.push({
          mark_class: mark_class,
          register_product: register_product?.split(','),
        });
    });
    setSelectProductArr(productArr);
    setSelectProduct(product);
    //= step 4
    setForm(formSwitchFn('back2front', step4?.applicant_type));
    const applicantInfoObj = { ...step4 };
    applicantInfoObj.collection_consent = 'N'
    applicantInfoObj.delegation_consent = 'N'
    setApplicantInfo(applicantInfoObj);
    setTemp(true);
  };

  //* tempData Load
  const loadTempFn = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await loadAgenciesTempData();
    if (typeof result === 'object') {
      setTempModal(true);
      setTempWhether(true);
      setTempData(result?.data);
    }
  };

  //* tempData Delete
  const tempDeleteFn = async () => {
    const result = await deleteAgenciesTempData();
    if (typeof result !== 'object') return catchError(result, navigate);
  };

  //* tempData Save
  const tempSaveFn = async () => {
    const data = {};
    //= STEP 4
    const saveStep4 = async () => {
      let fourthResult;
      const formData = new FormData();
      switch (form) {
        case 'koreaCorporation':
          if (applicantInfo?.stamp_file)
            formData.append('domestic_corp_stamp', applicantInfo.stamp_file);
          break;
        case 'foreignPersonal':
          if (
            applicantInfo.foreigner_register_card_yn === 'y' &&
            applicantInfo.foreigner_register_card_front_file &&
            applicantInfo.foreigner_register_card_back_file
          ) {
            formData.append(
              'foreigner_register_card_front',
              applicantInfo?.foreigner_register_card_front_file
            );
            formData.append(
              'foreigner_register_card_back',
              applicantInfo?.foreigner_register_card_back_file
            );
          } else if (
            applicantInfo.foreigner_register_card_yn === 'n' &&
            applicantInfo.passport_file
          )
            formData.append('passport', applicantInfo.passport_file);
          if (applicantInfo.stamp_file)
            formData.append('foreigner_stamp', applicantInfo.stamp_file);
          break;
        case 'foreignCorporation':
          if (applicantInfo?.stamp_file)
            formData.append('overseas_corp_stamp', applicantInfo.stamp_file);
          break;
        case 'national':
          if (applicantInfo.stamp_file)
            formData.append(
              'domestic_institution_stamp',
              applicantInfo.stamp_file
            );
          break;
        default:
          break;
      }
      let bool;
      for (let key of formData.keys()) {
        if (key) bool = true;
        else bool = false;
      }
      if (bool) {
        const imageUploadResult = await saveImage(formData);
        if (typeof imageUploadResult === 'object') {
          imageUploadResult?.data?.forEach(({ imagetype, url }) => {
            switch (imagetype) {
              case 'domestic_corp_stamp':
                applicantInfo.stamp_filename = applicantInfo?.stamp_file?.name;
                applicantInfo.stamp_url = url;
                break;
              case 'foreigner_register_card_front':
                applicantInfo.foreigner_register_card_front_filename =
                  applicantInfo?.foreigner_register_card_front_file?.name;
                applicantInfo.foreigner_register_card_front_url = url;
                break;
              case 'foreigner_register_card_back':
                applicantInfo.foreigner_register_card_back_filename =
                  applicantInfo?.foreigner_register_card_back_file?.name;
                applicantInfo.foreigner_register_card_back_url = url;
                break;
              case 'passport':
                applicantInfo.passport_filename =
                  applicantInfo?.passport_file?.name;
                applicantInfo.passport_url = url;
                break;
              case 'foreigner_stamp':
                applicantInfo.foreigner_stamp_filename =
                  applicantInfo?.foreigner_stamp_file?.name;
                applicantInfo.foreigner_stamp_url = url;
                break;
              case 'overseas_corp_stamp':
                applicantInfo.stamp_filename = applicantInfo?.stamp_file?.name;
                applicantInfo.stamp_url = url;
                break;
              case 'domestic_institution_stamp':
                applicantInfo.stamp_filename = applicantInfo?.stamp_file?.name;
                applicantInfo.stamp_url = url;
                break;
              default:
                break;
            }
          });
        } else catchError(imageUploadResult, navigate);
      }
      switch (form) {
        case 'koreaPersonal':
          fourthResult = await koreaPersonalTemp(applicantInfo);
          break;
        case 'koreaCorporation':
          fourthResult = await koreaCorporationTemp(applicantInfo);
          break;
        case 'foreignPersonal':
          if (applicantInfo.foreigner_register_card_yn === 'y')
            fourthResult = await foreignPersonalYTemp(applicantInfo);
          else fourthResult = await foreignPersonalNTemp(applicantInfo);
          break;
        case 'foreignCorporation':
          fourthResult = await foreignCorporationTemp(applicantInfo);
          break;
        case 'national':
          fourthResult = await nationalTemp(applicantInfo);
          break;
        default:
          return;
      }
      setLoad(false);
      if (typeof fourthResult === 'object') {
        setModalType('save');
        setTempModal(true);
      } else return catchError(fourthResult, navigate);
    };
    //= STEP 3
    const saveStep3 = async () => {
      const thirdResult = await agenciesThirdTemp(selectProductArr);
      if (typeof thirdResult === 'object') {
        await saveStep4();
      } else {
        setLoad(false);
        return catchError(thirdResult, navigate);
      }
    };
    //= STEP 2
    const saveStep2 = async () => {
      const query = {
        input_type: selectSearchType === 'direct' ? '직접입력' : '선택입력',
        mark_class: selectClassList,
        category: categoryCode,
        input_keyword: searchTxt,
      };
      const secondResult = await agenciesSecondTemp(query);
      if (typeof secondResult === 'object') {
        await saveStep3();
      } else {
        setLoad(false);
        return catchError(secondResult, navigate);
      }
    };
    //= STEP 1
    const saveStep1 = async () => {
      if (tempWhether) {
        await tempDeleteFn();
        setTempWhether(false);
      }
      if (applicationType === 'text' && markNameArr.includes(''))
        return alert('상표명을 입력해 주세요.');
      else if (
        (applicationType === 'figure' || applicationType === 'compound') &&
        !img
      )
        return alert('이미지 파일을 업로드해 주세요.');
      data.mark_type = applicationType.toUpperCase();
      const firstResult = await agenciesFirstTemp(data);
      if (typeof firstResult === 'object') {
        await saveStep2();
      } else {
        setLoad(false);
        return catchError(firstResult, navigate);
      }
    };
    setLoad(true);
    for (let i = 0; i < 5; i++)
      data[`mark_name_step${i + 1}`] =
        markNameArr[i] === undefined ? '' : markNameArr[i];
    if (!file)
      await html2canvas(document.querySelector('.text-mark-preview'), {
        scale: 3,
      }).then(cvs => {
        cvs.toBlob(async blob => {
          await imageUpload(
            data,
            new File(
              [blob],
              `${markNameArr[0] || ''}${markNameArr[1] || ''}${
                markNameArr[2] || ''
              }${markNameArr[3] || ''}${markNameArr[4] || ''}.png`
            ),
            saveStep1
          );
        });
      });
    else await imageUpload(data, file, saveStep1);
  };

  //@ Step Handling Function
  const stepHandlingFn = async () => {
    if (step === 1) {
      if (applicationType === 'text' && markNameArr.includes(''))
        return alert('상표명을 입력해 주세요.');
      else if (
        (applicationType === 'figure' || applicationType === 'compound') &&
        !img
      )
        return alert('이미지 파일을 업로드해 주세요.');
      if (applicationType === 'text')
        await html2canvas(document.querySelector('.text-mark-preview'), {
          scale: 3,
        }).then(cvs => {
          cvs.toBlob(async blob => {
            setFile(
              new File(
                [blob],
                `${markNameArr[0] || ''}${markNameArr[1] || ''}${
                  markNameArr[2] || ''
                }${markNameArr[3] || ''}${markNameArr[4] || ''}.png`
              )
            );
          });
        });
      else {
      }
    } else if (step === 2 && !selectClassList.length)
      return alert('상품 분류를 선택해 주세요.');
    else if (step === 3 && classArr?.length !== selectProductArr?.length) {
      return alert('등록하실 상품을 모두 선택해 주세요.');
    } else if (step === 4) {
      const valCheckResult = essentialValueCheck(form, applicantInfo);
      if (!valCheckResult) return;
      if (applicantInfo?.collection_consent === 'N')
        return alert('개인 정보 수집에 동의해 주세요.');
      if (applicantInfo?.delegation_consent === 'N')
        return alert('위임에 동의해 주세요.');
    }
    return setStep(step + 1);
  };

  //~ useEffect~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  useEffect(() => {
    if (!getCookie('pickMyToken')) {
      navigate('/sign-in');
      return alert('로그인이 필요한 서비스입니다.');
    }
    changeTitle('마크픽 > 상표등록 대행');
    loadTempFn();
  }, []);

  useEffect(() => {
    if (temp) bindingTempData();
    else setTempData({});
  }, [temp]);

  useEffect(() => {
    handleClickTopMove();
  }, [step]);

  useEffect(() => {
    let str = JSON.stringify(markNameArr);
    str = str
      .replaceAll('"', '')
      .replaceAll('[', '')
      .replaceAll(']', '')
      .replaceAll(',', '');
    setMarkName(str);
  }, [markNameArr]);

  //~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  const requestPay = () => {
    let IMP = window.IMP;
    IMP.request_pay(
      {
        // param
        pg: 'html5_inicis.INIBillTst',
        pay_method: paymentMethod,
        merchant_uid: 'ORDER_TEST',
        name: '막그픽',
        amount: totalPrice,
        buyer_email: applicantInfo?.email,
        buyer_name: applicantInfo?.name_kor,
        buyer_tel: applicantInfo?.phone_number,
        buyer_addr: `${applicantInfo?.address} ${applicantInfo?.detail_address}`,
        buyer_postcode: applicantInfo?.zip_code,
      },
      rsp => {
        // callback
        if (rsp.success) {
          console.log(rsp);
          // 결제 성공 시 로직,
        } else {
          console.log(rsp);
          // 결제 실패 시 로직,
        }
      }
    );
  };

  const saveApplicationInfo = async () => {
    if (paymentMethod === '') return alert('결제 수단을 선택해 주세요.');
    if (temp) await tempDeleteFn();
    setLoad(true);
    const markNameObj = {};
    for (let i = 0; i < 5; i++)
      markNameObj[`mark_name_step${i + 1}`] =
        markNameArr[i] === undefined ? '' : markNameArr[i];
    const detailArr = [];
    selectProductArr?.forEach(({ mark_class, register_product }, idx) => {
      detailArr.push({
        mark_class: mark_class,
        register_product: register_product,
        apply_type: application[idx],
      });
    });
    const uploadData = {};
    const applyFn = async () => {
      let obj;
      let taxBillObj;
      if (applicationType === 'text') obj = { ...markNameObj, ...uploadData };
      else obj = { ...uploadData };
      if (paymentMethod === 'bank') taxBillObj = { ...taxBillInfo };
      else taxBillObj = {};
      const data = {
        info_base: {
          ...obj,
          ...taxBillObj,
          mark_type: applicationType.toUpperCase(),
          input_type: selectSearchType === 'direct' ? '직접입력' : '선택입력',
          input_keyword: searchTxt,
          category: categoryCode,
          applicant_type: formSwitchFn('front2back', form),
          application_number: '',
        },
        info_detail: detailArr,
        info_applicant: applicantInfo,
      };

      const saveResult = await saveApplication(data);
      if (typeof saveResult === 'object') {
        const result = await applyApplication(saveResult?.data?.apply_agent_pk);
        if (typeof result === 'object') {
          setPaymentInfo(result?.data?.data);
          setLoad(false);
          setPaymentMethod('bank');
          setPaymentStep(2);
        } else catchError(result, navigate);
      } else catchError(saveResult, navigate);
    };
    if (temp) {
      uploadData.image_url = img;
      uploadData.image_filename = fileName;
      applyFn();
    } else await imageUpload(uploadData, file, applyFn);
  };

  return (
    <>
      <div
        className={`content-wrap agencies ${
          step === 1
            ? 'select-mark'
            : step === 2
            ? 'select-classification'
            : step === 3
            ? 'select-product'
            : step === 4
            ? 'input-applicant'
            : step === 5
            ? 'payment-confirm'
            : ''
        }`}>
        <CommonSiteMap title='상표등록 대행' />
        <AgenciesStep step={step} />
        {step === 1 && <CommonSelectMark {...selectMarkProps} />}
        {step === 2 && (
          <CommonSelectClassification {...selectClassificationProps} />
        )}
        {step === 3 && <CommonSelectProduct {...selectProductProps} />}
        {step === 4 && <InputApplicantInfo {...inputApplicantInfoProps} />}
        {step === 5 && <PaymentConfirm {...paymentConfirmProps} />}
        <div className='btn-wrap row'>
          {step > 1 ? (
            step === 5 && paymentStep === 2 ? (
              <></>
            ) : (
              <button
                className='border-btn'
                onClick={() => {
                  if (step === 5)
                    setTemp(true)
                  setStep(step - 1);
                }}>
                이전
              </button>
            )
          ) : (
            <></>
          )}
          {(step === 5 && paymentStep === 1) || (
            <button
              className='gray-btn'
              onClick={() => {
                if (step === 5 && paymentStep === 1) {
                  setPaymentMethod('bank');
                  // setPaymentStep(2);
                  requestPay();
                } else if (step === 5 && paymentStep === 2)
                  return navigate('/my-page');
                else tempSaveFn();
              }}>
              {step === 5
                ? paymentStep === 1
                  ? '무통장입금'
                  : '마이페이지'
                : '임시 저장'}
            </button>
          )}
          <button
            className='gradient-btn'
            onClick={() => {
              if (step === 5 && paymentStep === 1) saveApplicationInfo();
              else if (step === 5 && paymentStep === 2) return navigate('/');
              else stepHandlingFn();
            }}>
            {step === 5 ? (paymentStep === 1 ? '결제' : '홈으로 가기') : '다음'}
          </button>
        </div>
      </div>
      {tempModal && <TempSave {...tempSaveModalProps} />}
      {load && <Loading />}
    </>
  );
};

export default ApplicationAgencies;
