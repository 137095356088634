import React, { useState, useEffect, useRef } from 'react';
import bottomArrow from 'image/Mypage/additional_btn.svg';
import upArrow from 'image/Mypage/additional_btn_active.svg';

const AdditionalSelectBox = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState('무통장입금');
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectRef]);

  const handleOptionClick = option => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  return (
    <div className='custom-select' ref={selectRef}>
      <div
        className={showOptions ? 'select-value on' : 'select-value'}
        onClick={() => setShowOptions(!showOptions)}>
        {selectedOption}
        {showOptions ? (
          <img src={upArrow} alt='결제 방법 선택하기' />
        ) : (
          <img src={bottomArrow} alt='결제 방법 선택하기' />
        )}
      </div>
      {showOptions && (
        <ul className={showOptions ? 'select-list on' : 'select-list'}>
          {options?.map(option => (
            <li key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdditionalSelectBox;
