import { useState, useEffect } from 'react';
import CommonCheckBox from 'components/CommonCheckBox';
import CommonSelect from 'components/CommonSelect';
import SearchCountry from '../SearchCountry';
import { changeState, fileSettingFn, inputCheck } from 'js/commonFn';
import { emailArr } from 'js/commonFn';

const ForeignPersonalForm = ({
  emailFilter,
  setEmailFilter,
  applicantInfo,
  setApplicantInfo,
  openPostModal,
}) => {
  const [searchModal, setSearchModal] = useState(false);
  const [email, setEmail] = useState({
    id: applicantInfo?.email?.split('@')[0] || '',
    domain: applicantInfo?.email?.split('@')[1] || '',
  });
  const [foreignNum, setForeignNum] = useState({
    first: applicantInfo?.foreigner_register_number?.split('-')[0] || '',
    last: applicantInfo?.foreigner_register_number?.split('-')[1] || '',
  });
  const [phoneNum, setPhoneNum] = useState({
    first: applicantInfo?.phone_number?.split('-')[0] || '',
    middle: applicantInfo?.phone_number?.split('-')[1] || '',
    last: applicantInfo?.phone_number?.split('-')[2] || '',
  });
  const [passport, setPassport] = useState(applicantInfo?.passport_file || '');
  const [registerFront, setRegisterFront] = useState(
    applicantInfo?.foreigner_register_card_front_file || ''
  );
  const [registerBack, setRegisterBack] = useState(
    applicantInfo?.foreigner_register_card_back_file || ''
  );
  const [sign, setSign] = useState(applicantInfo?.stamp_file || '');
  const [country, setCountry] = useState(applicantInfo?.country || '');

  useEffect(() => {
    changeState(setApplicantInfo, 'email', `${email.id}@${email.domain}`);
  }, [email]);

  useEffect(() => {
    if (applicantInfo.foreigner_register_card_yn === 'y')
      changeState(
        setApplicantInfo,
        'foreigner_register_number',
        `${foreignNum.first}-${foreignNum.last}`
      );
  }, [foreignNum]);

  useEffect(() => {
    if (applicantInfo.foreigner_register_card_yn === 'y')
      changeState(
        setApplicantInfo,
        'phone_number',
        `${phoneNum.first}-${phoneNum.middle}-${phoneNum.last}`
      );
  }, [phoneNum]);

  useEffect(() => {
    changeState(setApplicantInfo, 'passport_file', passport);
  }, [passport]);

  useEffect(() => {
    changeState(
      setApplicantInfo,
      'foreigner_register_card_front_file',
      registerFront
    );
  }, [registerFront]);

  useEffect(() => {
    changeState(
      setApplicantInfo,
      'foreigner_register_card_back_file',
      registerBack
    );
  }, [registerBack]);

  useEffect(() => {
    changeState(setApplicantInfo, 'country', country);
  }, [country]);

  useEffect(() => {
    changeState(setApplicantInfo, 'stamp_file', sign);
  }, [sign]);

  useEffect(() => {
    if (emailFilter === '직접 입력') changeState(setEmail, 'domain', '');
    else changeState(setEmail, 'domain', emailFilter);
  }, [emailFilter]);

  return (
    <>
      <div className='form'>
        <div className='row'>
          <span className='essential'>이름(국문)</span>
          <input
            type='text'
            className='common-input'
            placeholder='출원인 이름(국문) ex.한지민'
            onChange={e =>
              inputCheck('korean', e, setApplicantInfo, 'name_kor')
            }
            value={applicantInfo.name_kor || ''}
          />
        </div>
        <div className='row'>
          <span className='essential'>이름(영문)</span>
          <input
            type='text'
            className='common-input'
            placeholder='출원인 이름(영문) ex.Han Ji Min'
            onChange={e =>
              inputCheck('english', e, setApplicantInfo, 'name_eng')
            }
            value={applicantInfo.name_eng || ''}
          />
        </div>
        <div className='row'>
          <span className='essential'>국적</span>
          <input
            type='text'
            className='search-input'
            disabled
            value={country.split('/')[1]}
          />
          <button className='search-btn' onClick={() => setSearchModal(true)}>
            찾기
          </button>
        </div>
        <div className='row check-foreign-certification'>
          <span className='essential'>외국인 등록증</span>
          <div className='row check-wrap'>
            <input
              type='checkbox'
              id='certification-y'
              className='display-none'
              checked={applicantInfo.foreigner_register_card_yn === 'y'}
              onChange={() =>
                changeState(setApplicantInfo, 'foreigner_register_card_yn', 'y')
              }
            />
            <CommonCheckBox
              id='certification-y'
              state={applicantInfo.foreigner_register_card_yn === 'y'}
              str='있음'
            />
            <input
              type='checkbox'
              id='certification-n'
              className='display-none'
              checked={applicantInfo.foreigner_register_card_yn === 'n'}
              onChange={() =>
                changeState(setApplicantInfo, 'foreigner_register_card_yn', 'n')
              }
            />
            <CommonCheckBox
              id='certification-n'
              state={applicantInfo.foreigner_register_card_yn === 'n'}
              str='없음'
            />
          </div>
        </div>
      </div>
      {applicantInfo.foreigner_register_card_yn === '' ? (
        ''
      ) : (
        <>
          <hr className='dashed foreign' />
          <div className='form'>
            {applicantInfo.foreigner_register_card_yn === 'y' ? (
              <>
                <div className='row'>
                  <span className='essential'>외국인등록증 사본</span>
                  <input
                    type='file'
                    id='copy-front'
                    className='display-none'
                    accept='image/*'
                    onChange={e => fileSettingFn(e, setRegisterFront)}
                  />
                  <label htmlFor='copy-front' className='copy-btn'>
                    앞면
                  </label>
                  <div className='file-name-input'>
                    {registerFront.name || '외국인 등록증 앞면'}
                  </div>
                </div>
                <div className='row'>
                  <input
                    type='file'
                    id='copy-back'
                    className='display-none'
                    accept='image/*'
                    onChange={e => fileSettingFn(e, setRegisterBack)}
                  />
                  <label htmlFor='copy-back' className='copy-btn'>
                    뒷면
                  </label>
                  <div className='file-name-input'>
                    {registerBack.name || '외국인 등록증 뒷면'}
                  </div>
                </div>
                <div className='row'>
                  <span className='essential'>외국인 등록번호</span>
                  <input
                    type='text'
                    className='id-input'
                    onChange={e =>
                      inputCheck('number', e, setForeignNum, 'first')
                    }
                    value={foreignNum.first || ''}
                    maxLength={6}
                  />
                  <span className='hyphen'>-</span>
                  <input
                    type='password'
                    className='id-input'
                    onChange={e =>
                      inputCheck('number', e, setForeignNum, 'last')
                    }
                    value={foreignNum.last || ''}
                    maxLength={7}
                  />
                </div>
                <div className='row'>
                  <span className='essential'>이메일</span>
                  <input
                    type='text'
                    className='email-input'
                    onChange={e => inputCheck('email', e, setEmail, 'id')}
                    value={email.id || ''}
                  />
                  <span className='at'>@</span>
                  <input
                    type='text'
                    className='email-input'
                    onChange={e => inputCheck('email', e, setEmail, 'domain')}
                    value={email.domain || ''}
                    disabled={emailFilter !== '직접 입력'}
                  />
                  <CommonSelect
                    opt={emailArr}
                    selectVal={emailFilter}
                    setSelectVal={setEmailFilter}
                  />
                </div>
                <div className='row'>
                  <span className='essential'>휴대전화</span>
                  <input
                    type='text'
                    className='phone-input'
                    onChange={e =>
                      inputCheck('number', e, setPhoneNum, 'first')
                    }
                    value={phoneNum.first || ''}
                    maxLength={3}
                  />
                  <span className='hyphen'>-</span>
                  <input
                    type='text'
                    className='phone-input'
                    onChange={e =>
                      inputCheck('number', e, setPhoneNum, 'middle')
                    }
                    value={phoneNum.middle || ''}
                    maxLength={4}
                  />
                  <span className='hyphen'>-</span>
                  <input
                    type='text'
                    className='phone-input'
                    onChange={e => inputCheck('number', e, setPhoneNum, 'last')}
                    value={phoneNum.last || ''}
                    maxLength={4}
                  />
                </div>
                <div className='row'>
                  <span className='essential'>우편번호</span>
                  <input
                    type='text'
                    className='search-input'
                    value={applicantInfo.zip_code || ''}
                    maxLength={5}
                    disabled
                  />
                  <button className='search-btn' onClick={openPostModal}>
                    찾기
                  </button>
                </div>
                <div className='row'>
                  <span className='essential'>등본상 주소</span>
                  <input
                    type='text'
                    className='common-input'
                    placeholder='주소 입력'
                    value={applicantInfo.address || ''}
                    disabled
                  />
                </div>
                <div className='row'>
                  <input
                    type='text'
                    className='common-input'
                    placeholder='상세 주소 입력'
                    onChange={e =>
                      changeState(
                        setApplicantInfo,
                        'detail_address',
                        e.target.value
                      )
                    }
                    value={applicantInfo.detail_address || ''}
                  />
                </div>
                <div className='row'>
                  <span className='optional'>서명</span>
                  <input
                    type='file'
                    id='file-input'
                    className='display-none'
                    accept='image/*'
                    onChange={e => fileSettingFn(e, setSign)}
                  />
                  <div className='row file-input'>
                    <label htmlFor='file-input' className='select-file-button'>
                      파일선택
                    </label>
                    <div className='file-name-input'>
                      {sign.name || '선택된 파일 없음'}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='row'>
                  <span className='optional'>여권사본</span>
                  <input
                    type='file'
                    id='file-input'
                    className='display-none'
                    accept='image/*'
                    onChange={e => fileSettingFn(e, setPassport)}
                  />
                  <div className='row file-input'>
                    <label htmlFor='file-input' className='select-file-button'>
                      파일선택
                    </label>
                    <div className='file-name-input'>
                      {passport.name || '여권사본 사진 부착면'}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <span className='essential'>여권번호</span>
                  <input
                    type='text'
                    className='common-input'
                    onChange={e =>
                      inputCheck(
                        'eng&num',
                        e,
                        setApplicantInfo,
                        'passport_number'
                      )
                    }
                    value={applicantInfo.passport_number || ''}
                    maxLength={8}
                  />
                </div>
                <div className='row'>
                  <span className='essential'>이메일</span>
                  <input
                    type='text'
                    className='email-input'
                    onChange={e => inputCheck('email', e, setEmail, 'id')}
                    value={email.id || ''}
                  />
                  <span className='at'>@</span>
                  <input
                    type='text'
                    className='email-input'
                    onChange={e => inputCheck('email', e, setEmail, 'domain')}
                    value={email.domain || ''}
                  />
                  <CommonSelect
                    opt={emailArr}
                    selectVal={emailFilter}
                    setSelectVal={setEmailFilter}
                  />
                </div>
                <div className='row'>
                  <span className='essential'>해외 주소(영문)</span>
                  <input
                    type='text'
                    className='common-input'
                    onChange={e =>
                      inputCheck(
                        'eng&num',
                        e,
                        setApplicantInfo,
                        'overseas_address'
                      )
                    }
                    value={applicantInfo.overseas_address || ''}
                  />
                </div>
                <div className='row'>
                  <span className='optional'>서명</span>
                  <input
                    type='file'
                    id='sign-file-input'
                    className='display-none'
                    accept='image/*'
                    onChange={e => fileSettingFn(e, setSign)}
                  />
                  <div className='row file-input'>
                    <label
                      htmlFor='sign-file-input'
                      className='select-file-button'>
                      파일선택
                    </label>
                    <div className='file-name-input'>
                      {sign.name || '선택된 파일 없음'}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      {searchModal && (
        <SearchCountry
          setSearchModal={setSearchModal}
          country={country}
          setCountry={setCountry}
        />
      )}
    </>
  );
};

export default ForeignPersonalForm;
