import { useState } from 'react';
import IntroGeneral from './IntroGeneral';
import IntroQuick from './IntroQuick';
import IntroQuickSurvey from './IntroQuickSurvey';
import IntroSurvey from './IntroSurvey';

const AgencyIntroduction = () => {
  // # general: 일반출원 survey: 조사출원 quick: 신속출원 quicksurvey: 신속조사출원
  const [applicationName, setApplicationName] = useState('general');
  return (
    <>
      <div className='intro-btn-wrap'>
        <button
          className={applicationName === 'general' ? 'active' : ''}
          onClick={() => setApplicationName('general')}>
          일반출원
        </button>
        <button
          className={applicationName === 'survey' ? 'active' : ''}
          onClick={() => setApplicationName('survey')}>
          조사출원
        </button>
        <button
          className={applicationName === 'quick' ? 'active' : ''}
          onClick={() => setApplicationName('quick')}>
          신속출원
        </button>
        <button
          className={applicationName === 'quicksurvey' ? 'active' : ''}
          onClick={() => setApplicationName('quicksurvey')}>
          신속조사출원
        </button>
      </div>
      {applicationName === 'general' && <IntroGeneral />}
      {applicationName === 'survey' && <IntroSurvey />}
      {applicationName === 'quick' && <IntroQuick />}
      {applicationName === 'quicksurvey' && <IntroQuickSurvey />}
    </>
  );
};

export default AgencyIntroduction;
