import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import activeSelect from 'image/activeSelect.svg';
import checkSvg from 'image/check.svg';
import { enterFn } from 'js/commonFn';
import {
  searchClassification,
  getCategories,
  getClasses,
} from 'js/agenciesAPI';
import { catchError } from 'js/commonFn';
import {
  getSelectCategory,
  getSelectSelfSub,
  getSelfCategoryDirect,
} from 'js/selfAPI';

const CommonSelectClassification = ({
  page, //~ 셀프 출원 / 상표 출원 대행 여부 (agencies/self)
  selectSearchType, //~ 입력 모드
  setSelectSearchType,
  selectCategoryList, //~ 선택한 카테고리
  setSelectCategoryList,
  selectClassList, //~ 선택한 류
  setSelectClassList,
  categoryCode, //~ 선택한 카테고리 이름
  setCategoryCode,
  temp, //~ 임시 저장 여부
  searchTxt,
  setSearchTxt,
  importTemp,
}) => {
  const [categoryArr, setCategoryArr] = useState([]); //- 카테고리 리스트
  const [directList, setDirectList] = useState([]); //- 직접입력 검색 시 검색 결과
  const [selectList, setSelectList] = useState([]); //- 선택입력 카테고리 선택 시 결과
  const [classification, setClassification] = useState(false); //- 분류 렌더 여부

  let prevent = false;
  const navigate = useNavigate();

  //= 선택 입력일 경우 상품 분류
  const renderSelectResult = () => {
    return selectList?.map(
      ({ kind_code, kind_name, img_url, recommend_yn }) => {
        const classListHandling = () => {
          setSelectClassList(prev => {
            const clone = [...prev];
            if (selectClassList.includes(kind_code)) {
              $(`.class-${kind_code}`).removeClass('active');
              clone.splice(clone.indexOf(kind_code), 1);
            } else {
              $(`.class-${kind_code}`).addClass('active');
              clone.push(kind_code);
            }
            return clone;
          });
        };

        return (
          <>
            <div
              className={`row classification-wrap class-${kind_code} ${
                recommend_yn ? 'on' : 'off'
              }`}
              onClick={classListHandling}
              key={kind_code}>
              {recommend_yn && <img src={img_url} alt='샘플 이미지' />}
              <div className='column'>
                <span>{kind_code}류</span>
                <span>{kind_name}</span>
              </div>
              <input
                type='checkbox'
                className='display-none'
                id={`check-class-${kind_code}`}
                onChange={e => {
                  if (e.target.checked)
                    $(`.class-${kind_code}`).addClass('active');
                  else $(`.class-${kind_code}`).removeClass('active');
                }}
              />
              {recommend_yn && <button className='recommend'>추천</button>}
              <label htmlFor={`check-class-${kind_code}`}>
                <div className='column'>
                  <img src={checkSvg} alt='체크 표시' />
                </div>
              </label>
            </div>
          </>
        );
      },
      <></>
    );
  };

  //= 직접 입력일 경우 상품 분류
  const renderSearchResult = () => {
    if (directList.length)
      return directList?.map(({ maincode, subcode }) => {
        let subcodeStr = JSON.stringify(subcode.slice(0, 2));
        subcodeStr = subcodeStr
          .replaceAll('"', '')
          .replaceAll('[', '')
          .replaceAll(']', '');

        const classListHandling = () => {
          setSelectClassList(prev => {
            const clone = [...prev];
            if (selectClassList.includes(maincode)) {
              $(`.class-${maincode}`).removeClass('active');
              clone.splice(clone.indexOf(maincode), 1);
            } else {
              $(`.class-${maincode}`).addClass('active');
              clone.push(maincode);
            }
            return clone;
          });
        };

        return (
          <div
            className={`row classification-wrap class-${maincode} off`}
            onClick={classListHandling}
            key={maincode}>
            <div className='column'>
              <span>{maincode}류</span>
              <span>
                {/* 띄어쓰기 되어 있는 쉼표와 안 되어 있는 쉼표들을 동일하게 띄어쓰기
              되어 있는 쉼표로 만들기 위한 과정 */}
                {subcodeStr.replaceAll(', ', ',').replaceAll(',', ', ')}
              </span>
            </div>
            <input
              type='checkbox'
              className='display-none'
              id={`check-class-${maincode}`}
              onChange={e => {
                classListHandling();
                if (e.target.checked)
                  $(`.class-${maincode}`).addClass('active');
                else $(`.class-${maincode}`).removeClass('active');
              }}
            />
            <label htmlFor={`check-class-${maincode}`}>
              <div className='column'>
                <img src={checkSvg} alt='체크 표시' />
              </div>
            </label>
          </div>
        );
      }, <></>);
    else
      return (
        <>
          <div className='column none-list'>검색 결과가 없습니다.</div>
        </>
      );
  };

  //= 선택 입력 - 분류 가져오기
  const getClassList = async () => {
    let result;
    if (page === 'agencies') {
      result = await getClasses(selectCategoryList);
    } else {
      result = await getSelectSelfSub(selectCategoryList);
    }
    if (typeof result === 'object') {
      setSelectList(result?.data);
    } else return catchError(result, navigate);
  };

  //= 카테고리 리스트 가져오기
  const getCategoryList = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    let result;
    if (page === 'agencies') result = await getCategories();
    else {
      result = await getSelectCategory();
    }
    if (typeof result === 'object') {
      setCategoryArr(result?.data);
      if (temp || importTemp)
        result?.data?.forEach(({ category_code }) => {
          if (category_code === categoryCode)
            setSelectCategoryList(category_code);
          else return;
        });
    } else return catchError(result, navigate);
  };

  //= 검색 결과
  const getSearchResult = async () => {
    if (!searchTxt) return alert('검색어를 입력해 주세요.');
    let result;
    if (page === 'agencies') result = await searchClassification(searchTxt);
    else {
      result = await getSelfCategoryDirect(searchTxt);
    }
    if (typeof result === 'object') {
      setClassification(true);
      setDirectList(result?.data);
    } else return catchError(result, navigate);
  };

  useEffect(() => {
    if (selectCategoryList.length) getClassList();
  }, [selectCategoryList]);

  useEffect(() => {
    if (searchTxt !== '') getSearchResult();
  }, []);

  useEffect(() => {
    setClassification(false);
    if (selectSearchType === 'select') getCategoryList();
    setTimeout(() => {
      for (let i of selectClassList) $(`.class-${i}`).addClass('active');
    }, 200);
    setClassification(true);
  }, [selectSearchType]);

  useEffect(() => {
    if (page === 'agency' && !temp) {
      setSelectSearchType('select');
    }
    if ((temp || importTemp) && selectSearchType === 'direct') {
      getSearchResult();
    }
  }, []);

  return (
    <>
      <div className='content-box'>
        <h3>카테고리 및 상품분류 선택</h3>
        <hr />
        <div className='content'>
          <div className='row select-wrap'>
            <div
              className={`column select-box ${
                selectSearchType === 'direct' ? 'active' : ''
              }`}
              onClick={() => {
                setSelectList([]);
                setSelectSearchType('direct');
              }}>
              <h5>직접입력</h5>
              {selectSearchType === 'direct' && (
                <img src={activeSelect} alt='체크 이미지' />
              )}
              <span>
                직접 입력하여
                <br />
                상품분류 선택
              </span>
            </div>
            <div
              className={`column select-box ${
                selectSearchType === 'select' ? 'active' : ''
              }`}
              onClick={() => {
                setDirectList([]);
                setSearchTxt('');
                setSelectSearchType('select');
              }}>
              <h5>선택입력</h5>
              {selectSearchType === 'select' && (
                <img src={activeSelect} alt='체크 이미지' />
              )}
              <span>
                카테고리로 선택하여
                <br />
                상품분류 선택
              </span>
            </div>
          </div>
          {selectSearchType === 'direct' ? (
            <>
              <p>상품 직접입력</p>
              <div className='row direct-input-wrap'>
                <input
                  type='text'
                  placeholder='상품분류를 입력하세요.'
                  className='common-text-input'
                  onChange={e => setSearchTxt(e.target.value)}
                  value={searchTxt}
                  onKeyDown={e => enterFn(e, getSearchResult)}
                />
                <button className='search-btn' onClick={getSearchResult}>
                  검색
                </button>
              </div>
              <p>상품분류 선택</p>
              {classification ? (
                <div className='column classification'>
                  {renderSearchResult()}
                </div>
              ) : (
                <div className='column none-list'>입력한 상품이 없습니다.</div>
              )}
            </>
          ) : (
            <>
              <p>카테고리 선택</p>
              <div className='row box-wrap'>
                {categoryArr?.map(({ category_name, category_code }) => {
                  return (
                    <span
                      className={`column box ${
                        category_code === selectCategoryList ? 'active' : ''
                      }`}
                      onClick={() => {
                        setCategoryCode(category_code);
                        setSelectCategoryList(category_code);
                      }}
                      key={category_name}>
                      {category_name}
                    </span>
                  );
                }, <></>)}
              </div>
              {selectCategoryList.length ? (
                <>
                  <p>상품분류 선택</p>
                  <div className='classification column'>
                    {renderSelectResult()}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CommonSelectClassification;
