import { Link } from 'react-router-dom';
import home from 'image/Customer/home.svg';
import ic_arrow from 'image/Customer/ic_arrow.svg';

const CommonSiteMap = ({ title }) => {
  return (
    <>
      <div className='title'>
        <div className='img-wrap'>
          <Link to='/'>
            <img src={home} alt='마크픽 메인페이지로 이동' />
          </Link>
          <img src={ic_arrow} alt='화살표 아이콘' />
          <span>{title}</span>
        </div>
      </div>
    </>
  );
};

export default CommonSiteMap;
