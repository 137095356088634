import { useState, useEffect } from 'react';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
//# 컴포넌트
import CustomerHead from 'components/CustomerHead';
import CustomerTitle from 'components/CustomerTitle';
//! 이미지(SVG)
import people2 from 'image/Customer/people2.png';
import { catchError, textCounting } from 'js/commonFn';
import { changeState, changeTitle } from 'js/commonFn';
import { createInquiry } from 'js/selfAPI';
import { getCookie } from 'js/cookie';

const Inquiry = () => {
  //# 글자수 관련 state
  const [textCount, setTextCount] = useState({ title: 0, context: 0 });
  //# 1:1문의글 작성 관련 state
  const [textParams, setTextParams] = useState({
    title: '',
    context: '',
    service_code: 100,
  });
  //# 알림창 관련 state
  const [alertMsg, setAlertMsg] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    changeTitle('마크픽 > 1:1 문의');
  }, []);

  const postInquiry = async () => {
    if (getCookie('pickMyToken') === undefined) {
      alert('로그인후 시도해 주세요.');
      return navigate('/sign-in');
    }
    if (textParams.title.trim() === '') {
      $('.i-data-title').focus();
      return alert('제목을 입력해 주세요.');
    } else if (textParams.context.trim() === '') {
      $('.i-data-context').focus();
      return alert('내용을 입력해 주세요.');
    }
    let result;
    const confirm = window.confirm(
      '1:1 문의를 등록하시겠습니까?\n등록 후 마이페이지로 이동됩니다.'
    );
    if (confirm) {
      result = await createInquiry(textParams);
    } else {
      return;
    }
    if (typeof result === 'object') {
      if (confirm) {
        //입력값 초기화
        $('.i-data-title,.i-data-context').val('');
        setTextParams({ title: '', context: '' });
        navigate('/my-page');
      }
    } else return catchError(result, navigate)
  };

  return (
    <div className='content-customer-wrap'>
      <CustomerHead step='1:1 문의' />
      <div className='customer inquiry'>
        <CustomerTitle
          title='1:1 문의'
          description='마크픽 이용 중 궁금하시거나 불편했던 사항을 남겨주세요.'
        />
        <div className='faq-move'>
          <h4>문의하기 전 FAQ를 확인해 주세요.</h4>
          <Link to='/faq'>FAQ로 이동하기 &#62;</Link>
          <p>평일 오후 4시 이후 문의는 당일 답변이 어려울 수 있습니다.</p>
          <p>
            토, 일요일 및 공휴일 문의는 평일 운영 시간 내 순차적으로
            답변드립니다.
          </p>
          <img src={people2} alt='1:1문의 전 FAQ를 확인해 주세요.' />
        </div>
        <div className='inquiry-details'>
          <button>
            <Link to='/my-page'>
              문의내역 확인 <span>&#62;</span>
            </Link>
          </button>
        </div>
        <ul className='inquiry-form'>
          <li>
            <span>*</span>
            <span>필수입력 사항</span>
          </li>
          <li>
            <div className='form-title'>
              <span>*</span>
              <h4>제목</h4>
            </div>
            <div className='form-content'>
              <input
                type='text'
                placeholder='문의 제목을 입력하세요.'
                className='i-data-title'
                value={textParams.title || ''}
                onChange={e => {
                  changeState(setTextParams, 'title', e.target.value);
                  textCounting(
                    e,
                    50,
                    'title',
                    setTextParams,
                    setAlertMsg,
                    setTextCount
                  );
                }}
              />
            </div>
            <span className='text-count'>{textCount.title}/50 byte</span>
          </li>
          <li>
            <div className='form-title'>
              <span>*</span>
              <h4>내용</h4>
            </div>
            <div className='form-content'>
              <textarea
                placeholder='문의 내용을 입력하세요.'
                value={textParams.context || ''}
                className='i-data-context'
                onChange={e =>
                  changeState(setTextParams, 'context', e.target.value)
                }
                onKeyUp={e =>
                  textCounting(
                    e,
                    3000,
                    'context',
                    setTextParams,
                    setAlertMsg,
                    setTextCount
                  )
                }></textarea>
            </div>
            <span className='text-count'>{textCount.context}/3000 byte</span>
          </li>
        </ul>
        <div className='inquiry-btn-wrap'>
          <button
            onClick={() => {
              postInquiry();
            }}>
            등록하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
