import axios from 'axios';
import { apiErrorHandling, formDataHeader, tokenHeader } from './commonFn';

//& 인증
//= 다날 본인인증
export const certification = callBackFn => {
  const IMP = window.IMP;
  const data = { popup: false };
  IMP.certification(data, callBackFn);
};

//= imp_uid로 userInfo 가져오는 API
export const getDanalInfo = async uid => {
  try {
    return await axios.post('/v1/auth/certification', { imp_uid: uid });
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//& 회원가입
//= 아이디 중복체크
export const duplicationIdCheck = async user_id => {
  try {
    return await axios.get(`/v1/auth/check/id-duplicates?user_id=${user_id}`);
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= 이미 가입된 회원인지 체크
export const duplicationUserCheck = async unique_key => {
  try {
    return await axios.post(`/v1/auth/check/user-exists`, {
      unique_key: unique_key,
    });
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= 회원가입
export const signUp = async info => {
  try {
    return await axios.post(`/v1/auth/signup`, info);
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//& 로그인
//= 로그인
export const signIn = async info => {
  try {
    return await axios.post(`/v1/auth/login`, info);
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//& 아이디/비밀번호 찾기
//= 아이디 찾기
export const findId = async unique_key => {
  try {
    return await axios.post(`/v1/auth/find-id`, { unique_key: unique_key });
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= 비밀번호 찾기 - 아이디 있는지 확인
export const idExistsCheck = async id => {
  try {
    return await axios.get(`/v1/auth/check/id-exists?user_id=${id}`);
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

export const changePw = async query => {
  try {
    return await axios.post(`/v1/auth/change-pw`, query);
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//& Application Agencies
//= 임시 저장 데이터 불러오기
export const loadAgenciesTempData = async () => {
  try {
    return await axios.get(`/v1/agency/temp-data`, tokenHeader());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= 임시 저장 데이터 삭제
export const deleteAgenciesTempData = async () => {
  try {
    return await axios.delete(`/v1/agency/temp-data`, tokenHeader());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= 이미지 Upload
export const saveImage = async formData => {
  try {
    return await axios.post(
      `/v1/agency/upload-image`,
      formData,
      formDataHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 1 임시 저장
export const agenciesFirstTemp = async data => {
  try {
    return await axios.post(`/v1/agency/step1`, data, tokenHeader());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 2 상품 분류 검색
export const searchClassification = async search_txt => {
  try {
    return await axios.get(
      `/v1/agency/search?input_text=${search_txt}`,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 2 상품 카테고리 불러오기
export const getCategories = async () => {
  try {
    return await axios.get(`/v1/agency/categories`, tokenHeader());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 2 상품 분류 불러오기
export const getClasses = async category_code => {
  try {
    return await axios.get(
      `/v1/agency/get-product-kind?category_code=${category_code}`,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 2 임시 저장
export const agenciesSecondTemp = async query => {
  try {
    return await axios.post(`/v1/agency/step2`, query, tokenHeader());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 3 등록 상품 검색
export const searchProduct = async (mark_class, search_txt) => {
  try {
    return await axios.get(
      `/v1/agency/search-product?mark_class=${mark_class?.replace(
        '류',
        ''
      )}&search_text=${search_txt}`,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 3 등록 상품 불러오기
export const getProducts = async (searchType, classNum, data) => {
  try {
    if (classNum === '') return;
    return await axios.get(
      `/v1/agency/product?direct=${
        searchType === 'direct'
      }&mark_class=${classNum}&${
        searchType === 'direct' ? `search_text` : 'category_code'
      }=${data}`,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 3 임시 저장
export const agenciesThirdTemp = async productArr => {
  try {
    return await axios.post(
      `/v1/agency/step3`,
      { products: productArr },
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 4 국내 개인 임시 저장
export const koreaPersonalTemp = async info => {
  try {
    return await axios.post(`/v1/agency/domestic-indiv`, info, tokenHeader());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 4 국내 법인 임시 저장
export const koreaCorporationTemp = async applicantInfo => {
  try {
    return await axios.post(
      `/v1/agency/domestic-corp`,
      applicantInfo,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 4 해외 개인 외국인 등록증 있을 때 임시 저장
export const foreignPersonalYTemp = async applicantInfo => {
  try {
    return await axios.post(
      `/v1/agency/overseas-indiv-arc`,
      applicantInfo,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 4 해외 개인 외국인 등록증 있을 때 임시 저장
export const foreignPersonalNTemp = async applicantInfo => {
  try {
    return await axios.post(
      `/v1/agency/overseas-indiv`,
      applicantInfo,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 4 해외 법인 임시 저장
export const foreignCorporationTemp = async applicantInfo => {
  try {
    return await axios.post(
      `/v1/agency/overseas-corp`,
      applicantInfo,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 4 해외 개인 임시 저장
export const nationalTemp = async applicantInfo => {
  try {
    return await axios.post(
      `/v1/agency/domestic-inst`,
      applicantInfo,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 4 국가 코드 불러오기
export const getCountries = async () => {
  try {
    return await axios.get(`/v1/agency/get-country`, tokenHeader());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 4 국가 코드 검색
export const getCountry = async searchTxt => {
  try {
    return await axios.get(
      `/v1/agency/find-country?input_keyword=${searchTxt}`,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 5 고시/비고시 여부
export const getNoticeBool = async mark_classes => {
  try {
    return await axios.post(
      `/v1/agency/calculate`,
      mark_classes,
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 5 저장
export const saveApplication = async data => {
  try {
    return await axios.post(`/v1/agency/save`, data, tokenHeader());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//= STEP 5 등록
export const applyApplication = async (pk) => {
  try {
    return await axios.post(`/v1/order/create?service=agency&id=${pk}`, {}, tokenHeader())
  } catch (error) {
    return await apiErrorHandling(error)
  }
}

//# 마이페이지 필요서류 이미지 등록
export const uploadRequireDocsImg = async formData => {
  try {
    return await axios.post(
      `/v1/my-page/agency/upload-image`,
      formData,
      formDataHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 마이페이지 필요서류 등록
export const applyRequireDocs = async (type, img_url, file_name) => {
  try {
    return await axios.post(
      `/v1/my-page/agency/essential-docs?docs_type=${type}`,
      { img_url: img_url, file_name: file_name },
      tokenHeader()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
