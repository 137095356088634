import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAgentMyOrder, getAgentDetail, getSelectCategory } from 'js/selfAPI';
import { catchError } from 'js/commonFn';
import close from '../../image/Modal/modal-close.svg';

const MypageAgent = ({ setAgentPopup, agentPopupId }) => {
  const [agentData, setAgentData] = useState([]);
  const [category, setCategory] = useState([]);
  const [creditData, setCreditData] = useState([]);

  const navigate = useNavigate();
  let prevent = false;

  const getCategoryName = categoryCode => {
    const categoryList = category.find(
      item => item.category_code === categoryCode
    );
    return categoryList ? categoryList.category_name : '';
  };

  const getMyOrder = async () => {
    const result = await getAgentMyOrder(agentPopupId);
    if (typeof result === 'object') {
      setCreditData(result?.data?.data);
    } else return catchError(result, navigate);
  };

  const getAgentDetailFunc = async () => {
    const result = await getAgentDetail(agentPopupId);
    if (typeof result === 'object') {
      setAgentData(result?.data);
      getMyOrder();
    } else return catchError(result, navigate);
  };

  const getCategory = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getSelectCategory();
    if (typeof result === 'object') {
      setCategory(result?.data);
      await getAgentDetailFunc();
    } else return catchError(result, navigate);
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <div className='modal-bg'>
      <div className='modal column mypage-agent-popup'>
        <div className='head'>
          <h4>출원대행 상세보기</h4>
          <img
            src={close}
            alt='출원대행 상세보기 닫기'
            onClick={() => setAgentPopup(false)}
          />
        </div>
        <hr />
        <div className='applicant-info'>
          <h4>1. 출원인 정보</h4>
          <div className='info-wrap'>
            <div className='info-one'>
              <div className='name-kr'>
                <div className='name-title'>이름(한글)</div>
                <div className='name'>{agentData?.name_kor}</div>
              </div>
              <div className='name-en'>
                <div className='name-title'>이름(영문)</div>
                <div className='name w-178'>{agentData?.name_eng}</div>
              </div>
            </div>
            <div className='info-one'>
              <div className='name-kr'>
                <div className='name-title'>휴대전화</div>
                <div className='name'>{agentData?.phone_number}</div>
              </div>
              <div className='name-en'>
                <div className='name-title'>이메일</div>
                <div className='name w-178'>{agentData?.email}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='mark-info'>
          <h4>2. 상표 정보</h4>
          <div className='info-wrap'>
            <div className='mark-type'>
              <div className='name-title'>상표유형</div>
              <div className='content'>
                {agentData?.mark_type === 'TEXT'
                  ? '문자상표'
                  : agentData?.mark_type === 'COMPOUND'
                  ? '복합상표'
                  : '도형상표'}
              </div>
            </div>
            {agentData?.mark_name && (
              <div className='mark-name'>
                <div className='name-title'>상표명</div>
                <div className='content'>{agentData?.mark_name}</div>
              </div>
            )}
            <div className='mark-preview'>
              <div className='name-title'>미리보기</div>
              <div className='img-wrap'>
                <img src={agentData?.image_url} alt='출원 상표 이미지' />
              </div>
            </div>
          </div>
        </div>
        <div className='category-info'>
          <h4>3. 카테고리 정보</h4>
          <div className='info-wrap'>
            <div className='name-title'>상품 카테고리</div>
            <div className='content'>
              {getCategoryName(agentData?.category)}
            </div>
          </div>
        </div>
        <div className='regi-info'>
          <h4>4. 등록상품정보</h4>
          <div className='table-wrap'>
            <div className='table-head'>
              <div>NO</div>
              <div>분류코드</div>
              <div>등록상품정보</div>
            </div>
            {agentData?.products?.map(
              ({ mark_class, register_product }, idx) => {
                return (
                  <div className='table-body'>
                    <div>{idx + 1}</div>
                    <div>{mark_class}류</div>
                    <div>
                      {register_product
                        ?.replaceAll(', ', ',')
                        ?.replaceAll(',', ', ')}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className='credit-info'>
          <h4>5. 결제 정보</h4>
          <div className='table-wrap'>
            <div className='table-head'>
              <div>결제일자</div>
              <div>결제수단</div>
              <div>결제상태</div>
              <div>입금자명</div>
              <div>결제금액</div>
              <div>취소요청</div>
            </div>
            {creditData?.length > 0 ? (
              creditData?.map(
                ({ paid_at, pay_method, status, buyer_name, amount }) => {
                  return (
                    <div className='table-body'>
                      <div>{paid_at?.split('T')[0]}</div>
                      <div>{pay_method === 'card' ? '카드' : '무통장입금'}</div>
                      <div>
                        {status === 'ready'
                          ? '결제대기'
                          : status === 'failed'
                          ? '결제실패'
                          : status === 'cancelled'
                          ? '결제취소'
                          : status === 'paid'
                          ? '결제승인'
                          : ''}
                      </div>
                      <div>{buyer_name}</div>
                      <div>{amount?.toLocaleString('ko-KR')}원</div>
                      <div>
                        {/* {item?.status === 'paid' || <button>취소하기</button>} */}
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <div className='table-body no-data'>
                <div>해당 데이터가 존재하지 않습니다.</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MypageAgent;
