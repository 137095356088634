import { useState, useEffect } from 'react';
import SignUpOne from 'components/signUp/SignUpOne';
import SignUpTwo from 'components/signUp/SignUpTwo';
import SignUpThree from 'components/signUp/SignUpThree';
import SignUpStep from 'components/signUp/SignUpStep';
import { changeTitle } from 'js/commonFn';
import CommonSiteMap from 'components/CommonSiteMap';

const SignUp = () => {
  const [step, setStep] = useState('one');
  const [userInfo, setUserInfo] = useState({
    user_id: '',
    password: '',
    name: '',
    phone: '',
    email: '',
    unique_key: '',
  });

  useEffect(() => {
    changeTitle('마크픽 > 회원가입');
  }, []);

  return (
    <>
      <div className={`content-wrap sign-up ${step}`}>
        <CommonSiteMap title='회원가입' />
        <SignUpStep step={step} />
        {step === 'one' && (
          <SignUpOne setStep={setStep} setUserInfo={setUserInfo} />
        )}
        {step === 'two' && (
          <SignUpTwo
            setStep={setStep}
            setUserInfo={setUserInfo}
            userInfo={userInfo}
          />
        )}
        {step === 'three' && <SignUpThree />}
      </div>
    </>
  );
};

export default SignUp;
