import { Routes, Route } from 'react-router-dom';
import FAQ from 'pages/CSCenter/FAQ';
import Inquiry from 'pages/CSCenter/Inquiry';
import Notice from 'pages/CSCenter/Notice';
import NoticeDetail from 'pages/NoticeDetail';
import InquiryDetails from 'pages/CSCenter/InquiryDetails';
import MyPage from 'pages/MyPage';
import Introduction from 'pages/Introduction';
import SelfSelectMark from 'pages/selfApplication/SelfSelectMark';
import Refund from 'pages/CSCenter/Refund';
import PersonalPrivacy from 'pages/clause/PersonalPrivacy';
import Ecommerce from 'pages/clause/Ecommerce';

const SelfRoutes = () => {
  return (
    <Routes>
      {/* 공지사항 */}
      <Route exact path='/notice' element={<Notice />} />
      <Route exact path='/notice/:noticeId' element={<NoticeDetail />} />
      {/* 고객지원 */}
      <Route exact path='/faq' element={<FAQ />} />
      <Route exact path='/inquiry' element={<Inquiry />} />
      <Route
        exact
        path='/inquiry-details/:inquiryId'
        element={<InquiryDetails />}
      />
      <Route exact path='/refund' element={<Refund />} />
      {/* 개인정보처리방침 */}
      <Route exact path='/privacy' element={<PersonalPrivacy />} />
      <Route exact path='/use' element={<Ecommerce />} />

      {/* 마이페이지 */}
      <Route exact path='/my-page' element={<MyPage />} />
      {/* 서비스 소개 */}
      <Route exact path='/service-intro' element={<Introduction />} />
      {/* 셀프 상표등록 */}
      <Route exact path='/self-application' element={<SelfSelectMark />} />
    </Routes>
  );
};

export default SelfRoutes;
