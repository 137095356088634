import { useState } from 'react';
import ApplicationCost from './ApplicationCost';
import ApplyCost from './ApplyCost';
import OpinionNoticeCost from './OpinionNoticeCost';
import InquireCost from './InquireCost';

const DetailCost = () => {
  const [tab, setTab] = useState('application');

  return (
    <div className={`cost-detail-wrap ${tab}`}>
      <div className='row tabs'>
        <span
          onClick={() => setTab('application')}
          className={tab === 'application' ? 'active' : ''}>
          상표 출원 시 비용
        </span>
        <span
          onClick={() => setTab('apply')}
          className={tab === 'apply' ? 'active' : ''}>
          상표 등록 시 비용
        </span>
        <span
          onClick={() => setTab('opinionNotice')}
          className={tab === 'opinionNotice' ? 'active' : ''}>
          의견통지 발생 시 비용
        </span>
        <span
          onClick={() => setTab('inquire')}
          className={tab === 'inquire' ? 'active' : ''}>
          상표 조사 시 비용
        </span>
      </div>
      <hr />
      {tab === 'application' && <ApplicationCost />}
      {tab === 'apply' && <ApplyCost />}
      {tab === 'opinionNotice' && <OpinionNoticeCost />}
      {tab === 'inquire' && <InquireCost />}
    </div>
  );
};

export default DetailCost;
