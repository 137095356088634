import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import checkSvg from 'image/check.svg';
import arrowImg from 'image/signUpStepArrow.svg';
import AgreeModal from 'components/modal/AgreeModal';
import {
  certification,
  getDanalInfo,
  duplicationUserCheck,
} from 'js/agenciesAPI';
import { catchError } from 'js/commonFn';

const SignUpOne = ({ setStep, setUserInfo }) => {
  const [allCheck, setAllCheck] = useState(false);
  const [termAgree, setTermAgree] = useState(false);
  const [privacyAgree, setPrivacyAgree] = useState(false);
  const [modal, setModal] = useState({
    bool: false,
    mode: '',
  });
  const navigate = useNavigate();

  //= 하나씩 선택
  const checkEach = e => {
    $('#' + e.target.id).prop('checked', e.target.checked);
    const check1 = $('#termAgreeCheck').is(':checked');
    const check2 = $('#privacyAgreeCheck').is(':checked');
    if (check1) setTermAgree(true);
    else setTermAgree(false);
    if (check2) setPrivacyAgree(true);
    else setPrivacyAgree(false);
    if (check1 && check2) {
      $('#allCheck').prop('checked', true);
      setAllCheck(true);
    } else {
      $('#allCheck').prop('checked', false);
      setAllCheck(false);
    }
  };

  //= 전체 선택
  const checkAll = e => {
    if (e.target.checked) {
      $('#termAgreeCheck, #privacyAgreeCheck').prop('checked', true);
      setTermAgree(true);
      setPrivacyAgree(true);
      setAllCheck(true);
    } else {
      $('#termAgreeCheck, #privacyAgreeCheck').prop('checked', false);
      setTermAgree(false);
      setPrivacyAgree(false);
      setAllCheck(false);
    }
  };

  //= 회원 중복 체크
  const userCheck = async (phone, name, unique_key) => {
    const result = await duplicationUserCheck(unique_key);
    if (typeof result === 'object') {
      setUserInfo(prev => {
        const clone = { ...prev };
        clone.unique_key = unique_key;
        clone.phone = phone;
        clone.name = name;
        return clone;
      });
      setStep('two');
    } else return catchError(result, navigate);
  };

  //= 다날 인증 API 호출
  const danalCheck = async response => {
    const { success, imp_uid } = response;
    if (success) {
      const result = await getDanalInfo(imp_uid);
      if (typeof result === 'object') {
        const { phone, name, unique_key } = result.data;
        userCheck(phone, name, unique_key);
      } else return catchError(result, navigate);
    } else return alert('인증에 실패하였습니다.');
  };

  return (
    <>
      <h4>약관동의</h4>
      <div className='terms-wrap'>
        <input
          type='checkbox'
          id='allCheck'
          onChange={checkAll}
          className='display-none'
        />
        <label htmlFor='allCheck'>
          <div className='row gray-area'>
            <div className={`checkBox column ${allCheck ? 'active' : ''}`}>
              {allCheck ? <img src={checkSvg} alt='체크 이미지' /> : ''}
            </div>
            <span>이용약관 전체 동의하기</span>
          </div>
        </label>
        <input
          type='checkbox'
          id='termAgreeCheck'
          onChange={checkEach}
          className='display-none'
        />
        <div className='row white-area'>
          <label htmlFor='termAgreeCheck'>
            <div className={`checkBox column ${termAgree ? 'active' : ''}`}>
              {termAgree ? <img src={checkSvg} alt='체크 이미지' /> : ''}
            </div>
            <span>[필수] 이용약관에 동의합니다.</span>
          </label>
          <img
            src={arrowImg}
            alt='화살표 이미지'
            onClick={() =>
              setModal({
                bool: true,
                mode: 'terms',
              })
            }
          />
        </div>
        <hr />
        <input
          type='checkbox'
          id='privacyAgreeCheck'
          onChange={checkEach}
          className='display-none'
        />
        <div className='row white-area'>
          <label htmlFor='privacyAgreeCheck'>
            <div className={`checkBox column ${privacyAgree ? 'active' : ''}`}>
              {privacyAgree ? <img src={checkSvg} alt='체크 이미지' /> : ''}
            </div>
            <span>[필수] 개인정보 수집 및 이용에 동의합니다.</span>
          </label>
          <img
            src={arrowImg}
            alt='화살표 이미지'
            onClick={() =>
              setModal({
                bool: true,
                mode: 'privacy',
              })
            }
          />
        </div>
        <hr />
      </div>
      <h4>본인확인</h4>
      <div className='gray-area authorize row'>
        <span>휴대폰 인증 (고객님 명의의 휴대폰으로 인증)</span>
        <button
          onClick={() => {
            if (allCheck) certification(danalCheck);
            else return alert('이용약관에 동의해 주세요.');
          }}>
          인증하기
        </button>
      </div>
      {modal.bool ? <AgreeModal modal={modal} setModal={setModal} /> : <></>}
    </>
  );
};

export default SignUpOne;
