import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import hamburger from 'image/Customer/hamburgerBtn.svg';
import watch from 'image/Customer/watch.svg';
import { getChangeNoticeDetail } from 'js/selfAPI';
import { catchError } from 'js/commonFn';

const NoticeDetail = () => {
  const [detailInfo, setDetailInfo] = useState();

  const { noticeId } = useParams();
  const navigate = useNavigate();

  const getDetail = async () => {
    const detailData = await getChangeNoticeDetail(noticeId);
    if (typeof detailData === 'object') {
      const { data } = detailData;
      setDetailInfo(data);
    } else return catchError(detailData, navigate);
  };

  useEffect(() => {
    getDetail();
  }, [noticeId]);

  return (
    <>
      <div className='notice-detail-wrap'>
        <h2>공지사항</h2>
        <p>마크픽의 새로운 소식을 알려드립니다.</p>
        <ul className='content-box'>
          <li>
            <img src={watch} alt='날짜' />{' '}
            <span>
              날짜 <span>{detailInfo?.notice?.created_at?.split('T')[0]}</span>
            </span>
          </li>
          <li>
            <strong>{detailInfo?.notice?.title}</strong>
          </li>
          <li>{detailInfo?.notice?.context}</li>
        </ul>
        <div className='bottom-nav'>
          {detailInfo?.notice_prev?.id ? (
            <>
              <Link
                exact='true'
                to={`/notice/${detailInfo?.notice_prev?.id}`}
                className='nav-btn'>
                <span className='prev'></span>
                <p>{detailInfo?.notice_prev?.title}</p>
              </Link>
            </>
          ) : (
            <span></span>
          )}

          <Link to='/notice' exact='true' className='list-btn' title='목록버튼'>
            <img src={hamburger} alt='공지사항 목록으로 가기' />
          </Link>
          {detailInfo?.notice_next?.id ? (
            <>
              <Link
                exact='true'
                to={`/notice/${detailInfo?.notice_next?.id}`}
                className='nav-btn'>
                <p className='right'>{detailInfo?.notice_next?.title}</p>
                <span className='next'></span>
              </Link>
            </>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    </>
  );
};

export default NoticeDetail;
