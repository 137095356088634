import { Link } from 'react-router-dom';
import IntroTitle from 'components/Introduction/IntroTitle';
import introMan from 'image/Introduction/man.png';
const SelfIntroduction = ({ title }) => {
  const handleDownload = () => {
    const files = [
      '/files/이용가이드-셀프 상표등록(특허고객발급 프로세스).pdf',
      '/files/이용가이드-셀프 상표등록(특허청 상표등록 프로세스).pdf',
    ];
    files.forEach(file => {
      var link = document.createElement('a');
      link.href = file;
      link.download = file.split('/').pop();
      link.click();
    });
  };
  return (
    <>
      <IntroTitle title='셀프 상표등록 서비스' />
      <p className='self-service-disc'>
        <Link>
          <span onClick={() => handleDownload()}>당사가이드</span>
        </Link>
        에 따라 온라인으로 간편하게 신청하고, 신청한 데이터를 파일로 다운로드
        받아 <br />
        특허청
        <a
          href='https://www.patent.go.kr/smart/portal/Main.do'
          target='_blank'
          rel='noopener noreferrer'>
          (<span>http://www.patent.go.kr/</span>)
        </a>
        에서 직접 상표등록을 하는 서비스
        <img src={introMan} alt='의자에 앉아서 노트북치고 있는 남자' />
      </p>
      <IntroTitle title='이용방법' />
      <ul className='utilization-wrap'>
        <li>
          <div className='base'>
            <span>Step 1</span>
          </div>
          <div className='text-wrap'>마크픽 회원가입</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 2</span>
          </div>
          <div className='text-wrap'>
            특허청 사이트로 이동하여 당사 가이드에 따라 특허고객 등록
          </div>
        </li>
        <li>
          <div className='base'>
            <span>Step 3</span>
          </div>
          <div className='text-wrap'>
            마크픽으로 이동하여 셀프 상표 등록 작성 및 저장
          </div>
        </li>
        <li>
          <div className='base'>
            <span>Step 4</span>
          </div>
          <div className='text-wrap'>
            특허청 사이트로 이동하여 상표등록출원서 작성화면에서 저장한 파일
            불러오기
          </div>
        </li>
        <li>
          <div className='base'>
            <span>Step 5</span>
          </div>
          <div className='text-wrap'>온라인 제출 및 결제 (출원 완료)</div>
        </li>
      </ul>
      <IntroTitle title='유의사항' />
      <ul className='utilization-wrap'>
        <li>
          <div className='base'>
            <span>1</span>
          </div>
          <div className='text-wrap'>
            특허 고객 등록을 하지 않으면 셀프 출원이 불가합니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>2</span>
          </div>
          <div className='text-wrap'>
            특허 고객 등록 시 특허 고객 번호 발급에 1~2영업일 가량 소요됩니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>3</span>
          </div>
          <div className='text-wrap'>
            국내 개인 또는 법인 대상으로만 셀프출원이 가능합니다.
          </div>
        </li>
      </ul>
    </>
  );
};

export default SelfIntroduction;
