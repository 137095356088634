import { useEffect } from 'react';
import { changeTitle } from 'js/commonFn';
import html2canvas from 'html2canvas';
import rotateIcon from 'image/rotating-arrows-right.svg';
import plusImg from 'image/plusIcon.svg';
import minusImg from 'image/minusIcon.svg';
import fileUploadIcon from 'image/fileUploadIcon.svg';

const CommonSelectMark = ({
  applicationType, //~ 출원 유형
  setApplicationType,
  markNameArr, //~ 텍스트일 경우 텍스트 담는 배열
  setMarkNameArr,
  fileName, //~ 도형/복합일 경우 이미지 파일 이름
  setFileName,
  setFile, //~ 도형/복합일 경우 파일 저장
  img, //~ 도형/복합일 경우 이미지
  setImg,
  // temp, //~ 임시 저장 여부
  // selfType, //~ 컴포넌트 구분
  // file,
  // importTemp,
}) => {
  const imgPreview = e => {
    for (let file of e.target.files) {
      setFileName(file.name);
      const reader = new FileReader();
      setFile(file);
      reader.onload = () => setImg(reader.result);
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    changeTitle('마크픽 > 상표등록 대행');
  }, []);

  return (
    <>
      <div className='content-box'>
        <h3>상표선택</h3>
        <hr />
        <div className='content'>
          <p>상표 유형</p>
          <div className='row'>
            <div
              className={`column ${applicationType === 'text' ? 'active' : ''}`}
              onClick={() => setApplicationType('text')}>
              <h5>문자상표</h5>
              <span>
                텍스트로만
                <br />
                상표등록
              </span>
            </div>
            <div
              className={`column ${
                applicationType === 'figure' ? 'active' : ''
              }`}
              onClick={() => {
                if (applicationType === 'compound') {
                  setFile('');
                  setFileName('');
                  setImg('');
                }
                setApplicationType('figure');
              }}>
              <h5>도형상표</h5>
              <span>
                도형 이미지로만
                <br />
                상표등록
              </span>
            </div>
            <div
              className={`column ${
                applicationType === 'compound' ? 'active' : ''
              }`}
              onClick={() => {
                if (applicationType === 'figure') {
                  setFile('');
                  setFileName('');
                  setImg('');
                }
                setApplicationType('compound');
              }}>
              <h5>복합상표</h5>
              <span>
                도형 이미지에 텍스트가
                <br />
                포함되어 있는 상표등록
              </span>
            </div>
          </div>
        </div>
        <hr className='dashed' />
        <div className='content'>
          <p>상표명</p>
          {applicationType === 'text' && (
            <>
              <div className='column input-wrap'>
                {markNameArr?.map((input, idx) => {
                  const placeHolderArr = ['마크', '픽', 'MarkPick'];

                  return (
                    <div className='row'>
                      <span>{idx + 1}단</span>
                      <input
                        applicationType='text'
                        className='common-text-input'
                        maxLength={20}
                        value={input}
                        placeholder={placeHolderArr[idx]}
                        onChange={e =>
                          setMarkNameArr(prev => {
                            const clone = [...prev];
                            clone[idx] = e.target.value;
                            return clone;
                          })
                        }
                      />
                      <img
                        src={idx === 0 ? plusImg : minusImg}
                        alt={`${idx === 0 ? '플러스' : '마이너스'} 아이콘`}
                        className={
                          markNameArr.length === 5 && idx === 0
                            ? 'disabled'
                            : ''
                        }
                        onClick={() =>
                          setMarkNameArr(prev => {
                            const clone = [...prev];
                            if (idx === 0) {
                              if (markNameArr.length === 5) return clone;
                              clone.push('');
                              return clone;
                            } else {
                              clone.splice(idx, 1);
                              return clone;
                            }
                          })
                        }
                      />
                    </div>
                  );
                }, <></>)}
                <button className='transform-btn'>
                  <span>텍스트를 이미지로 변환</span>
                  <img src={rotateIcon} alt='변환 아이콘' />
                </button>
              </div>
              <p>미리보기</p>
              <div className='preview column'>
                <div className='column text-mark-preview'>
                  {markNameArr?.map(input => {
                    return (
                      <>
                        <span>{input}</span>
                      </>
                    );
                  }, <></>)}
                </div>
              </div>
            </>
          )}
          {applicationType === 'figure' || applicationType === 'compound' ? (
            <>
              <div className='column upload'>
                <input
                  type='file'
                  id='inputFile'
                  className='display-none'
                  accept='image/*'
                  onChange={e => imgPreview(e)}
                />
                <div className='row upload-file'>
                  <label htmlFor='inputFile' className='row upload-file-btn'>
                    <span>파일 선택</span>
                  </label>
                  <span className='file-name'>
                    {fileName === '' ? '선택된 파일 없음' : fileName}
                  </span>
                </div>
                <div className='file-upload-zone column'>
                  {img === '' ? (
                    <label htmlFor='inputFile' className='column'>
                      <img src={fileUploadIcon} alt='파일 업로드 아이콘' />
                      <span>이곳을 클릭하여 이미지를 첨부하세요.</span>
                    </label>
                  ) : (
                    <img
                      src={
                        img?.includes(
                          'https://thumbnail-test2.s3.ap-northeast-2.amazonaws.com'
                        )
                          ? `${img}?${Date.now()}`
                          : img
                      }
                      alt='이미지'
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default CommonSelectMark;
