import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonSelect from 'components/CommonSelect';
import { changeState, regularExpression } from 'js/commonFn';
import { duplicationIdCheck, signUp } from 'js/agenciesAPI';
import { emailArr, catchError } from 'js/commonFn';

const SignUpTwo = ({ setStep, userInfo, setUserInfo }) => {
  const [pwCheck, setPwCheck] = useState({
    once: '',
    twice: '',
  });
  const [email, setEmail] = useState({
    id: '',
    domain: '',
  });
  const [caution, setCaution] = useState({
    id: '',
    pw: '',
    pwCheck: '',
    email: '',
  });
  const [duplicationCheck, setDuplicationCheck] = useState(false);
  const [emailFilter, setEmailFilter] = useState('직접 입력');

  const navigate = useNavigate();

  //= 이메일 형식 체크 함수
  const emailCheckFn = async () => {
    if (userInfo.email.trim() === '@')
      changeState(setCaution, 'email', '이메일을 입력해 주세요.');
    else if (!(await regularExpression('email', userInfo.email)))
      changeState(setCaution, 'email', '유효한 이메일을 입력해 주세요.');
    else changeState(setCaution, 'email', '사용 가능한 이메일입니다.');
  };

  //= 아이디 중복 체크 함수
  const duplicateCheck = async () => {
    const result = await duplicationIdCheck(userInfo?.user_id);
    if (typeof result === 'object') setDuplicationCheck(true);
    else setDuplicationCheck(false);
  };

  //= 회원가입 함수
  const signUpFn = async () => {
    if (
      caution.id.includes('가능') &&
      caution.pw.includes('가능') &&
      caution.pwCheck.includes('일치합니다') &&
      caution.email.includes('가능')
    ) {
      const result = await signUp(userInfo);
      if (typeof result === 'object') {
        setStep('three');
      } else return catchError(result, navigate);
    } else return;
  };

  //= 이메일 필터에 따라 이메일 도메인 변경
  useEffect(() => {
    if (emailFilter === '직접 입력') {
      changeState(setEmail, 'domain', '');
      changeState(setUserInfo, 'email', `${email.id}@`);
    } else {
      changeState(setEmail, 'domain', emailFilter);
      changeState(setUserInfo, 'email', `${email.id}@${emailFilter}`);
    }
  }, [emailFilter]);

  useEffect(() => {
    emailCheckFn();
  }, [userInfo.email]);

  //= 비밀번호 같으면 userInfo State에 최종 반영
  useEffect(() => {
    if (pwCheck.once !== pwCheck.twice)
      changeState(setUserInfo, 'password', '');
    else changeState(setUserInfo, 'password', pwCheck.once);
  }, [pwCheck]);

  //= 이메일 중복체크
  useEffect(() => {
    if (duplicationCheck)
      changeState(setCaution, 'id', '사용 가능한 아이디입니다.');
  }, [duplicationCheck]);

  return (
    <>
      <h4>정보입력</h4>
      <div className='input-wrap column'>
        <div className='row'>
          <span>
            <p>아이디</p>
          </span>
          <input
            type='text'
            className='common-text-input'
            value={userInfo.user_id}
            onChange={async e => {
              changeState(setUserInfo, 'user_id', e.target.value);
              if (e.target.value.trim() === '')
                changeState(setCaution, 'id', '아이디를 입력해 주세요.');
              else if (!(await regularExpression('id', e.target.value)))
                changeState(
                  setCaution,
                  'id',
                  '아이디는 4~30자로 설정해 주세요.'
                );
              else if (!duplicationCheck)
                changeState(setCaution, 'id', '아이디 중복 확인을 해 주세요.');
              else changeState(setCaution, 'id', '사용 가능한 아이디입니다.');
              if (duplicationCheck) setDuplicationCheck(false);
            }}
          />
          <button className='blue-border-btn' onClick={() => duplicateCheck()}>
            중복체크
          </button>
        </div>
        {caution.id.trim() === '' ? (
          ''
        ) : (
          <p
            className={`caution ${
              caution.id.includes('가능') ? 'correct' : 'incorrect'
            }`}>
            {caution.id}
          </p>
        )}
        <div className='row'>
          <span>
            <p>비밀번호</p>
          </span>
          <input
            type='password'
            className='common-text-input'
            value={pwCheck.once}
            maxLength={20}
            onChange={async e => {
              changeState(setPwCheck, 'once', e.target.value);
              if (e.target.value.trim() === '')
                changeState(setCaution, 'pw', '비밀번호를 입력해 주세요.');
              else if (!(await regularExpression('pw', e.target.value)))
                changeState(
                  setCaution,
                  'pw',
                  '8~20자리 숫자/소문자/특수문자를 모두 포함해 주세요.'
                );
              else changeState(setCaution, 'pw', '사용 가능한 비밀번호입니다.');
            }}
          />
        </div>
        {caution.pw.trim() === '' ? (
          ''
        ) : (
          <p
            className={`caution ${
              caution.pw.includes('가능') ? 'correct' : 'incorrect'
            }`}>
            {caution.pw}
          </p>
        )}
        <div className='row'>
          <span>
            <p>비밀번호 확인</p>
          </span>
          <input
            type='password'
            className='common-text-input'
            value={pwCheck.twice}
            maxLength={20}
            onChange={async e => {
              changeState(setPwCheck, 'twice', e.target.value);
              if (e.target.value !== pwCheck.once)
                changeState(
                  setCaution,
                  'pwCheck',
                  '비밀번호가 일치하지 않습니다.'
                );
              else {
                changeState(setCaution, 'pwCheck', '비밀번호가 일치합니다.');
              }
            }}
          />
        </div>
        {caution.pwCheck.trim() === '' ? (
          ''
        ) : (
          <p
            className={`caution ${
              caution.pwCheck.includes('일치합니다') ? 'correct' : 'incorrect'
            }`}>
            {caution.pwCheck}
          </p>
        )}
        <div className='row'>
          <span>
            <p>이름</p>
          </span>
          <input
            type='text'
            className='common-text-input'
            value={userInfo.name}
            disabled
          />
        </div>
        <div className='row email'>
          <span>
            <p>이메일</p>
          </span>
          <input
            type='text'
            className='common-text-input'
            placeholder='이메일'
            value={email.id}
            onChange={e => {
              changeState(setEmail, 'id', e.target.value);
              changeState(
                setUserInfo,
                'email',
                `${e.target.value}@${email.domain}`
              );
            }}
          />
          <span className='at'>@</span>
          <input
            type='text'
            className={`common-text-input ${
              emailFilter !== '직접 입력' ? 'disable' : ''
            }`}
            disabled={emailFilter !== '직접 입력'}
            value={email.domain}
            onChange={e => {
              changeState(setEmail, 'domain', e.target.value);
              changeState(
                setUserInfo,
                'email',
                `${email.id}@${e.target.value}`
              );
            }}
          />
          <CommonSelect
            opt={emailArr}
            selectVal={emailFilter}
            setSelectVal={setEmailFilter}
          />
        </div>
        {caution.email.trim() === '' ? (
          ''
        ) : (
          <p
            className={`caution ${
              caution.email.includes('가능') ? 'correct' : 'incorrect'
            }`}>
            {caution.email}
          </p>
        )}
        <div className='row phone'>
          <span>
            <p>휴대전화</p>
          </span>
          <input
            type='text'
            className='common-text-input'
            value={userInfo.phone.slice(0, 3)}
            disabled
          />
          <span className='hyphen'>-</span>
          <input
            type='text'
            className='common-text-input'
            value={userInfo.phone.slice(3, 7)}
            disabled
          />
          <span className='hyphen'>-</span>
          <input
            type='text'
            className='common-text-input'
            value={userInfo.phone.slice(7)}
            disabled
          />
        </div>
      </div>
      <hr />
      <div className='btn-wrap'>
        <button className='gray-btn' onClick={() => setStep('one')}>
          이전
        </button>
        <button className='blue-btn' onClick={signUpFn}>
          회원가입
        </button>
      </div>
    </>
  );
};

export default SignUpTwo;
