import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CommonSiteMap from 'components/CommonSiteMap';
import { changeTitle, enterFn } from 'js/commonFn';
import { idExistsCheck } from 'js/agenciesAPI';
import { catchError } from 'js/commonFn';

const FindPw = () => {
  const [key, setKey] = useState('');
  const [id, setId] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const checkIdFn = async () => {
    if (id.trim() === '') return alert('아이디를 입력해 주세요.');
    const result = await idExistsCheck(id);
    if (typeof result === 'object') {
      if (!key) navigate('/find-pw/auth', { state: { user_id: id } });
      else navigate('/change-pw', { state: { unique_key: key, user_id: id } });
    } else return catchError(result, navigate);
  };

  useEffect(() => {
    changeTitle('마크픽 > 비밀번호 찾기');
    if (location.state === null) setKey('');
    else setKey(location.state.unique_key);
  }, []);

  return (
    <>
      {key ? (
        <div className='content-wrap simple-find-pw'>
          <CommonSiteMap title='비밀번호 찾기' />
          <h2>비밀번호 찾기</h2>
          <hr />
          <input
            type='text'
            className='common-text-input'
            placeholder='아이디를 입력해 주세요.'
            value={id}
            onChange={e => setId(e.target.value)}
            onKeyDown={e => enterFn(e, checkIdFn)}
          />
          <div className='btn-wrap'>
            <button className='blue-btn' onClick={checkIdFn}>
              다음
            </button>
          </div>
        </div>
      ) : (
        <div className='content-wrap find-pw'>
          <CommonSiteMap title='비밀번호 찾기' />
          <h2>비밀번호 찾기</h2>
          <hr />
          <span>
            비밀번호를 찾고자 하는 아이디를 입력해 주세요.
            <br />
            아이디를 모를 경우 아이디 찾기를 진행해 주세요.
          </span>
          <input
            type='text'
            placeholder='아이디'
            className='common-text-input'
            value={id}
            onChange={e => setId(e.target.value)}
            onKeyDown={e => enterFn(e, checkIdFn)}
          />
          <div className='btn-wrap column'>
            <button className='blue-btn' onClick={checkIdFn}>
              다음
            </button>
            <button
              className='blue-border-btn'
              onClick={() => navigate('/find-id')}>
              아이디 찾기
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FindPw;
