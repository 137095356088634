import delBtn from 'image/delete.svg';
import creBtn from 'image/create.svg';

const ApplicantInput = ({
  onAdd,
  onDelete,
  isAddButtonDisplayed,
  id,
  setIssueModal,
  setApplicants,
  applicants,
  inputCheck,
}) => {
  const handleAdd = () => {
    onAdd({ applicant_name: '', applicant_code: '' });
  };

  const handleDelete = () => {
    if (id) {
      onDelete(id);
    }
  };

  const changeName = val => {
    setApplicants(pr => {
      const clone = [...pr];
      clone[id] = { ...clone[id], applicant_name: val };
      return clone;
    });
  };

  const changeShare = val => {
    // 숫자만 추출
    const onlyNums = val.replace(/[^0-9]/g, '');
    // 100 이상인 경우, 100으로 고정
    const share = Math.min(parseInt(onlyNums, 10), 100);
    setApplicants(pr => {
      const clone = [...pr];
      clone[id] = { ...clone[id], applicant_share: share };
      return clone;
    });
  };

  return (
    <>
      <div
        className={
          inputCheck === 'direct' ? 'input-container direct' : 'input-container'
        }>
        {inputCheck === 'auto' ? (
          <>
            <div className='head'>
              <strong>출원인 입력</strong>
              <span onClick={isAddButtonDisplayed ? handleAdd : handleDelete}>
                {isAddButtonDisplayed ? (
                  <>
                    <img src={creBtn} alt='출원인 추가' />
                    <span>출원인 추가하기</span>
                  </>
                ) : (
                  <>
                    <img src={delBtn} alt='출원인 삭제' />
                    <span>출원인 삭제하기</span>
                  </>
                )}
              </span>
            </div>
          </>
        ) : (
          <div className='head direct'>
            <div>
              <strong>출원인 입력</strong>
              <span onClick={isAddButtonDisplayed ? handleAdd : handleDelete}>
                {isAddButtonDisplayed ? (
                  <>
                    <img src={creBtn} alt='출원인 추가' />
                    <span>출원인 추가하기</span>
                  </>
                ) : (
                  <>
                    <img src={delBtn} alt='출원인 삭제' />
                    <span>출원인 삭제하기</span>
                  </>
                )}
              </span>
            </div>
          </div>
        )}
        <div
          className={
            inputCheck === 'auto' ? 'input-wrap' : 'input-wrap direct'
          }>
          <input
            type='text'
            placeholder='출원인 이름 입력'
            value={applicants[id]?.applicant_name}
            onChange={e => {
              changeName(e.target.value);
            }}
          />
          {inputCheck === 'direct' && (
            <div className='share-input-wrap'>
              <input
                type='text'
                placeholder='지분입력'
                maxLength={3}
                value={applicants[id]?.applicant_share || ''}
                onChange={e => {
                  changeShare(e.target.value);
                }}
              />
              <span>/100</span>
            </div>
          )}
        </div>
        <div
          className={
            inputCheck === 'auto' ? 'input-wrap' : 'input-wrap direct'
          }>
          <input
            type='text'
            placeholder='특허 고객번호 입력'
            maxLength={15}
            value={applicants[id]?.applicant_code}
            onChange={e => {
              setApplicants(prev => {
                const clone = [...prev];
                clone[id] = {
                  ...clone[id],
                  applicant_code: e.target.value
                    .replace(/[^0-9]/g, '')
                    .replace(
                      /^(\d{1,1})(\d{4,4})(\d{0,6})(\d{0,1})$/g,
                      '$1-$2-$3-$4'
                    )
                    .replace(/(\-{1,2})$/g, ''),
                };
                return clone;
              });
            }}
          />
        </div>
        <div
          className={inputCheck === 'auto' ? 'link-wrap' : 'link-wrap direct'}>
          <button onClick={() => setIssueModal(true)}>
            특허고객번호 발급받기
          </button>
          <button onClick={() => setIssueModal(true)}>내번호찾기</button>
        </div>
        {applicants?.length === 1 ? <></> : <div className='line'></div>}
      </div>
    </>
  );
};

export default ApplicantInput;
