import { useState, useEffect } from 'react';
import logo from 'image/Mypage/user_delete_link_logo.svg';
import { memberWithdrawal } from 'js/selfAPI';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from 'js/cookie';
import { catchError } from 'js/commonFn';

const UserDeleteModal = ({ setUserDeleteModal, setUserType }) => {
  //# 탈퇴사유
  const [reason, setReason] = useState('');
  //# 탈퇴사유 직접입력
  const [directReason, setDirectReason] = useState('새 아이디 생성 목적');

  //# Navigate 변수
  const navigate = useNavigate();

  useEffect(() => {
    // 모달 창이 열릴 때, body 요소의 스크롤을 막습니다.
    document.body.style.overflow = 'hidden';

    // 모달 창이 닫힐 때, 스크롤을 다시 허용합니다.
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const handleChange = e => {
    setDirectReason(e.target.value);
  };

  const userDelete = async () => {
    let result;
    if (directReason === '직접 입력') {
      if (reason?.trim() === '') return alert('직접 입력 사유를 작성해 주세요.');
      result = await memberWithdrawal(reason);
    } else {
      result = await memberWithdrawal(directReason);
    }

    if (typeof result === 'object') {
      setUserDeleteModal(false);
      alert(
        '정상적으로 탈퇴가 완료되었습니다.\n그동안 마크픽을 이용해주셔서 감사합니다.'
      );
      removeCookie('pickMyToken');
      removeCookie('pickRfToken');
      return navigate('/sign-in');
    } else return catchError(result, navigate);
  };

  return (
    <>
      <div className='modal-overlay user-delete'>
        <div className='modal'>
          <div className='head'>
            <img src={logo} alt='마크픽 로고' />
            <h4>회원 탈퇴하기</h4>
          </div>
          <div className='content'>
            <strong>유의사항</strong>
            <p className='mb-18'>
              - 회원 탈퇴 처리 후에는 회원님의 개인정보, 이벤트 내역 등을 복원할
              수 없습니다.
            </p>
            <strong>탈퇴사유</strong>
            <div>
              <select value={directReason} onChange={handleChange}>
                <option value='새 아이디 생성 목적'>새 아이디 생성 목적</option>
                <option value='타사 서비스 이용'>타사 서비스 이용</option>
                <option value='서비스 불만'>서비스 불만</option>
                <option value='잦은 서비스 오류 및 장애'>
                  잦은 서비스 오류 및 장애
                </option>
                <option value='직접 입력'>직접 입력</option>
              </select>
            </div>
            <textarea
              readOnly={directReason !== '직접 입력'}
              autoFocus={true}
              className={directReason === '직접 입력' && 'active'}
              onChange={e => setReason(e.target.value)}></textarea>
          </div>
          <hr className='line' />
          <p className='user-delete-text'>계정을 정말로 삭제할까요?</p>
          <div className='user-btn-wrap'>
            <button
              className='ok'
              onClick={() => {
                userDelete();
              }}>
              확인
            </button>
            <button
              className='close'
              onClick={() => {
                setUserType('');
                setReason('');
                setUserDeleteModal(false);
              }}>
              취소
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDeleteModal;
