import { useState, useEffect } from 'react';
import CommonSiteMap from 'components/CommonSiteMap';
import { changeTitle } from 'js/commonFn';
import selfImg from 'image/selfApplicationImg.png';
import agenciesImg from 'image/applicationImg.png';

const ApplicationStep = () => {
  const [select, setSelect] = useState('self');

  useEffect(() => {
    changeTitle('마크픽 > 상표 등록 절차');
  }, []);

  return (
    <div className='content-wrap application-step'>
      <CommonSiteMap title='상표 등록 절차' />
      <h1>상표 등록 절차</h1>
      <div className='row select-step'>
        <span
          onClick={() => setSelect('self')}
          className={select === 'self' ? 'active' : ''}>
          셀프 상표등록 서비스
        </span>
        <span
          onClick={() => setSelect('agencies')}
          className={select === 'agencies' ? 'active' : ''}>
          마크픽 상표등록 대행
        </span>
      </div>
      <div className='step-description column'>
        <div className='service-description column'>
          <h1>
            {select === 'self'
              ? '셀프 상표등록 서비스'
              : '마크픽 상표등록 대행'}
          </h1>
          <span>
            {select === 'self' ? (
              <>
                당사 가이드에 따라 온라인으로 간편하게 신청하고
                <br />
                신청한 데이터를 파일로 다운로드 받아 특허청에서 직접 상표등록을
                하는 서비스입니다.
              </>
            ) : (
              '상표전문가를 통해 특허청에 상표를 등록하는 서비스입니다.'
            )}
          </span>
        </div>
        {select === 'self' ? (
          <img src={selfImg} alt='셀프 상표등록 서비스 설명 이미지' />
        ) : (
          <img src={agenciesImg} alt='마크픽 상표등록 대행 설명 이미지' />
        )}
      </div>
    </div>
  );
};

export default ApplicationStep;
