import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonSiteMap from 'components/CommonSiteMap';
import { changeTitle } from 'js/commonFn';
import completeChangePwImg from 'image/checkResult.svg';

const CompleteChangePw = () => {
  const navigate = useNavigate();

  useEffect(() => {
    changeTitle('마크픽 > 비밀번호 찾기');
  }, []);

  return (
    <div className='content-wrap complete-change-pw'>
      <CommonSiteMap title='비밀번호 찾기' />
      <h2>비밀번호 찾기</h2>
      <hr />
      <img src={completeChangePwImg} alt='비밀번호 찾기 성공 이미지' />
      <h3>비밀번호가 변경되었습니다.</h3>
      <span>새로운 비밀번호로 로그인해 주세요.</span>
      <div className='btn-wrap'>
        <button className='blue-btn' onClick={() => navigate('/sign-in')}>
          로그인
        </button>
      </div>
    </div>
  );
};

export default CompleteChangePw;
