import { useState } from 'react';
import PaymentInfo from 'components/agencies/payment/PaymentInfo';
import PaymentSuccess from 'components/agencies/payment/PaymentSuccess';

const PaymentConfirm = ({
  applicationType,
  markName,
  totalPrice,
  setTotalPrice,
  paymentStep,
  //setPaymentStep,
  selectProductArr,
  application,
  setApplication,
  paymentMethod,
  setPaymentMethod,
  paymentInfo,
  taxBillInfo,
  setTaxBillInfo,
  form,
  temp,
}) => {
  const [priceArr, setPriceArr] = useState([]);
  const [typeArr, setTypeArr] = useState([]);

  //= application에서 중복 제거한 배열
  const set2Arr = [...new Set(application)];

  //= 결제금액 확인 쪽 출원 유형별 종류 리스트 렌더
  const returnTypeList = (type, returnType) => {
    let pos = 0;
    const arr = [];
    const productArr = [];
    selectProductArr?.forEach(({ mark_class }) => {
      productArr.push(mark_class);
    });
    while (true) {
      const foundPos = application.indexOf(type, pos);
      if (foundPos === -1) break;
      arr.push(`${productArr[foundPos]}류`);
      pos = foundPos + 1;
    }
    if (returnType === 'str') {
      let str = JSON.stringify(arr);
      str = str
        .replaceAll(`"`, '')
        .replaceAll(`[`, '')
        .replaceAll(`]`, '')
        .replaceAll(`,`, ' | ');
      return str;
    } else return arr;
  };

  const paymentInfoProps = {
    applicationType,
    markName,
    totalPrice,
    setTotalPrice,
    setPriceArr,
    typeArr,
    setTypeArr,
    application,
    setApplication,
    paymentMethod,
    setPaymentMethod,
    set2Arr,
    returnTypeList,
    selectProductArr,
    taxBillInfo,
    setTaxBillInfo,
    form,
  };

  const PaymentSuccessProps = {
    priceArr,
    typeArr,
    application,
    set2Arr,
    totalPrice,
    paymentMethod,
    returnTypeList,
    paymentInfo,
  };

  return (
    <div className='content-box'>
      <h3>주문내역 및 등록확인</h3>
      {/* <h3>주문내역 및 결제확인</h3> */}
      <hr />
      <div className='row payment-step'>
        <div className={paymentStep === 1 ? 'active' : ''}>주문</div>
        <div className={paymentStep === 2 ? 'active' : ''}>등록 완료</div>
        {/* <div className={paymentStep === 1 ? 'active' : ''}>주문/결제</div> */}
        {/* <div className={paymentStep === 2 ? 'active' : ''}>결제 완료</div> */}
      </div>
      {paymentStep === 1 && <PaymentInfo {...paymentInfoProps} />}
      {paymentStep === 2 && <PaymentSuccess {...PaymentSuccessProps} />}
    </div>
  );
};

export default PaymentConfirm;
