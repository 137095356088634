import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSelectCategory, getSelfDetail, myPageXmlDown } from 'js/selfAPI';
import close from '../../image/Modal/modal-close.svg';
import { catchError } from 'js/commonFn';

const MypageSelf = ({ setSelfPopup, selfPopupId }) => {
  const [imgChange, setImgChange] = useState(false);
  const [selfData, setSelfData] = useState([]);
  const [category, setCategory] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);
  let markNameArr = [];

  const navigate = useNavigate();
  let prevent = false;

  const getCategory = async () => {
    const result = await getSelectCategory();
    if (typeof result === 'object') {
      setCategory(result?.data);
    } else catchError(result, navigate);
  };

  const getSelfDetailFunc = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getSelfDetail(selfPopupId);
    if (typeof result === 'object') {
      setSelfData(result?.data);
      await getCategory();
    } else catchError(result, navigate);
  };

  const handleImgDownload = () => {
    markNameArr = [];
    for (let i = 1; i <= 5; i++) {
      const propName = `mark_name_step${i}`;
      const propValue = selfData?.[propName];
      if (propValue && propValue !== '') {
        markNameArr.push(propValue);
      }
    }
    const imgText = markNameArr?.join('\n') ?? '상표명\n미리보기'; // 텍스트 생성
    const fileName = 'image.jpg';
    const imgElement = document.querySelector('.img-preview');

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 800;
    canvas.height = 800;
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = '100px sans-serif';
    context.fillStyle = '#000000';
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    // 텍스트 그리기
    const lines = imgText.split('\n');
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const y = canvas.height / 2 + (i - (lines.length - 1) / 2) * 120;
      context.fillText(line, canvas.width / 2, y);
    }

    const dataUrl = canvas.toDataURL('image/jpeg', 1);

    const link = document.createElement('a');
    link.download = fileName;
    link.href = dataUrl;
    link.click();

    // 새로운 이미지 요소 생성
    const newImgElement = new Image();
    newImgElement.src = dataUrl;
    newImgElement.className = 'img-preview';
    newImgElement.width = '120';
    newImgElement.height = '120';

    // 기존 이미지 요소 대체
    imgElement.replaceWith(newImgElement);

    setImgUrl(dataUrl);
  };

  // const imgDownFunc = () => {
  //   if (imgOn) {
  //     const link = document.createElement('a');
  //     link.download = fileName;
  //     link.href = img;
  //     link.click();
  //   }
  // };

  const getCategoryName = categoryCode => {
    const categoryList = category.find(
      item => item.category_code === categoryCode
    );
    return categoryList ? categoryList.category_name : '';
  };
  //# XML 다운로드
  const selfXmlDownFunc = async data => {
    const updatedApplicants = [];
    const markNameArr = [];
    const mark_class_data = [];
    for (let i = 1; i <= 5; i++) {
      const nameKey = `applicant_name${i}`;
      const shareKey = `applicant_share${i}`;
      const nameStep = `mark_name_step${i}`;

      if (data[nameKey] !== '') {
        if (data[shareKey] !== '') {
          const applicants = {
            applicant_name: data[`applicant_name${i}`],
            applicant_code: data[`applicant_code${i}`],
            applicant_share: data[`applicant_share${i}`],
          };
          updatedApplicants.push(applicants);
        } else {
          const applicants = {
            applicant_name: data[`applicant_name${i}`],
            applicant_code: data[`applicant_code${i}`],
          };
          updatedApplicants.push(applicants);
        }
      }
      if (data[nameStep] !== '') {
        markNameArr.push({ mark_name_step: data[nameStep] });
      }
    }
    if (data?.mark_classes?.length > 0) {
      for (let j = 0; j < data?.mark_classes?.length; j++) {
        mark_class_data.push({
          mark_class: data?.mark_classes[j],
          register_product: [data?.register_products[j]],
        });
      }
    }

    const result = await myPageXmlDown(
      data,
      updatedApplicants,
      markNameArr,
      mark_class_data
    );
    if (typeof result === 'object') {
      const xmlData = result?.data;
      // Blob 객체 생성
      const blob = new Blob([xmlData], { type: 'application/xml' });
      // Blob 객체로부터 URL 생성
      const url = URL.createObjectURL(blob);
      // a 태그 생성
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // 이 파일명은 임의로 지정하세요.
      a.download = 'myData.xml';
      // a 태그를 DOM에 추가
      document.body.appendChild(a);
      // a 태그를 클릭하여 파일 다운로드
      a.click();
      // a 태그 삭제
      document.body.removeChild(a);
    } else catchError(result, navigate);
  };

  const fileDownFunc = () => {
    const link = document.createElement('a');
    link.download = selfData?.filename;
    link.href = selfData?.image_url;
    link.click();
  };

  useEffect(() => {
    getSelfDetailFunc();
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <div className='modal-bg'>
      <div className='modal column mypage-self-popup'>
        <div className='head'>
          <h4>셀프출원 내역 상세보기</h4>
          <img
            src={close}
            alt='셀프출원 내역 상세보기 닫기'
            onClick={() => setSelfPopup(false)}
          />
        </div>
        <hr />
        <div className='applicant-info'>
          <h4>1. 출원인 정보</h4>
          <div className='info-wrap'>
            <div className='info-one'>
              <div className='name-kr'>
                <div className='name-title'>이름(한글)</div>
                <div className='name'>{selfData?.applicant_name1}</div>
              </div>
            </div>
            <div className='info-one'>
              <div className='name-kr'>
                <div className='name-title'>특허고객번호</div>
                <div className='name'>{selfData?.applicant_code1}</div>
              </div>
            </div>
            {selfData?.applicant_share1 !== '' && (
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>특허고객번호</div>
                  <div className='name'>{selfData?.applicant_share1}</div>
                </div>
              </div>
            )}
          </div>
          {selfData?.applicant_name2 !== '' && (
            <div className='info-wrap'>
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>이름(한글)</div>
                  <div className='name'>{selfData?.applicant_name1}</div>
                </div>
              </div>
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>특허고객번호</div>
                  <div className='name'>{selfData?.applicant_code1}</div>
                </div>
              </div>
              {selfData?.applicant_share2 !== '' && (
                <div className='info-one'>
                  <div className='name-kr'>
                    <div className='name-title'>특허고객번호</div>
                    <div className='name'>{selfData?.applicant_share2}</div>
                  </div>
                </div>
              )}
            </div>
          )}
          {selfData?.applicant_name3 !== '' && (
            <div className='info-wrap'>
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>이름(한글)</div>
                  <div className='name'>{selfData?.applicant_name1}</div>
                </div>
              </div>
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>특허고객번호</div>
                  <div className='name'>{selfData?.applicant_code1}</div>
                </div>
              </div>
              {selfData?.applicant_share3 !== '' && (
                <div className='info-one'>
                  <div className='name-kr'>
                    <div className='name-title'>특허고객번호</div>
                    <div className='name'>{selfData?.applicant_share3}</div>
                  </div>
                </div>
              )}
            </div>
          )}
          {selfData?.applicant_name4 !== '' && (
            <div className='info-wrap'>
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>이름(한글)</div>
                  <div className='name'>{selfData?.applicant_name1}</div>
                </div>
              </div>
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>특허고객번호</div>
                  <div className='name'>{selfData?.applicant_code1}</div>
                </div>
              </div>
              {selfData?.applicant_share4 !== '' && (
                <div className='info-one'>
                  <div className='name-kr'>
                    <div className='name-title'>특허고객번호</div>
                    <div className='name'>{selfData?.applicant_share4}</div>
                  </div>
                </div>
              )}
            </div>
          )}
          {selfData?.applicant_name5 !== '' && (
            <div className='info-wrap'>
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>이름(한글)</div>
                  <div className='name'>{selfData?.applicant_name1}</div>
                </div>
              </div>
              <div className='info-one'>
                <div className='name-kr'>
                  <div className='name-title'>특허고객번호</div>
                  <div className='name'>{selfData?.applicant_code1}</div>
                </div>
              </div>
              {selfData?.applicant_share5 !== '' && (
                <div className='info-one'>
                  <div className='name-kr'>
                    <div className='name-title'>지분</div>
                    <div className='name'>{selfData?.applicant_share5}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='mark-info'>
          <h4>2. 상표 정보</h4>
          <div className='info-wrap'>
            <div className='mark-type'>
              <div className='name-title'>상표유형</div>
              <div className='content'>
                {selfData?.mark_type === 'TEXT'
                  ? '문자상표'
                  : selfData?.mark_type === 'COMPOUND'
                  ? '복합상표'
                  : '도형상표'}
              </div>
            </div>
            <div className='mark-preview'>
              <div className='name-title'>미리보기</div>
              <div className='preview-wrap'>
                <div className='img-wrap'>
                  {selfData?.image_url !== '' ? (
                    <div className='img-preview'>
                      <img src={selfData?.image_url} alt='출원 상표 이미지' />
                    </div>
                  ) : (
                    <div>
                      {imgChange ? (
                        <>
                          <div className='img-preview thumbnail'>
                            <div className='preview-text'>
                              {selfData?.mark_name_step1}
                            </div>
                            {selfData?.mark_name_step2 !== '' && (
                              <div className='preview-text'>
                                {selfData?.mark_name_step2}
                              </div>
                            )}
                            {selfData?.mark_name_step3 !== '' && (
                              <div className='preview-text'>
                                {selfData?.mark_name_step3}
                              </div>
                            )}
                            {selfData?.mark_name_step4 !== '' && (
                              <div className='preview-text'>
                                {selfData?.mark_name_step4}
                              </div>
                            )}
                            {selfData?.mark_name_step5 !== '' && (
                              <div className='preview-text'>
                                {selfData?.mark_name_step5}
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className='img-preview preview-text'>
                          상표명
                          <br />
                          미리보기
                        </div>
                      )}
                      {imgChange ? (
                        <div className='btn-wrap'>
                          <button onClick={() => handleImgDownload()}>
                            이미지 다운로드
                          </button>
                        </div>
                      ) : (
                        <div className='btn-wrap'>
                          <button
                            onClick={() => {
                              setImgChange(true);
                            }}>
                            이미지 전환하기
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {selfData?.image_url !== '' && (
                  <div className='btn-wrap'>
                    <button onClick={() => fileDownFunc()}>
                      이미지 다운로드
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='category-info'>
          <h4>3. 카테고리 정보</h4>
          {selfData?.category === '' ? (
            <></>
          ) : (
            <div className='info-wrap mb-4'>
              <div className='name-title'>상품 카테고리</div>
              <div className='content'>
                {getCategoryName(selfData?.category)}
              </div>
            </div>
          )}
          <div className='info-wrap'>
            <div className='name-title'>상품분류코드</div>
            <div className='content'>
              {selfData?.mark_classes?.map((num, idx) => {
                const isLastItem = idx === selfData.mark_classes.length - 1;
                return isLastItem ? num + '류' : num + '류, ';
              })}
            </div>
          </div>
        </div>
        <div className='regi-info'>
          <h4>4. 등록상품정보</h4>
          {/* {selfData?.mark_classes?.map((num, idx) => {
            return (
              <div className='info-wrap'>
                <div className={idx === 0 ? 'name-title' : 'name-title none'}>
                  {idx === 0 ? '등록상품' : ''}
                </div>
                <div className='content'>
                  {num + '류'} - {selfData?.register_products[idx]}
                </div>
              </div>
            );
          })} */}
          <div className='info-wrap'>
            <div className='name-title'>등록상품</div>
            <div className='content'>
              {selfData?.mark_classes?.map((num, idx) => (
                <div key={idx}>
                  {num}류 - {selfData?.register_products[idx]}
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr className='line' />
        <div className='xml-btn-wrap'>
          <button onClick={() => selfXmlDownFunc(selfData)}>
            상표등록정보 파일 다운로드
          </button>
        </div>
      </div>
    </div>
  );
};

export default MypageSelf;
