import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CommonSiteMap from 'components/CommonSiteMap';
import SelfApplicationStep from 'components/SelfSelectService/SelfApplicationStep';
import TempSave from 'components/modal/TempSave';
import ApplicantSelf from 'components/SelfSelectService/ApplicantSelf';
import CommonSelectMark from 'components/CommonSelectMark';
import CommonSelectProduct from 'components/CommonSelectProduct';
import CommonSelectClassification from 'components/CommonSelectClassification';
import ApplicantFinalStep from 'components/SelfSelectService/ApplicantFinalStep';
import TemplateSave from 'components/modal/TemplateSave';
import ImportTemp from 'components/modal/ImportTemp';

import { getCookie } from 'js/cookie';
import { catchError } from 'js/commonFn';
import { changeTitle } from 'js/commonFn';
import {
  getSelfTemp,
  selectSelfMarkFunc,
  selectSelfCategoryFunc,
  selectSelfProductSave,
  createSelfInfomation,
  getUploadFile,
  deleteSelfTemp,
  saveTemplate,
} from 'js/selfAPI';
import { tempDataBinding } from 'js/commonFn';

const SelfSelectMark = () => {
  const [applicationType, setApplicationType] = useState('text');
  const [step, setStep] = useState(1);
  const [modalType, setModalType] = useState('');
  const [deleteTemp, setDeleteTemp] = useState(false);
  //# 모달창 state
  const [tempModal, setTempModal] = useState(false);

  //# 임시저장  state
  const [tempData, setTempData] = useState(null);
  const [temp, setTemp] = useState(false);
  //# 템플릿 불러오기 state
  const [importTemp, setImportTemp] = useState(false);

  //# step1 state
  const [applicants, setApplicants] = useState([
    { id: 0, applicant_name: '', applicant_code: '' },
  ]);
  //# 템플릿 불러오기 useState
  const [templeteModal, setTempleteModal] = useState(false);
  //# step2 state
  const [markNameArr, setMarkNameArr] = useState(['', '', '']);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [img, setImg] = useState('');

  //# step3 state 및 변수
  const page = 'self';
  const [selectSearchType, setSelectSearchType] = useState('select');
  const [selectCategoryList, setSelectCategoryList] = useState('');
  const [selectClassList, setSelectClassList] = useState([]);

  const [categoryCode, setCategoryCode] = useState('');
  const [searchTxt, setSearchTxt] = useState(''); //- 검색어

  //# step4 state
  const [selectProductArr, setSelectProductArr] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [classArr, setClassArr] = useState([]);

  //# step5 state
  const [templateInput, setTemplateInput] = useState('');

  let selfType = 'self';

  //# step2, step5 state
  const [imgUrl, setImgUrl] = useState('');

  //# 임시저장 불러오기 함수
  const loadTempFunc = async () => {
    let prevent = false;
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getSelfTemp();
    if (typeof result === 'object') {
      setTempModal(true);
      setTempData(result?.data);
    }
  };

  // # step1 임시저장
  const stepOneSave = async () => {
    //# 임시저장 데이터 삭제 기존 데이터를 날리고 다시 쌓도록 하기 위해
    // if (tempData) {
    //   const deleteResult = await deleteSelfTemp(tempData);
    // }

    if (
      applicants[0]?.applicant_name.trim() === '' ||
      applicants[0]?.applicant_code.trim() === ''
    ) {
      return alert('첫번째 출원인 정보가 입력되지 않았습니다.');
    }
    const updatedApplicants = applicants.filter(applicant => {
      // applicant_name 또는 applicant_code이 빈 문자열인 경우 해당 요소를 필터링합니다.
      return (
        applicant.applicant_name.trim() !== '' ||
        applicant.applicant_code.trim() !== ''
      );
    });
    setApplicants(updatedApplicants);

    let result = '';

    result = await createSelfInfomation(updatedApplicants, false);

    setTempData(result?.data);

    if (typeof result === 'object') {
      setTempModal(true);
      setModalType('save');
    } else return catchError(result, navigate);
    if ((fileName && imgUrl) || markNameArr) {
      await stepTwoSave(result?.data);
    }
    if (selectClassList?.length > 0) {
      await tempThreeSaveFunc(stepThreeProps, result?.data);
    }

    if (selectProduct?.length > 0) {
      await tempFourSaveFunc(selectProductProps, result?.data);
    }
  };

  //# Step2 임시저장하기
  const stepTwoSave = async tempResult => {
    let prevent = false;
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await selectSelfMarkFunc(selectMarkProps, tempResult);
    if (typeof result === 'object') {
      setTempModal(true);
      setModalType('save');
    } else return catchError(result, navigate);
  };
  //# Step2 이미지 업로드
  const selfUploadFileFunc = async () => {
    if (
      img?.includes('https://thumbnail-test2.s3.ap-northeast-2.amazonaws.com')
    ) {
    } else {
      const base64Data = img.split(',')[1];
      const decodedData = atob(base64Data);
      const array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        array[i] = decodedData.charCodeAt(i);
      }
      const fileImg = new Blob([array], { type: file.type });

      const formData = new FormData();
      formData.append('logo_self', fileImg);
      const result = await getUploadFile(formData);
      if (typeof result === 'object') {
        setImgUrl(result?.data[0]?.url);
        // setFileName(result?.data[0]?.filename);
      } else return catchError(result, navigate);
    }
  };

  //# step3 임시저장
  const tempThreeSaveFunc = async (stepData, tempResult) => {
    let prevent = false;
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);

    const result = await selectSelfCategoryFunc(stepData, tempResult);

    if (typeof result === 'object') {
      setTempModal(true);
      setModalType('save');
    } else return catchError(result, navigate);
  };
  //# step4 임시저장
  const tempFourSaveFunc = async (stepData, tempResult) => {
    let prevent = false;
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await selectSelfProductSave(stepData, tempResult);

    if (typeof result === 'object') {
      setTempModal(true);
      setModalType('save');
    } else return catchError(result, navigate);
  };

  //# step5 props
  const stepFiveProps = {
    applicants,
    markNameArr,
    fileName,
    file,
    setApplicationType,
    applicationType,
    selectList,
    selectProductArr,
    setImgUrl,
    setSelectList,
    img,
    imgUrl,
    selectSearchType,
    searchTxt,
    categoryCode,
    tempData,
    setModalType,
    templateInput,
    setTemplateInput,
  };

  //# step4 props
  const selectProductProps = {
    page,
    setSelectProductArr,
    selectClassList,
    selectProduct,
    setSelectProduct,
    temp,
    searchTxt,
    selectSearchType,
    selectProductArr,
    categoryCode,
    tempData,
    selectList,
    setSelectList,
    classArr,
    setClassArr,
  };

  //# step3 props
  const stepThreeProps = {
    page, //~ 셀프 출원 / 상표 출원 대행 여부 (agencies/self)
    selectSearchType, //~ 입력 모드
    setSelectSearchType,
    selectCategoryList, //~ 선택한 카테고리
    setSelectCategoryList,
    selectClassList, //~ 선택한 류
    setSelectClassList,
    categoryCode, //~ 선택한 카테고리 이름
    setCategoryCode,
    temp, //~ 임시 저장 여부
    tempData,
    searchTxt,
    setSearchTxt,
    importTemp,
  };

  //# step2 props
  const selectMarkProps = {
    applicationType, //~ 출원 유형
    setApplicationType,
    markNameArr, //~ 텍스트일 경우 텍스트 담는 배열
    setMarkNameArr,
    fileName, //~ 도형/복합일 경우 이미지 파일 이름
    setFileName,
    setFile, //~ 도형/복합일 경우 파일 저장
    img, //~ 도형/복합일 경우 이미지
    setImg,
    temp, //~ 임시 저장 여부
    selfType, //~ 컴포넌트 구분
    file,
    tempData,
    setImgUrl,
    imgUrl,
    importTemp,
  };

  //# step1 props
  const applicantSelfProps = {
    setStep,
    applicants,
    setApplicants,
    stepOneSave,
    tempData,
    setTempData,
    setTempleteModal,
  };

  //# tempDataBinding props
  const tempDataProps = {
    setApplicants,
    tempData,
    setMarkNameArr,
    setApplicationType,
    setFileName,
    setImg,
    setTemp,
    setSelectSearchType,
    setSearchTxt,
    setSelectCategoryList,
    setSelectClassList,
    setCategoryCode,
    setImgUrl,
    setSelectProduct,
    setSelectList,
    setSelectProductArr,
    setStep,
    applicants,
    stepOneSave,
    setTempData,
    setImportTemp,
    setTempleteModal,
  };

  const navigate = useNavigate();

  const handleTextPreview = () => {
    if (applicationType === 'text') {
      if (markNameArr[0]?.trim() === '') {
        return alert('1단부터 입력을 해 주세요');
      }
    } else {
      if (applicationType === 'figure' || applicationType === 'compound') {
        if (!fileName) {
          return alert('출원에 사용 하려는 상표 이미지를 넣어주세요.');
        }
      }
    }
    setStep(3);
  };

  const stepFourNext = () => {
    if (selectClassList?.length !== selectProductArr?.length)
      return alert(
        '선택 되지 않은 분류코드가 존재합니다. 만약 분류코드가 필요하지 않다면 step3에서 해당 분류코드를 해제해 주세요.'
      );
    setStep(5);
  };

  useEffect(() => {
    if (img?.includes('base64')) {
      selfUploadFileFunc();
    }
  }, [img]);

  useEffect(() => {
    if (!getCookie('pickMyToken')) {
      navigate('/sign-in');
      return alert('로그인이 필요한 서비스입니다.');
    }
    changeTitle('마크픽 > 셀프 상표등록');
    //# 임시저장 데이터가 있는지 확인
    loadTempFunc();
  }, []);

  //# 임시저장 불러오기 useEffect
  useEffect(() => {
    if (temp) {
      //# 임시저장 예를 눌렀을때 작동
      tempDataBinding({ ...tempDataProps });
    }
  }, [temp]);

  //# step5 템플릿 저장
  const finalSave = async () => {
    if (templateInput.trim() === '') {
      return;
    }

    //# applicants id 키값 제외한 변수 재생성
    const updatedApplicants = applicants.map(
      ({ id, applicant_share, ...rest }) => {
        let newObj = { ...rest };

        if (applicant_share !== undefined) {
          newObj.applicant_share = String(applicant_share);
        }

        return newObj;
      }
    );
    const result = await saveTemplate(
      tempData,
      updatedApplicants,
      selectProductArr,
      applicationType,
      fileName,
      selectSearchType,
      searchTxt,
      categoryCode,
      img,
      markNameArr,
      templateInput,
      'existing'
    );
    if (typeof result === 'object') {
      setModalType('');
      return alert('신규 템플릿으로 저장되었습니다.');
    } else return catchError(result, navigate);
  };

  return (
    <>
      <div className='self-application-wrap'>
        <div className='content-wrap self-application'>
          <CommonSiteMap title='셀프 상표등록' />
          <SelfApplicationStep step={step} />
          {step === 1 && <ApplicantSelf {...applicantSelfProps} />}
          {step === 2 && (
            <div className='content-wrap agencies select-mark'>
              <CommonSelectMark {...selectMarkProps} />
              <div className='btn-wrap row'>
                <button className='border-btn' onClick={() => setStep(1)}>
                  이전
                </button>
                <button className='gray-btn' onClick={() => stepOneSave()}>
                  임시저장
                </button>
                <button
                  className='gradient-btn'
                  onClick={() => {
                    handleTextPreview();
                  }}>
                  다음
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className='select-classification self-classification'>
              <CommonSelectClassification {...stepThreeProps} />

              <div className='btn-wrap row'>
                <button className='border-btn' onClick={() => setStep(2)}>
                  이전
                </button>
                <button className='gray-btn' onClick={() => stepOneSave()}>
                  임시저장
                </button>
                <button className='gradient-btn' onClick={() => setStep(4)}>
                  다음
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className='select-product'>
              <CommonSelectProduct {...selectProductProps} />

              <div className='btn-wrap row'>
                <button className='border-btn' onClick={() => setStep(3)}>
                  이전
                </button>
                <button className='gray-btn' onClick={() => stepOneSave()}>
                  임시저장
                </button>
                <button
                  className='gradient-btn'
                  onClick={() => {
                    stepFourNext();
                  }}>
                  다음
                </button>
              </div>
            </div>
          )}
          {step === 5 && (
            <>
              <ApplicantFinalStep {...stepFiveProps} />
              <div className='step5-btn-wrap'>
                <button onClick={() => setStep(4)}>이전</button>
                {importTemp && <button className='save-btn'>저장</button>}
                <button
                  onClick={() => {
                    setModalType('template');
                  }}>
                  신규등록
                </button>
              </div>
            </>
          )}
        </div>

        {tempModal && (
          <TempSave
            setTempModal={setTempModal}
            setTemp={setTemp}
            modalType={modalType}
            setDeleteTemp={setDeleteTemp}
          />
        )}

        {modalType === 'template' && <TemplateSave {...stepFiveProps} />}
        {templeteModal && <ImportTemp {...tempDataProps} />}
      </div>
    </>
  );
};

export default SelfSelectMark;
