import { useState, useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import CommonCheckBox from 'components/CommonCheckBox';
import CommonSiteMap from 'components/CommonSiteMap';
import { changeState, enterFn, changeTitle, catchError } from 'js/commonFn';
import { signIn } from 'js/agenciesAPI';
import { setCookie, getCookie } from 'js/cookie';

const SignIn = () => {
  const [saveId, setSaveId] = useState(false);
  const [userInfo, setUserInfo] = useState({
    user_id: '',
    origin_password: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie('pickMyToken')) return navigate('/');
    if (localStorage.getItem('user_id')) {
      changeState(setUserInfo, 'user_id', localStorage.getItem('user_id'));
      setSaveId(true);
    }
    changeTitle('마크픽 > 로그인');
  }, []);

  const signInFn = async () => {
    if (userInfo.user_id.trim() === '') {
      alert('아이디를 입력해 주세요.');
      $('.id-input').focus();
      return;
    } else if (userInfo.origin_password.trim() === '') {
      alert('비밀번호를 입력해 주세요.');
      $('.pw-input').focus();
      return;
    } else {
      if (saveId) localStorage.setItem('user_id', userInfo.user_id);
      else localStorage.removeItem('user_id');
      const result = await signIn(userInfo);
      if (typeof result === 'object') {
        const { access_token, refresh_token } = result.data;
        setCookie('pickMyToken', access_token, { path: '/' });
        setCookie('pickRfToken', refresh_token, { path: '/' });
        return navigate('/');
      } else {
        setUserInfo({
          user_id: '',
          origin_password: '',
        });
        $('.id-input').focus();
        return catchError(result, navigate);
      }
    }
  };

  return (
    <div className='content-wrap sign-in'>
      <CommonSiteMap title='로그인' />
      <div className='sign-in-wrap'>
        <h2 className='sign-in-title'>로그인</h2>
        <hr />
        <div className='form-wrap column'>
          <input
            type='text'
            placeholder='아이디'
            className='common-text-input id-input'
            value={userInfo.user_id}
            onChange={e => changeState(setUserInfo, 'user_id', e.target.value)}
            onKeyDown={e => enterFn(e, signInFn)}
          />
          <input
            type='password'
            placeholder='비밀번호'
            className='common-text-input pw-input'
            value={userInfo.origin_password}
            onChange={e =>
              changeState(setUserInfo, 'origin_password', e.target.value)
            }
            onKeyDown={e => enterFn(e, signInFn)}
          />
          <div className='row check-wrap'>
            <input
              type='checkbox'
              id='save-id'
              className='display-none'
              onChange={e => setSaveId(e.target.checked)}
            />
            <CommonCheckBox state={saveId} str='아이디 저장' id='save-id' />
          </div>
          <div className='btn-wrap column'>
            <button className='gradient-btn' onClick={() => signInFn()}>
              로그인
            </button>
            <button className='border-btn' onClick={() => navigate('/sign-up')}>
              회원가입
            </button>
          </div>
          <div className='find-wrap'>
            <span onClick={() => navigate('/find-id')}>아이디 찾기</span>
            <span onClick={() => navigate('/find-pw')}>비밀번호 찾기</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
