import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { enterFn } from 'js/commonFn';
import { getCountries, getCountry } from 'js/agenciesAPI';
import { catchError } from 'js/commonFn';

const SearchCountry = ({ setSearchModal, country, setCountry }) => {
  const [countryList, setCountryList] = useState([]);
  const [searchTxt, setSearchTxt] = useState('');

  let prevent = false;
  const navigate = useNavigate();

  const getCountryList = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getCountries(searchTxt);
    if (typeof result === 'object') {
      setCountryList(result?.data);
    } else return catchError(result, navigate);
  };

  const searchCountry = async () => {
    const result = await getCountry(searchTxt);
    if (typeof result === 'object') {
      setCountryList(result?.data);
    } else return catchError(result, navigate);
  };

  const renderCountry = () => {
    return countryList?.map(({ country_code, country_name }) => {
      return (
        <li
          className={country_code === country.split('/')[0] ? 'active' : ''}
          onClick={() => setCountry(`${country_code}/${country_name}`)}>
          <span className='country-code'>{country_code}</span>
          <span className='country'>{country_name}</span>
        </li>
      );
    }, <></>);
  };

  useEffect(() => {
    getCountryList();
  }, []);

  return (
    <div className='modal-bg'>
      <div className='modal column search-country'>
        <h3>국적 찾기</h3>
        <hr />
        <div className='row'>
          <span>국가 코드 검색 :</span>
          <input
            type='text'
            placeholder='찾기를 원하는 국가명이나 코드를 입력'
            className='common-text-input'
            onChange={e => setSearchTxt(e.target.value)}
            value={searchTxt}
            onKeyDown={e => enterFn(e, searchCountry)}
          />
        </div>
        <>
          <div className='column country-list'>
            <span className='result'>검색 결과</span>
            <ul className='column'>
              <li className='top'>
                <span className='country-code'>코드</span>
                <span className='country'>국가</span>
              </li>
              {renderCountry()}
            </ul>
          </div>
        </>
        <div className='btn-wrap'>
          <button
            className='gradient-btn'
            onClick={() => setSearchModal(false)}>
            입력
          </button>
          <button
            className='gray-btn'
            onClick={() => {
              setCountry('');
              setSearchModal(false);
            }}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchCountry;
