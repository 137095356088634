import { useState } from 'react';
import selectArrow from 'image/selectArrow.svg';
import yellowSelectArrow from 'image/downArrow.svg';

const CommonSelect = ({ opt, selectVal, setSelectVal, color }) => {
  const [select, setSelect] = useState('off');

  /*
    ! props
    & opt => type(Array) => 선택 옵션
    = useState[selectVal, setSelectVal] => type(String) => 선택된 값
  */
  const changeSelectVal = () => {
    switch (selectVal) {
      default:
        return selectVal;
    }
  };

  return (
    <div className={`selectBox ${select}`}>
      <div
        className={`selectVal`}
        onClick={() => {
          select === 'on' ? setSelect('off') : setSelect('on');
        }}>
        <span>{changeSelectVal()}</span>
        <img
          src={color === 'yellow' ? yellowSelectArrow : selectArrow}
          alt='선택 아이콘'
        />
      </div>
      {select === 'on' && (
        <div className='selectOptGroup'>
          {opt?.length > 0 ? (
            opt?.reduce((acc, option) => {
              return (
                <>
                  {acc}
                  <div
                    className={`selectOpt ${
                      selectVal === option ? 'active' : ''
                    }`}
                    onClick={() => {
                      setSelectVal(option);
                      setSelect('off');
                    }}>
                    {option}
                  </div>
                </>
              );
            }, <></>)
          ) : (
            <>
              <div className='selectOpt'>목록이 없습니다.</div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CommonSelect;
