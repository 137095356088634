import spinner from 'image/pickLoading.gif'

const Loading = () => {
  return (
    <div className='loading column'>
      <img src={spinner} alt="로딩 이미지" />
    </div>
  );
};

export default Loading;