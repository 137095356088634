const ApplicationCost = () => {
  return (
    <>
      <div className='column description-header'>
        <h3>
          상표 등록은 특허청에 심사를 신청하는 출원 시와 심사 통과 후 등록 결정
          시에 두 번으로 나누어 비용이 발생합니다
        </h3>
      </div>
      <table className='application-table'>
        <colgroup>
          <col width='10%' />
          <col width='50%' />
          <col width='25%' />
          <col width='15%' />
        </colgroup>
        <thead>
          <tr>
            <th>유형</th>
            <th>당사 수수료</th>
            <th>특허청 관납료</th>
            <th>합계(VAT포함)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>일반 출원</td>
            <td>50,000원(VAT 10% 별도)</td>
            <td className='left'>
              56,000원(비 고시 명칭 상품으로 출원 진행 시 6,000원 추가 비용
              발생)
            </td>
            <td>111,000원</td>
          </tr>
          <tr>
            <td>조사 출원</td>
            <td className='column'>
              <div className='row'>
                <span>텍스트 상표</span>
                <span>100,000원(VAT 10% 별도)</span>
              </div>
              <div className='row'>
                <span>도형(이미지) 상표</span>
                <span>130,000원(VAT 10% 별도)</span>
              </div>
              <div className='row'>
                <span>복합(텍스트+이미지) 상표</span>
                <span>170,000원(VAT 10% 별도)</span>
              </div>
            </td>
            <td className='left'>
              56,000원(비 고시 명칭 상품으로 출원 진행 시 6,000원 추가 비용
              발생)
            </td>
            <td>
              <div className='column'>
                <span>166,000원</span>
                <span>199,000원</span>
                <span>243,000원</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>신속 출원</td>
            <td>200,000원(VAT 10% 별도)</td>
            <td className='left'>
              216,000원(비 고시 명칭 상품으로 출원 진행 시 6,000원 추가 비용
              발생)
            </td>
            <td>436,000원</td>
          </tr>
          <tr>
            <td>신속조사 출원</td>
            <td className='column'>
              <div className='row'>
                <span>텍스트 상표</span>
                <span>250,000원(VAT 10% 별도)</span>
              </div>
              <div className='row'>
                <span>도형(이미지) 상표</span>
                <span>280,000원(VAT 10% 별도)</span>
              </div>
              <div className='row'>
                <span>복합(텍스트+이미지) 상표</span>
                <span>320,000원(VAT 10% 별도)</span>
              </div>
            </td>
            <td className='left'>
              216,000원(비 고시 명칭 상품으로 출원 진행 시 6,000원 추가 비용
              발생)
            </td>
            <td>
              <div className='column'>
                <span>491,000원</span>
                <span>524,000원</span>
                <span>568,000원</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='cost-guide'>
        <h5>
          상기 비용은 1개 상표, 1개 분류에 한 명의 권리자 기준의 비용으로
          공동출원, 미성년자, 외국인, 국가기관의 경우 추가 요금이 발생합니다
        </h5>
        <h5>
          특허청 관납료는 특허청 고시명칭과 지정상품 20개 기준입니다.(*20개 초과
          시 추가 관납료 발생)
          <br />비 고시 명칭 상품으로 출원 시 특허청 출원 관납료가 6,000원 추가
          청구됩니다.
        </h5>
      </div>
    </>
  );
};

export default ApplicationCost;
