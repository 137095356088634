import { useCallback, useEffect, useState } from 'react';
import CommonSiteMap from 'components/CommonSiteMap';
import mypageArrow from 'image/Mypage/mypage-arrow.svg';
import CommonPagination from 'components/CommonPagination';
import AdditionalSelectBox from 'components/AdditionalSelectBox';
import { Link, useNavigate } from 'react-router-dom';
import {
  appNumberCreate,
  createRefundAccount,
  deleteApplicantNum,
  deleteAppNum,
  editRefundAccount,
  getAdditionalOrder,
  getAgencyList,
  getApplicantList,
  getAppList,
  getBankList,
  getInquiry,
  getInquiryList,
  getSelfInfoTemplete,
  getUserInfo,
  patentNumberCreate,
  templateDel,
  userEmailChange,
  userPhoneChange,
} from 'js/selfAPI';
import { getCookie } from 'js/cookie';
import MyPageUserInfoModal from 'components/modal/MyPageUserInfoModal';
import { changeState, regularExpression } from 'js/commonFn';
import PasswordChange from 'components/modal/PasswordChange';
import {
  applyRequireDocs,
  certification,
  getDanalInfo,
  uploadRequireDocsImg,
} from 'js/agenciesAPI';
import UserDeleteModal from 'components/modal/UserDeleteModal';
import PatentView from 'components/modal/PatentView';
import ApplicationView from 'components/modal/ApplicationView';
import { applicantType } from 'js/array';
import MypageAgent from 'components/popup/MypageAgent';
import MypageSelf from 'components/popup/MypageSelf';
import { catchError } from 'js/commonFn';

const MyPage = () => {
  //# User Info
  const [userInfoData, setUserInfoData] = useState();

  //# pageInfo
  const [pageInfo, setPageInfo] = useState([]);
  //# 셀프출원 내역
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageInfo, setCurrentPageInfo] = useState({
    page: 1,
    totalPage: 1,
    limit: 5,
  });
  const [selfTempleteData, setSelfTempleteData] = useState([]);
  const [selfPopup, setSelfPopup] = useState(false);
  const [selfPopupId, setSelfPopupId] = useState(null);

  //# 상표등록대행 정보
  const [agentCurrentPage, setAgentCurrentPage] = useState(1);
  //# 상표등록대행 상세 및 팝업
  const [agentPopup, setAgentPopup] = useState(false);
  const [agentPopupId, setAgentPopupId] = useState(null);
  //# 출원 상표 내역 대행
  const [appAgentCurrentPage, setAppAgentCurrentPage] = useState(1);
  //# 문의내역
  const [inquiryData, setInquiryData] = useState();
  const inquiryPage = {
    page: 1,
    totalPage: 1,
    limit: 5,
  };

  //# 이메일,회원탈퇴 userType State -> 이메일: email 회원탈퇴: withdrawal
  const [userType, setUserType] = useState('');

  //# 회원정보 변경 모달 - 이메일 - userInfoModal State는 회원탈퇴와 공용으로 사용
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [userInfoOn, setUserInfoOn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  //# 비밀번호 변경 모달 - 이메일
  const [userPwInfoModal, setUserPwInfoModal] = useState(false);

  //# 현재비밀번호
  const [curPassword, setCurPassword] = useState('');

  //# 마이페이지 API 값 담을 State
  // const [myData, setMyData] = useState([]);

  //# 마이페이지 회원탈퇴
  const [userDeleteModal, setUserDeleteModal] = useState(false);

  //# 환불계좌
  const [selectBank, setSelectBank] = useState('선택하기');
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNum, setAccountNum] = useState('');
  //# 환불계좌조회
  const [accountData, setAccountData] = useState(null);
  const [accountBool, setAccountBool] = useState(true);

  //# 필요 서류
  const [requireDocsName, setRequireDocsName] = useState({
    biz_license: '',
    stamp_certificate: '',
    stamp: '',
  });
  const [requireDocs, setRequireDocs] = useState('');

  //# 은행리스트
  const [bankArr, setBankArr] = useState([]);

  //# Navigate 변수
  const navigate = useNavigate();

  //# Patent-View 모달 State
  const [patentModal, setPatentModal] = useState(false);

  //# Application-view 모달 State
  const [applicationModal, setApplicationModal] = useState(false);

  //# 출원 정보 입력(특허고객번호)
  const [applicantInput, setApplicantInput] = useState(['', '', '', '']);
  //# 출원 정보 입력(출원번호)
  const [appNumInput, setAppNumInput] = useState(['', '', '']);
  //# 출원 정보 목록(특허고객번호,출원번호)
  const [appCodeList, setAppCodeList] = useState([]);
  const [appNumList, setAppNumList] = useState([]);
  //# 상표등록대행 내역
  const [agencyPage, setAgencyPage] = useState(1);
  const [agencyCurrentPageInfo, setAgencyCurrentPageInfo] = useState({
    page: 1,
    total_page: 1,
    limit: 5,
  });

  const [agencyData, setAgencyData] = useState();
  //# 추가 결제 필요 내역
  const [additionalData, setAdditionalData] = useState([]);
  //# 출원 상표 내역
  const [applicantPage, setApplicantPage] = useState(1);
  const [applicantCurPageInfo, setApplicantCurPageInfo] = useState({
    page: 1,
    total_page: 1,
    limit: 5,
  });
  const [applicantData, setApplicantData] = useState();
  //# 문의내역
  const fetchData = async () => {
    // await getBankListFunc();
    // await getTempleteInfo();
    // await getAppListFunc();
    getUserinfoFunc();
    getBankListFunc();
    getTempleteInfo();
    getAppListFunc();
    getInquiryListFunc();

    // const data = [result1, result2, result3, result4, result5];
    // setData(data);
  };

  //# 마이페이지 회원정보 불러오기
  const getUserinfoFunc = async () => {
    const userInfo = await getUserInfo();
    if (typeof userInfo === 'object') {
      setUserInfoData(userInfo?.data?.data);
    } else catchError(userInfo, navigate);
  };

  //# 회원정보 변경 함수
  const userInfoFunc = async () => {
    if (!userInfoOn) {
      setUserType('email');
      setUserInfoModal(true);
      setCurPassword('');
    } else {
      if (userEmail.trim() && userEmail === '') {
        return alert('이메일을 입력해 주세요.');
      }
      if (!(await regularExpression('email', userEmail))) {
        return alert('이메일의 형식에 맞지 않습니다.');
      }
      const confirmData = window.confirm('변경사항을 적용하시겠습니까?');
      if (confirmData) {
        //# 이메일 변경 API 호출
        const result = await userEmailChange(
          userInfoData?.user_id,
          userEmail,
          curPassword
        );
        if (typeof result === 'object') {
          setUserType('');
          alert('변경되었습니다.');
          return window.location.reload();
        } else return catchError(result, navigate)
      } else return;
    }
  };

  //# 다날 인증 API 호출
  const danalCheck = async response => {
    const { success, imp_uid } = response;
    if (success) {
      const result = await getDanalInfo(imp_uid);
      if (typeof result === 'object') {
        const { phone } = result.data;
        const changePhoneResult = await userPhoneChange(
          imp_uid,
          userInfoData?.user_id,
          phone
        );
        if (typeof changePhoneResult === 'object') {
          return alert('연락처가 변경되었습니다.');
        } else return catchError(changePhoneResult, navigate);
      } else return catchError(result, navigate);
    } else return alert('인증에 실패하였습니다.');
  };

  //# 회원탈퇴 모달 생성 함수
  const userDeleteModalCreate = () => {
    if (!userDeleteModal) {
      setUserType('withdrawal');
      setUserInfoModal(true);
      setCurPassword('');
    }
  };

  //# 은행 리스트 조회
  const getBankListFunc = async () => {
    const result = await getBankList();
    if (typeof result === 'object') {
      setBankArr(result?.data);
    } else return catchError(result, navigate);
  };

  //# 환불계좌정보 등록
  const createRefundAccountFunc = async () => {
    const result = await createRefundAccount(
      accountHolder,
      accountNum,
      selectBank
    );
    if (typeof result === 'object') {
      setAccountBool(false);
      alert('환불계좌가 등록 되었습니다.');
      return window.location.reload();
    } else return catchError(result, navigate);
  };

  //# 환불계좌정보 수정
  const editRefundAccountFunc = async () => {
    const result = await editRefundAccount(
      accountData?.id,
      accountHolder,
      accountNum,
      selectBank
    );
    if (
      accountHolder?.trim() === '' ||
      accountNum?.trim() === '' ||
      selectBank?.trim() === '' ||
      selectBank?.trim() === '선택하기'
    ) {
      return alert('필수정보를 입력하지 않았습니다.');
    }
    if (typeof result === 'object') {
      setAccountBool(false);
      alert('환불계좌가 저장 되었습니다.');
      return window.location.reload();
    } else return catchError(result, navigate);
  };

  //# 환불계좌정보 조회
  const getInquiryFunc = async () => {
    const result = await getInquiry();
    if (typeof result === 'object') {
      if (Object.keys(result?.data)?.length === 0) {
        setAccountBool(true);
      } else {
        setAccountBool(false);
        setAccountData(result?.data);
        setSelectBank(result?.data?.refund_bank);
        setAccountNum(result?.data?.refund_account);
        setAccountHolder(result?.data?.refund_holder);
      }
    } else return catchError(result, navigate);
  };

  //# 상표 등록 대행 필요 서류 파일 핸들링
  const requireFileHandlingFn = (type, file) => {
    changeState(setRequireDocsName, type, file?.name);
    setRequireDocs(file);
  };

  //# 상표 등록 대행 필요 서류 등록
  const applyRequireDocsFn = async type => {
    const formData = new FormData();
    formData.append(type, requireDocs);
    const uploadResult = await uploadRequireDocsImg(formData);
    if (typeof uploadResult === 'object') {
      const { imagetype, url } = uploadResult?.data[0];
      const applyResult = applyRequireDocs(
        imagetype,
        url,
        requireDocsName[imagetype]
      );
      if (typeof applyResult === 'object') {
        alert('등록이 완료되었습니다.');
        await getUserinfoFunc();
        return;
      } else return catchError(applyResult, navigate);
    } else return catchError(uploadResult, navigate);
  };

  //# 셀프출원 템플릿 내역
  const getTempleteInfo = async () => {
    const result = await getSelfInfoTemplete(currentPage);
    if (typeof result === 'object') {
      setSelfTempleteData(result?.data?.data);
      setCurrentPageInfo(result?.data?.meta);
    } else return catchError(result, navigate);
  };

  const templateDelete = async id => {
    const userConfirmation = window.confirm(
      '템플릿을 정말로 삭제하시겠습니까? 한번 삭제하면 복구할 수 없습니다.'
    );

    if (userConfirmation) {
      const result = await templateDel(id);
      if (typeof result === 'object') {
        alert('템플릿이 삭제 되었습니다.');
        return window.location.reload();
      } else return catchError(result, navigate);
    } else {
      return;
    }
  };

  //# 출원 정보 입력(특허고객번호)
  const patentCustomerNumCreate = async () => {
    if (
      applicantInput[0]?.trim()?.length === 1 &&
      applicantInput[1]?.trim()?.length === 4 &&
      applicantInput[2]?.trim()?.length === 6 &&
      applicantInput[3]?.trim()?.length === 1
    ) {
      if (appCodeList?.length >= 2) {
        return alert(
          '특허고객번호는 최대 2개 까지 등록이 가능합니다. 새로운 번호를 등록을 원하시면 출원 정보 목록에서 제거 후 이용해 주세요.'
        );
      }

      const dataChange = applicantInput?.join('-');

      const result = await patentNumberCreate(dataChange);
      if (typeof result === 'object') {
        alert(
          '특허고객번호가 등록 되었습니다. 출원 정보 목록에서 확인 하실 수 있습니다.'
        );
        return window.location.reload();
      } else return catchError(result, navigate);
    } else {
      return alert('특허고객번호의 형식과 맞지 않습니다.');
    }
  };
  //# 출원 정보 입력(출원번호)
  const appNumCreate = async () => {
    if (
      appNumInput[0]?.trim()?.length === 2 &&
      appNumInput[1]?.trim()?.length === 4 &&
      appNumInput[2]?.trim()?.length === 7
    ) {
      const dataChange = appNumInput?.join('-');
      const result = await appNumberCreate(dataChange);
      if (typeof result === 'object') {
        alert(
          '출원번호가 등록 되었습니다. 출원 정보 목록에서 확인 하실 수 있습니다.'
        );
      } else return catchError(result, navigate);
      return window.location.reload();
    } else {
      return alert('출원번호의 형식과 맞지 않습니다.');
    }
  };
  //# 특허고객번호 삭제
  const applicantNumDel = async num => {
    const confirmData = window.confirm(
      `${num} 특허고객번호를 삭제하시겠습니까?`
    );
    if (confirmData) {
      const result = await deleteApplicantNum(num);
      if (typeof result === 'object') {
        alert(`${num} 특허고객번호가 삭제되었습니다.`);
        return window.location.reload();
      } else return catchError(result, navigate);
    } else {
      return;
    }
  };
  //# 출원번호 삭제
  const appNumDel = async num => {
    const confirmData = window.confirm(`${num} 출원번호를 삭제하시겠습니까?`);
    if (confirmData) {
      const result = await deleteAppNum(num);
      if (typeof result === 'object') {
        alert(`${num} 출원번호가 삭제되었습니다.`);
        return window.location.reload();
      } else return catchError(result, navigate);
    } else {
      return;
    }
  };
  //# 출원 정보 목록
  const getAppListFunc = async () => {
    const result = await getAppList();
    if (typeof result === 'object') {
      setAppCodeList(result?.data?.applicant_code);
      setAppNumList(result?.data?.application_number);
    } else return catchError(result, navigate);
  };

  //# 상표등록대행 내역 data 내일 넣어달라고하기 그리고 데이터 바인딩
  // const getAgencyListFunc = async () => {
  //   const result = await getAgencyList(agencyPage);
  //   if (typeof result === 'object') {
  //     setAgencyCurrentPageInfo(result?.data?.meta);
  //     setAgencyData(result?.data?.data);
  //   } else return catchError(result, navigate)
  // };
  const getAgencyListFunc = useCallback(async () => {
    const result = await getAgencyList(agencyPage);
    if (typeof result === 'object') {
      setAgencyCurrentPageInfo(result?.data?.meta);
      setAgencyData(result?.data?.data);
    } else return catchError(result, navigate);
  }, [agencyPage]); // agencyPage를 의존성 배열에 추가합니다.

  //# 추가 결제 필요 내역
  const getAdditionalFunc = async () => {
    const result = await getAdditionalOrder();
    if (typeof result === 'object') {
      setAdditionalData(result?.data);
    } else return catchError(result, navigate);
  };

  //# 출원 상표 내역
  // const getApplicantListFunc = async () => {
  //   const result = await getApplicantList(applicantPage);
  //   if (typeof result === 'object') {
  //     setApplicantData(result?.data?.data);
  //     setApplicantCurPageInfo(result?.data?.meta);
  //   } else return catchError(result, navigate)
  // };
  const getApplicantListFunc = useCallback(async () => {
    const result = await getApplicantList(applicantPage);
    if (typeof result === 'object') {
      setApplicantData(result?.data?.data);
      setApplicantCurPageInfo(result?.data?.meta);
    } else return catchError(result, navigate);
  }, [applicantPage]);

  //# 출원번호 정규식 함수
  const handleInputChange = event => {
    const re = /^[0-9\b]+$/; // 숫자와 backspace만 허용하는 정규식

    // 입력 값이 비어있거나 숫자일 경우에만 상태 업데이트
    if (event.target.value === '' || re.test(event.target.value)) {
      setAccountNum(event.target.value);
    }
  };

  const bankCompare = bankCode => {
    const matchedBank = bankArr.find(bank => bank.bank_code === bankCode);
    return matchedBank ? matchedBank.bank_name : '';
  };

  //# 문의내역
  const getInquiryListFunc = async () => {
    const inquiryData = await getInquiryList(
      inquiryPage.page,
      inquiryPage.limit
    );
    if (typeof inquiryData === 'object') {
      setInquiryData(inquiryData?.data?.data);
    } else return catchError(inquiryData, navigate);
  };

  //# 상표등록대행 함수
  const agentPopupFunc = item => {
    setAgentPopupId(item?.id);
    setAgentPopup(true);
  };

  //# 셀프출원내역 팝업함수
  const selfPopupFunc = item => {
    setSelfPopupId(item?.id);
    setSelfPopup(true);
  };

  useEffect(() => {
    if (getCookie('pickMyToken') === undefined) {
      navigate('/sign-in');
      return alert('로그인 후 이용해 주세요.');
    } else {
      fetchData();
      getAdditionalFunc();
    }
  }, []);

  // useEffect(() => {
  //   getAgencyListFunc();
  // }, [agencyPage]);
  useEffect(() => {
    if (getCookie('pickMyToken') !== undefined) {
      getAgencyListFunc();
    }
  }, [getAgencyListFunc]);

  // useEffect(() => {
  //   getApplicantListFunc();
  // }, [applicantPage]);
  useEffect(() => {
    if (getCookie('pickMyToken') !== undefined) {
      getApplicantListFunc();
    }
  }, [getApplicantListFunc]);

  useEffect(() => {
    if (getCookie('pickMyToken') !== undefined) {
      getInquiryFunc();
    }
  }, []);

  return (
    <>
      <div className='mypage-wrap'>
        <div className='content-wrap mypage'>
          <CommonSiteMap title='마이페이지' />
          <div className='member-info common'>
            <div className='info-change'>
              <div className='left-info'>
                <strong>{userInfoData?.name} 회원님 안녕하세요!</strong>
                <p>{userInfoData?.email}</p>
              </div>
              <div className='right-info'>
                <div
                  onClick={() => {
                    userInfoFunc();
                  }}>
                  <button></button>
                  <span>
                    {userInfoOn ? '회원 정보 변경 완료' : '회원 정보 변경'}
                  </span>
                </div>
                <div
                  onClick={() => {
                    if (userInfoOn === true) {
                      return alert('회원정보 변경 후 이용해주시기 바랍니다.');
                    }
                    setUserPwInfoModal(true);
                  }}>
                  <button></button>
                  <span>비밀번호 변경</span>
                </div>
                <div onClick={() => certification(danalCheck)}>
                  <button></button>
                  <span>연락처 변경</span>
                </div>
              </div>
            </div>
            <div className='user-info'>
              <div className='info-title'>
                <strong>회원 정보</strong>
                <span>* 회원 정보는 이메일만 변경 가능합니다.</span>
              </div>
              <div className='info-content'>
                <div>
                  <div className='info-id'>
                    <div className='info-head'>아이디</div>
                    <div className='content'>{userInfoData?.user_id}</div>
                  </div>
                  <div className='height-line'></div>
                  <div className='info-phone'>
                    <div className='info-head'>연락처</div>
                    <div className='content'>{userInfoData?.phone}</div>
                  </div>
                </div>
                <div>
                  <div className='info-name'>
                    <div className='info-head'>성명</div>
                    <div className='content'>{userInfoData?.name}</div>
                  </div>
                  <div className='height-line'></div>
                  <div className='info-mail'>
                    <div className='info-head'>이메일</div>
                    {userInfoOn ? (
                      <div className={userInfoOn ? 'content on' : 'content'}>
                        <input
                          type='text'
                          placeholder='이메일을 입력해 주세요.'
                          autoFocus='true'
                          onChange={e => setUserEmail(e.target.value)}
                          className='email-input'
                        />
                      </div>
                    ) : (
                      <div className='content'>{userInfoData?.email}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='withdrawal-wrap'>
                <button
                  className='user-withdrawal'
                  onClick={() => userDeleteModalCreate()}>
                  회원 탈퇴하기 <img src={mypageArrow} alt='회원탈퇴하기' />
                </button>
              </div>
            </div>
            <div className='refund-account'>
              <div className='info-title'>
                <strong>환불 계좌 정보</strong>
              </div>
              {accountBool ? (
                <div className='info-content'>
                  <div>
                    <div className='title'>은행 선택</div>
                    <select
                      value={selectBank}
                      onChange={e => {
                        setSelectBank(e.target.value);
                      }}>
                      <option value='선택하기'>선택하기</option>
                      {bankArr?.map((item, idx) => {
                        return (
                          <option key={idx} value={item?.bank_code}>
                            {item?.bank_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <div className='title'>예금주</div>
                    <input
                      className='account-input'
                      type='text'
                      value={accountHolder}
                      placeholder='예금주를 입력해 주세요.'
                      maxLength={10}
                      onChange={e => setAccountHolder(e.target.value)}
                    />
                  </div>
                  <div>
                    <div className='title'>계좌번호</div>
                    <input
                      className='bank-account-input'
                      type='text'
                      placeholder='계좌번호를 입력해 주세요.'
                      value={accountNum}
                      maxLength={20}
                      onChange={handleInputChange}
                    />
                  </div>
                  <button
                    onClick={() => {
                      if (accountData) {
                        editRefundAccountFunc();
                      } else {
                        createRefundAccountFunc();
                      }
                    }}>
                    {accountData ? '저장' : '등록'}
                  </button>
                </div>
              ) : (
                <div className='info-content'>
                  <div>
                    <div className='title'>은행</div>
                    <div className='bankName'>
                      {bankCompare(accountData?.refund_bank)}
                    </div>
                  </div>
                  <div>
                    <div className='title'>예금주</div>
                    <div className='holderName'>
                      {accountData?.refund_holder}
                    </div>
                  </div>
                  <div>
                    <div className='title'>계좌번호</div>
                    <div className='accountNumber'>
                      {accountData?.refund_account}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setAccountBool(true);
                      setSelectBank(accountData?.refund_bank);
                      setAccountNum(accountData?.refund_account);
                      setAccountHolder(accountData?.refund_holder);
                    }}>
                    수정
                  </button>
                </div>
              )}
            </div>
            <div className='require-docs'>
              <div className='info-title'>
                <strong>상표 등록 대행 필요 서류 정보</strong>
              </div>
              <div className='column require-docs-list'>
                <div className='row'>
                  <span className='require'>사업자등록증</span>
                  <input
                    type='text'
                    className='file-name'
                    value={
                      requireDocsName.biz_license ||
                      userInfoData?.biz_license_filename
                    }
                    disabled
                  />
                  <label className='select-file-btn' htmlFor='biz_license'>
                    파일 선택
                  </label>
                  <button
                    className='apply-btn'
                    onClick={() => applyRequireDocsFn('biz_license')}>
                    등록
                  </button>
                  <input
                    accept='image/*'
                    type='file'
                    id='biz_license'
                    onChange={e =>
                      requireFileHandlingFn('biz_license', e.target.files[0])
                    }
                  />
                </div>
                <div className='row'>
                  <span className='require'>인감증명서</span>
                  <input
                    type='text'
                    className='file-name'
                    value={
                      requireDocsName.stamp_certificate ||
                      userInfoData?.stamp_certificate_filename
                    }
                    disabled
                  />
                  <label
                    className='select-file-btn'
                    htmlFor='stamp_certificate'>
                    파일 선택
                  </label>
                  <button
                    className='apply-btn'
                    onClick={() => applyRequireDocsFn('stamp_certificate')}>
                    등록
                  </button>
                  <input
                    accept='image/*'
                    type='file'
                    id='stamp_certificate'
                    onChange={e =>
                      requireFileHandlingFn(
                        'stamp_certificate',
                        e.target.files[0]
                      )
                    }
                  />
                </div>
                <div className='row'>
                  <span className='require'>개인인감</span>
                  <input
                    type='text'
                    className='file-name'
                    value={
                      requireDocsName.stamp || userInfoData?.stamp_filename
                    }
                    disabled
                  />
                  <label className='select-file-btn' htmlFor='stamp'>
                    파일 선택
                  </label>
                  <button
                    className='apply-btn'
                    onClick={() => applyRequireDocsFn('stamp')}>
                    등록
                  </button>
                  <input
                    accept='image/*'
                    type='file'
                    id='stamp'
                    onChange={e =>
                      requireFileHandlingFn('stamp', e.target.files[0])
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='common break-dwon'>
            <div className='user-self-appDetail'>
              <h4>셀프출원 템플릿 내역</h4>
              <div className='table-wrap'>
                <div className='table-head'>
                  <div>No</div>
                  <div>템플릿명</div>
                  <div>상표유형</div>
                  <div>상표명</div>
                  <div>상품분류</div>
                  <div>상품정보</div>
                  <div>특허고객번호</div>
                  <div>출원인명</div>
                  <div></div>
                </div>
                {/* //= API연결 작업 시 데이터 있을때와 없을때 분류해서 할 것 */}
                {selfTempleteData?.length ? (
                  <>
                    {selfTempleteData?.map((item, idx) => {
                      return (
                        <div className='table-body'>
                          <div>
                            {(currentPageInfo.total_page - 1) *
                              currentPageInfo.limit +
                              idx +
                              1}
                          </div>
                          <div>{item?.template_name}</div>
                          <div>
                            {item?.mark_type === 'TEXT'
                              ? '문자상표'
                              : item?.mark_type === 'COMPOUND'
                              ? '복합상표'
                              : '도형상표'}
                          </div>
                          <div onClick={() => selfPopupFunc(item)}>
                            {item?.mark_name_step1 !== '' ? (
                              <div>
                                {item?.mark_name_step1 +
                                  ' ' +
                                  item?.mark_name_step2 +
                                  ' ' +
                                  item?.mark_name_step3 +
                                  ' ' +
                                  item?.mark_name_step4 +
                                  ' ' +
                                  item?.mark_name_step5}
                              </div>
                            ) : (
                              <img
                                src={item?.image_url}
                                alt={item?.image_filename}
                              />
                            )}
                          </div>
                          <div>
                            {item?.mark_classes[0] &&
                              `${
                                item.mark_classes[0].startsWith('0')
                                  ? item.mark_classes[0].slice(1)
                                  : item.mark_classes[0]
                              }류 ${
                                item.mark_classes?.length - 1 === 0
                                  ? ''
                                  : `외 ` +
                                    (item.mark_classes?.length - 1) +
                                    '건'
                              }`}
                          </div>
                          <div className='product-info'>
                            <p>
                              {item?.register_products
                                ?.join(', ')
                                ?.replaceAll(', ', ',')
                                ?.replaceAll(',', ', ')}
                            </p>
                          </div>
                          <div>{item?.applicant_code1}</div>
                          <div>{item?.applicant_name1}</div>
                          <div>
                            <button onClick={() => templateDelete(item?.id)}>
                              삭제
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className='table-body'>
                    <div className='no-data'>
                      셀프출원 템플릿 내역이 없습니다.
                    </div>
                  </div>
                )}
              </div>
              <CommonPagination
                // postsPerPage={pageInfo?.limit}
                postsPerPage={1}
                viewPageNum={5}
                totalPosts={currentPageInfo?.totalPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className='user-info-enter common'>
              <h4>출원 정보 입력</h4>
              <div className='enter-wrap'>
                <div className='patent-input'>
                  <strong>특허고객번호</strong>
                  <input
                    type='text'
                    className='patent-input1'
                    placeholder='0'
                    maxLength={1}
                    value={applicantInput[0]}
                    onChange={e => {
                      let val = e.target.value.replace(/[^0-9]/g, '');
                      setApplicantInput(prev => [val, ...prev.slice(1)]);
                    }}
                  />
                  <span>-</span>
                  <input
                    type='text'
                    className='patent-input2'
                    placeholder='0000'
                    maxLength={4}
                    value={applicantInput[1]}
                    onChange={e => {
                      let val = e.target.value.replace(/[^0-9]/g, '');
                      setApplicantInput(prev => [
                        prev[0],
                        val,
                        ...prev.slice(2),
                      ]);
                    }}
                  />
                  <span>-</span>
                  <input
                    type='text'
                    className='patent-input3'
                    placeholder='000000'
                    maxLength={6}
                    value={applicantInput[2]}
                    onChange={e => {
                      let val = e.target.value.replace(/[^0-9]/g, '');
                      setApplicantInput(prev => [
                        prev[0],
                        prev[1],
                        val,
                        prev[3],
                      ]);
                    }}
                  />
                  <span>-</span>
                  <input
                    type='text'
                    className='patent-input4'
                    placeholder='0'
                    maxLength={1}
                    value={applicantInput[3]}
                    onChange={e => {
                      let val = e.target.value.replace(/[^0-9]/g, '');
                      setApplicantInput(prev => [...prev.slice(0, 3), val]);
                    }}
                  />

                  <button onClick={() => patentCustomerNumCreate()}>
                    등록
                  </button>
                </div>
                <div className='appNum-input'>
                  <strong>출원번호</strong>
                  <input
                    type='text'
                    className='appNum-input1'
                    placeholder='00'
                    maxLength={2}
                    value={appNumInput[0]}
                    onChange={e => {
                      let val = e.target.value.replace(/[^0-9]/g, '');
                      setAppNumInput(prev => [val, ...prev.slice(1)]);
                    }}
                  />
                  <span>-</span>
                  <input
                    type='text'
                    className='appNum-input2'
                    placeholder='0000'
                    maxLength={4}
                    value={appNumInput[1]}
                    onChange={e => {
                      let val = e.target.value.replace(/[^0-9]/g, '');
                      setAppNumInput(prev => [prev[0], val, ...prev.slice(2)]);
                    }}
                  />
                  <span>-</span>
                  <input
                    type='text'
                    className='appNum-input3'
                    placeholder='0000000'
                    maxLength={7}
                    value={appNumInput[2]}
                    onChange={e => {
                      let val = e.target.value.replace(/[^0-9]/g, '');
                      setAppNumInput(prev => [prev[0], prev[1], val]);
                    }}
                  />
                  <button onClick={() => appNumCreate()}>등록</button>
                </div>
              </div>
            </div>

            <div className='user-info-list-wrap'>
              <h4>출원 정보 목록</h4>
              <div className='list-container'>
                <div className='patent-num-wrap'>
                  <div className='head'>특허고객번호</div>
                  {/* //= 데이터가 없을경우 body 클래스에도 no-data li에도 no-data가 될것 */}
                  <ul
                    className={
                      appCodeList?.length > 0 ? 'body ' : 'body no-data'
                    }>
                    {appCodeList?.length > 0 ? (
                      appCodeList?.map((item, idx) => {
                        return (
                          <li
                            key={idx}
                            title='삭제'
                            onClick={() => applicantNumDel(item)}>
                            {item}
                          </li>
                        );
                      })
                    ) : (
                      <li className='no-data'>
                        등록된 특허고객번호가 없습니다.
                      </li>
                    )}
                  </ul>
                </div>
                <div className='applicant-num-wrap'>
                  <div className='head'>출원번호</div>
                  {/* //= 데이터가 없을경우 body 클래스에도 no-data li에도 no-data가 될것 */}
                  <ul
                    className={
                      appNumList?.length > 0 ? 'body ' : 'body no-data'
                    }>
                    {appNumList?.length > 0 ? (
                      appNumList?.map((item, idx) => {
                        return (
                          <li
                            key={idx}
                            title='삭제'
                            onClick={() => appNumDel(item)}>
                            {item}
                          </li>
                        );
                      })
                    ) : (
                      <li className='no-data'>등록된 출원번호가 없습니다.</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {/* //= 셀프출원 추가 결제 필요내역 주석 */}
            {/* <div className='user-additional-payment'>
              <h4>추가 결제 필요 내역</h4>
              <div className='table-wrap'>
                <div className='table-head'>
                  <div>No</div>
                  <div>상표유형</div>
                  <div>상표명</div>
                  <div>상품분류</div>
                  <div>상품정보</div>
                  <div>출원유형</div>
                  <div>추가결제금액</div>
                  <div>추가금액발생이유</div>
                  <div>결제수단선택</div>
                  <div></div>
                </div> */}
            {/* //= 데이터가 없으면 table-body에 no-data 넣어주기 아이템도 no-data넣어주기 */}
            {/* <div className='table-body no-data'>
                  <div className='no-data'>추가 결제 필요내역이 없습니다.</div>
                </div>
                <div className='table-body'>
                  <div>1</div>
                  <div>문자상표</div>
                  <div>마크픽</div>
                  <div>45류</div>
                  <div>법무,보안</div>
                  <div>일반출원</div>
                  <div>106,000원</div>
                  <div>의견제출통지서</div>
                  <div>
                    <AdditionalSelectBox options={['무통장입금', '신용카드']} />
                  </div>
                  <div>
                    <button>결제하기</button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='user-trademark-detail common'>
              <h4>출원 상표 내역</h4>
              <div className='trademark-btn-wrap'>
                <button
                  onClick={() => {
                    if (appCodeList?.length > 0) {
                      setPatentModal(true);
                    } else {
                      alert(
                        '출원 정보 목록에 특허고객번호가 존재하지 않습니다.'
                      );
                    }
                  }}>
                  특허고객번호별 출원 상표 내역 보기
                </button>
                <button
                  onClick={() => {
                    if (appNumList?.length > 0) {
                      setApplicationModal(true);
                    } else {
                      alert('출원 정보 목록에 출원번호가 존재하지 않습니다.');
                    }
                  }}>
                  출원번호별 출원 상표 내역 보기
                </button>
              </div>
            </div>
          </div>
          <div className='common agent-info'>
            <div className='user-trademark-agent'>
              <h4>상표등록대행 내역</h4>
              <div className='table-wrap'>
                <div className='table-head'>
                  <div>No</div>
                  <div>상표 유형</div>
                  <div>상표명</div>
                  <div>출원인 유형</div>
                  <div>출원 진행 상태</div>
                  <div>결제 상태</div>
                  <div>결제 금액</div>
                  <div>결제일자</div>
                </div>

                {agencyData?.length > 0 ? (
                  agencyData?.map((item, idx) => {
                    return (
                      <div className='table-body'>
                        <div>
                          {(agencyCurrentPageInfo.page - 1) *
                            agencyCurrentPageInfo.limit +
                            idx +
                            1}
                        </div>
                        <div>
                          {item?.mark_type === 'TEXT'
                            ? '문자상표'
                            : item?.mark_type === 'COMPOUND'
                            ? '복합상표'
                            : '도형상표'}
                        </div>

                        <div
                          onClick={() => {
                            agentPopupFunc(item);
                          }}>
                          {item?.mark_type !== 'TEXT' ? (
                            <img src={item?.image_url} alt='상표이미지' />
                          ) : (
                            <div>
                              {item?.mark_name_step1 +
                                ' ' +
                                item?.mark_name_step2 +
                                ' ' +
                                item?.mark_name_step3 +
                                ' ' +
                                item?.mark_name_step4 +
                                ' ' +
                                item?.mark_name_step5}
                            </div>
                          )}
                        </div>
                        <div>{applicantType[item?.applicant_type]}</div>
                        <div>{applicantType[item?.progress_status]}</div>
                        <div>
                          {item?.paid_status === 'cancled'
                            ? '결제취소'
                            : item?.paid_status === 'ready'
                            ? '결제대기'
                            : '결제완료'}
                        </div>
                        <div>
                          {item?.paid_amount
                            ? new Intl.NumberFormat('ko-KR').format(
                                item?.paid_amount
                              ) + '원'
                            : ''}
                        </div>
                        <div>{item?.paid_at?.split('T')[0]}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className='table-body no-data'>
                    상표등록대행 내역이 없습니다.
                  </div>
                )}
              </div>

              <CommonPagination
                // postsPerPage={pageInfo?.limit}
                postsPerPage={1}
                viewPageNum={5}
                totalPosts={agencyCurrentPageInfo?.total_page}
                currentPage={agencyPage}
                setCurrentPage={setAgencyPage}
              />
            </div>
            <div className='user-additional-payment'>
              <h4>추가 결제 필요 내역</h4>
              <div className='table-wrap'>
                <div className='table-head'>
                  <div>No</div>
                  <div>상표 유형</div>
                  <div>상표명</div>
                  <div>상품 분류</div>
                  <div>상품 정보</div>
                  <div>출원 유형</div>
                  <div>추가 결제금액</div>
                  <div>추가금액 발생 이유</div>
                  <div>결제 수단 선택</div>
                  <div></div>
                </div>
                {/* //= 데이터가 없을시 no-data 붙여주기 */}
                {/* <div className='table-body no-data'>
                  <div className='no-data'>추가 결제 필요내역이 없습니다.</div>
                </div> */}
                {additionalData?.map((item, idx) => {
                  return (
                    <div className='table-body'>
                      <div>{idx + 1}</div>
                      <div>
                        {item?.mark_type === 'TEXT'
                          ? '문자상표'
                          : item?.mark_type === 'COMPOUND'
                          ? '복합상표'
                          : '도형상표'}
                      </div>
                      <div>
                        <div>
                          {item?.mark_name_step1 +
                            ' ' +
                            item?.mark_name_step2 +
                            ' ' +
                            item?.mark_name_step3 +
                            ' ' +
                            item?.mark_name_step4 +
                            ' ' +
                            item?.mark_name_step5 +
                            ' '}
                        </div>
                      </div>
                      <div>{item?.mark_classes}류</div>
                      <div>{item?.register_products}</div>
                      <div>{applicantType[item?.apply_types]}</div>
                      <div>
                        {new Intl.NumberFormat('ko-KR').format(
                          item?.merchant_price
                        )}
                        원
                      </div>
                      <div>{item?.paid_reason}</div>
                      <div>
                        <AdditionalSelectBox
                          options={['무통장입금', '신용카드']}
                        />
                      </div>
                      <div>
                        <button>결제하기</button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className='user-trademark-detail'>
              <h4>출원 상표 내역</h4>
              <div className='table-wrap'>
                <div className='table-head'>
                  <div>No</div>
                  <div>상표명</div>
                  <div>대표 출원인</div>
                  <div>출원 번호</div>
                  <div>출원일자</div>
                  <div>등록 번호</div>
                  <div>등록일자</div>
                </div>
                {/* //= 데이터가 없을시 no-data 붙여주기 */}
                {/* <div className='table-body no-data'>
                  <div className='no-data'>출원 상표내역이 없습니다.</div>
                </div> */}

                {applicantData?.length > 0 ? (
                  applicantData?.map((item, idx) => {
                    return (
                      <div className='table-body'>
                        <div>
                          {(applicantCurPageInfo?.page - 1) *
                            applicantCurPageInfo?.limit +
                            idx +
                            1}
                        </div>
                        <div>{item?.title}</div>
                        <div>{item?.agentName}</div>

                        <div>{item?.applicationNumber}</div>
                        <div>{item?.applicationDate?.split('T')[0]}</div>
                        <div>{item?.registrationNumber}</div>
                        <div>{item?.registrationDate?.split('T')[0]}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className='table-body no-data'>
                    <div className='no-data'>출원 상표 내역이 없습니다.</div>
                  </div>
                )}
              </div>
              <CommonPagination
                // postsPerPage={pageInfo?.limit}
                postsPerPage={1}
                viewPageNum={5}
                totalPosts={applicantCurPageInfo?.total_page}
                currentPage={applicantPage}
                setCurrentPage={setApplicantPage}
              />
            </div>
          </div>
          <div className='common inquiry-container'>
            <div className='user-inquiry-info'>
              <h4>
                문의내역
                <Link to='/inquiry'>
                  문의하러 가기 <img src={mypageArrow} alt='문의하러가기' />
                </Link>
              </h4>
              <ul>
                {inquiryData?.length > 0 ? (
                  inquiryData?.map((item, idx) => (
                    <li
                      key={idx}
                      onClick={() => {
                        navigate(`/inquiry-details/${item?.id}`);
                      }}>
                      <p>Q. {item?.title}</p>
                      <div>
                        <span>{item?.created_at?.split('T')[0]}</span>
                        {item?.answer === null ? (
                          <span className='no-complete'>미완료</span>
                        ) : (
                          <span>완료</span>
                        )}
                      </div>
                    </li>
                  ))
                ) : (
                  <>
                    <li className='inquiry-no-data'>문의 내역이 없습니다.</li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
        {userInfoModal && (
          <MyPageUserInfoModal
            userInfoModal={userInfoModal}
            setUserInfoModal={setUserInfoModal}
            curPassword={curPassword}
            setCurPassword={setCurPassword}
            setUserInfoOn={setUserInfoOn}
            userId={userInfoData?.user_id}
            userType={userType}
            setUserType={setUserType}
            setUserDeleteModal={setUserDeleteModal}
          />
        )}

        {userPwInfoModal && (
          <PasswordChange
            userPwInfoModal={userPwInfoModal}
            setUserPwInfoModal={setUserPwInfoModal}
            setCurPassword={setCurPassword}
            curPassword={curPassword}
            userId={userInfoData?.user_id}
          />
        )}
        {userDeleteModal && (
          <UserDeleteModal
            curPassword={curPassword}
            setCurPassword={setCurPassword}
            setUserDeleteModal={setUserDeleteModal}
            setUserType={setUserType}
          />
        )}
        {patentModal && (
          <PatentView
            setPatentModal={setPatentModal}
            appCodeList={appCodeList}
          />
        )}
        {applicationModal && (
          <ApplicationView
            setApplicationModal={setApplicationModal}
            appNumList={appNumList}
          />
        )}
        {agentPopup && (
          <MypageAgent
            setAgentPopup={setAgentPopup}
            agentPopupId={agentPopupId}
          />
        )}
        {selfPopup && (
          <MypageSelf setSelfPopup={setSelfPopup} selfPopupId={selfPopupId} />
        )}
      </div>
    </>
  );
};

export default MyPage;
