const Footer = () => {
  return (
    <footer className='footer'>
      <div className='content-container'>
        <div className='top'>
          <ul>
            <li className='nav-list'>
              <a
                href='/privacy'
                rel='noopener noreferrer'
                target='_self'
                className='highlight'>
                개인정보처리방침
              </a>
            </li>
            <li className='nav-list'>
              <a
                href='/use'
                rel='noopener noreferrer'
                target='_self'
                className='bold'>
                이용약관
              </a>
            </li>
          </ul>
        </div>
        <div className='bottom'>
          <div>
            <p>
              해율특허법률사무소 (HAEYUL IP LAW FIRM) (06193) 서울시 강남구
              테헤란로 70길 14-8 A동 10층 (대치동 890-34) | 대표 : 정상일
              {/* <span className='mobile-none'>|</span>{' '} */}
              {/* <br className='mobile-only' /> */}
              {/* <span className='mobile-none'>|</span>{' '} */}
              {/* <br className='mobile-only' /> */}
            </p>
            <p>사업자등록번호 : 129-21-82502 | 통신판매업 신고번호 : 제 2023-서울강남-03386 호</p>
            <p>
              Email : ip2@haeyulip.com | 고객센터 Tel : 02 - 6713 - 3176 | 팩스
              : 02 - 6713 - 3172 | 민원 담당자 : 박정민{' '}
            </p>

            <p className='bold'>
              Copyright &copy; | 해율특허법률사무소, All rights reserved.
            </p>
          </div>

          {/* <img
            src='https://image.inicis.com/mkt/certmark/inipay/inipay_43x43_gray.png'
            alt='클릭하시면 이니시스 결제시스템의 유효성을 확인하실 수 있습니다.'
            onClick={handleClick}
          /> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
