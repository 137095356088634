import { useState } from 'react';
import blackCheckImg from 'image/blackCheckIcon.svg';

const SimpleCost = () => {
  const [markType, setMarkType] = useState('text');
  const [fastMarkType, setFastMarkType] = useState('text');

  return (
    <div className='cost-wrap row'>
      <div className='column cost-card'>
        <h4>BASIC</h4>
        <hr />
        <div className='column cost-description'>
          <h2 className='price'>￦ 111,000원</h2>
          <span>부가세 및 특허청 관납료가 포함된 가격입니다</span>
          <div className='column benefits'>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>특허 법인 변리사 선임</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>출원 상담 및 상표 관리</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>상표 관리 범위 설정</span>
            </div>
          </div>
        </div>
        <hr />
        <span>일반 출원</span>
      </div>
      <div className='column cost-card'>
        <div className='mark-type-wrap'>
          <span
            onClick={() => setMarkType('text')}
            className={markType === 'text' ? 'active' : ''}>
            텍스트 상표
          </span>
          <span
            onClick={() => setMarkType('figure')}
            className={markType === 'figure' ? 'active' : ''}>
            도형상표
          </span>
          <span
            onClick={() => setMarkType('compound')}
            className={markType === 'compound' ? 'active' : ''}>
            복합상표
          </span>
        </div>
        <hr />
        <div className='column cost-description'>
          <h2 className='price'>
            ￦{' '}
            {markType === 'text'
              ? '166,000'
              : markType === 'figure'
              ? '199,000'
              : '243,000'}
            원
          </h2>
          <span>부가세 및 특허청 관납료가 포함된 가격입니다</span>
          <div className='column benefits'>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>특허 법인 변리사 선임</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>출원 상담 및 상표 관리</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>상표 관리 범위 설정</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>등록 가능성 조사</span>
            </div>
          </div>
        </div>
        <hr />
        <span>조사 출원</span>
      </div>
      <div className='column cost-card'>
        <h4>BASIC</h4>
        <hr />
        <div className='column cost-description'>
          <h2 className='price'>￦ 276,000원</h2>
          <span>부가세 및 특허청 관납료가 포함된 가격입니다</span>
          <div className='column benefits'>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>특허 법인 변리사 선임</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>출원 상담 및 상표 관리</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>상표 관리 범위 설정</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>심사 기간 12개월 단축</span>
            </div>
          </div>
        </div>
        <hr />
        <span>신속 출원</span>
      </div>
      <div className='column cost-card'>
        <div className='mark-type-wrap'>
          <span
            onClick={() => setFastMarkType('text')}
            className={fastMarkType === 'text' ? 'active' : ''}>
            텍스트 상표
          </span>
          <span
            onClick={() => setFastMarkType('figure')}
            className={fastMarkType === 'figure' ? 'active' : ''}>
            도형상표
          </span>
          <span
            onClick={() => setFastMarkType('compound')}
            className={fastMarkType === 'compound' ? 'active' : ''}>
            복합상표
          </span>
        </div>
        <hr />
        <div className='column cost-description'>
          <h2 className='price'>
            ￦{' '}
            {fastMarkType === 'text'
              ? '491,000'
              : fastMarkType === 'figure'
              ? '524,000'
              : '568,000'}
            원
          </h2>
          <span>부가세 및 특허청 관납료가 포함된 가격입니다</span>
          <div className='column benefits'>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>특허 법인 변리사 선임</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>출원 상담 및 상표 관리</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>상표 관리 범위 설정</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>등록 가능성 조사</span>
            </div>
            <div className='row'>
              <img src={blackCheckImg} alt='체크 이미지' />
              <span>심사 기간 12개월 단축</span>
            </div>
          </div>
        </div>
        <hr />
        <span>신속조사 출원</span>
      </div>
    </div>
  );
};

export default SimpleCost;
