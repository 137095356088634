import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonCheckBox from 'components/CommonCheckBox';
import normalIcon from 'image/normal.svg';
import shortenIcon from 'image/shorten.svg';
import inquireIcon from 'image/inquire.svg';
import fastSearchIcon from 'image/shortenInquire.svg';
import guideIcon from 'image/guideIcon.svg';
import bankWhiteIcon from 'image/bankWhite.svg';
import bankGrayIcon from 'image/bankGray.svg';
import cardWhiteIcon from 'image/cardWhite.svg';
import cardGrayIcon from 'image/cardGray.svg';
import { changeState, guideEvent, inputCheck } from 'js/commonFn';
import { getNoticeBool } from 'js/agenciesAPI';
import { getUserInfo } from 'js/selfAPI';
import { catchError } from 'js/commonFn';

const PaymentInfo = ({
  applicationType,
  markName,
  totalPrice,
  setTotalPrice,
  setPriceArr,
  typeArr,
  setTypeArr,
  application,
  setApplication,
  set2Arr,
  returnTypeList,
  selectProductArr,
  paymentMethod,
  setPaymentMethod,
  taxBillInfo,
  setTaxBillInfo,
  form,
}) => {
  const [name, setName] = useState('');
  const [guideArr, setGuideArr] = useState([]);
  const [notiFee, setNotiFee] = useState(0);
  const [notiArr, setNotiArr] = useState([]);
  const [overArr, setOverArr] = useState([]);
  const [taxBill, setTaxBill] = useState('none');
  const [numInput, setNumInput] = useState({
    first: '',
    middle: '',
    last: '',
  });
  let prevent = false;
  const navigate = useNavigate();

  //= 출원 유형 리턴 함수
  const returnType = mark_class => {
    if (
      !typeArr.includes(`SEARCH-${mark_class}-1`) &&
      !typeArr.includes(`FAST-${mark_class}-1`)
    )
      return 'NORMAL';
    else if (
      typeArr.includes(`SEARCH-${mark_class}-1`) &&
      !typeArr.includes(`FAST-${mark_class}-1`)
    )
      return 'SEARCH';
    else if (
      !typeArr.includes(`SEARCH-${mark_class}-1`) &&
      typeArr.includes(`FAST-${mark_class}-1`)
    )
      return 'FAST';
    else if (
      typeArr.includes(`SEARCH-${mark_class}-1`) &&
      typeArr.includes(`FAST-${mark_class}-1`)
    )
      return 'FASTSEARCH';
  };

  const getName = async () => {
    const result = await getUserInfo();
    if (typeof result === 'object') {
      setName(result?.data?.data?.name);
    } else return catchError(result, navigate);
  };

  const getNotiFee = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getNoticeBool({ mark_classes: selectProductArr });
    if (typeof result === 'object') {
      let count = 0;
      const noti = [];
      const excess = [];
      result?.data?.forEach(
        ({ mark_class, notification, excess_product_cnt }) => {
          if (!notification) {
            count += 1;
            noti.push(`${mark_class}류`);
          }
          if (excess_product_cnt) {
            const obj = {};
            obj[mark_class] = excess_product_cnt;
            excess.push(obj);
          }
        }
      );
      setNotiArr(noti);
      setNotiFee(6000 * count);
      setOverArr(excess);
      getName();
    } else return catchError(result, navigate);
  };

  //= 출원 희망하는 리스트 렌더 & 출원 유형 선택
  const renderSelectTypeSection = () => {
    return selectProductArr.map(({ register_product, mark_class }) => {
      //& 출원 예상일자 계산 함수
      const calcTime = (type, classification) => {
        //# UNIX TO TIMESTAMP
        const unix2time = t => {
          var date = new Date(t * 1000);
          var year = date.getFullYear();
          var month = '0' + (date.getMonth() + 1);
          var day = '0' + date.getDate();
          return year + '-' + month.substr(-2) + '-' + day.substr(-2);
        };

        let date;
        const now = Math.floor(new Date().getTime() / 1000);
        if (classification === 'due') date = unix2time(now + 432000);
        else if (type === 'NORMAL' || type === 'SEARCH') {
          if (classification === 'firstNotice')
            date = unix2time(now + 38880000);
          else date = unix2time(now + 46656000);
        } else if (type === 'FAST' || type === 'FASTSEARCH') {
          if (classification === 'firstNotice') date = unix2time(now + 5184000);
          else date = unix2time(now + 12960000);
        }
        return date;
      };

      return (
        <>
          <div className='row order-content'>
            <span>{mark_class}류</span>
            <div className='column'>
              <h5>
                {JSON.stringify(register_product)
                  .replaceAll('"', '')
                  .replaceAll('[', '')
                  .replaceAll(']', '')
                  .replaceAll(',', ', ')}
              </h5>
              <div className='row check-wrap'>
                <input
                  type='checkbox'
                  id={`SEARCH${mark_class}`}
                  className='display-none'
                  onChange={e =>
                    setTypeArr(prev => {
                      const clone = [...prev];
                      if (e.target.checked)
                        clone.push(`SEARCH-${mark_class}-1`);
                      else
                        clone.splice(
                          clone.indexOf(`SEARCH-${mark_class}-1`),
                          1
                        );
                      return clone;
                    })
                  }
                />
                <CommonCheckBox
                  id={`SEARCH${mark_class}`}
                  str='등록 가능성 조사'
                  state={typeArr.includes(`SEARCH-${mark_class}-1`)}
                />
                <input
                  type='checkbox'
                  id={`FAST${mark_class}`}
                  className='display-none'
                  onChange={e =>
                    setTypeArr(prev => {
                      const clone = [...prev];
                      if (e.target.checked) clone.push(`FAST-${mark_class}-1`);
                      else
                        clone.splice(clone.indexOf(`FAST-${mark_class}-1`), 1);
                      return clone;
                    })
                  }
                />
                <CommonCheckBox
                  id={`FAST${mark_class}`}
                  str='심사 기간 단축'
                  state={typeArr.includes(`FAST-${mark_class}-1`)}
                />
              </div>
            </div>
            <div className='row application-type'>
              <img
                src={
                  returnType(mark_class) === 'NORMAL'
                    ? normalIcon
                    : returnType(mark_class) === 'FAST'
                    ? shortenIcon
                    : returnType(mark_class) === 'SEARCH'
                    ? inquireIcon
                    : fastSearchIcon
                }
                alt='출원 아이콘'
              />
              <h5>
                {returnType(mark_class) === 'NORMAL'
                  ? '일반 출원'
                  : returnType(mark_class) === 'FAST'
                  ? '신속 출원'
                  : returnType(mark_class) === 'SEARCH'
                  ? '조사 출원'
                  : '신속조사 출원'}
              </h5>
              {guideArr.includes(mark_class) && (
                <div className='guide column'>
                  <span>
                    {returnType(mark_class) === 'NORMAL'
                      ? '동일한 상표가 있는지 확인하고 출원 진행'
                      : returnType(mark_class) === 'FAST'
                      ? '동일상표 확인 및 심사기간 단축하여 출원 진행'
                      : returnType(mark_class) === 'SEARCH'
                      ? '상표 등록가능성 확인하고 출원 진행'
                      : '상표 등록가능성 확인 및 심사기간 단축하여 출원 진행'}
                  </span>
                  <span className='red'>
                    {returnType(mark_class) === 'NORMAL' ||
                    returnType(mark_class) === 'FAST'
                      ? '출원 전 동일한 상표 확인되면 전액 환불처리'
                      : '등록가능성이 낮을 경우 등록가능성 조사수수료 제외하고 환불처리'}
                  </span>
                  <div className='row'>
                    <div className='column date-title'>
                      <span>상표출원 예정일</span>
                      <span>1차 심사결과통지 예상일</span>
                      <span>최종결정 예상일</span>
                    </div>
                    <div className='column date'>
                      <span>
                        {calcTime(returnType(mark_class), 'due').split('-')[0]}
                        년{' '}
                        {calcTime(returnType(mark_class), 'due').split('-')[1]}
                        월{' '}
                        {calcTime(returnType(mark_class), 'due').split('-')[2]}
                        일
                      </span>
                      <span>
                        {
                          calcTime(returnType(mark_class), 'firstNotice').split(
                            '-'
                          )[0]
                        }
                        년{' '}
                        {
                          calcTime(returnType(mark_class), 'firstNotice').split(
                            '-'
                          )[1]
                        }
                        월경
                      </span>
                      <span>
                        {
                          calcTime(returnType(mark_class), 'finalNotice').split(
                            '-'
                          )[0]
                        }
                        년{' '}
                        {
                          calcTime(returnType(mark_class), 'finalNotice').split(
                            '-'
                          )[1]
                        }
                        월경
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <img
                src={guideIcon}
                alt='가이드 아이콘'
                className='guide-icon'
                onClick={() =>
                  setGuideArr(prev => {
                    const clone = [...prev];
                    if (!clone.includes(mark_class)) clone.push(mark_class);
                    else clone.splice(clone.indexOf(mark_class, 1), 1);
                    return clone;
                  })
                }
              />
            </div>
          </div>
          <hr />
        </>
      );
    }, <></>);
  };

  //= 출원 유형별 결제 금액 리턴 함수
  const returnPrice = (i, priceType) => {
    const charge =
      i === 'NORMAL'
        ? 50000
        : i === 'SEARCH' && applicationType === 'text'
        ? 100000
        : i === 'SEARCH' && applicationType === 'figure'
        ? 130000
        : i === 'SEARCH' && applicationType === 'compound'
        ? 170000
        : i === 'FAST'
        ? 200000
        : i === 'FASTSEARCH' && applicationType === 'text'
        ? 250000
        : i === 'FASTSEARCH' && applicationType === 'figure'
        ? 280000
        : i === 'FASTSEARCH' && applicationType === 'compound'
        ? 320000
        : '';

    const difference = returnTypeList(i, 'arr').filter(x =>
      notiArr.includes(x)
    );

    let excessCount = 0;
    overArr.forEach(obj =>
      Object.keys(obj).forEach((key, idx) => {
        if (returnTypeList(i, 'arr')?.includes(`${key}류`))
          excessCount += Object.values(obj)[idx];
      })
    );

    const officialPee =
      i === 'FAST' || i === 'FASTSEARCH'
        ? 216000 * returnTypeList(i, 'arr').length +
          6000 * difference.length +
          2000 * excessCount
        : 56000 * returnTypeList(i, 'arr').length +
          6000 * difference.length +
          2000 * excessCount;

    let applicantAddAmount = 0;
    if (
      form === 'foreignPersonal' ||
      form === 'foreignCorporation' ||
      form === 'national'
    )
      applicantAddAmount = 160000;

    if (priceType === 'charge')
      return (
        (charge + applicantAddAmount) *
        returnTypeList(i, 'arr').length
      ).toLocaleString();
    else if (priceType === 'vat')
      return (
        ((charge + applicantAddAmount) * returnTypeList(i, 'arr').length) /
        10
      ).toLocaleString();
    else if (priceType === 'official') return officialPee.toLocaleString();
    else {
      return (
        (charge + applicantAddAmount) * returnTypeList(i, 'arr').length +
        ((charge + applicantAddAmount) * returnTypeList(i, 'arr').length) / 10 +
        officialPee
      );
    }
  };

  //= 결제 금액 박스 렌더 함수
  const renderAmountList = () => {
    return set2Arr.map(i => {
      return (
        <>
          <div className='column amount'>
            <h5 className='application-type'>
              {i === 'NORMAL'
                ? '일반 출원'
                : i === 'FAST'
                ? '신속 출원'
                : i === 'SEARCH'
                ? '조사 출원'
                : '신속조사 출원'}
            </h5>
            <span>{returnTypeList(i, 'str')}</span>
            <hr className='dashed' />
            <div className='row'>
              <span>출원대행 수수료</span>
              <span className='price'>{returnPrice(i, 'charge')}원</span>
            </div>
            <div className='row'>
              <span>VAT</span>
              <span className='price'>{returnPrice(i, 'vat')}원</span>
            </div>
            <div className='row'>
              <span>특허청출원 관납료</span>
              <span className='price'>{returnPrice(i, 'official')}원</span>
            </div>
            <div className='row total-price'>
              <span>합계</span>
              <span className='price'>
                {returnPrice(i, 'all').toLocaleString()}원
              </span>
            </div>
            <hr className='dashed' />
            <div className='row final-check-price'>
              <span>결제금액</span>
              <span>{returnPrice(i, 'all').toLocaleString()}원</span>
            </div>
          </div>
        </>
      );
    }, <></>);
  };

  //= 결제 금액 최종 합산 금액 계산 함수
  const getFinalPriceFn = () => {
    const finalPriceArr = [];
    for (let i of set2Arr) finalPriceArr.push(returnPrice(i, 'all'));
    let final = 0;
    finalPriceArr.forEach(p => {
      final += p;
    });
    setPriceArr(finalPriceArr);
    setTotalPrice(final);
  };

  //- 최종 결제 금액 합산
  useEffect(() => {
    getFinalPriceFn();
  }, [application, notiFee]);

  //-
  useEffect(() => {
    const applicationArr = [];
    for (let { mark_class } of selectProductArr)
      applicationArr.push(returnType(mark_class));
    setApplication(applicationArr);
  }, [typeArr]);

  //- 가이드 버튼 이벤트 관리
  useEffect(() => {
    if (guideArr.length > 0)
      window.addEventListener('click', e =>
        guideEvent(e, () => setGuideArr([]))
      );
  }, [guideArr]);

  //- 세금 계산서 발행 시 사업자등록번호/휴대폰번호 input Handling
  useEffect(() => {
    if (taxBill === 'business')
      changeState(
        setTaxBillInfo,
        'biz_regist_number',
        `${numInput.first}-${numInput.middle}-${numInput.last}`
      );
    else if (taxBill === 'private')
      changeState(
        setTaxBillInfo,
        'phone',
        `${numInput.first}-${numInput.middle}-${numInput.last}`
      );
    else return;
  }, [numInput]);

  //- 고시/비고시 여부 API 호출
  useEffect(() => {
    getNotiFee();
  }, []);

  //- 세금계산서 발행 종류 바뀔 때마다 실행
  useEffect(() => {
    if (taxBill === 'business')
      setTaxBillInfo({
        biz_regist_number: '',
        owner: '',
        company: '',
        email: '',
      });
    else if (taxBill === 'private')
      setTaxBillInfo({
        phone: '',
      });
    changeState(setTaxBillInfo, 'tax_invoice_type', taxBill);
    setNumInput({
      first: '',
      middle: '',
      last: '',
    });
  }, [taxBill]);

  return (
    <>
      <div className='order-info column'>
        <div className='row mark-name'>
          <h4>출원 예정 상표명</h4>
          <h4>{markName}</h4>
        </div>
        <hr />
        {renderSelectTypeSection()}
      </div>
      <h4>결제금액 확인</h4>
      <div className='row view-amount-list'>{renderAmountList()}</div>
      <h3 className='final-check'>
        총 결제금액은{' '}
        <span className='highlight'>{totalPrice.toLocaleString()}</span>
        원입니다.
      </h3>
      <div className='payment-select column'>
        <h4 className='select-method-title'>결제수단 선택</h4>
        <div className='row select-area'>
          <span
            onClick={() => setPaymentMethod('bank')}
            className={paymentMethod === 'bank' ? 'active' : ''}>
            <img
              src={paymentMethod === 'bank' ? bankWhiteIcon : bankGrayIcon}
              alt='무통장입금 아이콘'
            />
            <p>무통장입금</p>
          </span>
          <span
            onClick={() => {
              return alert(
                '카드 결제는 현재 지원하지 않습니다.\n무통장 입금을 이용해 주세요.'
              );
              // setPaymentMethod('card')
            }}
            className={paymentMethod === 'card' ? 'active' : ''}>
            <img
              src={paymentMethod === 'card' ? cardWhiteIcon : cardGrayIcon}
              alt='신용카드 아이콘'
            />
            <p>신용카드</p>
          </span>
        </div>
        {paymentMethod === 'bank' && (
          <>
            <p className='bank-manual'>
              *입금자명은 {name}(으)로 부탁 드립니다.
              <br />
              *무통장입금의 경우 영업 시간(09시~18시) 중에만 확인이 가능합니다.
            </p>
            <h4 className='tax-bill-title'>세금계산서 발행</h4>
            <div className='row radio-area'>
              <input
                type='checkbox'
                className='display-none'
                id='business'
                checked={taxBill === 'business'}
                onChange={e => (e.target.checked ? setTaxBill('business') : '')}
              />
              <CommonCheckBox
                id='business'
                state={taxBill === 'business'}
                str='사업자'
              />
              <input
                type='checkbox'
                className='display-none'
                id='private'
                checked={taxBill === 'private'}
                onChange={e => (e.target.checked ? setTaxBill('private') : '')}
              />
              <CommonCheckBox
                id='private'
                state={taxBill === 'private'}
                str='개인'
              />
              <input
                type='checkbox'
                className='display-none'
                id='none'
                checked={taxBill === 'none'}
                onChange={e => (e.target.checked ? setTaxBill('none') : '')}
              />
              <CommonCheckBox
                id='none'
                state={taxBill === 'none'}
                str='발행 안 함'
              />
            </div>
            {paymentMethod === 'bank' ? (
              <>
                {taxBill === 'business' ? (
                  <>
                    <div className='column input-wrap business'>
                      <div className='row'>
                        <strong>사업자등록번호</strong>
                        <input
                          type='text'
                          className='number-input'
                          value={numInput.first}
                          onChange={e =>
                            inputCheck('number', e, setNumInput, 'first')
                          }
                          maxLength={3}
                        />
                        <span className='hyphen'>-</span>
                        <input
                          type='text'
                          className='number-input'
                          value={numInput.middle}
                          onChange={e =>
                            inputCheck('number', e, setNumInput, 'middle')
                          }
                          maxLength={2}
                        />
                        <span className='hyphen'>-</span>
                        <input
                          type='text'
                          className='number-input'
                          value={numInput.last}
                          onChange={e =>
                            inputCheck('number', e, setNumInput, 'last')
                          }
                          maxLength={5}
                        />
                      </div>
                      <div className='row'>
                        <strong>상호명</strong>
                        <input
                          type='text'
                          value={taxBillInfo?.company}
                          onChange={e =>
                            changeState(
                              setTaxBillInfo,
                              'company',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className='row'>
                        <strong>대표자명</strong>
                        <input
                          type='text'
                          value={taxBillInfo?.owner}
                          onChange={e =>
                            changeState(setTaxBillInfo, 'owner', e.target.value)
                          }
                        />
                      </div>
                      <div className='row'>
                        <strong>이메일</strong>
                        <input
                          type='text'
                          value={taxBillInfo?.email}
                          onChange={e =>
                            inputCheck('email', e, setTaxBillInfo, 'email')
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : taxBill === 'private' ? (
                  <>
                    <div className='column input-wrap private'>
                      <div className='row'>
                        <strong>휴대폰 번호</strong>
                        <input
                          type='text'
                          className='number-input'
                          value={numInput.first}
                          onChange={e =>
                            inputCheck('number', e, setNumInput, 'first')
                          }
                          maxLength={3}
                        />
                        <span className='hyphen'>-</span>
                        <input
                          type='text'
                          className='number-input'
                          value={numInput.middle}
                          onChange={e =>
                            inputCheck('number', e, setNumInput, 'middle')
                          }
                          maxLength={4}
                        />
                        <span className='hyphen'>-</span>
                        <input
                          type='text'
                          className='number-input'
                          value={numInput.last}
                          onChange={e =>
                            inputCheck('number', e, setNumInput, 'last')
                          }
                          maxLength={4}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {taxBill === 'none' || (
                  <p className={`red ${taxBill}`}>
                    총 결제 금액에는 당사 수수료와 특허청 관납료가 포함 되어
                    있으며,
                    <br />
                    세금 계산서는 수수료에 대해서만 발행 됩니다.
                  </p>
                )}
              </>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PaymentInfo;
