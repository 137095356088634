import checkSvg from 'image/check.svg';

const CommonCheckBox = ({ state, str, id }) => {
  return (
    <label htmlFor={id} className='common-checkBox'>
      <div className={`checkBox column ${state ? 'active' : ''}`}>
        {state ? <img src={checkSvg} alt='체크 표시' /> : ''}
      </div>
      <span>{str}</span>
    </label>
  );
};

export default CommonCheckBox;
