import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import MarkPickHome from 'pages/MarkPickHome';
import Gnb from 'components/Gnb';
import Footer from 'components/Footer';
import AgenciesRoutes from 'Routes/AgenciesRoutes';
import SelfRoutes from 'Routes/SelfRoutes';
import { handleClickTopMove } from 'js/commonFn';
import './App.css';
import 'swiper/css/bundle';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    const IMP = window.IMP;
    IMP.init('imp99495830');
  }, []);

  useEffect(() => {
    handleClickTopMove();
  }, [pathname]);

  return (
    <div className='App'>
      <div
        className={`container ${
          pathname.includes('agencies') ||
          pathname.includes('self-application') ||
          pathname.includes('privacy') ||
          pathname.includes('use')
            ? 'gray-bg'
            : ''
        }`}>
        <Gnb />
        <AgenciesRoutes />
        <SelfRoutes />
        <Routes>
          <Route exact path='/' element={<MarkPickHome />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
