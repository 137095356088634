import { useState, useEffect } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import CommonCheckBox from 'components/CommonCheckBox';
import KoreaPersonalForm from 'components/agencies/form/KoreaPersonalForm';
import KoreaCorporationForm from 'components/agencies/form/KoreaCorporationForm';
import ForeignPersonalForm from 'components/agencies/form/ForeignPersonalForm';
import ForeignCorporationForm from 'components/agencies/form/ForeignCorporationForm';
import NationalForm from 'components/agencies/form/NationalForm';
import WarrantModal from 'components/agencies/WarrantModal';
import { changeState, essentialValueCheck, guideEvent } from 'js/commonFn';
import notifyIcon from 'image/notifyIcon.svg';

const InputApplicantInfo = ({
  form,
  setForm,
  applicantInfo,
  setApplicantInfo,
  selectProductArr,
  markName,
  temp,
}) => {
  const defaultStatus = {
    koreaPersonal: false,
    koreaCorporation: false,
    foreignPersonal: false,
    foreignCorporation: false,
    national: false,
  };
  const [selectApplicant, setSelectApplicant] = useState(defaultStatus);
  const [agreeAll, setAgreeAll] = useState(false);
  const [warrantModal, setWarrantModal] = useState(false);
  const [privacyGuide, setPrivacyGuide] = useState(false);
  const [emailFilter, setEmailFilter] = useState('직접 입력');

  //= 다음 우편번호 찾기 모달 오픈
  const open = useDaumPostcodePopup();

  //= 주소 핸들링 함수
  const addressHandlingFn = data => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') extraAddress += data.bname;
      if (data.buildingName !== '')
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    changeState(setApplicantInfo, 'zip_code', data.zonecode);
    changeState(setApplicantInfo, 'address', fullAddress);
  };

  //& Applicant Info Form Default
  const dataHandlingFn = () => {
    switch (form) {
      case 'koreaPersonal':
        return setApplicantInfo({
          name_kor: applicantInfo.name_kor || '',
          name_eng: applicantInfo.name_eng || '',
          email: applicantInfo.email || '',
          collection_consent: 'N',
          delegation_consent: 'N',
          phone_number: applicantInfo.phone_number || '',
          tel_number: applicantInfo.tel_number || '',
          zip_code: applicantInfo.zip_code || '',
          address: applicantInfo.address || '',
          detail_address: applicantInfo.detail_address || '',
          social_security_number: applicantInfo.social_security_number || '',
        });
      case 'koreaCorporation':
        return setApplicantInfo({
          name_kor: applicantInfo.name_kor || '',
          name_eng: applicantInfo.name_eng || '',
          email: applicantInfo.email || '',
          collection_consent: 'N',
          delegation_consent: 'N',
          phone_number: applicantInfo.phone_number || '',
          tel_number: applicantInfo.tel_number || '',
          zip_code: applicantInfo.zip_code || '',
          address: applicantInfo.address || '',
          detail_address: applicantInfo.detail_address || '',
          corp_regist_number: applicantInfo.corp_regist_number || '',
          biz_regist_number: applicantInfo.biz_regist_number || '',
          representative_name: applicantInfo.representative_name || '',
          representative_social_security_number:
            applicantInfo.representative_social_security_number || '',
          stamp_file: applicantInfo.stamp_file || '',
        });
      case 'foreignPersonal':
        return setApplicantInfo({
          name_kor: applicantInfo.name_kor || '',
          name_eng: applicantInfo.name_eng || '',
          email: applicantInfo.email || '',
          collection_consent: 'N',
          delegation_consent: 'N',
          country: applicantInfo.country || '',
          foreigner_register_card_yn:
            applicantInfo.foreigner_register_card_yn || '',
          stamp_file: applicantInfo.stamp_file || '',
        });
      case 'foreignCorporation':
        return setApplicantInfo({
          name_kor: applicantInfo.name_kor || '',
          name_eng: applicantInfo.name_eng || '',
          email: applicantInfo.email || '',
          collection_consent: 'N',
          delegation_consent: 'N',
          country: applicantInfo.country || '',
          representative_name: applicantInfo.representative_name || '',
          overseas_address: applicantInfo.overseas_address || '',
          stamp_file: applicantInfo.stamp_file || '',
        });
      case 'national':
        return setApplicantInfo({
          name_kor: applicantInfo.name_kor || '',
          name_eng: applicantInfo.name_eng || '',
          email: applicantInfo.email || '',
          collection_consent: 'N',
          delegation_consent: 'N',
          biz_regist_number: applicantInfo.biz_regist_number || '',
          tel_number: applicantInfo.tel_number || '',
          zip_code: applicantInfo.zip_code || '',
          address: applicantInfo.address || '',
          detail_address: applicantInfo.detail_address || '',
          stamp_file: applicantInfo.stamp_file || '',
        });
      default:
        return;
    }
  };

  useEffect(() => {
    if (temp) return;
    if (form === 'foreignPersonal') {
      if (applicantInfo.foreigner_register_card_yn === 'y')
        setApplicantInfo({
          name_kor: applicantInfo.name_kor,
          name_eng: applicantInfo.name_eng,
          email: applicantInfo.email,
          collection_consent: 'N',
          delegation_consent: 'N',
          country: applicantInfo.country,
          foreigner_register_card_yn: applicantInfo.foreigner_register_card_yn,
          stamp_file: '',
          foreigner_register_card_front_file: '',
          foreigner_register_card_back_file: '',
          foreigner_register_number: '',
          phone_number: '',
          zip_code: '',
          address: '',
          detail_address: '',
        });
      else if (applicantInfo.foreigner_register_card_yn === 'n')
        setApplicantInfo({
          name_kor: applicantInfo.name_kor,
          name_eng: applicantInfo.name_eng,
          email: applicantInfo.email,
          collection_consent: 'N',
          delegation_consent: 'N',
          country: applicantInfo.country,
          foreigner_register_card_yn: applicantInfo.foreigner_register_card_yn,
          stamp_file: '',
          passport_file: '',
          passport_number: '',
          overseas_address: '',
        });
    }
  }, [applicantInfo.foreigner_register_card_yn]);

  //= 다음 우편번호 찾기 모달 커스텀 옵션
  const openPostModal = () => {
    open({
      onComplete: addressHandlingFn,
      top: window.innerHeight / 2 - 200,
      left: window.innerWidth / 2 - 300,
      width: 600,
      height: 600,
    });
  };

  const formProps = {
    emailFilter,
    setEmailFilter,
    applicantInfo,
    setApplicantInfo,
    openPostModal,
  };

  useEffect(() => {
    setSelectApplicant(() => {
      const clone = { ...defaultStatus };
      clone[form] = true;
      return clone;
    });
    setEmailFilter('직접 입력');
    dataHandlingFn();
  }, [form]);

  useEffect(() => {
    if (
      applicantInfo.collection_consent === 'Y' &&
      applicantInfo.delegation_consent === 'Y'
    )
      setAgreeAll(true);
    else setAgreeAll(false);
  }, [applicantInfo.collection_consent, applicantInfo.delegation_consent]);

  useEffect(() => {
    if (applicantInfo.delegation_consent === 'Y') setWarrantModal(true);
  }, [applicantInfo.delegation_consent]);

  useEffect(() => {
    if (privacyGuide)
      window.addEventListener('click', e =>
        guideEvent(e, () => setPrivacyGuide(false))
      );
  }, [privacyGuide]);

  useEffect(() => {
    return () => {
      changeState(setApplicantInfo, 'collection_consent', 'N');
      changeState(setApplicantInfo, 'delegation_consent', 'N');
    };
  }, []);

  return (
    <>
      <div className='content-box'>
        <div className='row topBar'>
          <h3>특허고객 등록</h3>
          <div className='row'>
            <input
              type='checkbox'
              id='koreaPersonal'
              className='display-none'
              checked={selectApplicant.koreaPersonal}
              onChange={e => (e.target.checked ? setForm('koreaPersonal') : '')}
            />
            <CommonCheckBox
              str='국내개인'
              id='koreaPersonal'
              state={selectApplicant.koreaPersonal}
            />
            <input
              type='checkbox'
              id='koreaCorporation'
              className='display-none'
              checked={selectApplicant.koreaCorporation}
              onChange={e =>
                e.target.checked ? setForm('koreaCorporation') : ''
              }
            />
            <CommonCheckBox
              str='국내법인'
              id='koreaCorporation'
              state={selectApplicant.koreaCorporation}
            />
            <input
              type='checkbox'
              id='foreignPersonal'
              className='display-none'
              checked={selectApplicant.foreignPersonal}
              onChange={e =>
                e.target.checked ? setForm('foreignPersonal') : ''
              }
            />
            <CommonCheckBox
              str='외국개인'
              id='foreignPersonal'
              state={selectApplicant.foreignPersonal}
            />
            <input
              type='checkbox'
              id='foreignCorporation'
              className='display-none'
              checked={selectApplicant.foreignCorporation}
              onChange={e =>
                e.target.checked ? setForm('foreignCorporation') : ''
              }
            />
            <CommonCheckBox
              str='외국법인'
              id='foreignCorporation'
              state={selectApplicant.foreignCorporation}
            />
            <input
              type='checkbox'
              id='national'
              className='display-none'
              checked={selectApplicant.national}
              onChange={e => (e.target.checked ? setForm('national') : '')}
            />
            <CommonCheckBox
              str='국가기관'
              id='national'
              state={selectApplicant.national}
            />
          </div>
        </div>
        <hr />
        {selectApplicant.koreaPersonal && <KoreaPersonalForm {...formProps} />}
        {selectApplicant.koreaCorporation && (
          <KoreaCorporationForm {...formProps} />
        )}
        {selectApplicant.foreignPersonal && (
          <ForeignPersonalForm {...formProps} />
        )}
        {selectApplicant.foreignCorporation && (
          <ForeignCorporationForm {...formProps} />
        )}
        {selectApplicant.national && <NationalForm {...formProps} />}
        <hr className='dashed' />
        <div className='row agree-wrap'>
          <span className='agree-title'>위임 동의</span>
          <div className='column'>
            <div className='row agree-all'>
              <input
                type='checkbox'
                id='agreeAll'
                className='display-none'
                checked={agreeAll}
                onChange={e => {
                  if (e.target.checked) {
                    const valCheckResult = essentialValueCheck(
                      form,
                      applicantInfo
                    );
                    if (!valCheckResult) return;
                    setAgreeAll(true);
                    changeState(setApplicantInfo, 'delegation_consent', 'Y');
                    changeState(setApplicantInfo, 'collection_consent', 'Y');
                  } else {
                    setAgreeAll(false);
                    changeState(setApplicantInfo, 'delegation_consent', 'N');
                    changeState(setApplicantInfo, 'collection_consent', 'N');
                  }
                }}
              />
              <CommonCheckBox id='agreeAll' state={agreeAll} />
              <span className='agree-context bold row'>
                마크픽의 모든 약관을 확인하고 전체 위임 동의합니다.
              </span>
            </div>
            {privacyGuide && (
              <div className='guide privacy column'>
                <span>1. 개인정보 수집 이용목적: 상표출원</span>
                <span>
                  2. 개인정보 수집 항목: 담당자 성명, 담당자 이메일, 담당자
                  휴대전화, 담당자 유선전화, 출원인 성명(한글,영문),
                  법인명(한글,영문), 인감(법인인감), 주민등록번호, 법인등록번호,
                  출원인 이메일, 출원인 휴대전화, 출원인 유선전화,
                  사업자등록번호(출원용,세금계산서용), 대표자명(세금계산서용),
                  이메일(세금계산서용)
                </span>
                <span>
                  3. 제3자에게 정보제공: 특허청, KCP, 국세청, 금융결제원, 각
                  카드사, 각 은행, 각 통신사, 나이스신용평가정보 외
                </span>
                <span>4. 개인정보 보유 이용 기간: 상표 출원일부터 10년</span>
                <span>
                  5. 위와 같은 개인 정보 수집 이용에 동의하지 않으실 수
                  있습니다. (동의 거부시 출원진행 불가)
                </span>
              </div>
            )}
            <div className='row'>
              <input
                type='checkbox'
                id='agreePrivacy'
                className='display-none'
                checked={applicantInfo.collection_consent === 'Y'}
                onChange={e => {
                  if (e.target.checked) {
                    const valCheckResult = essentialValueCheck(
                      form,
                      applicantInfo
                    );
                    if (!valCheckResult) return;
                    changeState(setApplicantInfo, 'collection_consent', 'Y');
                  } else
                    changeState(setApplicantInfo, 'collection_consent', 'N');
                }}
              />
              <CommonCheckBox
                id='agreePrivacy'
                state={applicantInfo.collection_consent === 'Y'}
              />
              <span className='agree-context row'>
                (필수) 개인정보 수집에 동의합니다.
                <img
                  src={notifyIcon}
                  alt='가이드 아이콘'
                  onClick={() => setPrivacyGuide(!privacyGuide)}
                  className='guide-icon'
                />
              </span>
            </div>
            <div className='row'>
              <input
                type='checkbox'
                id='agreeMandate'
                className='display-none'
                checked={applicantInfo.delegation_consent === 'Y'}
                onChange={e => {
                  if (e.target.checked) {
                    const valCheckResult = essentialValueCheck(
                      form,
                      applicantInfo
                    );

                    if (!valCheckResult) return;
                    changeState(setApplicantInfo, 'delegation_consent', 'Y');
                  } else
                    changeState(setApplicantInfo, 'delegation_consent', 'N');
                }}
              />
              <CommonCheckBox
                id='agreeMandate'
                state={applicantInfo.delegation_consent === 'Y'}
              />
              <span className='agree-context row'>
                (필수) 상표 등록과 관련된 일체의 업무에 대해 마크픽에 위임할
                것을 동의합니다.
              </span>
            </div>
          </div>
        </div>
      </div>
      {warrantModal && (
        <WarrantModal
          setApplicantInfo={setApplicantInfo}
          setModal={setWarrantModal}
          selectProductArr={selectProductArr}
          markName={markName}
          name={
            form === 'koreaPersonal' ||
            form === 'foreignPersonal' ||
            form === 'national'
              ? applicantInfo?.name_kor
              : applicantInfo?.representative_name
          }
        />
      )}
    </>
  );
};

export default InputApplicantInfo;
