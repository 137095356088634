import { Route, Routes } from 'react-router-dom';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import FindId from 'pages/findInfo/FindId';
import FindPw from 'pages/findInfo/FindPw';
import FindPwAuth from 'pages/findInfo/FindPwAuth';
import NoneIdResult from 'pages/findInfo/NoneIdResult';
import ChangePw from 'pages/findInfo/ChangePw';
import CompleteChangePw from 'pages/findInfo/CompleteChangePw';
import ApplicationStep from 'pages/ApplicationStep';
import Cost from 'pages/Cost';
import ApplicationAgencies from 'pages/ApplicationAgencies';

function AgenciesRoutes() {
  return (
    <Routes>
      {/* 로그인 */}
      <Route exact path='/sign-in' element={<SignIn />} />
      {/* 회원가입 */}
      <Route exact path='/sign-up' element={<SignUp />} />
      {/* 아이디/비밀번호 찾기 */}
      <Route exact path='/find-id' element={<FindId />} />
      <Route exact path='/find-pw' element={<FindPw />} />
      <Route exact path='/find-pw/auth' element={<FindPwAuth />} />
      <Route exact path='/none-id' element={<NoneIdResult />} />
      <Route exact path='/change-pw' element={<ChangePw />} />
      <Route exact path='/complete-change-pw' element={<CompleteChangePw />} />
      {/* 상표 등록 절차 */}
      <Route exact path='/application-step' element={<ApplicationStep />} />
      {/* 요금 안내 */}
      <Route exact path='/cost' element={<Cost />} />
      {/* 상표 등록 대행 */}
      <Route exact path='/agencies' element={<ApplicationAgencies />} />
    </Routes>
  );
}

export default AgenciesRoutes;
