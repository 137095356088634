import { useState, useEffect } from 'react';
import close from 'image/Modal/modal-close.svg';
import guideArrow from 'image/Modal/guide-arrow.svg';
import btnArrow from 'image/Modal/btn-arrow.svg';

const Issue = ({ setIssueModal }) => {
  useEffect(() => {
    // 모달 창이 열릴 때, body 요소의 스크롤을 막습니다.
    document.body.style.overflow = 'hidden';

    // 모달 창이 닫힐 때, 스크롤을 다시 허용합니다.
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);
  const handleDownload = () => {
    const files = [
      '/files/이용가이드-셀프 상표등록(특허고객발급 프로세스).pdf',
      '/files/이용가이드-셀프 상표등록(특허청 상표등록 프로세스).pdf',
    ];
    files.forEach(file => {
      var link = document.createElement('a');
      link.href = file;
      link.download = file.split('/').pop();
      link.click();
    });
  };
  return (
    <div className='modal-overlay issue'>
      <div className='modal issue-modal'>
        <div className='head'>
          <p>특허고객번호 발급받기 / 내번호찾기</p>
          <button onClick={() => setIssueModal(false)}>
            <img src={close} alt='특허고객번호 / 내번호찾기 모달창 닫기 버튼' />
          </button>
        </div>
        <div className='content'>
          <p>
            해당 서비스는 특허로 홈페이지(
            <a
              href='www.patent.go.kr'
              target='_blank'
              rel='noopener noreferrer'>
              www.patent.go.kr
            </a>
            )에서 진행됩니다.
          </p>
          <p>특허로 홈페이지로 이동하시겠습니까?</p>
          <div className='issue-btn-wrap'>
            <div>
              <a
                href='https://www.patent.go.kr/smart/jsp/ka/prestep/codeapp/CodeAppView.do'
                target='_blank'
                rel='noopener noreferrer'>
                <button>
                  특허고객번호 발급받기 이동{' '}
                  <img src={btnArrow} alt='특허고객번호 모달창 열기' />
                </button>
              </a>
              <a
                href='https://www.patent.go.kr/smart/jsp/kiponet/mp/mpopenpatinfo/apagtinfo/ReadApAgtInfoInput.do'
                target='_blank'
                rel='noopener noreferrer'>
                <button>
                  내번호찾기 이동{' '}
                  <img src={btnArrow} alt='내번호찾기 모달창 열기' />
                </button>
              </a>
            </div>
            <button onClick={() => setIssueModal(false)}>닫기</button>
          </div>
        </div>
        <div className='issue-guide'>
          <div className='txt-wrap'>
            <div>
              특허로 홈페이지가 처음이신 분들을 위해 마크픽에서 이용가이드를
              <br />
              제공하고 있습니다. 참고하여 이용하시기 바랍니다.
            </div>
            <button onClick={() => handleDownload()}>
              이용가이드 보기 <img src={guideArrow} alt='이용가이드보기' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Issue;
