import agreeTermImg from 'image/termAgree.svg';
import infoInputGray from 'image/infoInputGray.svg';
import infoInput from 'image/infoInput.svg';
import signUpCompleteGray from 'image/signUpCompleteGray.svg';
import signUpComplete from 'image/signUpComplete.svg';
import signUpStepArrow from 'image/signUpStepArrow.svg';

const SignUpStep = ({ step }) => {
  return (
    <>
      <h2>회원가입</h2>
      <div className='row'>
        <div className='column'>
          <div className='circle active'>
            <img src={agreeTermImg} alt='약관동의 아이콘' />
          </div>
          <span className='bolder'>약관동의</span>
        </div>
        <img
          src={signUpStepArrow}
          alt='회원가입 스텝 화살표'
          className='step-arrow'
        />
        <div className='column'>
          <div className={`circle ${step !== 'one' ? 'active' : ''}`}>
            {step !== 'one' ? (
              <img src={infoInput} alt='정보입력 아이콘' />
            ) : (
              <img src={infoInputGray} alt='정보입력 아이콘' />
            )}
          </div>
          <span className={`${step !== 'one' ? 'bolder' : ''}`}>정보입력</span>
        </div>
        <img
          src={signUpStepArrow}
          alt='회원가입 스텝 화살표'
          className='step-arrow'
        />
        <div className='column'>
          <div className={`circle ${step === 'three' ? 'active' : ''}`}>
            {step === 'three' ? (
              <img src={signUpComplete} alt='가입 완료 아이콘' />
            ) : (
              <img src={signUpCompleteGray} alt='가입 완료 아이콘' />
            )}
          </div>
          <span className={`${step === 'three' ? 'bolder' : ''}`}>
            가입완료
          </span>
        </div>
      </div>
    </>
  );
};

export default SignUpStep;
