import Issue from 'components/modal/Issue';

import { useState } from 'react';
import ApplicantInput from './ApplicantInput';

const ApplicantSelf = ({
  setStep,
  applicants,
  setApplicants,
  stepOneSave,
  tempData,
  setTempData,
  setTempleteModal,
}) => {
  //# auto : 지분 자동 1/n 분할 direct : 지분 직접 입력
  const [inputCheck, setInputCheck] = useState('auto');
  //# Issue 모달 창 useState
  const [IssueModal, setIssueModal] = useState(false);

  const handleAddApplicant = newApplicant => {
    if (applicants?.length === 5) return;
    setApplicants([...applicants, { ...newApplicant, id: applicants.length }]);
  };

  const handleDeleteApplicant = id => {
    const newApplicants = applicants.filter(applicant => applicant.id !== id);
    setApplicants(
      newApplicants.map((applicant, index) => ({ ...applicant, id: index }))
    );
  };

  const nextValidMove = () => {
    //# 지분을 더하는 계산식 100이 초과할 경우 alert창
    const totalShare = applicants.reduce((acc, cur) => {
      return acc + (cur.applicant_share || 0);
    }, 0);

    if (inputCheck === 'direct') {
      if (totalShare > 100) {
        return alert('지분은 100을 초과할 수 없습니다.');
      } else if (totalShare < 100) {
        return alert('지분이 100이 되도록 해 주세요.');
      }
    }
    setStep(2);
  };

  // # 다음버튼을 눌렀을 때 첫번째 아이디의 값이 없을경우, 2번,3번의 값이 빈 문자열일 경우 값 업데이트
  const handleDeleteEmptyApplicants = () => {
    if (
      applicants[0]?.applicant_name.trim() === '' ||
      applicants[0]?.applicant_code.trim() === ''
    ) {
      return alert('첫번째 출원인 정보가 입력되지 않았습니다.');
    }
    const updatedApplicants = applicants.filter(applicant => {
      // applicant_name 또는 applicant_code이 빈 문자열인 경우 해당 요소를 필터링합니다.
      return (
        applicant.applicant_name.trim() !== '' ||
        applicant.applicant_code.trim() !== ''
      );
    });
    setApplicants(updatedApplicants);
    nextValidMove();
  };

  return (
    <>
      <div className='applicant-info-wrap'>
        <div className='regi-head'>
          <h4>특허고객등록</h4>
          <div>
            <label>
              <input
                type='radio'
                name='share'
                checked={inputCheck === 'auto'}
                onChange={() => setInputCheck('auto')}
              />
              <span className='radio'></span>
              <span className='text'>지분 자동 1/n 분할</span>
            </label>
            <label>
              <input
                type='radio'
                name='share'
                checked={inputCheck === 'direct'}
                onChange={() => setInputCheck('direct')}
              />
              <span className='radio'></span>
              <span className='text'>지분 직접 입력</span>
            </label>
          </div>
        </div>
        <div className='regi-content'>
          {applicants.map((applicant, index) => (
            <ApplicantInput
              key={applicant.id}
              id={applicant.id}
              onAdd={handleAddApplicant}
              onDelete={handleDeleteApplicant}
              applicants={applicants}
              setApplicants={setApplicants}
              isAddButtonDisplayed={index === 0}
              setIssueModal={setIssueModal}
              inputCheck={inputCheck}
            />
          ))}
        </div>
      </div>
      <div className='issue-btn-container'>
        <button className='template' onClick={() => setTempleteModal(true)}>
          템플릿 불러오기
        </button>
        <button className='temporary-save' onClick={() => stepOneSave()}>
          임시저장
        </button>
        <button
          className='next'
          onClick={() => {
            handleDeleteEmptyApplicants();
          }}>
          다음
        </button>
      </div>

      {IssueModal && <Issue setIssueModal={setIssueModal} />}
    </>
  );
};

export default ApplicantSelf;
