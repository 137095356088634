import { useState, useEffect } from 'react';
import CommonSelect from 'components/CommonSelect';
import { changeState, fileSettingFn, inputCheck } from 'js/commonFn';
import { emailArr } from 'js/commonFn';

const NationalForm = ({
  emailFilter,
  setEmailFilter,
  applicantInfo,
  setApplicantInfo,
  openPostModal,
}) => {
  const [email, setEmail] = useState({
    id: applicantInfo?.email?.split('@')[0] || '',
    domain: applicantInfo?.email?.split('@')[1] || '',
  });
  const [bizNum, setBizNum] = useState({
    first: applicantInfo?.biz_regist_number?.split('-')[0] || '',
    middle: applicantInfo?.biz_regist_number?.split('-')[1] || '',
    last: applicantInfo?.biz_regist_number?.split('-')[2] || '',
  });
  const [telNum, setTelNum] = useState({
    first: applicantInfo?.tel_number?.split('-')[0] || '',
    middle: applicantInfo?.tel_number?.split('-')[1] || '',
    last: applicantInfo?.tel_number?.split('-')[2] || '',
  });
  const [stamp, setStamp] = useState(applicantInfo.stamp_file || '');

  useEffect(() => {
    changeState(setApplicantInfo, 'email', `${email.id}@${email.domain}`);
  }, [email]);

  useEffect(() => {
    changeState(
      setApplicantInfo,
      'biz_regist_number',
      `${bizNum.first}-${bizNum.middle}-${bizNum.last}`
    );
  }, [bizNum]);

  useEffect(() => {
    changeState(
      setApplicantInfo,
      'tel_number',
      `${telNum.first}-${telNum.middle}-${telNum.last}`
    );
  }, [telNum]);

  useEffect(() => {
    changeState(setApplicantInfo, 'stamp_file', stamp);
  }, [stamp]);

  useEffect(() => {
    if (emailFilter === '직접 입력')
      changeState(
        setEmail,
        'domain',
        applicantInfo?.email?.split('@')[1] || ''
      );
    else changeState(setEmail, 'domain', emailFilter);
  }, [emailFilter]);

  return (
    <div className='form'>
      <div className='row'>
        <span className='essential'>국가/공공 기관명(국문)</span>
        <input
          type='text'
          className='common-input'
          onChange={e => inputCheck('korean', e, setApplicantInfo, 'name_kor')}
          value={applicantInfo.name_kor || ''}
        />
      </div>
      <div className='row'>
        <span className='essential'>국가/공공 기관명(영문)</span>
        <input
          type='text'
          className='common-input'
          onChange={e => inputCheck('english', e, setApplicantInfo, 'name_eng')}
          value={applicantInfo.name_eng || ''}
        />
      </div>
      <div className='row'>
        <span className='essential'>고유번호(업자등록번호)</span>
        <input
          type='text'
          className='phone-input'
          maxLength={3}
          onChange={e => inputCheck('number', e, setBizNum, 'first')}
          value={bizNum.first || ''}
        />
        <span className='hyphen'>-</span>
        <input
          type='text'
          className='phone-input'
          maxLength={2}
          onChange={e => inputCheck('number', e, setBizNum, 'middle')}
          value={bizNum.middle || ''}
        />
        <span className='hyphen'>-</span>
        <input
          type='text'
          className='phone-input'
          maxLength={5}
          onChange={e => inputCheck('number', e, setBizNum, 'last')}
          value={bizNum.last || ''}
        />
      </div>
      <div className='row'>
        <span className='essential'>기관 대표 메일</span>
        <input
          type='text'
          className='email-input'
          placeholder='이메일'
          onChange={e => inputCheck('email', e, setEmail, 'id')}
          value={email.id || ''}
        />
        <span className='at'>@</span>
        <input
          type='text'
          className='email-input'
          onChange={e => inputCheck('email', e, setEmail, 'domain')}
          value={email.domain || ''}
          disabled={emailFilter !== '직접 입력'}
        />
        <CommonSelect
          opt={emailArr}
          selectVal={emailFilter}
          setSelectVal={setEmailFilter}
        />
      </div>
      <div className='row'>
        <span className='essential'>유선전화</span>
        <input
          type='text'
          className='phone-input'
          onChange={e => inputCheck('number', e, setTelNum, 'first')}
          value={telNum.first || ''}
          maxLength={3}
        />
        <span className='hyphen'>-</span>
        <input
          type='text'
          className='phone-input'
          onChange={e => inputCheck('number', e, setTelNum, 'middle')}
          value={telNum.middle || ''}
          maxLength={4}
        />
        <span className='hyphen'>-</span>
        <input
          type='text'
          className='phone-input'
          onChange={e => inputCheck('number', e, setTelNum, 'last')}
          value={telNum.last || ''}
          maxLength={4}
        />
      </div>
      <div className='row'>
        <span className='essential'>우편번호</span>
        <input
          type='text'
          className='search-input'
          value={applicantInfo.zip_code || ''}
          disabled
        />
        <button className='search-btn' onClick={openPostModal}>
          찾기
        </button>
      </div>
      <div className='row'>
        <span className='essential'>등본상 주소</span>
        <input
          type='text'
          className='common-input'
          placeholder='주소 입력'
          value={applicantInfo.address || ''}
          disabled
        />
      </div>
      <div className='row'>
        <input
          type='text'
          className='common-input'
          placeholder='상세 주소 입력'
          onChange={e =>
            changeState(setApplicantInfo, 'detail_address', e.target.value)
          }
          value={applicantInfo.detail_address || ''}
        />
      </div>
      <div className='row'>
        <span className='optional'>공인대장(기관인감)</span>
        <input
          type='file'
          id='file-input'
          className='display-none'
          accept='image/*'
          onChange={e => fileSettingFn(e, setStamp)}
        />
        <div className='row file-input'>
          <label htmlFor='file-input' className='select-file-button'>
            파일선택
          </label>
          <div className='file-name-input'>
            {stamp.name || '선택된 파일 없음'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NationalForm;
