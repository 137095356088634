import { useEffect } from 'react';
import close from 'image/Modal/modal-close.svg';

const TempSave = ({ setTempModal, setTemp, modalType }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <div className='modal-overlay temp'>
      <div className='modal temp-modal'>
        <div className='head'>
          <p>{modalType === 'save' ? '임시 저장' : '임시 저장된 데이터'}</p>
          <button onClick={() => setTempModal(false)}>
            <img src={close} alt='임시 저장 모달창 닫기 버튼' />
          </button>
        </div>
        <div className='txt-wrap'>
          {modalType === 'save'
            ? '임시 저장 되었습니다.'
            : '기존에 임시 저장된 데이터를 불러오시겠습니까?'}
        </div>
        <div className='temp-btn-wrap'>
          <button
            className='check-btn'
            onClick={() => {
              setTempModal(false);
              if (modalType !== 'save') setTemp(true);
            }}>
            확인
          </button>
          {modalType !== 'save' && (
            <button className='cancel-btn' onClick={() => setTempModal(false)}>
              취소
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TempSave;
