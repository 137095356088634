import { useState, useEffect } from 'react';
import CommonSelect from 'components/CommonSelect';
import SearchCountry from '../SearchCountry';
import { changeState, fileSettingFn, inputCheck } from 'js/commonFn';
import { emailArr } from 'js/commonFn';

const ForeignCorporationForm = ({
  emailFilter,
  setEmailFilter,
  setApplicantInfo,
  applicantInfo,
}) => {
  const [searchModal, setSearchModal] = useState(false);
  const [email, setEmail] = useState({
    id: applicantInfo?.email?.split('@')[0] || '',
    domain: applicantInfo?.email?.split('@')[1] || '',
  });
  const [sign, setSign] = useState(applicantInfo?.stamp_file || '');
  const [country, setCountry] = useState(applicantInfo?.country || '');

  useEffect(() => {
    changeState(setApplicantInfo, 'email', `${email.id}@${email.domain}`);
  }, [email]);

  useEffect(() => {
    changeState(setApplicantInfo, 'stamp_file', sign);
  }, [sign]);

  useEffect(() => {
    if (emailFilter === '직접 입력')
      changeState(
        setEmail,
        'domain',
        applicantInfo?.email?.split('@')[1] || ''
      );
    else changeState(setEmail, 'domain', emailFilter);
  }, [emailFilter]);

  useEffect(() => {
    changeState(setApplicantInfo, 'country', country);
  }, [country]);

  return (
    <>
      <div className='form'>
        <div className='row'>
          <span className='essential'>법인이름(국문)</span>
          <input
            type='text'
            className='common-input'
            placeholder='출원인 이름(국문) ex.한지민'
            onChange={e =>
              inputCheck('korean', e, setApplicantInfo, 'name_kor')
            }
            value={applicantInfo.name_kor || ''}
          />
        </div>
        <div className='row'>
          <span className='essential'>법인이름(영문)</span>
          <input
            type='text'
            className='common-input'
            placeholder='출원인 이름(국문) ex.Han Ji Min'
            onChange={e =>
              inputCheck('english', e, setApplicantInfo, 'name_eng')
            }
            value={applicantInfo.name_eng || ''}
          />
        </div>
        <div className='row'>
          <span className='essential'>국적</span>
          <input
            type='text'
            className='search-input'
            disabled
            value={country.split('/')[1] || ''}
          />
          <button className='search-btn' onClick={() => setSearchModal(true)}>
            찾기
          </button>
        </div>
        <div className='row'>
          <span className='essential'>대표자 이름(영문)</span>
          <input
            type='text'
            className='common-input'
            onChange={e =>
              inputCheck('english', e, setApplicantInfo, 'representative_name')
            }
            value={applicantInfo.representative_name || ''}
          />
        </div>
        <div className='row'>
          <span className='essential'>법인 이메일</span>
          <input
            type='text'
            className='email-input'
            placeholder='이메일'
            onChange={e => inputCheck('email', e, setEmail, 'id')}
            value={email.id || ''}
          />
          <span className='at'>@</span>
          <input
            type='text'
            className='email-input'
            onChange={e => inputCheck('email', e, setEmail, 'domain')}
            value={email.domain || ''}
            disabled={emailFilter !== '직접 입력'}
          />
          <CommonSelect
            opt={emailArr}
            selectVal={emailFilter}
            setSelectVal={setEmailFilter}
          />
        </div>
        <div className='row'>
          <span className='essential'>법인 주소(영문)</span>
          <input
            type='text'
            className='common-input'
            onChange={e =>
              inputCheck('eng&num', e, setApplicantInfo, 'overseas_address')
            }
            value={applicantInfo.overseas_address || ''}
          />
        </div>
        <div className='row'>
          <span className='essential'>대표자 서명</span>
          <input
            type='file'
            id='file-input'
            className='display-none'
            accept='image/*'
            onChange={e => fileSettingFn(e, setSign)}
          />
          <div className='row file-input'>
            <label htmlFor='file-input' className='select-file-button'>
              파일선택
            </label>
            <div className='file-name-input'>
              {sign.name || '선택된 파일 없음'}
            </div>
          </div>
        </div>
      </div>
      {searchModal && (
        <SearchCountry
          setSearchModal={setSearchModal}
          country={country}
          setCountry={setCountry}
        />
      )}
    </>
  );
};

export default ForeignCorporationForm;
