import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CustomerHead from 'components/CustomerHead';
import CustomerTitle from 'components/CustomerTitle';
import { changeTitle } from 'js/commonFn';
import upArrow from 'image/Customer/customer-up.svg';
import downArrow from 'image/Customer/customer-down.svg';
import people from 'image/Customer/people.png';

const FAQ = () => {
  const [faqArcodian, setFaqArcodian] = useState([]);

  const handleFaqCheck = itemId => {
    if (faqArcodian.includes(itemId)) {
      setFaqArcodian(faqArcodian.filter(id => id !== itemId));
    } else {
      setFaqArcodian([...faqArcodian, itemId]);
    }
  };

  useEffect(() => {
    changeTitle('마크픽 > FAQ');
  }, []);

  return (
    <div className='content-customer-wrap'>
      <CustomerHead step='FAQ' />
      <div className='customer faq'>
        <CustomerTitle
          title='자주 묻는 질문'
          description='문의하려는 내용과 유사한 질문의 답변을 확인해 보세요.'
        />
        <ul className='faq-list'>
          <li className={faqArcodian.includes(0) ? 'active' : ''}>
            <div className='arcodian' onClick={() => handleFaqCheck(0)}>
              <div className='img-wrap'>
                <span className='Q'>Q.</span>
                <span>아이디와 비밀번호를 모르겠어요.</span>
              </div>
              <div className='img-wrap'>
                <img
                  className='arcodian-arrow'
                  src={faqArcodian.includes(0) ? upArrow : downArrow}
                  alt='FAQ 아코디언 화살표'
                />
              </div>
            </div>
            <div className='find-wrap'>
              <p>
                <span className='A'>A.</span>
                <span>
                  화면 상단의 로그인 영역에서 '아이디 찾기' 혹은 '비밀번호
                  찾기'를 클릭하면 아이디와 비밀번호를 찾을 수 있습니다.
                </span>
              </p>
              <ul className='find-list'>
                <li>
                  <p>
                    <Link to='/find-id'>아이디 찾기 &#62;</Link>
                  </p>
                  <span>
                    본인인증(휴대폰 인증)을 하지 않는 경우 아이디 찾기를
                    이용하실 수 없습니다.
                  </span>
                </li>
                <li>
                  <p>
                    <Link to='/find-pw'>비밀번호 찾기 &#62;</Link>
                  </p>
                  <span>
                    비밀번호 찾기는 본인인증(휴대폰 인증)을 통해 하실 수
                    있습니다.
                  </span>
                </li>
              </ul>
            </div>
          </li>
          <li className={faqArcodian.includes(1) ? 'active' : ''}>
            <div className='arcodian' onClick={() => handleFaqCheck(1)}>
              <div className='img-wrap'>
                <span className='Q'>Q.</span>
                <span>아이디를 변경하고 싶어요.</span>
              </div>
              <div className='img-wrap'>
                <img
                  className='arcodian-arrow'
                  src={faqArcodian.includes(1) ? upArrow : downArrow}
                  alt='FAQ 아코디언 화살표'
                />
              </div>
            </div>
            <div className='change-wrap'>
              <p>
                <span className='A'>A.</span>
                <span>마크픽은 아이디 변경이 불가능합니다.</span>
              </p>
              <div className='change-description'>
                <p>
                  아이디 변경을 원하시면 마크픽 회원 탈퇴 후 재가입하여
                  변경하고자 하는 아이디로 회원가입을 하신 후 이용하실 수
                  있습니다.
                </p>
                <p>
                  회원 탈퇴는 로그인 후 마이페이지 &#62; 회원탈퇴에서
                  가능합니다.
                </p>
                <p className='high-light'>
                  회원 탈퇴 시 기존의 모든 정보(개인정보, 이용권 결제 내역 등)는
                  본인 요청에 의해 개인 정보 파기가 진행된 경우를 제외하고
                  분쟁조율 및<br /> 향후 서비스 개선 목적을 이유로 최대 5년간
                  보존됩니다. 탈퇴 완료 후 아이디 복구는 불가능합니다.
                </p>
              </div>
            </div>
          </li>
          <li className={faqArcodian.includes(2) ? 'active' : ''}>
            <div className='arcodian' onClick={() => handleFaqCheck(2)}>
              <div className='img-wrap'>
                <span className='Q'>Q.</span>
                <span>비밀번호를 변경하고 싶어요.</span>
              </div>
              <div className='img-wrap'>
                <img
                  className='arcodian-arrow'
                  src={faqArcodian.includes(2) ? upArrow : downArrow}
                  alt='FAQ 아코디언 화살표'
                />
              </div>
            </div>
            <div className='change-wrap'>
              <p>
                <span className='A'>A.</span>
                <span>비밀번호를 변경하는 방법은 다음과 같습니다.</span>
              </p>
              <div className='change-description'>
                <p>
                  1) 로그인 후 홈페이지 우측 상단에 <span>마이페이지</span>를
                  클릭합니다.
                </p>
                <p>
                  2) 회원정보에서 <span>비밀번호 변경</span>을 클릭합니다.
                </p>
                <p>
                  3) 현재 비밀번호, 새로운 비밀번호를 입력하신 후 확인을
                  클릭하시면 비밀번호를 변경하실 수 있습니다.
                </p>
                <p className='high-light'>
                  &#8251; 비밀번호는 8~20자리여야 하며, 숫자/소문자/특수문자를
                  모두 포함해야 합니다.
                </p>
              </div>
            </div>
          </li>
          <li className={faqArcodian.includes(3) ? 'active' : ''}>
            <div className='arcodian' onClick={() => handleFaqCheck(3)}>
              <div className='img-wrap'>
                <span className='Q'>Q.</span>
                <span>회원 탈퇴를 하고 싶어요.</span>
              </div>
              <div className='img-wrap'>
                <img
                  className='arcodian-arrow'
                  src={faqArcodian.includes(3) ? upArrow : downArrow}
                  alt='FAQ 아코디언 화살표'
                />
              </div>
            </div>
            <div className='change-wrap'>
              <p>
                <span className='A'>A.</span>
                <span>
                  회원 탈퇴는 로그인 후 <span>마이페이지 &#62; 회원 탈퇴</span>
                  에서 가능합니다.
                </span>
              </p>
              <div className='change-description'>
                <p>
                  탈퇴 후 마크픽의 이용권 결제 취소 및 환불은 불가능하니,
                  신중하게 탈퇴를 진행해 주시길 바랍니다.
                </p>

                <p className='high-light'>
                  회원 탈퇴 시 기존의 모든 정보(개인 정보, 이용권 결제 내역 등)는
                  본인 요청에 의해 개인 정보 파기가 진행된 경우를 제외하고
                  분쟁 조율 및 <br /> 향후 서비스 개선 목적을 이유로 최대 5년간
                  보존됩니다. 탈퇴 완료 후 아이디 복구는 불가능합니다.
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div className='inquiry-move'>
          <h4>궁금한 내용이 FAQ에 없다면, 1:1 문의를 이용해 주세요.</h4>
          <Link to='/inquiry'>1:1 문의로 이동하기 &#62;</Link>
          <p>평일 오후 4시 이후 문의는 당일 답변이 어려울 수 있습니다.</p>
          <p>
            토, 일요일 및 공휴일 문의는 평일 운영 시간 내 순차적으로
            답변드립니다.
          </p>
          <img
            src={people}
            alt='FAQ에서 궁금한 내용 없으면 1:1문의를 이용해 주세요.'
          />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
