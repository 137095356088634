import { useNavigate } from 'react-router-dom';
import successImg from 'image/checkResult.svg';

const SignUpThree = () => {
  const navigate = useNavigate();
  return (
    <>
      <h4>가입완료</h4>
      <img src={successImg} alt='회원가입 성공 이미지' className='successImg' />
      <h3>회원가입이 완료되었습니다.</h3>
      <span>로그인해 주세요.</span>
      <hr />
      <div className='btn-wrap'>
        <button className='blue-btn' onClick={() => navigate('/sign-in')}>
          로그인
        </button>
      </div>
    </>
  );
};

export default SignUpThree;
