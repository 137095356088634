import { useEffect } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import logo from 'image/markPickLogo.svg';
import { useNavigate } from 'react-router-dom';
import { getCookie, removeCookie } from 'js/cookie';

const Gnb = () => {
  const navigate = useNavigate();

  const headerHoverEvent = () => {
    const main = '.main-nav';
    const sub = '.sub-nav';
    const bg = '.sub-nav-bg';
    const speed = 300;

    $(main).mouseenter(function () {
      $(sub + ',' + bg)
        .stop()
        .slideUp(0);
      $(this).next().stop().slideDown(speed);
      $(bg).stop().slideDown(speed);
    });

    $(main)
      .parent()
      .mouseleave(function () {
        $(this).find(sub).stop().slideUp(speed);
        $(bg).stop().slideUp(speed);
      });

    $(sub).mouseenter(function () {
      $(this).stop().slideDown(speed);
      $(bg).stop().slideDown(speed);
    });

    $(sub).mouseleave(function () {
      $(this).stop().slideUp(speed);
      $(bg).stop().slideUp(speed);
    });
  };

  //= header hover Event 실행
  useEffect(() => {
    headerHoverEvent();
  }, []);

  return (
    <div className='header row'>
      <div className='header-wrap row'>
        <img src={logo} alt='마크픽 로고' onClick={() => navigate('/')} />
        <ul className='row menu'>
          <li>
            <Link to='/service-intro'>서비스 소개</Link>
          </li>
          <li>
            <Link to='/application-step'>상표 등록 절차</Link>
          </li>
          <li>
            <Link to='/cost'>요금 안내</Link>
          </li>
          <li>
            <Link to='/notice' className='main-nav'>
              고객지원
            </Link>
            <ul className='sub-nav row'>
              <li>
                <Link to='/notice'>공지사항</Link>
              </li>
              <li>
                <Link to='/faq'>FAQ</Link>
              </li>
              <li>
                <Link to='/inquiry'>1:1 문의</Link>
              </li>
              <li>
                <Link to='/refund'>환불안내</Link>
              </li>
            </ul>
          </li>
          <div className='sub-nav-bg'></div>
        </ul>
      </div>
      <ul className='row userMenu'>
        {getCookie('pickMyToken') ? (
          <>
            <li
              onClick={() => {
                removeCookie('pickMyToken');
                removeCookie('pickRfToken');
                window.location.reload();
              }}>
              로그아웃
            </li>
            <li onClick={() => navigate('/my-page')}>마이페이지</li>
          </>
        ) : (
          <>
            <li onClick={() => navigate('/sign-in')}>로그인</li>
            <li onClick={() => navigate('/sign-up')}>회원가입</li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Gnb;
