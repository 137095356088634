import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { changeTitle } from 'js/commonFn';
import { certification, getDanalInfo } from 'js/agenciesAPI';
import findPwImg from 'image/findId.svg';
import CommonSiteMap from 'components/CommonSiteMap';
import { catchError } from 'js/commonFn';

const FindPwAuth = () => {
  const navigate = useNavigate();
  const { user_id } = useLocation().state;

  useEffect(() => {
    changeTitle('마크픽 > 비밀번호 찾기');
  }, []);

  //= 다날 인증 API 호출
  const danalCheck = async response => {
    const { success, imp_uid } = response;
    if (success) {
      const result = await getDanalInfo(imp_uid);
      if (typeof result === 'object') {
        const { unique_key } = result.data;
        navigate('/change-pw', {
          state: {
            unique_key: unique_key,
            user_id: user_id,
          },
        });
      } else return catchError(result, navigate);
    } else return alert('인증에 실패하였습니다.');
  };

  return (
    <div className='content-wrap find-pw-auth'>
      <CommonSiteMap title='비밀번호 찾기' />
      <h2>비밀번호 찾기</h2>
      <hr />
      <img src={findPwImg} alt='비밀번호 찾기 이미지' />
      <span>회원 정보를 확인하기 위해</span>
      <strong>
        본인 인증이
        <br />
        필요해요
      </strong>
      <div className='btn-wrap'>
        <button className='blue-btn' onClick={() => certification(danalCheck)}>
          본인인증
        </button>
      </div>
    </div>
  );
};

export default FindPwAuth;
