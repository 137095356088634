import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  changeState,
  regularExpression,
  changeTitle,
  enterFn,
} from 'js/commonFn';
import { changePw } from 'js/agenciesAPI';
import { catchError } from 'js/commonFn';
import completeChangePwImg from 'image/checkResult.svg';
import CommonSiteMap from 'components/CommonSiteMap';

const ChangePw = () => {
  const [status, setStatus] = useState('change');
  const [newPw, setNewPw] = useState({
    pw: '',
    pwCheck: '',
  });
  const [caution, setCaution] = useState({
    pw: '',
    pwCheck: '',
  });
  const { unique_key, user_id } = useLocation().state;
  const navigate = useNavigate();

  useEffect(() => {
    changeTitle('마크픽 > 비밀번호 찾기');
  }, []);

  const changePwFn = async () => {
    const query = {
      unique_key: unique_key,
      user_id: user_id,
      password: newPw.pw,
    };
    const result = await changePw(query);
    if (typeof result === 'object') setStatus('success');
    else return catchError(result, navigate);
  };

  return (
    <>
      {status === 'change' && (
        <div className='content-wrap change-pw'>
          <CommonSiteMap title='비밀번호 찾기' />
          <h2>비밀번호 찾기</h2>
          <hr />
          <span>새로운 비밀번호를 등록해 주세요.</span>
          <input
            type='password'
            className='common-text-input'
            placeholder='새 비밀번호'
            value={newPw.pw}
            onChange={async e => {
              changeState(setNewPw, 'pw', e.target.value);
              if (!(await regularExpression('pw', e.target.value)))
                changeState(
                  setCaution,
                  'pw',
                  '비밀번호는 특수문자를 포함한 8~20자로 설정해 주세요.'
                );
              else changeState(setCaution, 'pw', '사용 가능한 비밀번호입니다.');
            }}
            onKeyDown={e => enterFn(e, changePwFn)}
          />
          {caution.pw.trim() === '' ? (
            ''
          ) : (
            <p
              className={`caution ${
                caution.pw.includes('가능') ? 'correct' : 'incorrect'
              }`}>
              {caution.pw}
            </p>
          )}
          <input
            type='password'
            className='common-text-input'
            placeholder='새 비밀번호 확인'
            onChange={e => {
              changeState(setNewPw, 'pwCheck', e.target.value);
              if (newPw.pw !== e.target.value)
                changeState(
                  setCaution,
                  'pwCheck',
                  '비밀번호가 일치하지 않습니다.'
                );
              else changeState(setCaution, 'pwCheck', '비밀번호가 일치합니다.');
            }}
            onKeyDown={e => enterFn(e, changePwFn)}
          />
          {caution.pwCheck.trim() === '' ? (
            ''
          ) : (
            <p
              className={`caution ${
                caution.pwCheck.includes('일치합니다') ? 'correct' : 'incorrect'
              }`}>
              {caution.pwCheck}
            </p>
          )}
          <div className='btn-wrap'>
            <button className='blue-btn' onClick={changePwFn}>
              확인
            </button>
          </div>
        </div>
      )}
      {status === 'success' && (
        <div className='content-wrap complete-change-pw'>
          <CommonSiteMap title='비밀번호 찾기' />
          <h2>비밀번호 찾기</h2>
          <hr />
          <img src={completeChangePwImg} alt='비밀번호 찾기 성공 이미지' />
          <h3>비밀번호가 변경되었습니다.</h3>
          <span>새로운 비밀번호로 로그인해 주세요.</span>
          <div className='btn-wrap'>
            <button className='blue-btn' onClick={() => navigate('/sign-in')}>
              로그인
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePw;
