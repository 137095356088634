import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonSiteMap from 'components/CommonSiteMap';
import { changeTitle } from 'js/commonFn';
import noneIdImg from 'image/noneId.svg';

const NoneIdResult = () => {
  const navigate = useNavigate();

  useEffect(() => {
    changeTitle('마크픽 > 아이디 찾기');
  }, []);

  return (
    <div className='content-wrap failed-find-id'>
      <CommonSiteMap title='아이디 찾기' />
      <h2>아이디 찾기</h2>
      <hr />
      <img src={noneIdImg} alt='아이디 없음 이미지' />
      <strong>가입된 아이디가 없습니다.</strong>
      <span>
        지금 회원가입을 하시면, 마크픽의 많은 서비스를 이용하실 수 있어요.
      </span>
      <div className='btn-wrap column'>
        <button className='blue-btn' onClick={() => navigate('/sign-up')}>
          회원가입
        </button>
        <button className='blue-border-btn' onClick={() => navigate('/')}>
          홈으로 가기
        </button>
      </div>
    </div>
  );
};

export default NoneIdResult;
