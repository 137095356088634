import { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomerHead from 'components/CustomerHead';
import CustomerTitle from 'components/CustomerTitle';
import CommonPagination from 'components/CommonPagination';
import { changeTitle } from 'js/commonFn';
import { catchError } from 'js/commonFn';
import { getNoticeList } from 'js/selfAPI';

const Notice = () => {
  const [list, setList] = useState([]);
  const [pageInfo, setPageInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    changeTitle('마크픽 > 공지사항');
  }, []);

  //# 공지사항 리스트 조회 함수
  const getList = useCallback(async () => {
    const listData = await getNoticeList(currentPage);
    if (typeof listData === 'object') {
      const { data, meta } = listData?.data;
      setList(data);
      setPageInfo(meta);
    } else return catchError(listData, navigate);
  }, [currentPage]);

  useEffect(() => {
    getList();
  }, [getList]);

  //# 공지사항 리스트 생성
  const renderList = () => {
    if ([...list].length === 0) {
      return <li className='none'>공지사항이 없습니다.</li>;
    }
    return (
      <>
        {[...list].reduce((acc, { id, title, created_at }) => {
          return (
            <>
              {acc}
              <li className='notice-list'>
                <Link exact='true' to={`/notice/${id}`}>
                  <div>
                    <strong className='content-text'>{title}</strong>
                    <span>{created_at?.split('T')[0]}</span>
                  </div>
                </Link>
              </li>
            </>
          );
        }, <></>)}
      </>
    );
  };

  return (
    <div className='content-customer-wrap'>
      <CustomerHead step='공지사항' />
      <div className='customer notice'>
        <CustomerTitle
          title='공지사항'
          description='마크픽의 새로운 소식을 알려드립니다.'
        />
        <ul className='notice-list-wrap'>{renderList()}</ul>
        <CommonPagination
          postsPerPage={pageInfo?.limit}
          viewPageNum={5}
          totalPosts={pageInfo?.total_count}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Notice;
