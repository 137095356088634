import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonSiteMap from 'components/CommonSiteMap';
import { changeTitle } from 'js/commonFn';
import { getDanalInfo, certification, findId } from 'js/agenciesAPI';
import findIdImg from 'image/findId.svg';
import checkIdImg from 'image/checkResult.svg';
import { catchError } from 'js/commonFn';

const FindId = () => {
  const [step, setStep] = useState(1);
  const [id, setId] = useState('');
  const [key, setKey] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    changeTitle('마크픽 > 아이디 찾기');
  }, []);

  const findIdFn = async unique_key => {
    const result = await findId(unique_key);
    if (typeof result === 'object') {
      setId(result?.data);
      setStep(2);
      setKey(unique_key);
    } else return catchError(result, navigate);
  };

  //= 다날 인증 API 호출
  const danalCheck = async response => {
    const { success, imp_uid } = response;
    if (success) {
      const result = await getDanalInfo(imp_uid);
      if (typeof result === 'object') {
        const { unique_key } = result.data;
        findIdFn(unique_key);
      } else return catchError(result, navigate);
    } else return alert('인증에 실패하였습니다.');
  };

  return (
    <>
      {step === 1 && (
        <div className='content-wrap find-id'>
          <CommonSiteMap title='아이디 찾기' />
          <h2>아이디 찾기</h2>
          <hr />
          <img src={findIdImg} alt='아이디 찾기 이미지' />
          <span>회원 정보를 확인하기 위해</span>
          <strong>
            본인 인증이
            <br />
            필요해요
          </strong>
          <div className='btn-wrap'>
            <button
              className='blue-btn'
              onClick={() => certification(danalCheck)}>
              본인인증
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className='content-wrap check-id'>
          <CommonSiteMap title='아이디 찾기' />
          <h2>아이디 찾기</h2>
          <hr />
          <img src={checkIdImg} alt='아이디 찾기 결과 이미지' />
          <span>고객님의 아이디는</span>
          <h3 className='id-result'>{id}</h3>
          <span>
            로그인 또는 비밀번호를 모르실 경우
            <br />
            비밀번호 찾기를 해 주세요.
          </span>
          <div className='btn-wrap column'>
            <button className='blue-btn' onClick={() => navigate('/sign-in')}>
              로그인
            </button>
            <button
              className='blue-border-btn'
              onClick={() =>
                navigate('/find-pw', { state: { unique_key: key } })
              }>
              비밀번호 찾기
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FindId;
