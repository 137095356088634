const AgenciesStep = ({ step }) => {
  return (
    <div className='row'>
      <div
        className={`row base ${
          step === 1 ? 'active' : step > 1 ? 'prev' : ''
        }`}>
        <h3>Step 1</h3>
        <span>상표 선택</span>
      </div>
      <div
        className={`row base ${
          step === 2 ? 'active' : step > 2 ? 'prev' : ''
        }`}>
        <h3>Step 2</h3>
        <span>상품분류 선택</span>
      </div>
      <div
        className={`row base ${
          step === 3 ? 'active' : step > 3 ? 'prev' : ''
        }`}>
        <h3>Step 3</h3>
        <span>등록상품 선택</span>
      </div>
      <div
        className={`row base ${
          step === 4 ? 'active' : step > 4 ? 'prev' : ''
        }`}>
        <h3>Step 4</h3>
        <span>출원인 정보입력</span>
      </div>
      <div className={`row base ${step === 5 ? 'active' : ''}`}>
        <h3>Step 5</h3>
        <span>등록확인</span>
        {/* <span>결제확인</span> */}
      </div>
    </div>
  );
};

export default AgenciesStep;
