import { useEffect, useState } from 'react';
import close from 'image/Mypage/my-page-popup-close.svg';
import { appNumListFunc } from 'js/selfAPI';

const ApplicationView = ({ setApplicationModal, appNumList }) => {
  const [appData, setAppData] = useState([]);

  const getAppList = async () => {
    const result = await appNumListFunc(appNumList);
    if (typeof result === 'object') {
      setAppData(result?.data);
    } else {
      alert('해당 출원번호가 존재하지 않습니다.');
      return setApplicationModal(false);
    }
  };

  useEffect(() => {
    getAppList();
  }, []);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <>
      <div className='modal-overlay application-view'>
        <div className='modal'>
          <div className='head'>
            <h4>출원번호별 출원 상표 내역</h4>
            <img
              src={close}
              alt='출원번호별 출원 상표 내역 닫기'
              onClick={() => setApplicationModal(false)}
            />
          </div>
          <div className='body'>
            <div className='table-wrap'>
              <div className='table-head'>
                <div>No</div>
                <div>상표명</div>
                <div>대표 출원인</div>
                <div>출원상태</div>
                <div>출원번호</div>
                <div>출원일자</div>
                <div>등록번호</div>
                <div>등록일자</div>
              </div>

              {appData?.length > 0 ? (
                appData?.map((item, idx) => {
                  return (
                    <div className='table-body'>
                      <div>{idx + 1}</div>
                      <div>{item?.title}</div>
                      <div>{item?.applicantName}</div>
                      <div>{item?.status}</div>
                      <div>{item?.applicationNumber}</div>
                      <div>{item?.applicationDate?.split('T')[0]}</div>
                      <div>{item?.registrationNumber}</div>
                      <div>{item?.registrationDate?.split('T')[0]}</div>
                    </div>
                  );
                })
              ) : (
                <div className='loader-wrap application'>
                  <div className='loader'></div>
                  <p>정보를 불러오는 중입니다.</p>
                  <p>잠시만 기다려주세요.</p>
                </div>
              )}
            </div>
          </div>
          <hr className='line' />
          <div className='btn-wrap'>
            <button onClick={() => setApplicationModal(false)}>닫기</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationView;
