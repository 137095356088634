import axios from 'axios';
import { getCookie, setCookie, removeCookie } from './cookie';
import { errorList } from './array';

export const tokenHeader = () => ({
  headers: {
    'Content-Type': 'application/json',
    'access-token': getCookie('pickMyToken'),
  },
});

export const formDataHeader = () => ({
  headers: {
    'Content-Type': 'multipart/form-data',
    'access-token': getCookie('pickMyToken'),
  },
});

//# 토큰 재발급
const tokenReissue = async () => {
  const headers = {
    'refresh-token': getCookie('pickRfToken'),
  };
  try {
    const result = await axios.get(`/v1/auth/new-token`, { headers });
    removeCookie('pickMyToken', {
      path: '/',
    });
    setCookie('pickMyToken', result?.data, {
      path: '/',
    });
    window.location.reload();
  } catch (error) {
    return apiErrorHandling(error);
  }
};

//# API 통신 중 발생하는 에러 핸들링 함수
export const apiErrorHandling = async error => {
  const { status } = error?.response;
  const { detail } = error?.response?.data;

  switch (status) {
    case 401:
      if (detail === 'WrongID') return 'wrongId';
      else if (detail === 'WrongPW') return 'wrongPw';
      else if (detail === 'WrongEmail') return 'wrongEmail';
      else if (detail === 'Certification Error') return 'wrongBank';
      break;
    case 403:
      if (detail === 'User already exists') return 'alreadyUser';
      else if (detail === 'ID already exists') return 'alreadyId';
      else if (detail === 'ID does not exist') return 'notUser';
      else if (detail === 'Email already exists') return 'alreadyEmail';
      else if (detail === 'User does not exist') return 'invalidId';
      else if (detail === 'Incorrect Password') return 'invalidPw';
      else if (detail === 'Incorrect User') return 'invalidUser';
      else if (detail === 'Incorrect Token') return 'wrongAccess';
      else if (detail === 'WrongID') return 'wrongId';
      else if (detail === 'WrongPW') return 'wrongPw';
      else if (detail === 'WrongEmail') return 'wrongEmail';
      else if (detail === 'WrongToken') return 'wrongToken';
      else if (detail === 'WrongUser') return 'wrongUser';
      else if (detail === 'Retired User') return 'retired';
      else if (detail === 'Logins Exceeded') return 'exceededLogin';
      else if (detail === 'Login Required') return 'loginRequired';
      else if (detail === 'Access Token Expired') return await tokenReissue();
      break;
    case 404:
      if (detail === '데이터가 존재하지 않습니다.') return 'dataDoesNotExist';
      else return 'notFound';
    case 405:
      if (detail === 'External API Error') return 'serverError';
      break;
    case 499:
      if (detail === 'Incorrect Password') return 'invalidPw';
      else if (detail === 'Invalid Token Format') return 'invalidToken';
      break;
    case 500:
    case 501:
    case 504:
      return 'serverError';
    default:
      return '';
  }
};

//! Error Handling Function
export const catchError = (result, navigate) => {
  if (
    result === 'wrongToken' ||
    result === 'loginRequired' ||
    result === 'invalidTokenFormat'
  ) {
    removeCookie('pickRfToken');
    removeCookie('pickMyToken');
    navigate('/sign-in');
  }
  if (errorList[result] !== undefined) return alert(errorList[result]);
};

//& 이메일 리스트
export const emailArr = ['직접 입력', 'naver.com', 'daum.net', 'gmail.com'];

//& Input이나 기타 str값 emptyCheck
export const emptyCheck = value => {
  if (!value?.length || value?.trim() === '') return false;
  else return true;
};

//& Object 형식의 State 변경 용이하게 하는 함수
export const changeState = (setState, col, val) => {
  setState(prev => {
    const clone = { ...prev };
    clone[col] = val;
    return clone;
  });
};

//& 엔터 입력했을 때 사용하는 함수
export const enterFn = (e, okFn) => {
  if (e.key === 'Enter') okFn();
};

//& Document Title 변경 함수
export const changeTitle = txt => {
  document.title = txt;
};

//& alert, confirm창 Handling
export const commonModalSetting = (setAlertBox, bool, mode, content) => {
  if (bool) {
    setAlertBox({
      mode: mode,
      content: content,
      bool: bool,
    });
  } else {
    setAlertBox({
      mode: '',
      content: '',
      bool: bool,
    });
  }
};

//& 정규식
export const regularExpression = async (type, str) => {
  let regExp;
  switch (type) {
    case 'id':
      regExp = /^[a-z0-9]{4,30}$/;
      break;
    case 'pw':
      regExp =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/;
      break;
    case 'email':
      regExp =
        /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(kr|aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
      break;
    default:
      break;
  }
  return regExp.test(str.trim());
};

//& 가이드 외 다른 Section 클릭했을 때 가이드 닫기
export const guideEvent = (e, closeGuide) => {
  if (!e.target.className.includes('guide')) {
    window.removeEventListener('click', this);
    closeGuide();
  }
};

//& File Setting Function
export const fileSettingFn = (e, setFileInfo) => {
  for (let file of e.target.files) {
    if (!file) return;
    setFileInfo(file);
  }
};

export const formSwitchFn = (position, str) => {
  if (position === 'front2back') {
    switch (str) {
      case 'koreaPersonal':
        return 'dom_indiv';
      case 'koreaCorporation':
        return 'dom_corp';
      case 'foreignPersonal':
        return 'fgn_indiv';
      case 'foreignCorporation':
        return 'fgn_corp';
      case 'national':
        return 'dom_inst';
      default:
        return '';
    }
  } else
    switch (str) {
      case 'dom_indiv':
        return 'koreaPersonal';
      case 'dom_corp':
        return 'koreaCorporation';
      case 'fgn_indiv':
        return 'foreignPersonal';
      case 'fgn_corp':
        return 'foreignCorporation';
      case 'dom_inst':
        return 'national';
      default:
        return '';
    }
};

export const inputCheck = (type, e, setState, col) => {
  if (type === 'number') {
    if (col)
      changeState(
        setState,
        col,
        e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      );
    else
      setState(
        e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      );
  } else if (type === 'english') {
    if (col)
      changeState(setState, col, e.target.value.replace(/[^A-Za-z\s]/gi, ''));
    else setState(e.target.value.replace(/[^A-Za-z]/gi, ''));
  } else if (type === 'email') {
    if (col)
      changeState(
        setState,
        col,
        e.target.value.replace(/[^a-zA-Z\\d`~!@#$%^&*()-_=+]/gi, '')
      );
    else e.target.value.replace(/[^a-zA-Z0-9\\d`~!@#$%^&*()-_=+]/gi, '');
  } else if (type === 'korean') {
    if (col)
      changeState(setState, col, e.target.value.replace(/[^ㄱ-힣]/g, ''));
    else setState(e.target.value.replace(/[^ㄱ-힣]/g, ''));
  } else if (type === 'eng&num') {
    if (col)
      changeState(setState, col, e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
    else setState(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
  }
};

export const essentialValueCheck = (form, applicantInfo) => {
  const essentialValueArr = {
    koreaPersonal: [
      'name_kor',
      'name_eng',
      'email',
      'social_security_number',
      'phone_number',
      'zip_code',
      'address',
      'detail_address',
    ],
    koreaCorporation: [
      'name_kor',
      'name_eng',
      'email',
      'corp_regist_number',
      'biz_regist_number',
      'representative_name',
      'representative_social_security_number',
      'zip_code',
      'address',
      'detail_address',
      'stamp_file',
    ],
    foreignPersonalY: [
      'name_kor',
      'name_eng',
      'email',
      'country',
      'foreigner_register_card_front_file',
      'foreigner_register_card_back_file',
      'foreigner_register_number',
      'phone_number',
      'zip_code',
      'address',
      'detail_address',
    ],
    foreignPersonalN: [
      'name_kor',
      'name_eng',
      'email',
      'country',
      'passport_number',
      'overseas_address',
    ],
    foreignCorporation: [
      'name_kor',
      'name_eng',
      'email',
      'country',
      'representative_name',
      'overseas_address',
      'stamp_file',
    ],
    national: [
      'name_kor',
      'name_eng',
      'email',
      'biz_regist_number',
      'tel_number',
      'zip_code',
      'address',
      'detail_address',
    ],
  };

  let formName = '';
  switch (form) {
    case form === 'foreignPersonal' &&
      applicantInfo?.foreigner_register_card_yn === 'y':
      formName = 'foreignerRegisterY';
      break;
    case form === 'foreignPersonal' &&
      applicantInfo?.foreigner_register_card_yn === 'n':
      formName = 'foreignerRegisterN';
      break;
    default:
      formName = form;
  }
  for (let essentialValue of essentialValueArr[formName]) {
    if (applicantInfo[essentialValue] === '') {
      alert('필수 입력 항목을 입력해 주세요.');
      return false;
    }
  }
  return true;
};

// @ 클릭하였을때 스크롤 제일 최상단으로 이동시키는 함수
export const handleClickTopMove = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};

// @글자수 Bytes 제한
export const textCounting = (
  e,
  maxByte,
  column,
  setTextParams,
  setAlertMsg,
  setTextCount
) => {
  const inputData = e.target.value; //현재 입력한 값
  const inputDataLen = inputData.length; //입력한 문자수
  let totalByte = 0; //전체 바이트수
  let cutData = ''; //문자열 자르기 변수
  let returnLen = 0; //반환될 문자열 갯수
  for (let i = 0; i < inputDataLen; i++) {
    const each_char = inputData.charAt(i);
    const uni_char = escape(each_char); //유니코드 형식으로 변환
    if (uni_char.length > 4) totalByte += 2; //한글은 2Byte
    else totalByte++;
    if (totalByte <= maxByte) returnLen = i + 1;
  }
  if (column === 'context' || column === 'title')
    changeState(setTextCount, column, totalByte); //작성한 내용의 경우 bytes가 변하는것이 보여야함
  // if(column === "context") setTextCount(totalByte); //작성한 내용의 경우 bytes가 변하는것이 보여야함
  if (totalByte > maxByte) {
    setAlertMsg('length_exceeded');
    cutData = inputData.substring(0, returnLen); //maxBytes넘어가는 글자는 자르기
    if (column === 'context' || column === 'title')
      changeState(setTextParams, column, cutData);
    //textParams가 object 형태일 때
    else setTextParams(cutData);
    if (column === 'context' || column === 'title')
      changeState(setTextCount, column, maxByte); //최대 입력 bytes를 넘으면 textCount를 maxByte까지만 보이도록 처리
  }
};

//@ 임시저장 데이터 바인딩
export const tempDataBinding = ({
  setApplicants,
  tempData,
  setMarkNameArr,
  setApplicationType,
  setFileName,
  setImg,
  setTemp,
  setSelectSearchType,
  setSearchTxt,
  setSelectCategoryList,
  setSelectClassList,
  setCategoryCode,
  setImgUrl,
  setSelectProduct,
  setSelectList,
  setSelectProductArr,
}) => {
  //# step1에 대한 임시저장된 데이터 바인딩
  const applicants = [];

  for (let i = 1; i <= 5; i++) {
    const applicantName = tempData[`applicant_name${i}`];
    const applicantCode = tempData[`applicant_code${i}`];
    const applicantShare = tempData[`applicant_share${i}`];

    if (applicantName && applicantCode && applicantShare) {
      applicants.push({
        id: i - 1,
        applicant_name: applicantName,
        applicant_code: applicantCode,
        applicant_share: applicantShare,
      });
    } else if (applicantName && applicantCode) {
      applicants.push({
        id: i - 1,
        applicant_name: applicantName,
        applicant_code: applicantCode,
      });
    }
  }
  setApplicants(applicants);

  //# step2에 대한 임시저장된 데이터 바인딩
  if (tempData?.mark_type === 'TEXT') {
    setApplicationType('text');
    const markNames = [];
    for (let i = 1; i <= 5; i++) {
      const markName = tempData[`mark_name_step${i}`];
      if (markName) {
        markNames.push(markName);
      }
    }
    if (markNames?.length > 0) {
      setMarkNameArr(markNames);
    } else {
      setMarkNameArr(['']);
    }
  } else if (tempData?.mark_type === 'FIGURE') {
    const newFileName = tempData?.image_filename || 'dddaaaa';
    setMarkNameArr(['']);
    setApplicationType('figure');
    setFileName(newFileName);
    setImg(tempData?.image_url);
    setImgUrl(tempData?.image_url);
  } else if (tempData?.mark_type === 'COMPOUND') {
    const newFileName = tempData?.image_filename || '';
    setMarkNameArr(['']);
    setApplicationType('compound');
    setFileName(newFileName);
    setImg(tempData?.image_url);
    setImgUrl(tempData?.image_url);
  }

  //# step3에 대한 임시저장된 데이터 바인딩
  if (tempData?.input_type === '직접입력') {
    setSelectSearchType('direct');
    setSearchTxt(tempData?.input_keyword);
    setSelectClassList(tempData?.mark_classes);
  } else if (tempData?.input_type === '선택입력') {
    setSelectSearchType('select');
    setSelectClassList(tempData?.mark_classes);
    setSelectCategoryList(tempData?.category);
    setCategoryCode(tempData?.category);
  }

  //# step4에 대한 임시저장된 데이터 바인딩
  if (tempData?.register_products?.length > 0) {
    let products = tempData?.register_products || [];
    let result = products
      .map(item => item.split(','))
      .flat()
      .filter(item => item.trim() !== '');

    let dataChange = tempData?.mark_classes?.map((mark_class, index) => {
      return {
        mark_class: mark_class,
        register_product: tempData.register_products[index].split(','),
      };
    });
    setSelectProduct(result);

    setSelectProductArr(dataChange);
    // setSelectList(tempData?.register_products[0]?.split(','));
  }
};
