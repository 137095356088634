import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import close from 'image/Modal/modal-close.svg';
import { saveTemplate } from 'js/selfAPI';
import { catchError } from 'js/commonFn';

const TemplateSave = ({
  applicants,
  markNameArr,
  fileName,
  applicationType,
  selectProductArr,
  img,
  imgUrl,
  selectSearchType,
  searchTxt,
  categoryCode,
  tempData,
  setModalType,
  templateInput,
  setTemplateInput,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  //# step5 템플릿 저장
  const fileDownFunc = async () => {
    if (templateInput.trim() === '') {
      return;
    }

    //# applicants id 키값 제외한 변수 재생성
    const updatedApplicants = applicants.map(
      ({ id, applicant_share, ...rest }) => {
        let newObj = { ...rest };

        if (applicant_share !== undefined) {
          newObj.applicant_share = String(applicant_share);
        }

        return newObj;
      }
    );
    const result = await saveTemplate(
      tempData,
      updatedApplicants,
      selectProductArr,
      applicationType,
      fileName,
      selectSearchType,
      searchTxt,
      categoryCode,
      img,
      markNameArr,
      templateInput,
      imgUrl,
      'temporary'
    );
    if (typeof result === 'object') {
      setModalType('');
      return alert('신규 템플릿으로 저장되었습니다.');
    } else return catchError(result, navigate);
  };

  return (
    <div className='modal-overlay temp'>
      <div className='modal template-modal'>
        <div className='head'>
          <p>셀프 상표등록 템플릿 신규등록</p>
          <button onClick={() => setModalType('')}>
            <img src={close} alt='임시 저장 모달창 닫기 버튼' />
          </button>
        </div>
        <div className='txt-wrap'>
          템플릿명 :
          <input
            className='template-input'
            type='text'
            placeholder='등록하실 템플릿 명을 입력해 주세요.'
            onChange={e => setTemplateInput(e.target.value)}
            maxLength={10}
          />
        </div>
        <div className='temp-btn-wrap'>
          <button
            className='check-btn'
            onClick={() => {
              fileDownFunc();
            }}>
            저장
          </button>

          <button
            className='cancel-btn'
            onClick={() => {
              setModalType('');
            }}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateSave;
