import IntroTitle from 'components/Introduction/IntroTitle';

const IntroGeneral = () => {
  return (
    <>
      <IntroTitle title='일반출원' />
      <p className='self-service-disc general'>
        온라인으로 간편하게 신청하고 권리 범위를 제대로 확보할 수 있도록 상담
        후, 상표 출원 절차를 대행해 드립니다.
      </p>
      <IntroTitle title='이용방법' />
      <ul className='utilization-wrap'>
        <li>
          <div className='base'>
            <span>Step 1</span>
          </div>
          <div className='text-wrap'>회원가입</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 2</span>
          </div>
          <div className='text-wrap'>상표 출원 신청 및 결제</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 3</span>
          </div>
          <div className='text-wrap'>상담 전화</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 4</span>
          </div>
          <div className='text-wrap'>출원인 최종 확인</div>
        </li>
        <li>
          <div className='base'>
            <span>Step 5</span>
          </div>
          <div className='text-wrap'>출원 완료</div>
        </li>
      </ul>
      <IntroTitle title='서비스 비용' />
      <table className='general-table'>
        <thead>
          <tr>
            <th colSpan={2}>당사</th>
            <th colSpan={2}>특허청</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>수수료</td>
            <td className='td-two'>50,000원</td>
            <td rowSpan={2} className='td-three'>
              출원 관납료
            </td>
            <td rowSpan={2} className='td-last'>
              56,000원
              <br />
              <span>
                (*비 고시 명칭 상품으로 출원 진행 시<br /> 6,000원 추가비용
                발생)
              </span>
            </td>
          </tr>
          <tr>
            <td>부가세</td>
            <td className='td-two'>5,000원</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>합계</td>
            <td colSpan={3}>111,000원</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <IntroTitle title='유의사항' />
      <ul className='utilization-wrap'>
        <li>
          <div className='base'>
            <span>1</span>
          </div>
          <div className='text-wrap'>
            1개 상표, 1개 분류에 한 명의 권리자가 출원하는 기준의 비용입니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>2</span>
          </div>
          <div className='text-wrap'>
            일반 출원은 동일 상표가 존재하는지만 확인한 후, 상표를 출원합니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>3</span>
          </div>
          <div className='text-wrap'>
            특허청 출원 진행 요청 후에는 출원 비용 환불이 불가합니다.
          </div>
        </li>
        <li>
          <div className='base'>
            <span>4</span>
          </div>
          <div className='text-wrap'>
            공통출원, 미성년자, 외국인, 국가기관인 경우 출원 시와 등록 시
            추가 요금이 발생합니다.
          </div>
        </li>
      </ul>
    </>
  );
};

export default IntroGeneral;
