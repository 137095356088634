import exitIcon from 'image/xIcon.svg';
import { changeState } from 'js/commonFn';

const WarrantModal = ({
  setApplicantInfo,
  setModal,
  name,
  selectProductArr,
  markName,
}) => {
  const nowDate = new Date();

  return (
    <div className='modal-bg'>
      <div className='modal column warrant'>
        <h3 className='warrant-title'>위임장</h3>
        <img
          src={exitIcon}
          alt='닫기 아이콘'
          className='exit-img'
          onClick={() => {
            changeState(setApplicantInfo, 'delegation_consent', 'N');
            setModal(false);
          }}
        />
        <h4 className='sub-title'>수임자</h4>
        <div className='row info'>
          <div className='row info-detail'>
            <span className='row info-title'>
              <p>성</p>
              <p>명</p>
            </span>
            <span className='colon'>:</span>
            <span className='info-content'>변리사 정상일</span>
          </div>
          <div className='row info-detail'>
            <span className='row info-title'>
              <p>대</p>
              <p>리</p>
              <p>인</p>
              <p>코</p>
              <p>드</p>
            </span>
            <span className='colon'>:</span>
            <span className='info-content'>9-2010-001289-8</span>
          </div>
        </div>
        <h4 className='sub-title'>위임자</h4>
        <div className='column info-wrap'>
          <div className='row info'>
            <div className='row info-detail'>
              <span className='row info-title'>
                <p>성</p>
                <p>명</p>
              </span>
              <span className='colon'>:</span>
              <span className='info-content'>{name}</span>
            </div>
            <div className='row info-detail'>
              <span className='row info-title'>
                <p>특허고객번호</p>
              </span>
              <span className='colon'>:</span>
              <span className='info-content'>(추후 기입)</span>
            </div>
          </div>
          <div className='row info-one'>
            <div className='row info-detail'>
              <span className='row info-title'>
                <p>사건과의 관계</p>
              </span>
              <span className='colon'>:</span>
              <span className='info-content'>출원인</span>
            </div>
          </div>
        </div>
        <h4 className='sub-title'>사건의 표시</h4>
        <p className='event-mark'>
          상품명: {markName} / 상품류:{' '}
          {selectProductArr?.map(({ mark_class }, idx) => {
            return (
              <>
                {mark_class}류 {idx + 1 === selectProductArr?.length ? '' : '|'}{' '}
              </>
            );
          }, [])}
        </p>
        <h2 className='warrant-matters-title'>위임사항</h2>
        <div className='column warrant-matters'>
          <p>1. 출원에 관한 모든 절차</p>
          <p>2. 등록에 관한 모든 절차</p>
          <p>3. 이의 신청에 관한 모든 절차</p>
          <p>4. 심판 청구에 관한 모든 절차</p>
          <p>5. 기술평가 청구에 관한 모든 절차</p>
          <p>6. 출원의 변경, 포기, 취하</p>
          <p>7. 특허권의 존속기간의 연장등록 출원의 취하</p>
          <p>8. 청구 및 신청의 취하</p>
          <p>
            9. 특허법 제55조 제1항(실용신안법 제11조)의 규정에 의한 우선권
            주장이나 그 취하
          </p>
          <p>10. 대리인의 해임 및 변경</p>
          <p>11. 복대리인의 선임 및 해임</p>
          <p>
            12. 특허법 제132조의 3및 제132조의 4(실용신안법 제35조, 디자인법
            제72조, 상표법 제79조)의 규정에 의한 심판청구
          </p>
          <p>13. 상표의 마드리드 국제출원과 관련된 본국관청에 관한 모든 절차</p>
        </div>
        <div className='warrant-info column'>
          <span className='date'>
            {nowDate.getFullYear()}년 {nowDate.getMonth() + 1}월{' '}
            {nowDate.getDate()}일
          </span>
          <span>위와 같이 특허청장(특허심판원장, 심판장)에게 제출합니다.</span>
          <span>상표법 제8조의 규정에 의하여 위와 같이 위임합니다.</span>
        </div>
        <button
          className='agree-btn'
          onClick={() => {
            changeState(setApplicantInfo, 'delegation_consent', 'Y');
            setModal(false);
          }}>
          동의합니다
        </button>
      </div>
    </div>
  );
};

export default WarrantModal;
