import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonSelect from './CommonSelect';
import plus from 'image/yellowPlusIcon.svg';
import blackDownArrow from 'image/blackDownArrow.svg';
import blackUpArrow from 'image/blackUpArrow.svg';
import ProductInputModal from './modal/ProductInputModal';
import { getProducts } from 'js/agenciesAPI';
import { catchError } from 'js/commonFn';
import { selectSelfProduct } from 'js/selfAPI';
import RegiProductSearchModal from './modal/RegiProductSearchModal';

const CommonSelectProduct = ({
  page,
  selectProductArr,
  setSelectProductArr,
  selectClassList,
  selectProduct,
  setSelectProduct,
  selectSearchType,
  searchTxt,
  categoryCode,
  selectList,
  setSelectList,
  classArr,
  setClassArr,
  temp,
}) => {
  const [deleteList, setDeleteList] = useState([]);
  const [typeFilter, setTypeFilter] = useState('');
  const [modalType, setModalType] = useState('');
  const [modal, setModal] = useState(false);
  const [productArr, setProductArr] = useState([]);
  let prevent = false;

  const navigate = useNavigate();
  const getProductFn = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    let result;
    if (page === 'agencies') {
      result = await getProducts(
        selectSearchType,
        typeFilter[0] === '0'
          ? typeFilter.replaceAll('류', '').replaceAll('0', '')
          : typeFilter.replaceAll('류', ''),
        selectSearchType === 'direct' ? searchTxt : categoryCode
      );
    } else {
      const type = selectSearchType === 'direct' ? 'true' : 'false';

      result = await selectSelfProduct(
        typeFilter[0] === '0'
          ? typeFilter.replaceAll('류', '').replaceAll('0', '')
          : typeFilter.replaceAll('류', ''),
        type,
        searchTxt,
        categoryCode
      );
    }

    if (typeof result === 'object') {
      if (result?.data?.length === 0) {
        setProductArr([]);
        return alert(
          '상품 키워드에 해당하는 추천 상품이 존재하지 않습니다.\n직접 입력 또는 상품 검색을 통해\n해당 상품 키워드를 등록해 주세요.'
        );
      } else {
        setProductArr(result?.data?.product_name);
      }
    } else return catchError(result, navigate);
  };

  const changeSelectProductFn = () => {
    if (selectProductArr.length)
      setSelectProduct(prev => {
        let clone = [...prev];
        selectProductArr.forEach(obj => {
          if (obj.mark_class === typeFilter.replaceAll('류', '')) {
            clone = obj.register_product;
          }
        });
        return clone;
      });
    else setSelectProduct([]);
  };

  useEffect(() => {
    const arr = [];
    selectClassList?.sort()?.forEach(classNum => arr.push(`${classNum}류`));
    setClassArr(arr);
    setTypeFilter(arr[0]);
  }, [selectClassList]);

  useEffect(() => {
    if (!typeFilter) return;
    getProductFn();
    changeSelectProductFn();
    setSelectProductArr(prev => {
      const clone = [...prev];
      let bool = true;
      clone.forEach(obj => {
        if (obj.mark_class === typeFilter.replaceAll('류', ''))
          return (bool = false);
      });
      if (bool)
        clone.push({
          mark_class: typeFilter.replaceAll('류', ''),
          register_product: [],
        });
      return clone;
    });
  }, [typeFilter]);

  useEffect(() => {
    if (typeFilter === '' || !selectProduct.length) return;
    setSelectProductArr(prev => {
      let clone = [...prev];
      const found = selectProductArr.find(
        //= 클래스명 같은 obj 갖고오기
        obj => obj.mark_class === typeFilter.replaceAll('류', '')
      );
      found.register_product = selectProduct;
      let bool = true;
      clone.forEach(obj => {
        if (obj.mark_class === typeFilter.replaceAll('류', ''))
          return (bool = false);
      });
      if (bool) clone.push(found);
      return clone;
    });
  }, [selectProduct]);

  return (
    <>
      <div className='content-box'>
        <h3>등록상품 선택</h3>
        <hr />
        <div className='content'>
          <div className='row topBar'>
            <CommonSelect
              color='yellow'
              opt={classArr}
              selectVal={typeFilter}
              setSelectVal={setTypeFilter}
            />
            <div
              className='direct-input row'
              onClick={() => {
                setModalType('direct');
                setModal(true);
              }}>
              <span>직접입력</span>
              <img src={plus} alt='직접입력 팝업창열기' />
            </div>
          </div>
          <div className='row product-wrap'>
            {productArr?.map((product_name, idx) => {
              return (
                <span
                  key={idx + product_name}
                  className={`product ${
                    selectList?.includes(`${product_name}`) ? 'select' : ''
                  }`}
                  onClick={() =>
                    setSelectList(prev => {
                      const clone = [...prev];
                      if (clone.includes(product_name))
                        clone.splice(clone.indexOf(product_name), 1);
                      else clone.push(product_name);
                      return clone;
                    })
                  }>
                  {product_name}
                </span>
              );
            }, <></>)}
          </div>
          <div className='product-button-wrap row'>
            <button
              onClick={() => {
                const inter = selectList?.filter(i =>
                  selectProduct?.includes(i)
                );
                if (inter?.length >= 1) {
                  return alert('이미 등록 된 상품입니다.');
                }
                selectList?.forEach(i => {
                  setSelectProduct(prev => {
                    const clone = [...prev];
                    clone.push(i);
                    return clone;
                  });
                });
                setSelectList([]);
              }}>
              <img src={blackDownArrow} alt='아래 화살표' />
            </button>
            <button
              onClick={() => {
                setDeleteList([]);
                setSelectProductArr(prev => {
                  const clone = [...prev];
                  clone.forEach(({ register_product }) => {
                    deleteList.forEach(deleteProduct => {
                      register_product.forEach((product, idx) => {
                        if (deleteProduct === product) {
                          register_product.splice(idx, 1);
                        }
                      });
                    });
                  });
                  return clone;
                });
              }}>
              <img src={blackUpArrow} alt='위 화살표' />
            </button>
            <button
              onClick={() => {
                if (selectList?.length === 0 && productArr?.length === 0) {
                  return alert('선택할 추천 상품 키워드가 존재하지 않습니다.');
                }
                if (selectList.length === productArr.length) setSelectList([]);
                else setSelectList([...productArr]);
              }}>
              전체선택
            </button>
            <button
              onClick={() => {
                setSelectProduct([]);
                setSelectProductArr(prev => {
                  const clone = [...prev];
                  clone.forEach(obj => {
                    obj.register_product = [];
                  });
                  return clone;
                });
              }}>
              전체삭제
            </button>
            <button
              onClick={() => {
                setModalType('search');
                setModal(true);
              }}>
              상품검색
            </button>
          </div>
          {classArr?.map(cls => {
            return (
              <>
                <div className='row select-product-container'>
                  <span className='class-name'>{cls}</span>
                  <div className='row select-product-wrap'>
                    {selectProductArr?.map(
                      ({ mark_class, register_product }) => {
                        return mark_class === cls?.replaceAll('류', '')
                          ? register_product?.map(item => {
                              return (
                                <span
                                  className={`product ${
                                    deleteList?.includes(item) ? 'select' : ''
                                  }`}
                                  onClick={() =>
                                    setDeleteList(prev => {
                                      const clone = [...prev];
                                      if (clone.includes(item))
                                        clone.splice(clone.indexOf(item), 1);
                                      else clone.push(item);
                                      return clone;
                                    })
                                  }>
                                  {item}
                                </span>
                              );
                            })
                          : '';
                      }
                    )}
                  </div>
                </div>
              </>
            );
          }, <></>)}
        </div>
      </div>
      {modal && modalType === 'direct' && (
        <ProductInputModal
          setModal={setModal}
          modalType={modalType}
          setModalType={setModalType}
          selectProduct={selectProduct}
          setSelectProduct={setSelectProduct}
          selectList={selectList}
        />
      )}

      {modal && modalType === 'search' && (
        <RegiProductSearchModal
          typeFilter={typeFilter}
          page={page}
          setModal={setModal}
          setModalType={setModalType}
          selectProduct={selectProduct}
          setSelectProduct={setSelectProduct}
        />
      )}
    </>
  );
};

export default CommonSelectProduct;
