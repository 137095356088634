import { useEffect, useState } from 'react';
import { changeTitle } from 'js/commonFn';
import CommonSiteMap from 'components/CommonSiteMap';
import SelfIntroduction from 'components/Introduction/SelfIntroduction';
import AgencyIntroduction from 'components/Introduction/AgencyIntroduction';

const Introduction = () => {
  const [introTab, setIntroTab] = useState('self');

  useEffect(() => {
    changeTitle('마크픽 > 서비스 소개');
  }, []);

  return (
    <div className='service-intro-wrap'>
      <div className='content-wrap service-intro'>
        <CommonSiteMap title='서비스 소개' />
        <h2>서비스 소개</h2>
        <div className='intro-tab'>
          <div
            onClick={() => setIntroTab('self')}
            className={introTab === 'self' ? 'self-tab active' : 'self-tab'}>
            셀프 상표 등록 서비스
          </div>
          <div
            onClick={() => setIntroTab('agency')}
            className={
              introTab === 'agency' ? 'agency-tab active' : 'agency-tab'
            }>
            마크픽 상표 등록 대행
          </div>
        </div>

        {introTab === 'self' && <SelfIntroduction />}
        {introTab === 'agency' && <AgencyIntroduction />}
      </div>
    </div>
  );
};

export default Introduction;
