import { useState } from 'react';
import modalClose from 'image/Modal/modal-close.svg';

const ProductInputModal = ({
  setModal,
  modalType,
  setModalType,
  selectProduct,
  setSelectProduct,
  selectList,
}) => {
  const [input, setInput] = useState('');

  const directInputSave = () => {
    if (!selectProduct?.includes(input)) {
      setSelectProduct(prev => {
        const clone = [...prev];
        clone.push(input);
        return clone;
      });
      setInput('');
      setModal(false);
      setModalType('');
      selectList.forEach(i => {
        if (selectProduct?.includes(i))
          return alert('이미 등록 된 상품입니다.');
        setSelectProduct(prev => {
          const clone = [...prev];
          clone.push(i);
          return clone;
        });
      });
    } else return alert('이미 있는 상품입니다.');
  };

  return (
    <div className='modal-bg'>
      <div className='modal column'>
        <div className='head'>
          <h3>등록대상상품 {modalType === 'direct' ? '직접입력' : '검색'}</h3>
          <img
            src={modalClose}
            alt='직점검색창 닫기'
            onClick={() => setModal(false)}
            className='close-btn'
          />
        </div>
        <hr />
        <div className='row input-section'>
          <span>상품 :</span>
          <input
            type='text'
            className='common-text-input'
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                directInputSave();
              }
            }}
          />
        </div>
        <div className='btn-wrap row'>
          <button
            className='gradient-btn'
            onClick={() => {
              directInputSave();
            }}>
            저장
          </button>
          <button
            className='gray-btn'
            onClick={() => {
              setInput('');
              setModal(false);
              setModalType('');
            }}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductInputModal;
