const InquireCost = () => {
  return (
    <>
      <div className='column description-header'>
        <h3>상표 출원 이전, 미리 등록 가능성 여부를 조사할 때 발생하는 비용</h3>
      </div>
      <table>
        <thead>
          <tr>
            <th>유형</th>
            <th>합계(VAT포함)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>텍스트 상표</td>
            <td>55,000원</td>
          </tr>
          <tr>
            <td>도형(이미지)상표</td>
            <td>88,000원</td>
          </tr>
          <tr>
            <td>복합(텍스트+이미지)상표</td>
            <td>132,000원</td>
          </tr>
        </tbody>
      </table>
      <div className='cost-guide'>
        <h5>상표 등록 가능성 여부 조사 이후에는 비용 환불이 불가합니다</h5>
        <h5>
          등록 가능성 30% 이하인 경우는 동일 분류 내 1회 재조사가 가능합니다
        </h5>
      </div>
    </>
  );
};

export default InquireCost;
