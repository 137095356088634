import axios from 'axios';
import { apiErrorHandling } from './commonFn';
import { getCookie } from './cookie';

export const h1 = {
  'Content-Type': 'application/json',
  'access-token': getCookie('pickMyToken'),
};
export const h2 = {
  'Content-Type': 'application/json',
};

const header = () => ({
  headers: {
    'Content-Type': 'application/json',
    'access-token': getCookie('pickMyToken'),
  },
});

// ------------------------------------------------------------공지사항--------------------------------------------------------------
// 공지사항 리스트 조회 api
export const getNoticeList = async currentPage => {
  try {
    return await axios.get(`/v1/cs/notice?page=${currentPage}&limit=10`, {
      headers: h1,
    });
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

// 공지사항 상세보기 및 이전,다음 공지사항으로 이동 api
export const getChangeNoticeDetail = async noti_id => {
  try {
    return await axios.get(`/v1/cs/notice/between/${noti_id}`, { headers: h1 });
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
// ------------------------------------------------------------1:1 문의--------------------------------------------------------------
//# 1:1 문의 등록하기
export const createInquiry = async inquiryData => {
  const { title, context } = inquiryData;
  const query = {
    title,
    context,
  };
  try {
    return await axios.post(`/v1/cs/inquiry`, query, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 문의 사항 불러오기
export const getInquiryList = async (page, limit) => {
  try {
    return await axios.get(
      `/v1/my-page/cs/inquiry?page=${page}&limit=${limit}`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 문의 사항 상세내역 불러오기
export const getInquiryDetail = async inquiryId => {
  try {
    return await axios.get(`/v1/my-page/cs/inquiry/${inquiryId}`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 문의 사항 수정하기
export const inquiryEdit = async (inquiryId, titleInput, contextData) => {
  try {
    return await axios.put(
      `/v1/cs/inquiry/edit/${inquiryId}`,
      {
        title: titleInput,
        context: contextData,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

// ------------------------------------------------------------마이페이지--------------------------------------------------------------
//# 마이페이지 회원정보 불러오기
export const getUserInfo = async () => {
  try {
    return await axios.get(`/v1/my-page/auth/info`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 마이페이지 비밀번호 확인
export const checkPassword = async (userId, originPassword) => {
  try {
    return await axios.post(
      `/v1/auth/check/password`,
      {
        user_id: userId,
        origin_password: originPassword,
      },
      { headers: h2 }
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 마이페이지 이메일 변경
export const userEmailChange = async (userId, email, originPassword) => {
  try {
    return await axios.post(
      `/v1/my-page/auth/user/modify`,
      {
        user_id: userId,
        email: email,
        origin_password: originPassword,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 마이페이지 비밀번호 변경
export const userPwChange = async (userId, originPassword, newPassword) => {
  try {
    return await axios.post(
      `/v1/my-page/auth/user/modify`,
      {
        user_id: userId,
        password: newPassword,
        origin_password: originPassword,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 마이페이지 연락처 변경
export const userPhoneChange = async (imp_uid, user_id, phone) => {
  try {
    return await axios.post(
      `/v1/my-page/auth/user/modify/phone`,
      {
        imp_uid,
        user_id,
        phone,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 마이페이지 회원탈퇴
export const memberWithdrawal = async reason => {
  try {
    return await axios.post(
      `/v1/my-page/auth/user/delete`,
      {
        reason,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

// ------------------------------------------------------------셀프출원--------------------------------------------------------------
//# 셀프출원 임시저장 데이터 조회
export const getSelfTemp = async () => {
  try {
    return await axios.get(`/v1/self/temporary`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//# 셀프출원 템플릿 불러오기
export const getSelfInfoTemplete = async currentPage => {
  try {
    return await axios.get(
      `/v1/self/template?page=${currentPage}&limit=5`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 임시저장 데이터 삭제 (취소버튼 눌렀을때)
export const deleteSelfTemp = async tempData => {
  let config = {
    headers: h1,
    // data: { temp_ids: [tempData.id] },
  };
  try {
    return await axios.delete(`/v1/self/temporary`, config);
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 셀프 임시저장 Step1 tempId가 있을경우(임시저장값이 있을때) 와 없을경우 구분
export const createSelfInfomation = async (applicants, tempId) => {
  try {
    if (tempId) {
      return await axios.post(
        `/v1/self/information?temp_id=${tempId}`,
        applicants,
        header()
      );
    } else {
      return await axios.post(`/v1/self/information`, applicants, header());
    }
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 셀프출원 Step2 임시저장하기
export const selectSelfMarkFunc = async (
  { applicationType, fileName, imgUrl, markNameArr, tempData },
  tempResult
) => {
  try {
    if (fileName && imgUrl) {
      return await axios.put(
        `/v1/self/mark?temp_id=${tempResult?.temp_id}`,
        {
          mark_type: applicationType,
          image_filename: fileName,
          image_url: imgUrl,
        },
        header()
      );
    } else {
      const convertedData = [];
      markNameArr.forEach(item => {
        convertedData.push({
          mark_name_step: item,
        });
      });
      return await axios.put(
        `/v1/self/mark?temp_id=${tempResult?.temp_id}`,
        {
          mark_type: applicationType,
          mark_name_steps: [...convertedData],
        },
        header()
      );
    }
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//# 셀프출원 상품분류 선택입력 시 카테고리에 따라 보여줄 상품분류 선택 리스트
export const getSelectSelfSub = async list => {
  try {
    return await axios.get(
      `/v1/self/category/select/sub?category_code=${list}`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//# 셀프출원 카테고리가져오기 step3
export const getSelectCategory = async () => {
  try {
    return await axios.get(`/v1/self/category/select/main`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//# 셀프출원 상품류 불러오기 step3
export const getSelfCategoryDirect = async searchTxt => {
  try {
    return await axios.get(
      `/v1/self/category/direct?input_text=${searchTxt}`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 셀프출원 step3 임시저장
export const selectSelfCategoryFunc = async (
  {
    selectSearchType,
    selectCategoryList,
    selectClassList,
    categoryName,
    tempData,
    searchTxt,
  },
  tempResult
) => {
  try {
    const convertedData = [];
    selectClassList.forEach(item => {
      convertedData.push({
        mark_class: item,
      });
    });

    if (selectSearchType === 'select') {
      return await axios.put(
        `/v1/self/category?temp_id=${tempResult?.temp_id}`,
        {
          input_type: '선택입력',
          category: selectCategoryList,
          mark_classes: [...convertedData],
        },
        header()
      );
    } else {
      return await axios.put(
        `/v1/self/category?temp_id=${tempResult?.temp_id}`,
        {
          input_type: '직접입력',
          input_keyword: searchTxt,
          mark_classes: [...convertedData],
        },
        header()
      );
    }
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# step4 상품분류에 따른 등록상품 조회(자사)
export const selectSelfProduct = async (classNum, direct, input, type) => {
  try {
    if (!classNum) return;
    let url = `/v1/self/product?mark_class=${classNum}&direct=${direct}`;
    if (input) {
      url += `&search_text=${input}`;
    } else {
      url += `&category_code=${type}`;
    }

    return axios.get(url, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# step4 등록대상상품 검색 (특허로)
export const selectSelfSearch = async (search, number) => {
  try {
    return axios.get(
      `/v1/self/product?search_text=${search}&mark_class=${number}`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# step4 등록상품 선택 임시저장
export const selectSelfProductSave = async (stepData, tempResult) => {
  try {
    return axios.put(
      `/v1/self/product?temp_id=${tempResult?.temp_id}`,
      stepData?.selectProductArr,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

// //# 셀프출원 XML 다운로드
export const downloadSelfFunc = async (
  tempData,
  updatedApplicants,
  selectProductArr,
  applicationType,
  fileName,
  selectSearchType,
  searchTxt,
  categoryCode,
  img,
  markNameArr
) => {
  try {
    let data = {
      applicants: [...updatedApplicants],
      mark_type: applicationType,
      input_type: selectSearchType === 'direct' ? '직접입력' : '선택입력',
      mark_classes: [...selectProductArr],
    };

    if (searchTxt) {
      data.input_keyword = searchTxt;
    }

    if (categoryCode) {
      data.category = categoryCode;
    }

    if (markNameArr?.length > 0 && applicationType === 'text') {
      const result = markNameArr.map(item => ({ mark_name_step: item }));
      data.mark_name_steps = result;
    }

    return await axios.post(`/v1/self/download`, data, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 셀프출원 상표이미지 업로드
export const getUploadFile = async formData => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    'access-token': getCookie('pickMyToken'),
  };

  try {
    return await axios.post(`/v1/self/upload-file`, formData, { headers });
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 셀프출원 템플릿 임시 저장여부
export const saveTemplate = async (
  tempData,
  updatedApplicants,
  selectProductArr,
  applicationType,
  fileName,
  selectSearchType,
  searchTxt,
  categoryCode,
  img,
  markNameArr,
  templateInput,
  imgUrl,
  type
) => {
  let data = {
    applicants: [...updatedApplicants],
    mark_type: applicationType,
    input_type: selectSearchType === 'direct' ? '직접입력' : '선택입력',
    mark_classes: [...selectProductArr],
    template_name: templateInput,
  };

  if (searchTxt) {
    data.input_keyword = searchTxt;
  }

  if (categoryCode) {
    data.category = categoryCode;
  }

  if (fileName) {
    data.image_filename = fileName;
    if (img) {
      data.image_url = imgUrl;
    }
  }
  if (markNameArr?.length > 0 && applicationType === 'text') {
    const result = markNameArr.map(item => ({ mark_name_step: item }));
    data.mark_name_steps = result;
  }

  if (type === 'existing') {
    data.temp_id = tempData?.id;
  }

  try {
    return await axios.post(`/v1/self/save`, data, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 셀프출원 템플릿 삭제
export const templateDel = async id => {
  let config = {
    headers: h1,
    data: { temp_ids: [id] },
  };
  try {
    return await axios.delete(`/v1/my-page/self/template`, config);
  } catch (error) {
    return apiErrorHandling(error);
  }
};
//@ 마이페이지 은행 리스트 조회
export const getBankList = async () => {
  try {
    return await axios.get(`/v1/my-page/order/bank`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//@ 마이페이지 환불계좌정보 등록
export const createRefundAccount = async (holder, account, bank) => {
  try {
    return await axios.post(
      `/v1/my-page/order/account`,
      {
        refund_holder: holder,
        refund_account: account,
        refund_bank: bank,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//@ 마이페이지 환불계좌정보 조회
export const getInquiry = async () => {
  try {
    return await axios.get(`/v1/my-page/order/account`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//@ 환불계좌정보 수정
export const editRefundAccount = async (id, holder, account, bank) => {
  try {
    return await axios.put(
      `/v1/my-page/order/account?id=${id}`,
      {
        refund_holder: holder,
        refund_account: account,
        refund_bank: bank,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//@ 마이페이지 특허고객번호 저장
export const patentNumberCreate = async num => {
  try {
    return await axios.post(
      `/v1/my-page/self/applicant-code`,
      {
        applicant_code: num,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//@ 마이페이지 출원번호 저장
export const appNumberCreate = async num => {
  try {
    return await axios.post(
      `/v1/my-page/self/application-number`,
      {
        application_number: num,
      },
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//@ 마이페이지 출원 정보 목록(특허고객번호,출원번호)
export const getAppList = async () => {
  try {
    return await axios.get(`/v1/my-page/self/application`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//@ 마이페이지 특허고객번호 삭제 미완성
export const deleteApplicantNum = async num => {
  let config = {
    headers: h1,
    data: { applications: [num] },
  };
  try {
    return await axios.delete(`/v1/my-page/self/applicant-code`, config);
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
//@ 마이페이지 출원번호 삭제 미완성
export const deleteAppNum = async num => {
  let config = {
    headers: h1,
    data: { applications: [num] },
  };
  try {
    return await axios.delete(`/v1/my-page/self/application-number`, config);
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//@ 마이페이지 특허고객번호별 출원상표 내역
export const applicantListFunc = async list => {
  const queryString = list.map(item => `applicant_codes=${item}`).join('&');
  try {
    return await axios.get(
      `/v1/my-page/self/applicant-code?limit=5&${queryString}`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//@ 출원번호별 출원상표 내역
export const appNumListFunc = async list => {
  const queryString = list.map(item => `application_numbers=${item}`).join('&');
  try {
    return await axios.get(
      `/v1/my-page/self/application-number?limit=10&${queryString}`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//@ 마이페이지 상표등록대행 내역
export const getAgencyList = async page => {
  try {
    return axios.get(`/v1/my-page/agency/list?page=${page}&limit=5`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//@ 마이페이지 상표등록대행 추가 결제 필요 내역
export const getAdditionalOrder = async () => {
  try {
    return axios.get(`/v1/my-page/agency/additional-order`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//@ 상표등록대행 출원 상표 내역
export const getApplicantList = async page => {
  try {
    return axios.get(
      `/v1/my-page/agency/application-info?page=${page}`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//@ 마이페이지 출원대행 상세정보 페이지
export const getAgentDetail = async id => {
  try {
    return axios.get(
      `/v1/my-page/agency/detail?apply_agent_pk=${id}`,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//@ 마이페이지 셀프출원 관리 상세조회
export const getSelfDetail = async id => {
  try {
    return axios.get(`/v1/my-page/self/template/${id}`, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 셀프출원 XML 다운로드
export const myPageXmlDown = async (
  data,
  updatedApplicants,
  markNameArr,
  mark_class_data
) => {
  try {
    let selfData = {
      applicants: [...updatedApplicants],
      mark_type: data?.mark_type?.toLowerCase(),
      input_type: data?.input_type,
      mark_classes: [...mark_class_data],
    };

    if (data?.input_keyword !== '') {
      selfData.input_keyword = data?.input_keyword;
    }

    if (data?.category !== '') {
      selfData.category = data?.category;
    }

    if (markNameArr?.length > 0 && data?.mark_type === 'TEXT') {
      selfData.mark_name_steps = markNameArr;
    }

    return await axios.post(`/v1/self/download`, selfData, header());
  } catch (error) {
    return await apiErrorHandling(error);
  }
};

//# 마이페이지 agent 상세페이지 건별 주문내역 보기
export const getAgentMyOrder = async id => {
  try {
    return await axios.post(
      `/v1/order/info/${id}?service=agency`,
      null,
      header()
    );
  } catch (error) {
    return await apiErrorHandling(error);
  }
};
