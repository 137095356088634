import React from 'react';

const ApplyCost = () => {
  return (
    <>
      <div className='column description-header'>
        <h3>
          상표 등록은 특허청에 심사를 신청하는 출원 시와 심사 통과 후 등록 결정
          시에 두 번으로 나누어 비용이 발생합니다
        </h3>
      </div>
      <table className='apply-table'>
        <thead>
          <tr>
            <th>유형</th>
            <th>당사 수수료</th>
            <th>기간</th>
            <th>특허청 관납료</th>
            <th>합계(VAT포함)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2} className='row-span'>
              당사 출원고객
            </td>
            <td>50,000원(VAT 10% 별도)</td>
            <td>5년</td>
            <td>141,120원</td>
            <td>196,120원</td>
          </tr>
          <tr>
            <td>50,000원(VAT 10% 별도)</td>
            <td>10년</td>
            <td>220,120원</td>
            <td>275,120원</td>
          </tr>
        </tbody>
      </table>
      <div className='cost-guide'>
        <h5>
          상기 비용은 1개 상표, 1개 분류에 한 명의 권리자 기준의 비용으로
          공동출원, 미성년자, 외국인, 국가기관의 경우 추가요금이 발생합니다
        </h5>
      </div>
    </>
  );
};

export default ApplyCost;
