const FinalViewData = ({ name, infoData }) => {
  return (
    <>
      <div className='info-wrap'>
        <p>{name}</p>
        <div>{infoData}</div>
      </div>
    </>
  );
};

export default FinalViewData;
