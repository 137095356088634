import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import close from 'image/Modal/modal-close.svg';
import CommonPagination from 'components/CommonPagination';
import { getSelfInfoTemplete } from 'js/selfAPI';
import { tempDataBinding } from 'js/commonFn';
import { catchError } from 'js/commonFn';

const ImportTemp = ({
  setApplicants,
  tempData,
  setMarkNameArr,
  setApplicationType,
  setFileName,
  setImg,
  setTemp,
  setSelectSearchType,
  setSearchTxt,
  setSelectCategoryList,
  setSelectClassList,
  setCategoryCode,
  setImgUrl,
  setSelectProduct,
  setSelectList,
  setSelectProductArr,
  setStep,
  applicants,
  stepOneSave,
  setTempData,
  setTempleteModal,
  setImportTemp,
}) => {
  const [templeteData, setTempleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    total_count: 0,
    total_page: 0,
    offset: 0,
    limit: 5,
  });
  const [selectedIdx, setSelectedIdx] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // 모달 창이 열릴 때, body 요소의 스크롤을 막습니다.
    document.body.style.overflow = 'hidden';

    // 모달 창이 닫힐 때, 스크롤을 다시 허용합니다.
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const tempDataProps = {
    setApplicants,
    tempData,
    setMarkNameArr,
    setApplicationType,
    setFileName,
    setImg,
    setTemp,
    setSelectSearchType,
    setSearchTxt,
    setSelectCategoryList,
    setSelectClassList,
    setCategoryCode,
    setImgUrl,
    setSelectProduct,
    setSelectList,
    setSelectProductArr,
    setStep,
    applicants,
    stepOneSave,
    setTempData,
    setImportTemp,
    setTempleteModal,
  };

  const getTemplete = async () => {
    const result = await getSelfInfoTemplete(currentPage);
    if (typeof result === 'object') {
      setTempleteData(result?.data?.data);
      setPageInfo(result?.data?.meta);
    } else return catchError(result, navigate);
  };

  const handleSelectItem = (item, idx) => {
    if (selectedIdx === idx) {
      setSelectedIdx(null);
      setTempData(null);
    } else {
      setSelectedIdx(idx);
      setTempData(item);
    }
  };

  const selectFunc = () => {
    tempDataBinding({ ...tempDataProps });
    setImportTemp(true);
    setTempleteModal(false);
  };

  useEffect(() => {
    getTemplete();
  }, [currentPage]);

  return (
    <div className='modal-overlay temp'>
      <div className='modal temp-modal import-modal'>
        <div className='head'>
          <p>템플릿 불러오기</p>
          <button onClick={() => setTempleteModal(false)}>
            <img src={close} alt='임시저장 모달창 닫기 버튼' />
          </button>
        </div>
        <hr />
        <ul className='temp-list'>
          <li className='temp-list-title'>템플릿명</li>

          {templeteData?.length !== 0 ? (
            templeteData?.map((item, idx) => (
              <>
                <li
                  key={idx}
                  onClick={() => handleSelectItem(item, idx)}
                  className={selectedIdx === idx ? 'active' : ''}>
                  {item?.template_name}
                </li>
              </>
            ))
          ) : (
            <li>템플릿이 존재하지 않습니다. </li>
          )}
        </ul>

        <CommonPagination
          // postsPerPage={pageInfo?.limit}
          postsPerPage={5}
          viewPageNum={5}
          totalPosts={pageInfo?.total_count}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <hr className='bottom-line' />
        <div className='temp-btn-wrap import-wrap'>
          <button
            className='select-btn'
            onClick={() => {
              selectFunc();
            }}>
            선택
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImportTemp;
