const OpinionNoticeCost = () => {
  return (
    <>
      <div className='column description-header'>
        <h3>
          특허청에 상표 등록 심사에 실패 시, 변리사의 대응이 필요한 경우
          발생하는 비용
        </h3>
      </div>
      <table className='opinion-notice-table'>
        <thead>
          <tr>
            <th>유형</th>
            <th>서비스명</th>
            <th>합계(VAT포함)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3}>당사 출원고객</td>
            <td>일반 의견 대응(보정)</td>
            <td>110,000원</td>
          </tr>
          <tr>
            <td>전문 의견 대응</td>
            <td>220,000원</td>
          </tr>
          <tr>
            <td>특수 대응</td>
            <td>330,000원</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default OpinionNoticeCost;
