import { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { changeTitle } from 'js/commonFn';
//= 이미지
import selfApplicationIcon from 'image/selfApplicationIcon.svg';
import selfApplicationGraphic from 'image/selfApplicationGraphic.png';
import applicationIcon from 'image/applicationIcon.svg';
import applicationGraphic from 'image/applicationGraphic.png';
import rightArrow from 'image/rightArrow.svg';
import cardGraphic01 from 'image/applicationGraphic01.png';
import cardGraphic02 from 'image/applicationGraphic02.png';
import cardGraphic03 from 'image/applicationGraphic03.png';
import cardGraphic04 from 'image/applicationGraphic04.png';
import checkIcon from 'image/yellowCheckIcon.svg';
import applicationProgress from 'image/progressGraphic.svg';
import selfBoxGraphic from 'image/selfBoxGraphic.png';
import agenciesBoxGraphic from 'image/agenciesBoxGraphic.png';
import blackRightArrow from 'image/blackRightArrow.svg';
import playBtn from 'image/playBtn.svg';
import pauseBtn from 'image/pauseBtn.svg';

const MarkPickHome = () => {
  const [slideBtn, setSlideBtn] = useState(false);
  const [inquireCost, setInquireCost] = useState('text');
  const [shortenInquireCost, setShortenInquireCost] = useState('text');
  const navigate = useNavigate();

  const swiperRef = useRef();

  const slideBtnControl = type => {
    if (type === 'stop') {
      swiperRef.current.swiper.autoplay.stop();
      setSlideBtn(true);
      $('.progress').removeClass('animate');
    } else {
      swiperRef.current.swiper.autoplay.start();
      setSlideBtn(false);
      $('.progress').addClass('animate');
    }
  };

  const pagination = {
    type: 'custom',
    renderCustom: function (s, current, total) {
      return `<div class='row progress-wrap'><span class="middle">0${
        current % 2 === 0 ? 2 : current % 2
      }</span><div class="progressBar"><div class='progress'></div></div><span class="middle">0${
        total / 2
      }</span></div>`;
    },
  };

  const handleDownload = () => {
    const files = [
      '/files/이용가이드-상표등록대행.pdf',
      '/files/이용가이드-셀프 상표등록(특허고객발급 프로세스).pdf',
      '/files/이용가이드-셀프 상표등록(특허청 상표등록 프로세스).pdf',
    ];
    files.forEach(file => {
      var link = document.createElement('a');
      link.href = file;
      link.download = file.split('/').pop();
      link.click();
    });
  };

  useEffect(() => {
    changeTitle('상표 출원 서비스 마크픽');
    //= useEffect에 그냥 jQuery 쓰면 안 먹어서 setTimeout으로 핸들링
    setTimeout(() => {
      $('.progress').addClass('animate');
    }, 0);
  }, []);

  return (
    <div className='content-wrap pick-home'>
      <Swiper
        ref={swiperRef}
        loop={true}
        pagination={pagination}
        autoplay={{ delay: 8000, disableOnInteraction: false }}
        speed={2000}
        modules={[Navigation, Autoplay, Pagination]}
        navigation={true}
        slidesPerView={'auto'}
        centeredSlides={true}
        spaceBetween={24}
        allowTouchMove={false}
        onSlideChangeTransitionStart={() =>
          $('.progress').removeClass('animate')
        }
        onSlideChangeTransitionEnd={() => $('.progress').addClass('animate')}
        effect={'flip'}>
        {new Array(2).fill('').map(() => {
          return (
            <>
              <SwiperSlide>
                <div className='card a-card row'>
                  <div className='column'>
                    <h3>상표 셀프 출원 서비스</h3>
                    <h4>
                      전문가 도움 없이
                      <br />
                      <strong>스스로 상표 출원</strong>
                    </h4>
                    <span>
                      온라인으로 간편하게 신청하고 특허청에서 직접 상표 등록
                    </span>
                    <div className='btn-wrap row'>
                      <button
                        className='row common-btn'
                        onClick={() => navigate('/self-application')}>
                        <img src={selfApplicationIcon} alt='셀프 출원 아이콘' />
                        <span>셀프 출원하기</span>
                        <img src={rightArrow} alt='오른쪽 화살표 아이콘' />
                      </button>
                      <button
                        className='border-btn'
                        onClick={() => navigate('/agencies')}>
                        <span>상표 등록 신청</span>
                        <i></i>
                      </button>
                    </div>
                  </div>
                  <img src={selfApplicationGraphic} alt='셀프 출원 그래픽' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='card b-card row'>
                  <div className='column'>
                    <h3>상표 등록 대행 서비스</h3>
                    <h4>
                      전문가에게 의뢰하여
                      <br />
                      <strong>시행착오 없이 상표 출원</strong>
                    </h4>
                    <span>
                      온라인으로 간편하게 신청하고 전문가 상담 후, 상표 출원
                    </span>
                    <div className='btn-wrap row'>
                      <button
                        className='row border-btn'
                        onClick={() => navigate('/self-application')}>
                        <span>셀프 출원하기</span>
                        <i></i>
                      </button>
                      <button
                        className='common-btn'
                        onClick={() => navigate('/agencies')}>
                        <img src={applicationIcon} alt='셀프 출원 아이콘' />
                        <span>상표 등록 신청</span>
                        <img src={rightArrow} alt='오른쪽 화살표 아이콘' />
                      </button>
                    </div>
                  </div>
                  <img src={applicationGraphic} alt='셀프 출원 그래픽' />
                </div>
              </SwiperSlide>
            </>
          );
        })}
        {!slideBtn ? (
          <button
            onClick={() => slideBtnControl('stop')}
            className='slide-control-btn'>
            <img src={pauseBtn} alt='정지 버튼' />
          </button>
        ) : (
          <button
            onClick={() => slideBtnControl('start')}
            className='slide-control-btn'>
            <img src={playBtn} alt='재생 버튼' />
          </button>
        )}
      </Swiper>
      <h2 className='application-home-title'>
        상표 등록,
        <br />왜 해야 할까요?
      </h2>
      <div className='row application-card-wrap'>
        <div className='column'>
          <h5>상표 침해 방지</h5>
          <p>
            소중한 내 브랜드,
            <br />
            나도 모르게 빼앗길 수 없죠.
          </p>
          <img src={cardGraphic01} alt='상표 침해 방지 그래픽' />
        </div>
        <div className='column'>
          <h5>도용 방지</h5>
          <p>
            유사 상표를
            <br />
            제재할 수 있어요.
          </p>
          <img src={cardGraphic02} alt='도용 방지 그래픽' />
        </div>
        <div className='column'>
          <h5>온라인 진출</h5>
          <p>
            점점 성장할 내 브랜드,
            <br />
            안심하고 노출시킬 수 있어요.
          </p>
          <img src={cardGraphic03} alt='온라인 진출 그래픽' />
        </div>
        <div className='column'>
          <h5>소중한 내 브랜드</h5>
          <p>
            든든하게, 안전하게
            <br />내 브랜드를 완성할 수 있어요.
          </p>
          <img src={cardGraphic04} alt='소중한 내 브랜드 그래픽' />
        </div>
      </div>
      <div className='yellow-area column'>
        <span>마크픽은</span>
        <strong>누구나 상표권을 보호 받을 수 있도록</strong>
        <span>상표 등록의 문턱을 낮추겠습니다.</span>
      </div>
      <h2 className='application-home-title'>
        내가 원하는 출원 서비스만
        <br />
        찾아서 선택하세요!
      </h2>
      <div className='cost-card-wrap row'>
        <div className='column'>
          <div className='topBar row'>
            <h5>일반 출원</h5>
          </div>
          <div className='el-wrap row'>
            <span className='basic-el'>BASIC</span>
          </div>
          <h1>￦ 111,000</h1>
          <p>
            부가세 및 특허청 관납료가
            <br />
            포함된 가격입니다
          </p>
          <button onClick={() => navigate('/cost')}>자세히 보기</button>
          <hr />
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>특허 법인 변리사 선임</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>출원 상담 및 상표 관리</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>상표 권리 범위 설정</span>
          </div>
          <div className='bottom-content'>
            <p>기본적인 출원 서비스입니다.</p>
          </div>
        </div>
        <div className='column'>
          <div className='topBar row'>
            <h5>조사 출원</h5>
            <div className='select-type row'>
              <span
                className={inquireCost === 'text' ? 'active' : ''}
                onMouseEnter={() => setInquireCost('text')}>
                텍스트
              </span>
              <span
                className={inquireCost === 'figure' ? 'active' : ''}
                onMouseEnter={() => setInquireCost('figure')}>
                도형
              </span>
              <span
                className={inquireCost === 'compound' ? 'active' : ''}
                onMouseEnter={() => setInquireCost('compound')}>
                복합
              </span>
            </div>
          </div>
          <div className='el-wrap row'>
            <span className='normal-el'>일반 출원 +</span>
            <span className='inquire-el'>등록 가능성 조사</span>
          </div>
          <h1>
            ￦{' '}
            {inquireCost === 'text'
              ? '166,000'
              : inquireCost === 'figure'
              ? '199,000'
              : '243,000'}
          </h1>
          <p>
            부가세 및 특허청 관납료가
            <br />
            포함된 가격입니다
          </p>
          <button onClick={() => navigate('/cost')}>자세히 보기</button>
          <hr />
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>특허 법인 변리사 선임</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>출원 상담 및 상표 관리</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>상표 권리 범위 설정</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>등록 가능성 조사</span>
          </div>
          <div className='bottom-content'>
            <p>
              출원 등록 전,{' '}
              <strong>등록 가능성이 높은 것으로 판단된 상표인지 조사</strong>
              하여 확인합니다.
            </p>
          </div>
        </div>
        <div className='column'>
          <div className='topBar row'>
            <h5>신속 출원</h5>
          </div>
          <div className='el-wrap row'>
            <span className='normal-el'>일반 출원 +</span>
            <span className='shorten-el'>심사기간 12개월 단축</span>
          </div>
          <h1>￦ 276,000</h1>
          <p>
            부가세 및 특허청 관납료가
            <br />
            포함된 가격입니다
          </p>
          <button onClick={() => navigate('/cost')}>자세히 보기</button>
          <hr />
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>특허 법인 변리사 선임</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>출원 상담 및 상표 관리</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>상표 권리 범위 설정</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>심사 기간 12개월 단축</span>
          </div>
          <div className='bottom-content'>
            <p>
              일반 심사 기간보다 심사 기간을 단축시켜{' '}
              <strong>빠르게 출원등록이 가능</strong>합니다.
            </p>
          </div>
        </div>
        <div className='column'>
          <div className='topBar row'>
            <h5>신속조사 출원</h5>
            <div className='select-type row'>
              <span
                className={shortenInquireCost === 'text' ? 'active' : ''}
                onMouseEnter={() => setShortenInquireCost('text')}>
                텍스트
              </span>
              <span
                className={shortenInquireCost === 'figure' ? 'active' : ''}
                onMouseEnter={() => setShortenInquireCost('figure')}>
                도형
              </span>
              <span
                className={shortenInquireCost === 'compound' ? 'active' : ''}
                onMouseEnter={() => setShortenInquireCost('compound')}>
                복합
              </span>
            </div>
          </div>
          <div className='el-wrap row'>
            <span className='normal-el'>조사 출원 +</span>
            <span className='shorten-el'>심사기간 12개월 단축</span>
          </div>
          <h1>
            ￦{' '}
            {shortenInquireCost === 'text'
              ? '491,000'
              : shortenInquireCost === 'figure'
              ? '524,000'
              : '568,000'}
          </h1>
          <p>
            부가세 및 특허청 관납료가
            <br />
            포함된 가격입니다
          </p>
          <button onClick={() => navigate('/cost')}>자세히 보기</button>
          <hr />
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>특허 법인 변리사 선임</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>출원 상담 및 상표 관리</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>상표 권리 범위 설정</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>등록 가능성 조사</span>
          </div>
          <div className='row benefit'>
            <img src={checkIcon} alt='체크 아이콘' />
            <span>심사 기간 12개월 단축</span>
          </div>
          <div className='bottom-content'>
            <p>
              등록 가능성이 높은 상표인지 출원 전 확인 후 심사 기간은 단축시켜{' '}
              <strong>정확하고 빠르게 출원 등록을 진행합니다.</strong>
            </p>
          </div>
        </div>
      </div>
      <div className='progress-content column'>
        <h2 className='application-home-title'>
          내가 신청한 상표 등록,
          <br />
          특허청에서 이렇게 진행됩니다!
        </h2>
        <img src={applicationProgress} alt='등록 절차 소개 이미지' />
      </div>
      <div className='column final-title-wrap'>
        <h6>복잡했던 상표 출원,</h6>
        <h5>마크픽과 시작해 보세요!</h5>
      </div>
      <div className='column box-wrap'>
        <div className='row'>
          <div className='self-box column'>
            <h4>셀프 상표 출원 서비스</h4>
            <span>
              전문가 도움 없이 온라인 간편 신청하고
              <br />
              특허청에서 직접 상표 등록
            </span>
            <div
              className='row direct-btn'
              onClick={() => navigate('/self-application')}>
              <span>바로가기</span>
              <img src={rightArrow} alt='오른쪽 화살표 아이콘' />
            </div>
            <img src={selfBoxGraphic} alt='셀프 상표 출원 서비스 이미지' />
          </div>
          <div className='agencies-box column'>
            <h4>상표 등록 대행 서비스</h4>
            <span>
              전문가에게 의뢰하여 시행착오 없이
              <br />
              상담 후 상표 출원 진행
            </span>
            <div
              className='row direct-btn'
              onClick={() => navigate('/agencies')}>
              <span>바로가기</span>
              <img src={rightArrow} alt='오른쪽 화살표 아이콘' />
            </div>
            <img src={agenciesBoxGraphic} alt='상표 등록 대행 서비스 이미지' />
          </div>
        </div>
        <div className='column guide-box'>
          <p>
            마크픽에서 제공하는 이용 가이드를 참고하여 출원 신청에 필요한
            <br />
            당사 홈페이지와 특허청 홈페이지를 쉽게 이용하실 수 있습니다.
          </p>
          <div
            className='row go-guide'
            onClick={() => {
              handleDownload();
            }}>
            <span>이용 가이드 보기</span>
            <img src={blackRightArrow} alt='오른쪽 화살표 아이콘' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkPickHome;
