const IntroTitle = ({ title }) => {
  return (
    <div className='title-wrap'>
      <div className='title'>
        <span>{title}</span>
        <div className='circle'></div>
      </div>
    </div>
  );
};

export default IntroTitle;
